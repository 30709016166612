import CookieIcon from '@mui/icons-material/Cookie'
import { IconButton, Tooltip } from '@mui/material'
import { useDispatch } from 'react-redux'

import { appearanceCreators } from '../actions/creators'

export default function CookiesButton({ iconStyle, buttonStyle }) {
    const dispatch = useDispatch()

    function openCookies() {
        dispatch(appearanceCreators.toggleCookieConsent())
        // Todo: create log
        // logEvent('OPEN_SETTINGS')
    }

    if (window.TADA_APIS.DISABLE_COOKIES) return null

    return (
        <Tooltip title="Cookie Settings">
            <IconButton sx={buttonStyle} onClick={openCookies}>
                <CookieIcon sx={iconStyle} />
            </IconButton>
        </Tooltip>
    )
}
