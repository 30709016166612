import { Chip, Tooltip } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { useDrag, useDrop } from 'react-dnd'

import { PropertyType } from 'genesis-suite/types/architectureTypes'
import { AggregateIconPicker } from 'genesis-suite/components'
import { Aggregation } from 'genesis-suite/types/visualTypes'
import { DisplayProperty, DragField } from './builderTypes'

interface Props {
    property: DisplayProperty
    onClick?: () => void
    onAggregationChange?: (value: Aggregation) => void
    onRemove?: () => void
    /** (optional) for dragging a property before or in place of this property */
    onAdd?: (field: DragField) => void
    /** (optional) if adding new property, properties that can be accepted */
    accept?: PropertyType[]
    /** (optional) if adding new property, show placeholder before this property */
    showPlaceholder?: boolean
}

export default function PropertyChip(props: Props) {
    // waiting to fetch properties
    if (!props.property?.type) return null

    return <PropertyChipRender {...props} />
}

const useStyles = makeStyles(({ palette, spacing }) => ({
    chip: {
        marginBottom: spacing(0.5),
        maxWidth: '100%',
        ['& .MuiChip-label']: {
            fontSize: '0.9rem',
        },
        ['&:hover']: {
            backgroundColor: palette.grayscale.light,
            border: `2px solid ${palette.grayscale.light}`,
            color: 'white',
        },
        ['&:hover .MuiChip-deleteIcon']: { color: 'white' },
    },
    aggregationButton: {
        padding: 0,
        margin: '2px',
        background: '#fff',
        '&:hover': { background: 'white' },
    },
    noAggregationIcon: {
        margin: '2px',
        background: '#fff',
        borderRadius: '10px',
    },
    placeholder: {
        height: '5px',
        borderRadius: '2px',
        background: palette.primary.main,
        marginBottom: '5px',
    },
}))

function PropertyChipRender({
    property,
    accept = [],
    showPlaceholder,
    onAdd,
    onClick,
    onAggregationChange,
    onRemove,
}: Props) {
    const { type, field, aggregation, selected, pointer, title, color = 'tada.teal', service } = property

    const classes = useStyles()

    const [{ isDragging }, drag] = useDrag({
        type,
        item: { type: type ?? '', pointer, ...field } as DragField,
        collect: monitor => ({ isDragging: monitor.isDragging() }),
    })

    const [{ isOver }, drop] = useDrop({
        accept,
        drop: onAdd,
        collect: monitor => ({
            isOver: monitor.isOver() && monitor.canDrop(),
        }),
    })

    const tooltipTitle = service ? (
        <div>
            {`Insight: ${service.name}`} <br /> {`Property: ${title}`}
        </div>
    ) : (
        <div>{`Property: ${title}`}</div>
    )

    const filledChip = {
        backgroundColor: color,
        border: `2px solid ${color}`,
        color: 'white',
        ['& .MuiChip-deleteIcon']: { color: 'white' },
    }

    const outlinedChip = {
        backgroundColor: 'white',
        border: `2px solid ${color}`,
        color,
        ['& .MuiChip-deleteIcon']: { color },
    }

    function handleRemove(e) {
        e.stopPropagation()
        onRemove()
    }

    return (
        <div ref={node => drop(drag(node))}>
            <div className={classes.placeholder} style={{ display: isOver && showPlaceholder ? 'inherit' : 'none' }} />
            <Tooltip title={tooltipTitle} placement="right">
                <Chip
                    className={classes.chip}
                    style={{ display: isDragging ? 'none' : 'inline-flex' }}
                    sx={selected ? filledChip : outlinedChip}
                    size="small"
                    label={title}
                    onClick={onClick}
                    onDelete={onRemove ? handleRemove : undefined}
                    icon={
                        onAggregationChange ? (
                            <AggregateIconPicker
                                buttonProps={{ className: classes.aggregationButton }}
                                value={aggregation}
                                onChange={onAggregationChange}
                            />
                        ) : undefined
                    }
                />
            </Tooltip>
        </div>
    )
}
