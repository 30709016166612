import {
    Dashboard,
    DashboardDevice,
    InlineWidget,
    LayoutDetails,
    Widget,
    WidgetPosition,
    WidgetWrapper,
} from 'genesis-suite/types/visualTypes'
import { Layout } from 'react-grid-layout'

export const gridGap = 8
export const mobileScale = 0.8

export interface Count {
    row: number
    column: number
}

export function makeGridLayout(
    widgets: WidgetWithPosition[],
    mode: LayoutMode,
    count: Count,
    isEditing = false
): Layout[] {
    if (!widgets?.length) return

    if (widgets.length === 1) {
        const widgetIds = widgets.map(w => w.id)
        return makeFixedLayout(widgetIds, 2, count)
    }

    const flexLayout = makeFlexLayout(widgets, count)

    if (!isEditing && mode) {
        const orderedIds = getOrderFromLayout(flexLayout)
        return makeFixedLayout(orderedIds, mode, { ...count, row: 2 })
    }

    return flexLayout
}

/** Make layout based on device adding any new widgets to the end */
export function makeFlexLayout(widgets: WidgetWithPosition[], count: Count): Layout[] {
    if (!widgets) return

    const newWidgetIds = widgets.filter(notPositioned).map(w => w.id)
    if (!newWidgetIds.length) return widgets.map(getLayoutFromWidget)

    const startRow = widgets.reduce((acc, w) => {
        if (notPositioned(w)) return acc

        return Math.max(acc, w.position.y + w.position.h)
    }, 0)

    const newLayouts = makeFixedLayout(newWidgetIds, 2, count, startRow)

    return widgets.map(w => {
        const newLayout = newLayouts.find(l => l.i === w.id)
        return newLayout || getLayoutFromWidget(w)
    })
}

function notPositioned(widget: WidgetWithPosition) {
    const { x, y, w, h } = widget.position || {}
    return x == null || y == null || w == null || h == null
}

/** Make a grid perspective layout with given widgets and desired sizes */
function makeFixedLayout(widgetIds: string[], mode: LayoutMode, count: Count, startRow = 0): Layout[] {
    const itemWidth = count.column / mode
    const itemHeight = count.row / 2
    let w = itemWidth
    let h = itemHeight
    let x = 0
    let y = startRow
    let lastRowAdj = false

    const layout = widgetIds.map((id, index) => {
        // last row adjustments
        const widgetsLeft = widgetIds.length - index
        if (!lastRowAdj && x === 0 && widgetsLeft * w <= count.column) {
            w = count.column / widgetsLeft // share remaining columnCount
            // one row perspective - fit full height
            if (y === 0) h = count.row

            lastRowAdj = true
        }

        const layout = { i: id, x, y, w, h }
        const fitOnRow = x + 2 * w <= count.column
        y = fitOnRow ? y : y + h
        x = fitOnRow ? x + w : 0
        return layout
    })

    return layout
}

/** given the flex layouts and current breakpoint, determines the order (left to right) */
function getOrderFromLayout(layout: Layout[]): string[] {
    let y = 0
    let sorted = []

    const getIds = (layout, y) => layout.filter(l => l.y === y).map(w => w.i)

    while (layout.length > sorted.length || y < 1000) {
        const ids = getIds(layout, y)
        sorted = sorted.concat(ids)
        y++
    }

    return sorted
}

export function getPositionFromLayout(layoutArray: Layout[], widgetId: string) {
    const layout = layoutArray.find(l => l.i === widgetId)
    if (!layout) return

    const { x, y, w, h } = layout
    return { x, y, w, h }
}

function getLayoutFromWidget(widget: WidgetWithPosition) {
    const { id, position } = widget
    const { x, y, w, h } = position || {}
    return { i: id, x, y, w, h }
}

/** If 0, flex layout, else static layout with the number of grid items per row */
export type LayoutMode = number

export const grabHandleStyles = {
    '& .react-grid-placeholder': { bgcolor: 'primary.main' },
    '& .react-resizable-handle.react-resizable-handle-se': {
        borderBottom: 4,
        borderRight: 4,
        borderColor: 'primary.main',
        width: '15px',
        height: '15px',
        backgroundImage: 'none',
        '&::after': { display: 'none' },
    },
}

export function filterDashboardWidgets(widgets: WidgetWrapper[], device: DashboardDevice, only: 'top' | 'main') {
    if (!only) return widgets

    return widgets?.filter(w => {
        const isTop = w.positions?.[device]?.top
        return only === 'top' ? isTop : !isTop
    })
}

export function makeLayout(config: Dashboard, device: DashboardDevice): LayoutDetails {
    const {
        columnCount = device === DashboardDevice.LARGE ? 18 : 4,
        topColumnCount = device === DashboardDevice.LARGE ? 18 : 4,
        topHeightUnits = 'px',
        topHeight = 90,
        topRowCount = 1,
        rowCount = 18,
        rowHeight,
    } = config?.layoutByDevice?.[device] || {}

    return { columnCount, topColumnCount, topHeightUnits, topHeight, topRowCount, rowCount, rowHeight }
}

export type WidgetWithPosition = (Widget | InlineWidget) & { id: string; position: WidgetPosition; title?: string }

export function makeWidgetsWithPosition(
    config: Dashboard,
    widgets: Widget[],
    device: DashboardDevice
): WidgetWithPosition[] {
    if (!config?.widgets?.length) return []

    return config.widgets.map((w, i) => {
        let position = w.positions?.[device]
        if (device === DashboardDevice.EXTRASMALL && !position) {
            position = w.positions?.[DashboardDevice.SMALL]
        }
        const isHiddenInLarge = w.positions?.[DashboardDevice.LARGE]?.hide
        if (isHiddenInLarge) position = { ...(position || {}), hide: true }

        if (w.inline) {
            const widget: InlineWidget = w.inline
            return { ...widget, id: String(i), position }
        } else {
            const widget = widgets?.find(c => c.draft?.visualId === w.id || c.id === w.id)
            return { ...widget, id: w.id, position }
        }
    })
}
