import React, { useEffect, useState } from 'react'
import WidgetColorPicker from './WidgetColorPicker'
import { Button } from '@mui/material'

interface Props {
    value: string[]
    onChange: (val: string[]) => void
}

const ColorGradientPicker = ({ value, onChange }: Props) => {
    const [colors, setColors] = useState<string[]>([])

    useEffect(() => {
        console.log('Incoming value to ColorGradientPicker:', value)
        setColors(value || [])
    }, [value])

    const handleAddColor = () => {
        const updatedColors = [...colors, '#000000']
        setColors(updatedColors)
        onChange(updatedColors)
    }

    const handleUpdateColor = (index: number, newColor: string) => {
        const updatedColors = [...colors]
        updatedColors[index] = newColor
        setColors(updatedColors)
        onChange(updatedColors)
    }

    const handleRemoveColor = (index: number) => {
        const updatedColors = colors.filter((_, i) => i !== index)
        setColors(updatedColors)
        onChange(updatedColors)
    }

    return (
        <div>
            {colors.map((color, index) => (
                <div key={index} style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
                    <WidgetColorPicker
                        value={color}
                        onChange={newColor => {
                            handleUpdateColor(index, newColor.hex)
                        }}
                    />
                    <Button onClick={() => handleRemoveColor(index)} style={{ marginLeft: '8px' }}>
                        Remove
                    </Button>
                </div>
            ))}
            <Button onClick={handleAddColor} variant="contained" style={{ marginTop: '8px' }}>
                Add Color
            </Button>
        </div>
    )
}

export default ColorGradientPicker
