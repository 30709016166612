import React, { createContext, useContext, useEffect, useState, useMemo, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { authCreators } from '~/actions/creators'
import { authSelectors, applicationSelectors, moduleSelectors } from '~/selectors'
import { Dashboard } from 'genesis-suite/types/visualTypes'
import { userService } from '~/lib/services'
import useAppOptions from '~/lib/useAppOptions'

interface HomeDashboardContextProps {
    dashboard: Dashboard | null
    appOptionsDashboard: Dashboard | null
    createUserDashboard: (newDashboard: Dashboard) => void
    updateUserDashboard: (updatedDashboard: Dashboard) => void
    deleteUserDashboard: (dashboardId: string) => void
    addAppLevelDashboard: (newDashboard: Dashboard) => void
}

const DASHBOARD_PREFERENCES_KEY = 'CustomDashboards'
const APP_LEVEL_DASHBOARDS_OPTIONS_KEY = 'homeDashboards'
const HomeDashboardContext = createContext<HomeDashboardContextProps | undefined>(undefined)

export const HomeDashboardProvider = ({ children }) => {
    const dispatch = useDispatch()
    const userPreferences = useSelector(authSelectors.getUserPreferences)
    const appName = useSelector(applicationSelectors.getCurrentAppName)
    const moduleId = useSelector(moduleSelectors.getModuleId)
    const isV2Module = useSelector(moduleSelectors.getIsV2)
    const [userDashboards, setUserDashboards] = useState<Dashboard[]>(() => {
        if (userPreferences) {
            const dashboardPrefs = userPreferences.find(preference => preference.Key === DASHBOARD_PREFERENCES_KEY)
            if (dashboardPrefs) {
                try {
                    return JSON.parse(dashboardPrefs.Value)
                } catch (error) {
                    console.warn('Error parsing dashboard preferences', error)
                    return []
                }
            }
        }
        return []
    })
    const { getOptions: getAppOptions, updateOptions: updateAppOptions } = useAppOptions()
    const [appOptionsDashboards, setAppLevelDashboards] = useState<Dashboard[]>(() => {
        if (!getAppOptions) return []
        const homeDashboards = getAppOptions(APP_LEVEL_DASHBOARDS_OPTIONS_KEY)
        return homeDashboards || []
    })
    const hardReloadUserPreferences = useCallback(async () => {
        if (appName) {
            const preferences = await userService.getUserPreferences(appName)
            dispatch(authCreators.updateUserPrefs(preferences))
        }
    }, [appName, dispatch])

    useEffect(() => {
        if (userPreferences) {
            const dashboardPrefs = userPreferences.find(preference => preference.Key === DASHBOARD_PREFERENCES_KEY)
            let dashboardsFromPrefs: Dashboard[] = []

            if (dashboardPrefs) {
                if (!dashboardPrefs.Id) {
                    hardReloadUserPreferences()
                    dashboardsFromPrefs = []
                } else {
                    try {
                        dashboardsFromPrefs = JSON.parse(dashboardPrefs.Value)
                    } catch (error) {
                        dashboardsFromPrefs = []
                    }
                }
            }
            setUserDashboards(dashboardsFromPrefs)
        }
    }, [userPreferences])

    useEffect(() => {
        if (getAppOptions) {
            const homeDashboards = getAppOptions(APP_LEVEL_DASHBOARDS_OPTIONS_KEY)
            setAppLevelDashboards(homeDashboards || [])
        }
    }, [getAppOptions])

    const dashboard = useMemo(() => {
        if (userDashboards.length > 0) {
            const moduleDashboard = isV2Module
                ? userDashboards.find(d => d.moduleId === moduleId)
                : userDashboards.find(d => d.visorModuleId === moduleId)

            if (moduleDashboard) {
                return moduleDashboard
            } else {
                const appDashboard = userDashboards.find(d => !d.moduleId && !d.visorModuleId && d.appName === appName)
                if (appDashboard) {
                    return appDashboard
                }
            }
        }

        return null
    }, [userDashboards, appName, moduleId, isV2Module])

    const appOptionsDashboard = useMemo(() => {
        if (appOptionsDashboards && appOptionsDashboards.length > 0) {
            return appOptionsDashboards[0]
        }
        return null
    }, [appOptionsDashboards, appName, moduleId, isV2Module])

    const saveDashboardsToPreferences = (dashboardsToSave: Dashboard[]) => {
        const dashboardPrefs = userPreferences?.find(preference => preference.Key === DASHBOARD_PREFERENCES_KEY)

        const newPreference = {
            Id: dashboardPrefs?.Id || null,
            Key: DASHBOARD_PREFERENCES_KEY,
            Value: JSON.stringify(dashboardsToSave),
        }
        dispatch(authCreators.saveUserPreferencesForCurrentApplication([newPreference]))
    }

    const createUserDashboard = (newDashboard: Dashboard) => {
        const updatedDashboards = [...userDashboards, newDashboard]
        saveDashboardsToPreferences(updatedDashboards)
        setUserDashboards(updatedDashboards)
    }

    const updateUserDashboard = (updatedDashboard: Dashboard) => {
        const updatedDashboards = userDashboards.map(d => (d.id === updatedDashboard.id ? updatedDashboard : d))
        saveDashboardsToPreferences(updatedDashboards)
        setUserDashboards(updatedDashboards)
    }

    const deleteUserDashboard = (dashboardId: string) => {
        const updatedDashboards = userDashboards.filter(d => d.id !== dashboardId)
        saveDashboardsToPreferences(updatedDashboards)
        setUserDashboards(updatedDashboards)
    }

    const addAppLevelDashboard = (newDashboard: Dashboard) => {
        if (!appOptionsDashboards) return
        updateAppOptions(APP_LEVEL_DASHBOARDS_OPTIONS_KEY, [newDashboard])
        setAppLevelDashboards([newDashboard])
    }

    const contextValue: HomeDashboardContextProps = {
        dashboard,
        appOptionsDashboard,
        createUserDashboard,
        updateUserDashboard,
        deleteUserDashboard,
        addAppLevelDashboard,
    }

    return <HomeDashboardContext.Provider value={contextValue}>{children}</HomeDashboardContext.Provider>
}

export const useHomeDashboardContext = (): HomeDashboardContextProps | undefined => {
    const context = useContext(HomeDashboardContext)
    if (!context) {
        console.warn('useHomeDashboardContext must be used within a HomeDashboardProvider')
        return undefined
    }
    return context
}
