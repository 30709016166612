import CloseIcon from '@mui/icons-material/Close'
import SaveIcon from '@mui/icons-material/SaveOutlined'
import { Box, Button, IconButton, Tooltip } from '@mui/material'
import { useDispatch } from 'react-redux'
import { goBack } from 'redux-first-history'

export default function EditWidgetHeader2({ showActions, onSave }) {
    const reduxDispatch = useDispatch()
    return (
        <Box display="flex" alignItems="center" justifyContent="space-between" height="53px" gap="8px" px={1}>
            {showActions && (
                <>
                    <Box display="flex" alignItems="center">
                        <Button startIcon={<CloseIcon />}>Reset</Button>

                        <Button variant="contained" startIcon={<SaveIcon />} onClick={() => onSave()}>
                            Save
                        </Button>
                    </Box>
                </>
            )}
            <Tooltip title="Close" sx={{ position: 'fixed', right: 0 }}>
                <IconButton onClick={() => reduxDispatch(goBack())}>
                    <CloseIcon />
                </IconButton>
            </Tooltip>
        </Box>
    )
}
