const initializeHub = (model, user, successCallback, errorCallback) => {
    const { connection } = window.$

    connection.hub.url = `${window.TADA_APIS.COLLABORATION}/signalr`
    connection.hub.qs = { an: model, user }

    connection.hub.disconnected(function () {
        console.log('hub disconnected at: ', new Date().toISOString())
        setTimeout(function () {
            console.log('reconnecting hub at: ', new Date().toISOString())
            connection.hub
                .start()
                .done(() => {
                    successCallback(connection.hub.id)
                })
                .fail(error => errorCallback(error))
        }, 5000)
    })

    connection.hub
        .start()
        .done(() => {
            successCallback(connection.hub.id)
        })
        .fail(error => errorCallback(error))
}

const stopHub = () => {
    return new Promise((resolve, reject) => {
        if (window.$.connection && window.$.connection.collaborationHub) {
            const hub = window.$.connection.collaborationHub
            hub.connection.stop()
            resolve()
        }
    })
}

export const hubService = {
    initializeHub,
    stopHub,
}
