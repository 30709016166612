import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    IconButton,
    Tooltip,
    Typography,
    List,
    ListItemButton,
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import { forwardRef, useContext, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useIsMobile } from '~/hooks/useIsMobile'
import { ArrowDropDown, LaunchRounded } from '@mui/icons-material'
import { ChartType } from 'genesis-suite/types/visualTypes'
import { menuSelectors, moduleSelectors } from '~/selectors'
import { navigationCreators } from '~/actions/creators'
import { openInNewTab } from '~/lib/utils'
import { routePaths } from '~/lib/routes'
import { DashboardContext } from '~/components/widgets2/dashboard/DashboardContext'

const useStyles = makeStyles(({ palette, spacing }) => ({
    widget: {
        flex: 'none',
        borderRadius: '8px',
        display: 'flex',
        gap: spacing(1),
        alignItems: 'flex-end',
    },
    mobileHomeView: {
        backgroundColor: palette.background.default,
        border: `1px solid ${palette.divider}`,
        borderRadius: '15px',
        px: spacing(1),
        flex: 2,
        overflow: 'auto',
    },
    accordion: {
        background: palette.background.main,
        boxShadow: 'none',
        '&.Mui-expanded': {
            margin: 0,
        },
    },
    accordionSummary: {
        padding: spacing(0, 1),
        flexDirection: 'row',
        width: '100%',
        '&.MuiAccordionSummary-root': {
            minHeight: '48px',
        },
        '&.MuiAccordionSummary-root.Mui-expanded .MuiAccordionSummary-content': {
            margin: 0,
        },
    },
    accordionDetails: {
        padding: spacing(0, 1),
    },
    list: {
        padding: spacing(0),
    },
    listItem: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: spacing(0.5, 1),
        border: `1px solid ${palette.border.main}`,
        borderRadius: '30px',
        margin: spacing(2, 0),
    },
    submenuAccordion: {
        background: 'transparent',
        boxShadow: 'none',
        border: `1px solid ${palette.border.main}`,
        borderRadius: '30px',
        margin: spacing(2, 0),
        '&:first-of-type': {
            borderRadius: '30px',
        },
        '&:last-of-type': {
            borderRadius: '30px',
        },
        '&::before': {
            display: 'none',
        },
    },
    submenuAccordionSummary: {
        padding: spacing(0.5, 1),
        borderRadius: '30px',
        minHeight: '0',
    },
    titleText: {
        flex: 1,
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
    },
    smallBtn: {
        padding: spacing(0.5),
    },
    expanded: {
        margin: 0,
    },
    summary: {
        padding: spacing(1),
    },
}))

export default forwardRef<HTMLDivElement, { type: ChartType }>((props, ref) => {
    const { type } = props
    const classes = useStyles()
    const isMobile = useIsMobile()
    const dispatch = useDispatch()
    const [expandedSubmenu, setExpandedSubmenu] = useState<string | false>(false)
    const appOptions = JSON.parse(useSelector(moduleSelectors.getAppOptions) || null)
    const getHiddenBizElements = appOptions?.hideBusinessElements ?? []
    const getHidden360Views = appOptions?.hide360Views ?? []
    const modules = useSelector(moduleSelectors.getActiveModules)
    const moduleTitle = useSelector(moduleSelectors.getModuleTitle)

    const bizElements = useSelector(menuSelectors.getTopNav)
    const businessElements = bizElements?.filter(el => !getHiddenBizElements.includes(el.text))
    const _360views = useSelector(menuSelectors.getViews)
    const updated360Views = _360views?.filter(el => !getHidden360Views.includes(el.text))
    const orderedModules = modules && [...modules].sort((a, b) => a.title.localeCompare(b.title))

    const handleNavigation = (to, isExternal, type) => {
        if (isExternal && to) openInNewTab(to)
        else if (type === 'viewId') dispatch(navigationCreators.goToPerspective(to))
        else if (type === 'widgetId') dispatch(navigationCreators.goTo(routePaths.WIDGET, to))
        else if (type === 'elementName') dispatch(navigationCreators.goTo(routePaths.ELEMENT, to))
    }
    const handleSubmenuToggle = (text: string, widgetKey: string) => {
        setExpandedSubmenu(prev => (prev === text ? false : text))
        setTimeout(() => {
            toggleExpandCollapseWidget(widgetKey, true)
        }, 1000)
    }
    const { widgets, expandedWidgetRefs, toggleExpandCollapseWidget, expanded } = useContext(DashboardContext)
    const widgetIndex = widgets?.find(X => X.type === type)?.id

    const handleToggle = key => {
        toggleExpandCollapseWidget(key)
    }

    const renderAccordionWithSubmenus = (title, items, i) => (
        <Accordion
            className={classes.accordion}
            expanded={!!expanded[i]}
            onChange={() => handleToggle(i)}
            sx={{ overflow: 'hidden' }}
        >
            <AccordionSummary expandIcon={<ArrowDropDown />} classes={{ root: classes.accordionSummary }}>
                <Typography>
                    {title} ({items.length})
                </Typography>
            </AccordionSummary>
            <AccordionDetails
                className={classes.accordionDetails}
                sx={{ overflow: 'hidden', padding: 0, margin: 0 }}
                ref={el => {
                    expandedWidgetRefs.current[i] = el
                }}
            >
                <List className={classes.list}>
                    {items.map(({ text, onClick, subMenu, isDisabled }) =>
                        subMenu ? (
                            <Accordion
                                key={text}
                                expanded={expandedSubmenu === text}
                                onChange={() => handleSubmenuToggle(text, i)}
                                classes={{ root: classes.submenuAccordion }}
                            >
                                <AccordionSummary
                                    expandIcon={<ArrowDropDown />}
                                    classes={{ root: classes.submenuAccordionSummary }}
                                    sx={{
                                        '& .MuiAccordionSummary-content': {
                                            margin: '4px',
                                        },
                                    }}
                                >
                                    <Typography>{text}</Typography>
                                </AccordionSummary>
                                <AccordionDetails sx={{ padding: '0 16px' }}>
                                    <List>
                                        {subMenu.map(nestedItem => (
                                            <ListItemButton
                                                key={nestedItem.text}
                                                className={classes.listItem}
                                                onClick={nestedItem.onClick}
                                            >
                                                <Tooltip title="Launch Slideshow">
                                                    <IconButton
                                                        className={classes.smallBtn}
                                                        onClick={nestedItem.onClick}
                                                        size="large"
                                                    >
                                                        <LaunchRounded fontSize="small" />
                                                    </IconButton>
                                                </Tooltip>
                                                <Typography
                                                    variant="subtitle1"
                                                    display="inline"
                                                    title={nestedItem.text}
                                                    className={classes.titleText}
                                                >
                                                    {nestedItem.text}
                                                </Typography>
                                            </ListItemButton>
                                        ))}
                                    </List>
                                </AccordionDetails>
                            </Accordion>
                        ) : (
                            <ListItemButton
                                key={text}
                                className={classes.listItem}
                                onClick={onClick}
                                disabled={isDisabled}
                            >
                                <Tooltip title="Launch Slideshow">
                                    <IconButton className={classes.smallBtn} onClick={onClick} size="large">
                                        <LaunchRounded fontSize="small" />
                                    </IconButton>
                                </Tooltip>
                                <Typography
                                    variant="subtitle1"
                                    display="inline"
                                    title={text}
                                    className={classes.titleText}
                                >
                                    {text}
                                </Typography>
                            </ListItemButton>
                        )
                    )}
                </List>
            </AccordionDetails>
        </Accordion>
    )

    return (
        <div ref={ref} className={classes.widget}>
            <Box
                flex={1}
                height="100%"
                overflow="hidden"
                display="flex"
                flexDirection="column"
                justifyContent="space-between"
            >
                <div className={classes.mobileHomeView}>
                    {isMobile && (
                        <>
                            {type === ChartType.BUSINESS_ELEMENTS &&
                                businessElements?.length > 0 &&
                                renderAccordionWithSubmenus(
                                    'Business Elements',
                                    businessElements?.map(el => {
                                        return {
                                            text: el.text,
                                            onClick: () => handleNavigation(el.to, el.isExternal, el.type),
                                        }
                                    }),
                                    widgetIndex
                                )}

                            {type === ChartType.VIEWS &&
                                updated360Views?.length > 0 &&
                                renderAccordionWithSubmenus(
                                    'Views',
                                    updated360Views?.map(view => ({
                                        text: view.text,
                                        onClick: () => handleNavigation(view.to, view.isExternal, view.type),
                                        subMenu: view?.subMenuConfig?.map(subView => ({
                                            text: subView?.text,
                                            onClick: () =>
                                                handleNavigation(subView.to, subView.isExternal, subView.type),
                                        })),
                                    })),
                                    widgetIndex
                                )}
                            {type === ChartType.APPLICATIONS &&
                                orderedModules?.length > 0 &&
                                renderAccordionWithSubmenus(
                                    'Applications',
                                    orderedModules?.map(({ name, title }) => ({
                                        text: title,
                                        onClick: () => {
                                            if (title !== moduleTitle) dispatch(navigationCreators.goToModule(name))
                                        },
                                        isDisabled: title === moduleTitle,
                                    })),
                                    widgetIndex
                                )}
                        </>
                    )}
                </div>
            </Box>
        </div>
    )
})
