import { authCreators, backupCreators, chatCreators, deploymentCreators, hubCreators } from '.'
import { applicationService, userService } from '../../lib/services'
import { applicationTypes } from '../types'

const applicationChange = appName => ({
    type: applicationTypes.APPLICATION_SELECT,
    payload: { appName },
})

const applicationInfoRequest = () => ({
    type: applicationTypes.APPLICATION_INFO_REQUEST,
})

const applicationInfoSuccess = info => ({
    type: applicationTypes.APPLICATION_INFO_SUCCESS,
    payload: { info },
})

const applicationInfoFailure = error => ({
    type: applicationTypes.APPLICATION_INFO_FAILURE,
    payload: { error },
})

const getApplicationInfo = appName => dispatch => {
    dispatch(applicationInfoRequest())
    if (appName) {
        applicationService.getApplicationInfo(appName).then(
            results => {
                dispatch(applicationInfoSuccess(results))
            },
            error => {
                dispatch(applicationInfoFailure(error))
            }
        )
    }
}

const applicationFetchSuccess = apps => ({
    type: applicationTypes.APPLICATION_FETCH_SUCCESS,
    payload: { apps },
})

const getAllApplications = () => dispatch => {
    applicationService.getApps().then(apps => {
        dispatch(applicationFetchSuccess(apps))
    })
}

const selectApplication = appName => {
    return dispatch => {
        const dispatchActions = () => {
            dispatch(applicationChange(appName))
            dispatch(hubCreators.stop())
            dispatch(getApplicationInfo(appName))
            dispatch(deploymentCreators.setDeploymentAppName(appName))
            dispatch(backupCreators.removeSelectedSchedules())
            dispatch(chatCreators.clearAllMessages())
            dispatch(chatCreators.clearAIChatSession())
        }

        return new Promise((resolve, reject) => {
            userService
                .getUserPreferences(appName)
                .then(preferences => {
                    if (preferences?.length) {
                        dispatch(authCreators.updateUserPrefs(preferences))
                    }
                    dispatchActions()
                    resolve(preferences)
                })
                .catch(e => {
                    dispatchActions()
                    reject(e)
                })
        })
    }
}

export const applicationCreators = {
    selectApplication,
    getApplicationInfo,
    getAllApplications,
}
