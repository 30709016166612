import { Autocomplete, Box, Checkbox, FormControl, MenuItem, Select, TextField } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { Base } from 'genesis-suite/types/visualTypes'
import { isEmpty } from 'lodash'
import { useContext } from 'react'
import { ConfigContext } from '../../ConfigContext'
import { getAllProperties } from '../../utils'
import ColorGradientPicker from '../ColorGradientPicker'
import EditorWrapper from '../EditorWrapper'
import FieldLabel from '../FieldLabel'

const baseOptions = [
    { value: Base.K, label: 'K', tip: 'Thousands' },
    { value: Base.M, label: 'M', tip: 'Millions' },
    { value: Base.B, label: 'B', tip: 'Billions' },
    { value: Base.DYNAMIC, label: 'D', tip: 'Dynamic' },
]

const useStyles = makeStyles(({ spacing, palette }) => ({
    actionContainer: {
        border: `2px solid ${palette.grayscale.light}`,
        padding: spacing(0.5),
        borderRadius: spacing(0.5),
        marginTop: spacing(0.5),
    },
}))

export default function TableCellFormatEditor({ fieldsConfigKey }) {
    const { selectedField, config, configStructure, dispatch, resources, configKey } = useContext(ConfigContext)
    const seriesConfig = configStructure[fieldsConfigKey]
    const properties = getAllProperties(resources.byId)

    const classes = useStyles()
    const widgetConfig = config[configKey]
    const activeSeries = widgetConfig.Fields?.[selectedField.index]

    if (!seriesConfig || !activeSeries) return null
    console.log('tb acive', seriesConfig)
    const updateActiveSeries = (key, value) => {
        dispatch({ type: 'UPDATE_ACTIVE_SERIES', payload: { [key]: value } })
    }
    const sortedSeriesConfig = [...seriesConfig].sort(
        (a, b) => (a.DisplayOrder ?? Infinity) - (b.DisplayOrder ?? Infinity)
    )
    return (
        <EditorWrapper header="Cell Format">
            <Box className={classes.actionContainer}>
                <Box display="flex" flexDirection="column">
                    {sortedSeriesConfig.map((field, index) => {
                        if (!isEmpty(field.List)) {
                            return (
                                <div key={index}>
                                    <FieldLabel field={field} />
                                    <FormControl
                                        fullWidth
                                        sx={{ my: 0 }}
                                        variant="outlined"
                                        size="small"
                                        margin="normal"
                                    >
                                        <Select
                                            value={activeSeries[field.Name] ?? 'None'}
                                            onChange={e => updateActiveSeries(field.Name, e.target.value)}
                                            sx={theme => ({
                                                border: '1px solid #888',
                                                borderRadius: '4px',
                                                '&:hover': {
                                                    borderColor: `${theme.palette.text.primary}`,
                                                },
                                                '&.Mui-focused': {
                                                    borderColor: `${theme.palette.text.primary}`,
                                                },
                                            })}
                                        >
                                            {Object.entries(field.List as { [key: string]: string }).map(
                                                ([key, value]) => (
                                                    <MenuItem key={key} value={key}>
                                                        {value}
                                                    </MenuItem>
                                                )
                                            )}
                                        </Select>
                                    </FormControl>
                                </div>
                            )
                        } else if (field.Type.includes('Boolean')) {
                            return (
                                <div key={index} style={{ display: 'flex', alignItems: 'center' }}>
                                    <div
                                        key={index}
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <Checkbox
                                            onChange={e => updateActiveSeries(field.Name, e.target.checked)}
                                            name={field.Name}
                                            color="primary"
                                            defaultChecked={false}
                                            checked={activeSeries[field.Name] ?? false}
                                            inputProps={{ 'aria-label': `switch-${index}` }}
                                        />
                                        <FieldLabel field={field} />
                                    </div>
                                </div>
                            )
                        } else if (field.PropertyGroupName === 'Setup' && field.Type.includes('Nullable')) {
                            return (
                                <div key={index}>
                                    <FieldLabel field={field} />
                                    <TextField
                                        fullWidth
                                        variant="outlined"
                                        size="small"
                                        value={activeSeries[field.Name]}
                                        onChange={e => updateActiveSeries(field.Name, e.target.value)}
                                        placeholder={field.Name}
                                    />
                                </div>
                            )
                        } else if (field.IsSourceProperty && field.PropertyGroupName === 'Setup') {
                            return (
                                <div key={index}>
                                    <FieldLabel field={field} />
                                    <Autocomplete
                                        options={properties}
                                        value={properties?.find(p => p.name === activeSeries[field.Name]) ?? null}
                                        isOptionEqualToValue={(option, value) => option?.name == value?.name}
                                        getOptionLabel={option => option.name}
                                        onChange={(_, newValue) => {
                                            updateActiveSeries(field.Name, newValue?.name)
                                        }}
                                        renderInput={params => (
                                            <TextField
                                                {...params}
                                                variant="outlined"
                                                placeholder="Select Sort Field Name"
                                                size="small"
                                                margin="normal"
                                                sx={{ my: 0 }}
                                            />
                                        )}
                                    />
                                </div>
                            )
                        } else if (field.IsColorList && field.PropertyGroupName === 'Setup') {
                            return (
                                <div key={index}>
                                    <FieldLabel field={field} />
                                    <ColorGradientPicker
                                        value={activeSeries[field.Name] || []}
                                        onChange={newColors => {
                                            updateActiveSeries(field.Name, newColors)
                                        }}
                                    />
                                </div>
                            )
                        }
                    })}
                </Box>
            </Box>
        </EditorWrapper>
    )
}
