import { Avatar, IconButton, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { useDispatch, useSelector } from 'react-redux'
import { authSelectors } from '~/selectors'
import LogoutIcon from '@mui/icons-material/Logout'
import { authCreators } from '~/actions/creators'

const useStyles = makeStyles(({ palette, spacing }) => ({
    container: {
        color: '#000000',
        minHeight: '85px',
        minWidth: '150px',
        display: 'flex',
        flexDirection: 'column',
        padding: spacing(1, 1, 1),
    },
    nameWrapper: { display: 'flex', alignItems: 'center' },
    avatar: { backgroundColor: palette.primary.main, color: palette.primary.contrastText, width: 24, height: 24 },
    name: { marginLeft: spacing(), lineHeight: 1.4, color: palette.text.primary },
}))

export default function UserProfileButton() {
    const classes = useStyles()
    const userAlias = useSelector(authSelectors.getUserAlias)
    const dispatch = useDispatch()

    return (
        <div className={classes.container}>
            {userAlias && (
                <div className={classes.nameWrapper}>
                    <Avatar className={classes.avatar}>{userAlias[0].toUpperCase()}</Avatar>
                    <Typography variant="h6" className={classes.name}>
                        {userAlias}
                    </Typography>
                </div>
            )}

            <div style={{ marginTop: 'auto' }}>
                <IconButton
                    sx={{ alignItems: 'center', paddingLeft: '4px' }}
                    onClick={() => dispatch(authCreators.logout())}
                >
                    <LogoutIcon />
                    <Typography fontSize="small" className={classes.name}>
                        Sign Out
                    </Typography>
                </IconButton>
            </div>
        </div>
    )
}
