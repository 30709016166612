import NewIcon from '@mui/icons-material/AddCircleOutline'
import DateIcon from '@mui/icons-material/DateRange'
import StringIcon from '@mui/icons-material/FormatQuote'
import SumIcon from '@mui/icons-material/Functions'
import CalculateIcon from '@mui/icons-material/Iso'
import PhoneIcon from '@mui/icons-material/PhoneOutlined'
import BooleanIcon from '@mui/icons-material/ToggleOnOutlined'
import {
    Box,
    Button,
    Chip,
    CircularProgress,
    Divider,
    IconButton,
    List,
    ListItem,
    MenuItem,
    Paper,
    Stack,
    TextField,
    Tooltip,
    Typography,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { MouseEvent, useContext, useEffect, useMemo, useState } from 'react'
import { useDrag } from 'react-dnd'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import { ChopText, SearchBar, SwalContext } from 'genesis-suite/components'
import HomeIcon from 'genesis-suite/icons/House'
import { PropertyType } from 'genesis-suite/types/architectureTypes'
import { Property, ResourceType } from 'genesis-suite/types/networkTypes'
import { Service } from 'genesis-suite/types/visualTypes'
import { isEmpty } from 'lodash'
import { useDispatch } from 'react-redux'
import { ConfigContext } from '.'
import { navigationCreators } from '../../actions/creators'
import { widgetConstants } from '../../constants'
import { routePaths } from '../../lib/routes'
import { authSelectors } from '../../selectors'
import { DragField } from './builderTypes'

const useStyles = makeStyles(({ palette, spacing }) => ({
    dataFieldsTitle: { fontWeight: 'bold' },
    list: { overflow: 'auto', flex: 1 },
    loader: { alignSelf: 'center' },
    sectionTitle: { fontWeight: 'bold', marginLeft: spacing() },
    search: { flex: '0 0 auto', paddingRight: spacing(2.5), marginBottom: spacing(3), backgroundColor: 'white' },
    divider: { height: 2, marginBottom: spacing(3) },
    newPropertyButton: {
        borderRadius: 'inherit',
        borderTop: `1px solid ${palette.grayscale.light}`,
        backgroundColor: (p: any) => (p.isCreatingNewProperty ? palette.primary.main : 'inherit'),
        color: (p: any) => (p.isCreatingNewProperty ? palette.primary.contrastText : 'inherit'),
        fill: (p: any) => (p.isCreatingNewProperty ? palette.primary.contrastText : 'inherit'),
        '&:hover': { backgroundColor: (p: any) => (p.isCreatingNewProperty ? palette.primary.main : '') },
    },
    insightFieldWrapper: {
        display: 'flex',
        flexDirection: 'column',
        padding: spacing(0.8, 0.8, 0, 0.8),
        marginBottom: spacing(1.5),
        border: `2px solid ${palette.grayscale.light}`,
    },
    insightField: {
        ['& .MuiInputBase-input:focus']: { backgroundColor: 'white' },
        marginBottom: spacing(0.8),
    },
    insightFieldLabel: {
        fontWeight: 'bold',
        marginBottom: spacing(0.5),
    },
    insightChip: {
        maxWidth: '100%',
        fontWeight: 'bold',
        fontSize: 12,
        ['&:hover']: {
            backgroundColor: palette.grayscale.light,
            color: 'white',
            border: `2px solid ${palette.grayscale.light} `,
        },
    },
    selectedInsightsList: {
        maxHeight: 85,
        paddingBottom: spacing(1),
        overflowY: 'auto',
    },
    selectedInsightsTotal: {
        fontSize: 12,
    },
    categoryErrorContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        width: '100%',
        padding: spacing(1),
    },
    categoryErrorTextWrapper: {
        height: '70%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        border: `2px solid ${palette.grayscale.light}`,
        padding: spacing(0, 1.5),
    },
    categoryErrorText: {
        fontWeight: 'bold',
        textAlign: 'center',
    },
}))

const filledChip = {
    backgroundColor: 'primary.main',
    border: 2,
    borderColor: 'primary.main',
    color: 'white',
}

const outlinedChip = {
    backgroundColor: 'white',
    border: 2,
    borderColor: 'primary.main',
    color: 'primary.main',
}

const configTypeAndKeyMap = {
    Chart: 'ChartConfig',
    Table: 'TableConfig',
}

export default function DataFields({ className }) {
    const { widgetID } = useParams()
    const { confirm } = useContext(SwalContext)
    const isPowerUser = useSelector(authSelectors.getIsPowerUser)
    const [query, setQuery] = useState('')
    const { config, dispatch, insights, selectedInsights, resources, calculatedPropertyEditor, configKey } =
        useContext(ConfigContext)
    const resource = resources?.byId[resources.selectedId]
    const clickable = calculatedPropertyEditor.open
    const filteredProperties = filterProperties(resource?.properties, query)
    const elementProperties = filteredProperties.filter(p => p.semanticType.type !== PropertyType.QUANTITATIVE)
    const metricProperties = filteredProperties.filter(p => p.semanticType.type === PropertyType.QUANTITATIVE)
    const isCreatingNewProperty = calculatedPropertyEditor.open && !calculatedPropertyEditor.editId
    const isEditingBuilderWidget = widgetID === widgetConstants.Edit.BUSINESS_EXPLORER_ROUTE
    const classes = useStyles({ isCreatingNewProperty })
    const reduxDispatch = useDispatch()
    const focalPoints = insights.reduce((acc, i) => {
        acc = [...acc, ...i.nodeNames]
        return acc
    }, [])

    const insightHasCategories = config[configKey]?.LabelField
        ? resource?.properties.some(p => p.name === config[configKey]?.LabelField)
        : true

    useEffect(() => {
        if (config.Element) return

        if (focalPoints?.length > 0) {
            dispatch({ type: 'UPDATE_CONFIG', payload: { Element: focalPoints[0] } })
        }
    }, [focalPoints])
    const unusedInsights = useMemo(() => {
        let filteredInsights = insights
        if (config.Element) {
            filteredInsights = insights?.filter(i => i.nodeNames.includes(config.Element))
        }

        return filteredInsights?.filter(({ id }) => !selectedInsights.some(i => i.id === id))
    }, [config.Element, insights, selectedInsights])

    async function updateService(id) {
        if (id === resource?.id) return

        const servicesById = Object.values(resources.byId).reduce<{ [id: string]: Service }>((acc, currResource) => {
            const { id, name } = currResource
            return { ...acc, [id]: { ElementType: ResourceType.INSIGHT, id, ElementName: name } }
        }, {})

        const name = insights.find(i => i.id === id).name
        servicesById[id] = { ElementType: ResourceType.INSIGHT, id, ElementName: name }

        dispatch({
            type: 'SET_SERVICE',
            payload: { byId: servicesById, selectedId: id },
        })
    }

    async function handleNewInsightChange(id) {
        updateService(id)
    }

    async function handleFocalPointChange(point) {
        if (!isEmpty(config[configKey])) {
            const result = await confirm('Clear config and start over?', { type: 'warning' })
            if (result.dismiss) return

            dispatch({ type: 'UPDATE_CONFIG', payload: { Element: point } })
        }
    }

    function handleShowCalculatedProperty(editId?: string) {
        dispatch({ type: 'UPDATE_CALCULATED_PROPERTY_PANEL', payload: { open: true, editId } })
    }

    function handlePropertyClick(id?: string) {
        dispatch({ type: 'UPDATE_CALCULATED_PROPERTY_PANEL', payload: { selectedId: id } })
    }

    return (
        <div className={className}>
            <Box mx={1}>
                <Box display="flex" alignItems="center" gap={0.5}>
                    <IconButton
                        onClick={() => reduxDispatch(navigationCreators.goTo(routePaths.HOME))}
                        sx={{ color: '#FFF' }}
                    >
                        <HomeIcon />
                    </IconButton>
                    <Typography className={classes.dataFieldsTitle} variant="h6">
                        Network
                    </Typography>
                </Box>

                {isEditingBuilderWidget ? (
                    <>
                        <Typography variant="caption">Insight</Typography>
                        <ChopText gutterBottom variant="subtitle1">
                            {resource?.name}
                        </ChopText>
                    </>
                ) : (
                    <Box>
                        <Typography className={classes.insightFieldLabel} variant="subtitle2">
                            Focal Pount
                        </Typography>
                        <Paper className={classes.insightFieldWrapper} elevation={0}>
                            <TextField
                                select
                                className={classes.insightField}
                                value={config.Element ? config.Element : focalPoints[0]}
                                onChange={e => handleFocalPointChange(e.target.value)}
                                placeholder="Insight"
                            >
                                {focalPoints.map(fp => (
                                    <MenuItem key={fp} value={fp}>
                                        {fp}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Paper>
                        <Typography className={classes.insightFieldLabel} variant="subtitle2">
                            Insights
                        </Typography>
                        <Paper className={classes.insightFieldWrapper} elevation={0}>
                            {selectedInsights.length !== insights.length && (
                                <TextField
                                    select
                                    className={classes.insightField}
                                    value={unusedInsights?.some(i => i.id === resource?.id) ? resource?.id ?? '' : ''}
                                    onChange={e => handleNewInsightChange(e.target.value)}
                                    placeholder="Insight"
                                >
                                    {unusedInsights.map(insight => (
                                        <MenuItem key={insight.id} value={insight.id}>
                                            {insight.name}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            )}
                            {selectedInsights.length > 0 && (
                                <Stack className={classes.selectedInsightsList} alignItems="flex-start" spacing={0.8}>
                                    {selectedInsights.map(insight => (
                                        <Chip
                                            className={classes.insightChip}
                                            sx={resource?.id === insight.id ? filledChip : outlinedChip}
                                            key={insight.id}
                                            label={insight.ElementName}
                                            size="small"
                                            onClick={() => updateService(insight.id)}
                                        />
                                    ))}
                                </Stack>
                            )}
                        </Paper>
                    </Box>
                )}
                <SearchBar className={classes.search} onChange={setQuery} collapsable={false} />
            </Box>

            <Box overflow="auto" flex={1}>
                {!resource?.properties.length ? (
                    <Box display="flex" justifyContent="center" mt={1}>
                        <CircularProgress />
                    </Box>
                ) : !insightHasCategories ? (
                    <Box className={classes.categoryErrorContainer}>
                        <Box className={classes.categoryErrorTextWrapper}>
                            <Typography className={classes.categoryErrorText} variant="body2">
                                Category not found in one or more Insights. Please select another Insight.
                            </Typography>
                        </Box>
                    </Box>
                ) : (
                    <>
                        <div>
                            <Typography className={classes.sectionTitle} gutterBottom variant="subtitle2">
                                Elements
                            </Typography>
                            <List component="div" className={classes.list} dense>
                                {elementProperties.map(p => (
                                    <Field
                                        key={p.id}
                                        onClick={clickable ? () => handlePropertyClick(p.id) : undefined}
                                        selected={p.id === calculatedPropertyEditor.editId}
                                        isPowerUser={isPowerUser}
                                        onEdit={() => handleShowCalculatedProperty(p.id)}
                                        {...p}
                                    />
                                ))}
                            </List>
                        </div>

                        <Divider className={classes.divider} />

                        <div>
                            <Typography className={classes.sectionTitle} gutterBottom variant="subtitle2">
                                Metrics
                            </Typography>
                            <List component="div" className={classes.list} dense>
                                {metricProperties.map(p => (
                                    <Field
                                        key={p.id}
                                        onClick={clickable ? () => handlePropertyClick(p.id) : undefined}
                                        selected={p.id === calculatedPropertyEditor.editId}
                                        isPowerUser={isPowerUser}
                                        onEdit={() => handleShowCalculatedProperty(p.id)}
                                        {...p}
                                    />
                                ))}
                            </List>
                        </div>
                    </>
                )}
            </Box>

            {isPowerUser && (
                <Button
                    className={classes.newPropertyButton}
                    startIcon={<NewIcon fontSize="small" style={{ color: 'inherit' }} />}
                    onClick={() => handleShowCalculatedProperty()}
                >
                    Calculated Property
                </Button>
            )}
        </div>
    )
}

interface FieldProps extends Property {
    isPowerUser: boolean
    selected: boolean
    onClick?: (e: MouseEvent<HTMLElement>) => void
    onEdit: () => void
}

const useFieldStyles = makeStyles(({ palette, spacing }) => ({
    field: { cursor: (p: any) => (p.clickable ? 'pointer' : 'grab'), padding: spacing(0.5, 0, 0.5, 1) },
    selectedField: {
        backgroundColor: `${palette.primary.main} !important`,
        color: palette.primary.contrastText,
        fill: palette.primary.contrastText,
    },
    text: { flex: 1, color: 'inherit' },
    icon: { width: '10px', fontWeight: 'bold', fontSize: '10px', marginRight: spacing(0.5), color: 'inherit' },
    computedIcon: { fontSize: '14px', color: 'inherit' },
}))

function Field({
    semanticType,
    id,
    name,
    container,
    displayName,
    computed,
    isOptional,
    hasAggregates,
    isPowerUser,
    onClick,
    selected,
    onEdit,
}: FieldProps) {
    const clickable = Boolean(onClick)

    const [{ isDragging }, drag] = useDrag({
        type: semanticType.type,
        item: {
            type: semanticType.type,
            resourceType: container.type,
            resourceId: container.id,
            resourceName: container.name,
            id,
            name,
        } as DragField,
        collect: monitor => ({ isDragging: monitor.isDragging() }),
        canDrag: !clickable,
    })

    const classes = useFieldStyles({ clickable })

    function handleEdit(e) {
        e.stopPropagation()
        onEdit()
    }

    let Icon
    switch (semanticType.baseDataType) {
        case 'String':
            Icon = StringIcon
            break
        case 'Integer':
        case 'Small Number':
        case 'Large Number':
        case 'Decimal':
            Icon = NumberIcon
            break
        case 'Percentage':
            Icon = PercentIcon
            break
        case 'Currency':
            Icon = CurrencyIcon
            break
        case 'Binary':
        case 'True - False':
            Icon = BooleanIcon
            break
        case 'Time':
        case 'Date':
            Icon = DateIcon
            break
        case 'Phone':
            Icon = PhoneIcon
            break
    }

    const FunctionIcon = hasAggregates ? SumIcon : CalculateIcon

    const content = (
        <>
            {Icon && <Icon className={classes.icon} />}
            <ChopText showEllipsis className={classes.text}>
                {displayName}
            </ChopText>
            {computed && isOptional && (
                <Tooltip title={`Edit ${hasAggregates ? 'aggregate ' : ''}function`}>
                    <IconButton color="inherit" size="small" disabled={!isPowerUser} onClick={handleEdit}>
                        <FunctionIcon className={classes.computedIcon} />
                    </IconButton>
                </Tooltip>
            )}
        </>
    )

    return clickable ? (
        <ListItem
            button
            selected={selected}
            title={displayName}
            onClick={onClick}
            classes={{ root: classes.field, selected: classes.selectedField }}
        >
            {content}
        </ListItem>
    ) : (
        <ListItem
            ref={drag}
            selected={selected}
            title={displayName}
            classes={{ root: classes.field, selected: classes.selectedField }}
            style={{ opacity: isDragging ? '0.5' : '1' }}
        >
            {content}
        </ListItem>
    )
}

const makeTextIcon = text => p =>
    (
        <svg viewBox="0 0 24 24" {...p}>
            <text x="12" y="12" textAnchor="middle" alignmentBaseline="middle" fontSize="24px">
                {text}
            </text>
        </svg>
    )
const NumberIcon = makeTextIcon('#')
const PercentIcon = makeTextIcon('%')
const CurrencyIcon = makeTextIcon('$')

function filterProperties(properties: Property[], query: string) {
    if (!properties) return []
    if (!query) return properties

    const regex = new RegExp(`(${query})`, 'gi')
    return properties.filter(p => p.name.search(regex) > -1 || p.displayName.search(regex) > -1)
}
