import { Tooltip, Chip, Stack, Divider, Box, Button } from '@mui/material'
import { useState, useRef, useMemo } from 'react'
import { ChopText } from 'genesis-suite/components'
import { useSelector } from 'react-redux'
import { widgetSelectors } from '~/selectors'
import makeStyles from '@mui/styles/makeStyles'
import Widget from '../../components/widgets/Widget'

const useStyles = makeStyles(({ palette, border }) => ({
    right: {
        alignSelf: 'center',
        display: 'flex',
        justifyContent: 'flex-end',
        borderRadius: border.radius.round,
        backgroundColor: palette.background.sideNav,
        color: palette.primary.contrastText,
        marginRight: '20px',
    },
}))

export default function WidgetPills({ widgets }) {
    const classes = useStyles()
    const widgetHeight = 300
    const stackRef = useRef()

    const [currentWidget, setCurrentWidget] = useState(null)
    const widgetConfigs = useSelector(widgetSelectors.getWidgetConfigs)

    const widgetWidth = useMemo(() => {
        const element = stackRef.current || null
        if (element) {
            return element.clientWidth
        }
        return 400
    }, [stackRef.current])

    const handleOpen = id => {
        const widgetConfig = widgetConfigs?.[id]
        if (widgetConfig && widgetConfig.Id !== currentWidget?.Id) {
            setCurrentWidget(widgetConfig)
        }
    }

    const handleClose = () => {
        setCurrentWidget(null)
    }

    return (
        <Stack direction="row" useFlexGap ref={stackRef} spacing={1} flexWrap="wrap">
            {Object.entries(widgets).map(([id, name]: [string, string]) => (
                <Tooltip
                    title={
                        <Box
                            sx={{
                                width: '280px',
                                height: widgetHeight + 'px',
                                display: 'flex',
                                alignItems: 'center',
                            }}
                        >
                            {/* @ts-ignore */}
                            <Widget config={widgetConfigs[id]} />
                        </Box>
                    }
                    disableInteractive={false}
                >
                    <Chip
                        sx={{
                            maxWidth: '150px',
                            fontSize: '0.8rem',
                            textOverflow: 'ellipsis',
                            overflow: 'hidden',
                            height: '40px',
                            borderRadius: '24px',
                            cursor: 'pointer',
                            color: '#fff',
                        }}
                        label={name}
                        variant="outlined"
                        onClick={e => handleOpen(id)}
                    />
                </Tooltip>
            ))}

            {currentWidget && (
                <div>
                    <Divider />
                    <Box sx={{ display: 'flex', alignItems: 'center', p: 1 }}>
                        <Box
                            sx={{
                                flex: 1,
                                overflow: 'hidden',
                            }}
                        >
                            <ChopText variant="h6" showEllipsis>
                                {currentWidget.Title}
                            </ChopText>
                        </Box>
                        <div className={classes.right}>
                            <Button variant="outlined" onClick={handleClose}>
                                Close
                            </Button>
                        </div>
                    </Box>
                    <Box
                        sx={{
                            width: widgetWidth,
                            height: widgetHeight + 'px',
                            display: 'flex',
                            alignItems: 'center',
                            p: 1,
                        }}
                    >
                        {/* @ts-ignore */}
                        <Widget config={currentWidget} />
                    </Box>
                </div>
            )}
        </Stack>
    )
}
