import { Skeleton } from '@mui/material'
import withStyles from '@mui/styles/withStyles'
import { connect } from 'react-redux'
import tinyColor from 'tinycolor2'

import { getAppLogo } from '../lib/appLogoUtils'
import { logoSelectors } from '../selectors'
import { useIsMobile } from '~/hooks/useIsMobile'

const style = ({ palette, custom }) => ({
    image: props => ({
        maxHeight: `${custom.topNav.height - 25}px`,
        maxWidth: props.width ? props.width : '230px',
        verticalAlign: 'middle',
        cursor: 'pointer',
        marginLeft: '5px',
    }),
    skeleton: {
        backgroundColor: tinyColor.mostReadable(palette.background.topBar, ['#000', '#fff']).setAlpha(0.08).toString(),
    },
})

const ApplicationLogo = ({ classes, isPending, logoSrc, onClick = () => {} }) => (
    <div onClick={onClick ? onClick : undefined} className={classes.mobileLogo}>
        {logoSrc && <img className={classes.image} alt="AppLogo" src={logoSrc} draggable="false" />}
        {isPending && <Skeleton variant="rectangular" classes={{ root: classes.skeleton }} width={100} height={35} />}
    </div>
)

const mapStateToProps = (state, props) => {
    const { theme } = props
    const logo = logoSelectors.get(state)
    const { isPending, src } = logo
    const logoSrc = isPending ? '' : src || getDefaultLogo(theme)

    return { isPending, logoSrc }
}

function getDefaultLogo(theme) {
    const appLogo = getAppLogo()
    const topBarColor = theme.palette.background.topBar
    return tinyColor(topBarColor).isDark() ? appLogo.light : appLogo.dark
}

export default withStyles(style, { withTheme: true })(connect(mapStateToProps)(ApplicationLogo))
