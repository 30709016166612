import { useState } from 'react'
import { useSelector } from 'react-redux'
import { List, ListItemIcon, ListItem, ListItemText, Collapse, ListItemButton, Skeleton, Box } from '@mui/material'
import { makeStyles } from '@mui/styles'
import classNames from 'classnames'
import Bowser from 'bowser'
import { ChevronRight } from 'genesis-suite/icons'
import Spinner from 'genesis-suite/components/Spinner'
import { notificationService } from '~/lib/services'
import { applicationSelectors } from '~/selectors'
import AccountTreeOutlinedIcon from '@mui/icons-material/AccountTreeOutlined'
import AdUnitsOutlinedIcon from '@mui/icons-material/AdUnitsOutlined'

const browserName = Bowser.getParser(window.navigator.userAgent).getBrowserName()
const isEdge = browserName === 'Microsoft Edge'

const useStyles = makeStyles(({ spacing, typography, palette }) => ({
    container: { height: '100%', overflow: 'auto', padding: 0 },
    groupSticky: { position: 'sticky', top: 0, zIndex: 1, backgroundColor: palette.background.sideNav },
    ruleSticky: {
        backgroundColor: palette.primary.sideNav,
        paddingLeft: '30px',
    },
    gutters: { paddingLeft: '45px' },
    groupText: { ...typography.body1, fontWeight: 700 },
    divider: { borderBottom: '2.4px solid rgba(0, 0, 0, 0.1)' },
    expandIcon: { transition: '250ms', color: palette.text.primary },
    expandRuleIcon: { transition: '250ms', color: palette.getContrastText(palette.primary.main) },
    expandIconOpen: { transform: 'rotate(90deg)' },
    itemText: typography.body1,
    secondaryText: { color: palette.text.disabled },
    item: {
        display: 'flex',
        alignItems: 'center',
        paddingRight: spacing(1),
        '&>a': {
            flex: 1,
        },
    },
}))

export default function NotificationList({ data, pending, notificationResults }) {
    const classes = useStyles()
    const [groups, setGroups] = useState(updateData(data))
    const appName = useSelector(applicationSelectors.getCurrentAppName)

    if (pending) return <Spinner />

    if (!data) return null

    const getPrimaryText = data => {
        return data.Name + ' (' + data.Count + ')'
    }

    const Grouping = ({ group }) => {
        const CollapseIcon = (
            <ChevronRight className={classNames(classes.expandIcon, { [classes.expandIconOpen]: group.open })} />
        )

        const updateGroup = rules => {
            const data = [...groups]
            const index = data.findIndex(item => item.Id == group.Id)
            if (index > -1) {
                data[index].Rules = updateData(rules)
                setGroups(data)
            }
        }

        const groupCollapse = Bool => {
            const data = [...groups]
            const index = data.findIndex(item => item.Id == group.Id)
            if (index > -1) {
                data[index].open = Bool
                setGroups(data)
            }
        }

        const onClickGroup = async () => {
            if (!group.open && group?.Rules == undefined) {
                try {
                    const response = await notificationService.getRules(appName, group.Id)
                    updateGroup(response)
                } catch (e) {
                    console.error(e)
                }
            }
            groupCollapse(!group.open)
        }

        return (
            <>
                <ListItemButton onClick={() => onClickGroup()} className={isEdge ? '' : classes.groupSticky}>
                    <ListItemIcon>
                        <AccountTreeOutlinedIcon />
                    </ListItemIcon>
                    <ListItemText primary={getPrimaryText(group)} classes={{ primary: classes.groupText }} />
                    {CollapseIcon}
                </ListItemButton>

                {group.open && (
                    <>
                        {group.Rules !== undefined ? (
                            group.Rules.map(rule => <RuleGrouping group={group} rule={rule} />)
                        ) : (
                            <Box sx={{ p: 1 }}>
                                <Skeleton animation="wave" height={40} />
                            </Box>
                        )}
                    </>
                )}

                <div className={classes.divider} />
            </>
        )
    }

    const RuleGrouping = ({ group, rule }) => {
        const CollapseIcon = (
            <ChevronRight className={classNames(classes.expandRuleIcon, { [classes.expandIconOpen]: rule.open })} />
        )

        const updateRule = nData => {
            const data = [...groups]
            const groupIndex = data.findIndex(item => item.Id == group.Id)
            if (groupIndex > -1) {
                const ruleIndex = data[groupIndex].Rules.findIndex(r => r.Id == rule.Id)
                if (ruleIndex > -1) {
                    data[groupIndex].Rules[ruleIndex].data = nData
                    setGroups(data)
                }
            }
        }

        const ruleCollapse = Bool => {
            const data = [...groups]
            const groupIndex = data.findIndex(item => item.Id == group.Id)
            if (groupIndex > -1) {
                const ruleIndex = data[groupIndex].Rules.findIndex(r => r.Id == rule.Id)
                if (ruleIndex > -1) {
                    data[groupIndex].Rules[ruleIndex].open = Bool
                    setGroups(data)
                }
            }
        }

        const onClickRule = async () => {
            if (!rule.open && rule?.data == undefined) {
                try {
                    const response = await notificationService.get(appName, rule.Id)
                    updateRule(response)
                } catch (e) {
                    console.error(e)
                }
            }
            ruleCollapse(!rule.open)
        }

        return (
            <>
                <ListItemButton onClick={() => onClickRule()} className={isEdge ? '' : classes.ruleSticky}>
                    <ListItemIcon>
                        <AdUnitsOutlinedIcon />
                    </ListItemIcon>
                    <ListItemText primary={getPrimaryText(rule)} classes={{ primary: classes.groupText }} />
                    {CollapseIcon}
                </ListItemButton>

                {rule.open && (
                    <>
                        {rule.data !== undefined ? (
                            <NotificationList data={rule.data} />
                        ) : (
                            <Box sx={{ p: 1 }}>
                                <Skeleton animation="wave" height={40} />
                            </Box>
                        )}
                    </>
                )}
            </>
        )
    }

    const NotificationList = ({ data }) => {
        const notificationData = notificationResults(data)

        return (
            <>
                <Collapse in={true} timeout="auto" unmountOnExit>
                    <List component="div">
                        {notificationData
                            .sort((a, b) => new Date(b.creationDate) - new Date(a.creationDate))
                            .map((item, index) => {
                                const { primary, secondary, onClick, action: ActionComponent, disabled } = item

                                return (
                                    <div key={index} className={classes.item}>
                                        <ListItem
                                            dense
                                            button
                                            disabled={disabled}
                                            classes={{ gutters: classes.gutters }}
                                            onClick={onClick}
                                        >
                                            <ListItemText
                                                primary={primary}
                                                secondary={secondary}
                                                classes={{
                                                    primary: classes.itemText,
                                                    secondary: classes.secondaryText,
                                                }}
                                            />
                                        </ListItem>
                                        {ActionComponent && <ActionComponent />}
                                    </div>
                                )
                            })}
                    </List>
                </Collapse>
            </>
        )
    }

    return (
        <List className={classes.container}>
            {groups.map(group => (
                <Grouping group={group} />
            ))}
        </List>
    )
}

const updateData = data => {
    return data.map(item => ({ ...item, open: false }))
}
