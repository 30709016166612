import { default as ReactMarkdown, Components } from 'react-markdown'
import remarkGfm from 'remark-gfm'
import rehypeRaw from 'rehype-raw'

interface MarkdownProps {
    children: string
    components?: Components
}

const Markdown = ({ children, components }: MarkdownProps) => {
    if (!children) return null

    const corrected = children.replace(/\n|\\n/g, '  \n') // markdown needs 2 spaces before newlines

    return (
        <ReactMarkdown
            children={corrected}
            components={components}
            remarkPlugins={[remarkGfm]}
            rehypePlugins={[rehypeRaw]}
        />
    )
}

export default Markdown
