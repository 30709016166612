export enum MessageTagItemType {
    WIDGET = 1,
    PERSPECTIVE = 2,
    NODE = 3,
    LINK = 4,
    INSIGHT = 5,
}

export enum MessageConversationType {
    DIRECTMESSAGE = 0,
    GROUPCHAT = 1,
    PERSPECTIVEDISCUSSION = 2,
}

export enum MessageParticipantType {
    USER = 0,
    ITEM = 1,
}

export enum MessageLoadDirection {
    'UP' = 'UP',
    'DOWN' = 'DOWN',
    'BOTH' = 'BOTH',
}
