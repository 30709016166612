import { Typography, IconButton, Tooltip } from '@mui/material'
import InfoSharpIcon from '@mui/icons-material/InfoSharp'

export default function FieldLabel({ field }) {
    if (!field) return null

    return (
        <Typography fontWeight={'bold'} marginTop={0.2} variant="body1">
            {field.DisplayName}
            {field.Description && (
                <Tooltip title={field.Description || 'No description available'}>
                    <IconButton size="small">
                        <InfoSharpIcon />
                    </IconButton>
                </Tooltip>
            )}
        </Typography>
    )
}
