import { Popover } from '@mui/material'
import { MenuView } from '../views'

const MobileMenu = ({ isNavOpen, onNavClose, anchorEl }) => {
    return (
        <>
            <Popover
                open={isNavOpen}
                onClose={onNavClose}
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100%',
                }}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                anchorEl={anchorEl}
            >
                <MenuView onClose={onNavClose} />
            </Popover>
        </>
    )
}

export default MobileMenu
