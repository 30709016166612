import React, { useState, useRef } from 'react'
import { cloneDeep } from 'lodash'

import { WIDGET_CONFIG_MAP } from './lib/configMap'
import getDefaultPerspective from '../../lib/getDefaultPerspective'
import { logEvent } from '../../lib/amplitudeClient'
import { widgetConstants } from '../../constants'
import { calculatorCreators } from '../../actions/creators'

import { useDispatch } from 'react-redux'

const events = {
    canInfiniteNav: 'canInfiniteNav',
    onFilterOptionsLoaded: 'onFilterOptionsLoaded',
    onFilter: 'onFilter',
    onDrillDown: 'onDrillDown',
    onDrillDownWithCrumb: 'onDrillDownWithCrumb',
    onInfiniteNav: 'onInfiniteNav',
    onInfiniteNavWithCrumb: 'onInfiniteNavWithCrumb',
    onInlineFilters: 'onInlineFilters',
    onControl: 'onControl',
    onNavigation: 'onNavigation',
    onNavigationWithCrumb: 'onNavigationWithCrumb',
    onPageChange: 'onPageChange',
    onPageSizeChange: 'onPageSizeChange',
    onSearch: 'onSearch',
    onShowDetails: 'onShowDetails',
    onShowProfile: 'onShowProfile',
    onShowDetailsWithCrumb: 'onShowDetailsWithCrumb',
    onSort: 'onSort',
    onTooltip: 'onTooltip',
    onHighlightRow: 'onHighlightRow',
    onCalculator: 'onCalculator',
    onCopy: 'onCopy',
    onActionFilters: 'onActionFilters',
}

const InteractionContext = React.createContext()

/**
 * Provides interaction callback functions and state to widget visuals rendered as children
 * @param {Object} props.activeConfig - widget configuration currently being used to render the visual
 * @param {Object} props.baseconfig - initial widget configuration, sans any user interaction
 * @param {(Object | false)=} props.interactions - false to disable interactions, otherwise an object keyed by event name of interaction overrides
 * @param {React.Children} props.children - children components to be wrapped in the context provider
 * @param {function} props.setWidgetControl - react set state handle to set widgetControl
 * @param {Object} props.openDetails - open widget details table
 * @param {function} props.openProfile - open node profile
 * @param {Object} props.goToPerspective - go to perspective
 */
let InteractionProvider = ({
    activeConfig,
    baseConfig,
    interactions,
    children,
    setWidgetControl,
    openDetails,
    openProfile,
    setInlineFilters,
    setTooltipConfig,
    inlineFilters,
    appMode,
    interactionType,
    informationConfig,
    goToPerspective,
}) => {
    const visualConfigKey = WIDGET_CONFIG_MAP[activeConfig?.Type]?.ConfigKey

    const [state, setState] = useState({
        defaultRowCount: baseConfig?.[visualConfigKey]?.PageSize,
    })

    const [columnFilterObj, updateColumnFilterObj] = useState({})
    const [columnFilterSelectedValuesObj, saveColumnFilterObj] = useState({})
    const [highlightedRows, setHighlightedRows] = useState([])

    const dispatch = useDispatch()

    // bind global state to component instance (needed to get latest values in callbacks)
    const getInlineFilters = useRef()
    const getInteractionMode = useRef()
    const getInformationConfig = useRef()
    getInlineFilters.current = () => inlineFilters
    getInteractionMode.current = () => interactionType
    getInformationConfig.current = () => informationConfig

    // Crumb is invalid if Name, DisplayFieldName, and FieldName are null or undefined
    const isValidCrumb = crumb => {
        const { DefaultPerspective, ...coord } = crumb
        return !(!coord.Name && !coord.DisplayFieldName && !coord.FieldName && !coord.Filters)
    }

    // Construct crumb object
    const buildCrumb = (config, point, widgetConfig, cellValue) => {
        if (!point) return {}
        if (baseConfig?.Type === 'Table' && config.AggregationType !== 'Unknown') return {}

        const { DefaultPerspective: visualDefaultPerspective } = activeConfig[visualConfigKey]
        const {
            CrumbDisplayFieldName,
            CrumbFieldName,
            CrumbHeaderName,
            CrumbDisplayHeaderName,
            CrumbMetaName,
            DefaultPerspective,
        } = config

        let name
        let fieldName
        let value
        let displayFieldName
        let displayValue
        let defaultPerspective

        if (config.FlowType && config.FlowType.toLowerCase() === 'sankey') {
            name = point.crumbMetaName
            fieldName = point.crumbFieldName
            value = point.name
            displayFieldName = point.crumbDisplayFieldName
            displayValue = point.name
            defaultPerspective = DefaultPerspective || visualDefaultPerspective
        } else if (widgetConfig && widgetConfig.TableConfig && widgetConfig.TableConfig.PivotFieldConfig) {
            let pivotFieldConfig = widgetConfig.TableConfig.PivotFieldConfig
            let isTableField = false
            let columnName

            for (let key in point) {
                if (point[key] === cellValue) {
                    columnName = key
                    break
                }
            }

            if (config.HeaderName.toLowerCase() === 'series') columnName = config.HeaderName

            widgetConfig.TableConfig.Fields &&
                widgetConfig.TableConfig.Fields.forEach(field => {
                    if (field.HeaderName === columnName) isTableField = true
                })
            // case if selected element in pivot table is a non-pivoted column
            if (isTableField) {
                name = CrumbMetaName
                fieldName = CrumbFieldName
                value = cellValue
                displayFieldName = CrumbDisplayFieldName
                displayValue = cellValue
                defaultPerspective = DefaultPerspective
            } else {
                name = pivotFieldConfig.CrumbMetaName
                fieldName = pivotFieldConfig.CrumbFieldName
                value = columnName
                displayFieldName = pivotFieldConfig.CrumbDisplayFieldName
                displayValue = columnName
                defaultPerspective = pivotFieldConfig.DefaultPerspective
            }
        } else if (
            widgetConfig &&
            (widgetConfig.Type.toLowerCase() === 'heatmap' ||
                widgetConfig.Type.toLowerCase() === 'treemap' ||
                widgetConfig.Type.toLowerCase() === 'gantt')
        ) {
            name = CrumbMetaName
            fieldName = CrumbFieldName
            value = point.name
            displayFieldName = CrumbDisplayFieldName
            displayValue = point.name
            defaultPerspective = DefaultPerspective || visualDefaultPerspective
        } else {
            name = CrumbMetaName
            fieldName = CrumbFieldName
            value =
                point[CrumbFieldName] ||
                point[CrumbHeaderName] ||
                (widgetConfig && widgetConfig.TwoByTwoConfig && point['Value']) ||
                (widgetConfig &&
                    widgetConfig.TableConfig &&
                    widgetConfig.TableConfig.PivotFieldConfig &&
                    config.HeaderName)
            displayFieldName = CrumbDisplayFieldName
            displayValue =
                point[CrumbDisplayHeaderName || CrumbDisplayFieldName] ||
                point[CrumbHeaderName || CrumbFieldName] ||
                (widgetConfig && widgetConfig.TwoByTwoConfig && point['Value']) ||
                (widgetConfig &&
                    widgetConfig.TableConfig &&
                    widgetConfig.TableConfig.PivotFieldConfig &&
                    config.HeaderName)
            defaultPerspective = DefaultPerspective || visualDefaultPerspective
        }

        return {
            Name: name,
            FieldName: fieldName,
            Value: value,
            DisplayFieldName: displayFieldName,
            DisplayValue: displayValue,
            DefaultPerspective: defaultPerspective,
        }
    }

    // Construct table config for show details functionality
    const buildDetailsConfig = (context, source, title, id, cloud, model) => {
        title += ' - Details '
        id += '-Details'
        let filtersLength = context.Filters && context.Filters.length
        for (let index = 0; index < filtersLength; index++) {
            let filter = context.Filters[index]

            let filterTitleText = '[' + filter.PropertyName + ': '
            let tempId = filter.PropertyName

            if (filter.Values.length > 1) {
                filterTitleText += ' #' + filter.Values.length
                tempId += filter.Values.length
            } else {
                if (filter.Values[0] == null) continue
                filterTitleText += ' ' + filter.Values[0]
                tempId += filter.Values[0].toString().replace(new RegExp(' ', 'g'), '')
            }

            filterTitleText += ']'
            title += filterTitleText
            id += tempId
        }

        id = id.replace(/[^\w\s]/gi, '')

        let tableConfig = {
            DataUrl: '',
            DataPropertyName: 'Data',
            Source: source,
            Fields: null,
            PivotFieldConfig: null,
            ClassifierField: null,
            SetCellValueAsClass: false,
            ContainerClassName: null,
            SortOrders: null,
            PageSize: 200,
            MaxPageSize: 2000,
            PageNumber: 1,
            Context: null,
            GroupedRows: false,
            IsEditable: false,
        }

        return {
            Id: id,
            Type: 'Table',
            TableConfig: tableConfig,
            //CONTEXT MUST BE AFTER TYPE
            //DO NOT MOVE
            Context: JSON.stringify(context),
            Cloud: cloud,
            Model: model,
            Title: title,
        }
    }

    const findDistinct = (value, index, self) => {
        return self.indexOf(value) === index
    }

    const findChartSeries = (series, seriesName) => {
        let result = series.filter(s => s.SeriesName.toLowerCase() === seriesName)

        if (result && result.length) return result[0]

        let match = null
        let seriesLength = series.length
        for (let index = 0; index < seriesLength; index++) {
            if (!series[index].SubSeries || !series[index].SubSeries.length) continue

            match = findChartSeries(series[index].SubSeries, seriesName)
        }
        return match
    }

    const findChartSource = (chartConfig, seriesName) => {
        if (!seriesName || !seriesName.trim().length) return null

        const series = findChartSeries(chartConfig.Series, seriesName.toLowerCase())

        if (!series) return null
        const sourceObj = { ...series.Source }
        sourceObj.Context = series.Context

        if (chartConfig.Source && chartConfig.Source.Filters && chartConfig.Source.Filters.trim().length)
            sourceObj.Filters = chartConfig.Source.Filters

        return sourceObj
    }

    const findHeatmapSource = (heatmapConfig, $target, seriesName) => {
        if (seriesName == null || seriesName.length === 0) return null

        var filteredContext = heatmapConfig.Context.Filters.filter(item => {
            return item.Values[0] === seriesName
        })

        heatmapConfig.Source.Context = heatmapConfig.Context
        heatmapConfig.Source.Context.Filters = filteredContext

        return heatmapConfig.Source
    }

    const findMapSource = (mapConfig, $target, seriesName) => {
        if (!seriesName || !seriesName.trim().length) return null

        if (mapConfig.MarkerSeries) {
            let series = mapConfig.MarkerSeries.filter(
                marker => marker.SeriesName.toLowerCase() === seriesName.toLowerCase()
            )

            if (!series || !series.length) return null

            return series[0].Source
        }

        if (mapConfig.HeatMapSeries) {
            let series = mapConfig.HeatMapSeries.filter(
                heatmap => heatmap.SeriesName.toLowerCase() === seriesName.toLowerCase()
            )

            if (!series || !series.length) return null

            return series[0].Source
        }
        return null
    }

    const findLabelSource = (labelConfig, seriesName) => {
        let series = labelConfig.LabelSeries.filter(
            label => label.SeriesName.toLowerCase() === seriesName.toLowerCase()
        )

        if (!series || !series.length) return null

        return series[0].Source
    }

    // Find source for an interaction mode
    const findSource = (config, $target, seriesName) => {
        const type = config.Type.toLowerCase()
        switch (type) {
            case 'chart':
                switch (config.ChartConfig.ChartType.toString().toLowerCase()) {
                    case 'pie':
                    case 'doughnut':
                    case 'funnel':
                    case 'pyramid':
                        return config.ChartConfig.Source
                    case 'column':
                    case 'area':
                    case 'line':
                    case 'bar':
                    case 'waterfall':
                    case 'scatterplot':
                    case 'columnwithtarget':
                        return findChartSource(config.ChartConfig, seriesName)
                    case 'gantt':
                    case 'tornado':
                    case 'strategicpotfolio':
                    case 'governance':
                    case 'card':
                    case 'architecture':
                    default:
                        break
                }
                break
            case 'map':
                return findMapSource(config.MapConfig, $target, seriesName)
            case 'flow':
                switch (config.FlowConfig.FlowType.toString().toLowerCase()) {
                    case 'sankey':
                        return config.FlowConfig.Source
                    default:
                        break
                }
                break
            case 'heatmap':
                return findHeatmapSource(config.HeatmapConfig, $target, seriesName)
            case 'table':
                return config.TableConfig.Source
            case 'list':
                return config.ListConfig.Source
            case 'label':
                return findLabelSource(config.LabelConfig, seriesName)
            case 'bubblechart':
                return config.BubbleChartConfig.Source
            case 'card':
            case 'architecture':
            case 'matrix':
            case 'masonry':
            case 'radar':
                break
            case 'twobytwo':
                return config.TwoByTwoConfig.Source
            case 'histogram':
                break
            case 'pnl':
                return config.PnLConfig.Source
            case 'bullet':
                return config.BulletConfig.Series[0].Source
            case 'treemap':
                return config.TreemapConfig.Source
            case 'gauge':
                return config.GaugeConfig.Source
            case 'gantt':
                return config.Source
            default:
                return null
        }
        return null
    }

    // Merge multiple context objects
    const mergeContexts = (contexts, Id) => {
        if (!contexts || !(contexts instanceof Array) || !contexts.length) return null

        let result = null
        let contextsLength = contexts.length

        for (let index = 0; index < contextsLength; index++) {
            let context = contexts[index]

            if (!result) result = {}

            result.CloudName = context.CloudName && context.CloudName.trim().length > 0 ? context.CloudName : null

            result.ModelName = context.ModelName && context.ModelName.trim().length > 0 ? context.ModelName : null

            if (context.Filters) {
                let contextFiltersLength = context.Filters.length
                for (let filterIndex = 0; filterIndex < contextFiltersLength; filterIndex++) {
                    let filter = context.Filters[filterIndex]

                    if (!result.Filters) result.Filters = []

                    let existingFilters = result.Filters.filter(
                        resultFilter =>
                            resultFilter.PropertyName.toLowerCase() === filter.PropertyName.toLowerCase() &&
                            resultFilter.ResourceName.toLowerCase() === filter.ResourceName.toLowerCase() &&
                            resultFilter.ResourceType.toLowerCase() === filter.ResourceType.toLowerCase()
                    )

                    if (!existingFilters || !existingFilters.length) {
                        result.Filters.push(filter)
                        continue
                    }

                    //The match should not contain more than one.
                    let existingFilter = existingFilters[0]

                    if (!filter.Values) filter.Values = []

                    if (!existingFilter.Values) existingFilter.Values = []

                    if (filter.Values[0] != null && existingFilter.Values.indexOf(filter.Values[0]) == -1)
                        existingFilter.Values.push(filter.Values[0])
                }
            }

            if (!context.FieldName) continue

            if (!result.Filters) result.Filters = []

            let existingFilters = result.Filters.filter(
                resultFilter =>
                    resultFilter.PropertyName.toLowerCase() === context.FieldName.toLowerCase() &&
                    resultFilter.ResourceName.toLowerCase() === context.Name.toLowerCase()
            )

            if (!existingFilters || !existingFilters.length) {
                result.Filters.push({
                    FilterName: context.Name + new Date().getUTCMilliseconds(),
                    PropertyName: context.FieldName,
                    ResourceName: context.Name,
                    ResourceType: 'Concept',
                    Values: [context.Value],
                    WidgetId: Id,
                })

                continue
            }

            //The match should not contain more than one.
            let existingFilter = existingFilters[0]

            if (!existingFilter.Values) existingFilter.Values = []

            existingFilter.Values.push(context.Value)

            let uniqueFilterValues = existingFilter.Values.filter(findDistinct)

            existingFilter.Values = uniqueFilterValues
        }

        return result
    }

    // Construct combined context
    const buildCombinedContext = (
        coord,
        seriesName,
        widgetConfig,
        seriesConfig,
        ignoreCoord,
        ignoreAdjacentElements,
        point,
        columns,
        cellValue
    ) => {
        const informationConfig = getInformationConfig.current()
        if (informationConfig) widgetConfig = informationConfig

        if (ignoreCoord == null) ignoreCoord = false

        if (ignoreAdjacentElements == null) ignoreAdjacentElements = false

        let contexts = null

        if (coord && coord.toString().trim().length > 0 && !ignoreCoord) {
            if (contexts == null) contexts = []

            contexts.push(coord)
        }

        let crumbSpecificFilter = mergeContexts(contexts, widgetConfig.Id)

        if (
            widgetConfig.Context &&
            widgetConfig.Context.toString().trim().length > 0 &&
            widgetConfig.Context.toString() !== '{}' &&
            isValidCrumb(JSON.parse(widgetConfig.Context))
        ) {
            if (!contexts) contexts = []

            let context = JSON.parse(widgetConfig.Context)

            if (widgetConfig.Filters && widgetConfig.Filters.length > 0) context.Filters = widgetConfig.Filters

            contexts.push(context)
        }
        if (
            widgetConfig.Type &&
            widgetConfig.Type.toLowerCase() === 'heatmap' &&
            widgetConfig.HeatmapConfig.Context.Filters.length > 1
        ) {
            let HeatmapFilters = widgetConfig.HeatmapConfig.Context.Filters
            widgetConfig.HeatmapConfig.filters = HeatmapFilters
        }
        if (widgetConfig.HeatmapConfig && widgetConfig.HeatmapConfig.Context.Filters.length == 1) {
            widgetConfig.HeatmapConfig.Context.Filters = widgetConfig.HeatmapConfig.filters
        }

        if (widgetConfig.Type.toLowerCase() === 'table' && !ignoreAdjacentElements) {
            let adjacentCrumb
            for (let i = 0; i < columns.length; i++) {
                if (seriesConfig.HeaderName === columns[i].HeaderName) continue
                if (isNaN(point[columns[i].HeaderName])) {
                    if (columns[i].HeaderName && columns[i].HeaderName.toString().toLowerCase() === 'series')
                        adjacentCrumb = buildCrumb(columns[i], point, widgetConfig, cellValue)
                    else adjacentCrumb = buildCrumb(columns[i], point)
                    if (
                        isValidCrumb(coord) &&
                        isValidCrumb(adjacentCrumb) &&
                        adjacentCrumb.Name &&
                        coord.Name &&
                        adjacentCrumb.Value &&
                        adjacentCrumb.Value.toString().toLowerCase() !== 'series' &&
                        adjacentCrumb.Name.toString().toLowerCase() !== coord.Name.toString().toLowerCase()
                    ) {
                        contexts.push(adjacentCrumb)
                    }
                }
            }
        }

        let source = findSource(widgetConfig, document.getElementById('interaction-tooltip-target'), seriesName)

        if (!source) return null

        if (source.Context) {
            if (!contexts) contexts = []

            contexts.push(source.Context)
        }

        if (!contexts || !contexts.length) return null

        return {
            context: mergeContexts(contexts, widgetConfig.Id),
            crumbSpecificFilter,
            source,
            widgetConfig,
        }
    }

    const _events = {
        [events.canInfiniteNav]: (seriesConfig, point) => {
            const crumb = buildCrumb(seriesConfig, point)
            const perspectiveId = getDefaultPerspective(crumb)
            return !!perspectiveId
        },
        [events.onInfiniteNav]: (seriesConfig, point, widgetConfig, cellValue) => {
            logEvent(events.onInfiniteNav)
            const crumb = buildCrumb(seriesConfig, point, widgetConfig, cellValue)
            const perspectiveId = getDefaultPerspective(crumb)
            if (perspectiveId) goToPerspective(perspectiveId, crumb)
        },
        [events.onDrillDown]: (seriesConfig, point, widgetConfig, cellValue) => {
            logEvent(events.onDrillDown)
            const crumb = buildCrumb(seriesConfig, point, widgetConfig, cellValue)
            let seriesName = point.series && point.series.name
            if (widgetConfig.MapConfig) seriesName = seriesConfig.SeriesName
            let combinedContext = buildCombinedContext(crumb, seriesName, widgetConfig, seriesConfig, false, true)
            if (
                combinedContext &&
                combinedContext.context &&
                combinedContext.context.Filters &&
                combinedContext.context.Filters.length > 0
            ) {
                if (!crumb.Filters) crumb.Filters = []

                crumb.Filters = crumb.Filters.concat(combinedContext.context.Filters)
            }
            const perspectiveId = getDefaultPerspective(crumb)
            if (perspectiveId) goToPerspective(perspectiveId, crumb)
        },
        [events.onNavigation]: (seriesConfig, point, widgetConfig, cellValue) => {
            let interactMode = getInteractionMode.current()
            if (!interactMode) return

            if (interactMode === widgetConstants.Interactions.INFINITE)
                _events.onInfiniteNav(seriesConfig, point, widgetConfig, cellValue)
            else if (interactMode === widgetConstants.Interactions.DRILLDOWN)
                _events.onDrillDown(seriesConfig, point, widgetConfig, cellValue)
        },
        [events.onNavigationWithCrumb]: (crumb, widgetConfig) => {
            let interactMode = getInteractionMode.current()
            if (interactMode === widgetConstants.Interactions.INFINITE) _events.onInfiniteNavWithCrumb(crumb)
            else if (interactMode === widgetConstants.Interactions.DRILLDOWN)
                _events.onDrillDownWithCrumb(crumb, widgetConfig)
        },
        [events.onControl]: (seriesConfig, point, widgetConfig, cellValue, multiSelect) => {
            logEvent(events.onControl)
            let contexts = cloneDeep(getInlineFilters.current()?.contexts || {})

            // Returns the multiple filters
            const buildMultipleFilters = contexts => {
                let combinedFilters = []
                Object.keys(contexts).forEach(widgetId => {
                    Object.keys(contexts[widgetId]).forEach(key => {
                        if (!contexts[widgetId][key].filters) return
                        contexts[widgetId][key].filters.forEach((filter, index) => {
                            const combinedIndex = combinedFilters.findIndex(f => {
                                if (f.PropertyName !== filter.PropertyName) return false
                                if (f.ResourceName !== filter.ResourceName) return false
                                if (f.WidgetId !== filter.WidgetId) return false

                                return true
                            })

                            if (combinedIndex > -1) {
                                if (!combinedFilters[combinedIndex].Values.includes(filter.Values[0]))
                                    combinedFilters[combinedIndex].Values.push(filter.Values[0])
                            } else combinedFilters.push(filter)
                        })
                    })
                })

                return combinedFilters
            }

            const crumb = buildCrumb(seriesConfig, point, widgetConfig, cellValue)

            const { name, value, series } = point
            const seriesName = series?.name

            const combinedContext = buildCombinedContext(crumb, seriesName, widgetConfig, seriesConfig, false, true)
            const crumbSpecificFilter = combinedContext?.crumbSpecificFilter.Filters
            const newFilter = { context: { seriesName, name, value }, filters: crumbSpecificFilter }

            if (!multiSelect) {
                contexts = { [widgetConfig.Id]: { [cellValue]: newFilter } }
            } else {
                let widgetFilters = contexts?.[widgetConfig.Id]
                const isSelected = widgetFilters?.[cellValue]

                if (isSelected) {
                    delete widgetFilters[cellValue]
                    if (!Object.keys(widgetFilters).length) return
                } else {
                    if (!widgetFilters) {
                        widgetFilters = {}
                        contexts[widgetConfig.Id] = widgetFilters
                    }

                    widgetFilters[cellValue] = newFilter
                }
            }

            setInlineFilters({
                contexts,
                filters: buildMultipleFilters(contexts),
                widgetId: widgetConfig.Id,
            })
        },
        [events.onActionFilters]: (seriesConfig, point, widgetConfig, cellValue, pnlCellId, mapCellId) => {
            logEvent(events.onActionFilters)

            const getKey = () => {
                if (widgetConfig.TableConfig) return cellValue
                else if (widgetConfig.PnLConfig) {
                    const PnLKey = pnlCellId && JSON.parse(JSON.stringify(pnlCellId))
                    if (!PnLKey.parentName) PnLKey.parentName = 'PnL'
                    return `${PnLKey.keyfield}${PnLKey.parentName}`
                } else if (widgetConfig.MapConfig && widgetConfig.MapConfig.MapViewType.toLowerCase() === 'satellite') {
                    return `${
                        point[seriesConfig.MarkerLabelField]
                            ? point[seriesConfig.MarkerLabelField]
                            : point[seriesConfig.MarkerField]
                    }%${point[seriesConfig.LatitudeField]}%${point[seriesConfig.LongitudeField]}`
                } else if (widgetConfig.MapConfig && widgetConfig.MapConfig.MapViewType.toLowerCase() === 'standard') {
                    return mapCellId
                } else return `${point?.series?.name}-${point?.name}`
            }

            const filterKey = getKey()

            const buildMultipleFilters = contexts => {
                let combinedFilters = []
                Object.keys(contexts).forEach(widgetId => {
                    Object.keys(contexts[widgetId]).forEach(key => {
                        if (!contexts[widgetId][key].filters) return
                        contexts[widgetId][key].filters.forEach((filter, index) => {
                            const combinedIndex = combinedFilters.findIndex(f => {
                                if (f.PropertyName !== filter.PropertyName) return false
                                if (f.ResourceName !== filter.ResourceName) return false
                                if (f.WidgetId !== filter.WidgetId) return false

                                return true
                            })

                            if (combinedIndex > -1) {
                                if (!combinedFilters[combinedIndex].Values.includes(filter.Values[0]))
                                    combinedFilters[combinedIndex].Values.push(filter.Values[0])
                            } else combinedFilters.push(filter)
                        })
                    })
                })

                return combinedFilters
            }

            const crumb =
                widgetConfig.PnLConfig ||
                (widgetConfig.MapConfig && widgetConfig.MapConfig.MapViewType.toLowerCase() === 'standard')
                    ? point
                    : buildCrumb(seriesConfig, point, widgetConfig, cellValue)

            const { name, value } = point
            let seriesName = point.series && point.series.name
            if (widgetConfig.Type.toLowerCase() === 'heatmap') seriesName = point.category.toString()
            if (widgetConfig.MapConfig) seriesName = seriesConfig.SeriesName

            let combinedContext = buildCombinedContext(crumb, seriesName, widgetConfig, seriesConfig, false, true)

            if (!isValidCrumb(crumb) || !combinedContext) return

            const isStackedOrGrouped = widgetConfig.ChartConfig?.Series?.some(s => s.StackOnField || s.GroupOnField)
            const crumbSpecificFilter = isStackedOrGrouped
                ? combinedContext.context.Filters
                : combinedContext.crumbSpecificFilter.Filters

            const contexts = {
                [widgetConfig.Id]: {
                    [filterKey]: {
                        context: { seriesName: seriesName, name, value },
                        filters: crumbSpecificFilter,
                        pnlCellId: pnlCellId,
                    },
                },
            }
            const filter = {
                contexts,
                filters: buildMultipleFilters(contexts),
                widgetId: widgetConfig.Id,
            }

            return filter
        },
        [events.onInlineFilters]: (seriesConfig, point, widgetConfig, cellValue, pnlCellId, mapCellId) => {
            logEvent(events.onInlineFilters)
            let inlineFilters = getInlineFilters.current()

            const getKey = () => {
                if (widgetConfig.TableConfig) return cellValue
                else if (widgetConfig.PnLConfig) {
                    const PnLKey = pnlCellId && JSON.parse(JSON.stringify(pnlCellId))
                    if (!PnLKey.parentName) PnLKey.parentName = 'PnL'
                    return `${PnLKey.keyfield}${PnLKey.parentName}`
                } else if (widgetConfig.MapConfig && widgetConfig.MapConfig.MapViewType.toLowerCase() === 'satellite') {
                    return `${
                        point[seriesConfig.MarkerLabelField]
                            ? point[seriesConfig.MarkerLabelField]
                            : point[seriesConfig.MarkerField]
                    }%${point[seriesConfig.LatitudeField]}%${point[seriesConfig.LongitudeField]}`
                } else if (widgetConfig.MapConfig && widgetConfig.MapConfig.MapViewType.toLowerCase() === 'standard') {
                    return mapCellId
                } else return `${point?.series?.name}-${point?.name}`
            }

            const filterKey = getKey()

            // Returns the multiple filters
            const buildMultipleFilters = contexts => {
                let combinedFilters = []
                Object.keys(contexts).forEach(widgetId => {
                    Object.keys(contexts[widgetId]).forEach(key => {
                        if (!contexts[widgetId][key].filters) return
                        contexts[widgetId][key].filters.forEach((filter, index) => {
                            const combinedIndex = combinedFilters.findIndex(f => {
                                if (f.PropertyName !== filter.PropertyName) return false
                                if (f.ResourceName !== filter.ResourceName) return false
                                if (f.WidgetId !== filter.WidgetId) return false

                                return true
                            })

                            if (combinedIndex > -1) {
                                if (!combinedFilters[combinedIndex].Values.includes(filter.Values[0]))
                                    combinedFilters[combinedIndex].Values.push(filter.Values[0])
                            } else combinedFilters.push(filter)
                        })
                    })
                })

                return combinedFilters
            }

            const crumb =
                widgetConfig.PnLConfig ||
                (widgetConfig.MapConfig && widgetConfig.MapConfig.MapViewType.toLowerCase() === 'standard')
                    ? point
                    : buildCrumb(seriesConfig, point, widgetConfig, cellValue)

            const { name, value } = point
            let seriesName = point.series && point.series.name
            if (widgetConfig.Type.toLowerCase() === 'heatmap') seriesName = point.category.toString()
            if (widgetConfig.MapConfig) seriesName = seriesConfig.SeriesName
            let clonedInlineFilters = inlineFilters && cloneDeep(inlineFilters)

            let combinedContext = buildCombinedContext(crumb, seriesName, widgetConfig, seriesConfig, false, true)

            // Removes the duplicate filter
            if (clonedInlineFilters) {
                if (inlineFilters.contexts && widgetConfig.Id in inlineFilters.contexts) {
                    if (filterKey in inlineFilters.contexts[widgetConfig.Id]) {
                        if (Object.keys(clonedInlineFilters.contexts[widgetConfig.Id]).length === 1)
                            delete clonedInlineFilters.contexts[widgetConfig.Id]
                        else delete clonedInlineFilters.contexts[widgetConfig.Id][filterKey]

                        setInlineFilters({
                            contexts: clonedInlineFilters.contexts,
                            filters: buildMultipleFilters(clonedInlineFilters.contexts),
                            widgetId: null,
                            scrollTop: null,
                        })

                        return
                    }
                }
            }

            if (!isValidCrumb(crumb) || !combinedContext) return

            if (!combinedContext.crumbSpecificFilter) {
                setInlineFilters({
                    contexts: null,
                    filters: combinedContext.context.Filters,
                    widgetId: widgetConfig.Id,
                })

                return
            }

            const scrollTop =
                widgetConfig.BulletConfig && point.graphic.element.closest('.widget-parent-wrapper-bullet').scrollTop
            const isStackedOrGrouped = widgetConfig.ChartConfig?.Series?.some(s => s.StackOnField || s.GroupOnField)
            const crumbSpecificFilter = isStackedOrGrouped
                ? combinedContext.context.Filters
                : combinedContext.crumbSpecificFilter.Filters

            // Creates the inline filter object if the inlinefilters object is null
            if (!clonedInlineFilters) {
                const contexts = {
                    [widgetConfig.Id]: {
                        [filterKey]: {
                            context: { seriesName: seriesName, name, value },
                            filters: crumbSpecificFilter,
                            pnlCellId: pnlCellId,
                        },
                    },
                }

                setInlineFilters({
                    contexts,
                    filters: buildMultipleFilters(contexts),
                    widgetId: widgetConfig.Id,
                    scrollTop,
                })
            } // If the next inline filter is applied on the same widget
            else if (widgetConfig.Id in inlineFilters.contexts) {
                let contextObject = {
                    context: { seriesName: seriesName, name, value },
                    filters: crumbSpecificFilter,
                    pnlCellId: pnlCellId,
                }

                clonedInlineFilters.contexts[widgetConfig.Id][filterKey] = contextObject

                setInlineFilters({
                    contexts: clonedInlineFilters.contexts,
                    filters: buildMultipleFilters(clonedInlineFilters.contexts),
                    widgetId: widgetConfig.Id,
                    scrollTop,
                })
            }
            // If the inline filter is applied on the different widget
            else {
                clonedInlineFilters.contexts[widgetConfig.Id] = {
                    [filterKey]: {
                        context: { seriesName: seriesName, name, value },
                        filters: crumbSpecificFilter,
                        pnlCellId: pnlCellId,
                    },
                }

                setInlineFilters({
                    contexts: clonedInlineFilters.contexts,
                    filters: buildMultipleFilters(clonedInlineFilters.contexts),
                    widgetId: widgetConfig.Id,
                    scrollTop,
                })
            }
        },
        [events.onShowDetails]: (seriesConfig, point, widgetConfig, cellValue, columns) => {
            logEvent(events.onShowDetails)
            let crumb = buildCrumb(seriesConfig, point, widgetConfig, cellValue)
            if (widgetConfig.MapConfig && widgetConfig.MapConfig.MapViewType.toLowerCase() === 'standard') {
                crumb = point
            }
            let seriesName = point.series && point.series.name
            if (widgetConfig.MapConfig) seriesName = seriesConfig.SeriesName
            if (widgetConfig.Type.toLowerCase() === 'heatmap') seriesName = point.category.toString()

            let combinedContext = buildCombinedContext(
                crumb,
                seriesName,
                widgetConfig,
                seriesConfig,
                null,
                null,
                point,
                columns,
                cellValue
            )

            if (!combinedContext || !combinedContext.context || !combinedContext.source) return

            let combinedContextFiltersLength = combinedContext.context.Filters && combinedContext.context.Filters.length
            for (let index = 0; index < combinedContextFiltersLength; index++) {
                let filter = combinedContext.context.Filters[index]

                if (
                    filter.ResourceName == null ||
                    crumb.FieldName == null ||
                    filter.ResourceName.toString() !== crumb.FieldName.toString()
                )
                    continue

                filter.Values = [crumb.Value]
            }

            let { context, source } = combinedContext

            let { Id, Title, Cloud, Model } = combinedContext.widgetConfig
            let detailsConfig = buildDetailsConfig(context, source, Title, Id, Cloud, Model)
            openDetails(detailsConfig, context)
        },
        [events.onShowProfile]: (seriesConfig, point) => {
            logEvent(events.onShowProfile)
            const { CrumbMetaName, CrumbFieldName, CrumbHeaderName } = seriesConfig
            openProfile(CrumbMetaName, CrumbFieldName, point[CrumbHeaderName || CrumbFieldName])
        },
        [events.onInfiniteNavWithCrumb]: crumb => {
            logEvent(events.onInfiniteNavWithCrumb)
            if (!crumb) return
            const perspectiveId = getDefaultPerspective(crumb)
            if (perspectiveId) goToPerspective(perspectiveId, crumb)
        },
        [events.onDrillDownWithCrumb]: (crumb, widgetConfig) => {
            logEvent(events.onDrillDownWithCrumb)
            let combinedContext = buildCombinedContext(crumb, null, widgetConfig, null, false, true)
            if (
                combinedContext &&
                combinedContext.context &&
                combinedContext.context.Filters &&
                combinedContext.context.Filters.length > 0
            ) {
                if (!crumb.Filters) crumb.Filters = []

                crumb.Filters = crumb.Filters.concat(combinedContext.context.Filters)
            }
            const perspectiveId = getDefaultPerspective(crumb)
            if (perspectiveId) goToPerspective(perspectiveId, crumb)
        },
        [events.onShowDetailsWithCrumb]: (crumb, widgetConfig) => {
            logEvent(events.onShowDetailsWithCrumb)
            let combinedContext = buildCombinedContext(crumb, null, widgetConfig)

            if (!combinedContext || !combinedContext.context || !combinedContext.source) return

            let combinedContextFiltersLength = combinedContext.context.Filters && combinedContext.context.Filters.length
            for (let index = 0; index < combinedContextFiltersLength; index++) {
                let filter = combinedContext.context.Filters[index]

                if (
                    filter.ResourceName == null ||
                    crumb.FieldName == null ||
                    filter.ResourceName.toString() !== crumb.FieldName.toString()
                )
                    continue

                filter.Values = [crumb.Value]
            }

            let { context, source } = combinedContext

            let { Id, Title, Cloud, Model } = combinedContext.widgetConfig
            let detailsConfig = buildDetailsConfig(context, source, Title, Id, Cloud, Model)
            openDetails(detailsConfig, context)
        },
        [events.onPageChange]: pageNumber => {
            setWidgetControl(s => ({ ...s, pageNumber }))
        },
        [events.onPageSizeChange]: pageSize => {
            setWidgetControl(s => ({ ...s, pageSize, pageNumber: 1 }))
        },
        [events.onSort]: sortOrders => {
            setWidgetControl(s => ({ ...s, sortOrders }))
        },
        [events.onSearch]: (field, query) => {
            if (!query?.length) return

            const queryString = `${field}<c>${query}`
            const searchUrl = `${baseConfig[visualConfigKey].DataUrl}&cf=${encodeURIComponent(queryString)}`
            setWidgetControl(s => ({ ...s, searchUrl }))
        },
        [events.onHighlightRow]: (point, isElement = false) => {
            if (!point) return
            if (isElement) {
                setHighlightedRows(point)
            } else {
                const id = point.__index
                if (highlightedRows.includes(id)) setHighlightedRows(highlightedRows.filter(row => row !== id))
                else setHighlightedRows([...highlightedRows, id])
            }
        },
        [events.onTooltip]: data => {
            if (!data) return setTooltipConfig(null)

            const {
                seriesConfig,
                seriesData,
                point,
                html,
                target,
                /** optional. table cell value used for show details */
                cellValue,
                /** optional. if true, inline filter is already applied to point */
                inlineFilterFlag,
                /** optional. if provided, trigger mobile tooltip with form of { pageX: number, pageY: number } */
                mobileClickPosition,
                /** optional. if true, don't show interactions */
                hideInteractions,
            } = data

            const handleInteraction = interactionEvent => {
                if (activeConfig.Type.toLowerCase() === 'table') {
                    if (interactionEvent === events.onShowDetails)
                        tryInteraction(interactionEvent, seriesConfig, point, activeConfig, cellValue, seriesData)
                    else if (interactionEvent === events.onHighlightRow) tryInteraction(interactionEvent, point)
                    else if (interactionEvent === events.onCalculator) {
                        dispatch(calculatorCreators.setCurrentVal({ currentVal: cellValue }))
                    } else if (interactionEvent === events.onCopy) {
                        navigator.clipboard.writeText(cellValue)
                        dispatch(calculatorCreators.setCurrentVal({ currentVal: cellValue }))
                    } else if (interactionEvent === events.onActionFilters) {
                        return tryInteraction(interactionEvent, seriesConfig, point, activeConfig, cellValue)
                    } else tryInteraction(interactionEvent, seriesConfig, point, activeConfig, cellValue)
                } else if (activeConfig.Type.toLowerCase() === 'pnl') {
                    if (interactionEvent.toLowerCase() === 'oninlinefilters' || 'onActionFilters') {
                        let parentEl = target.parentElement
                        if (parentEl.nodeName === 'TD') parentEl = parentEl.parentElement
                        let pnlCellId = {
                            keyfield: parentEl.getAttribute('keyfield'),
                            parentName: parentEl.getAttribute('parentName'),
                        }
                        if (interactionEvent.toLowerCase() === 'oninlinefilters') {
                            target.style.borderColor = !inlineFilterFlag ? 'red' : null
                            target.style.borderStyle = !inlineFilterFlag ? 'solid' : null
                            target.style.borderWidth = !inlineFilterFlag ? 'medium' : null
                            !inlineFilterFlag
                                ? target.classList.add('inlinefilteredwidget')
                                : target.classList.remove('inlinefilteredwidget')
                            tryInteraction(interactionEvent, seriesConfig, point, activeConfig, null, pnlCellId, null)
                        }
                        if (interactionEvent === events.onActionFilters) {
                            return tryInteraction(
                                interactionEvent,
                                seriesConfig,
                                point,
                                activeConfig,
                                null,
                                pnlCellId,
                                null
                            )
                        }
                    } else tryInteraction('onShowDetailsWithCrumb', point, activeConfig)
                } else if (
                    activeConfig.MapConfig &&
                    activeConfig.MapConfig.MapViewType.toLowerCase() === 'standard' &&
                    interactionEvent.toLowerCase() === 'oninlinefilters'
                ) {
                    let seriesName = target.getAttribute('seriesname')
                    let mapCellId
                    if (activeConfig.MapConfig && activeConfig.MapConfig.HeatMapSeries) {
                        let values = JSON.parse(target.getAttribute('values'))
                        mapCellId = `${seriesName}$${values.id}`
                    } else {
                        let latLng = JSON.parse(target.getAttribute('loc'))
                        mapCellId = `${seriesName}$${latLng[0]}$${latLng[1]}`
                    }
                    if (interactionEvent === events.onInlineFilters) {
                        target.style.stroke = 'red'
                        target.style.strokeWidth = 2
                        target.classList.add('inlinefilteredwidget')
                        tryInteraction(interactionEvent, seriesConfig, point, activeConfig, null, null, null, mapCellId)
                    }
                    if (interactionEvent === events.onActionFilters) {
                        return tryInteraction(
                            interactionEvent,
                            seriesConfig,
                            point,
                            activeConfig,
                            null,
                            null,
                            null,
                            mapCellId
                        )
                    }
                } else if (
                    activeConfig.Type.toLowerCase() === 'twobytwo' &&
                    interactionEvent.toLowerCase() === 'oninlinefilters'
                ) {
                    if (interactionEvent.toLowerCase() === 'oninlinefilters') {
                        target.style.stroke = 'red'
                        target.style.strokeWidth = 3
                        target.classList.add('inlinefilteredwidget')
                    }
                } else if (interactionEvent === events.onActionFilters) {
                    return tryInteraction(interactionEvent, seriesConfig, point, activeConfig, seriesData)
                } else tryInteraction(interactionEvent, seriesConfig, point, activeConfig, seriesData)
            }

            const canNavigate = tryInteraction(events.canInfiniteNav, seriesConfig, point)

            setTooltipConfig({
                handleInteraction,
                mobileClickPosition,
                html,
                hideInteractions,
                inlineFilterFlag,
                appMode,
                canNavigate,
            })
        },
    }

    const tryInteraction = (event, ...args) => {
        if (interactions === false) return
        if (interactions[event]) return interactions[event](...args)
        else return _events[event](...args)
    }

    const getInteractionState = key => state[key]
    const setInteractionState = (key, value) => setState(state => ({ ...state, [key]: value }))

    return (
        <InteractionContext.Provider
            value={{
                events,
                tryInteraction,
                baseConfig,
                inlineFilters,
                appMode,
                getInteractionState,
                setInteractionState,
                setInlineFilters,
                updateColumnFilterObj,
                columnFilterObj,
                saveColumnFilterObj,
                columnFilterSelectedValuesObj,
                highlightedRows,
            }}
        >
            {children}
        </InteractionContext.Provider>
    )
}

InteractionProvider.defaultProps = {
    interactions: {},
}

export { InteractionContext, InteractionProvider }
