import { Button, FormControlLabel, TextField, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { isEqual } from 'lodash'
import { useContext, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { SwalContext } from 'genesis-suite/components'
import { useUpdateEffect } from 'genesis-suite/hooks'
import { VisualType } from 'genesis-suite/types/visualTypes'
import { authCreators, moduleCreators } from '../../actions/creators'
import { useRevalidateModules } from '../../hooks/useModules'
import { useFeature } from '../../lib/featureFlags'
import { appearanceSelectors, authSelectors, moduleSelectors } from '../../selectors'
import BooleanSelect from '../BooleanSelect'
import VisualVersionWithHistoryButton from '../widgets2/VisualVersionWithHistoryButton'

const useStyles = makeStyles(({ spacing, palette }) => ({
    manageModule: {
        padding: spacing(),
        '& .MuiInputLabel-root': { color: palette.text.primary },
        '& .MuiFormHelperText-root': { color: palette.text.primary },
        '& .MuiFormHelperText-root.Mui-error': { color: palette.error.main },
        '& .MuiInput-root::before': { borderColor: palette.text.primary },
    },
    field: { marginBottom: spacing(2) },
    deleteButton: { marginTop: spacing(3), backgroundColor: palette.status.error, color: '#fff' },
    convertButton: { marginTop: spacing(3) },
}))

export default function ManageModule({ updateSaveHandle, updateCancelHandle, onLoading, onClose, onError, module }) {
    const { alert, confirm } = useContext(SwalContext)
    const [enableBuilder] = useFeature('builder')
    const revalidateModules = useRevalidateModules()

    const otherModules = useSelector(moduleSelectors.getAllModules).filter(m => m.id !== module?.id)
    const currentModuleId = useSelector(moduleSelectors.getModuleId)
    const defaultModuleName = useSelector(authSelectors.getDefaultModule)
    const currentLayout = useSelector(appearanceSelectors.getApplicationLayout)
    const isV2 = module?.version === '2'
    const dispatch = useDispatch()

    const [draft, setDraft] = useState({})

    const { active = false, name = '', title = '', hideNetworkDiagram = false, hideBusinessExplorer = false } = draft

    const nameAlreadyUsed = otherModules.some(m => m.name.toLowerCase() === name.toLowerCase())
    const nameHelperText = nameAlreadyUsed
        ? 'Name already used by another Application'
        : !name
        ? 'Name is required'
        : 'Caution: Changing the name will change the url which will impact current users of the Application'

    const classes = useStyles()

    useEffect(() => {
        if (!module) return
        setDraft({ ...module })
    }, [module])

    useUpdateEffect(() => {
        if (!module) return

        const isDraftModule = Boolean(module.draft)
        const isSame = isEqual(module, draft)
        const isError = (nameAlreadyUsed && !isDraftModule) || !draft.name || !draft.title

        updateSaveHandle(isSame ? undefined : { label: 'Save', onClick: saveModule })
        updateCancelHandle(isSame ? undefined : () => setDraft({ name: module.name, title: module.title }))
        onError(isError)
    }, [draft])

    if (!module) return null

    async function saveModule() {
        const { id, version, active } = module
        const isV2 = version === '2'

        onLoading(true)
        try {
            if (module.name !== draft.name && module.name === defaultModuleName) {
                dispatch(authCreators.updateDefaultModule(draft.name))
            }
            await dispatch(moduleCreators.updateModule(id, draft, isV2))
            if (isV2 && active !== draft.active) revalidateModules()
            updateSaveHandle()
            updateCancelHandle()
        } catch (err) {
            alert('There was a problem saving.', { type: 'error' })
        }
        onLoading(false)
    }

    function handleNameChange(value) {
        setDraft(s => ({ ...s, name: value.replace(/[^\w-]+/g, '') }))
    }

    function handleTitleChange(value) {
        setDraft(s => ({ ...s, title: value }))
    }

    async function handleDelete() {
        const response = await confirm('Are you sure you want to delete this application?', { type: 'warning' })
        if (response.dismiss) return

        dispatch(moduleCreators.deleteModule(module.id))
        onClose()
    }

    async function handleConvert() {
        dispatch(moduleCreators.toggleMigration())
    }

    async function toggleActive() {
        if (draft.active && draft.revision) {
            const response = await confirm('Making the application private will delete the change history', {
                title: 'Delete change history?',
                type: 'warning',
            })
            if (response.dismiss) return
        }
        setDraft(s => ({ ...s, active: !s.active }))
    }

    return (
        <div className={classes.manageModule}>
            <TextField
                className={classes.field}
                fullWidth
                label="Name"
                helperText={nameHelperText}
                error={nameAlreadyUsed || !name}
                value={name}
                onChange={e => handleNameChange(e.target.value)}
            />

            <TextField
                className={classes.field}
                fullWidth
                helperText={!title ? 'Title is required' : ''}
                error={!title}
                label="Title"
                value={title}
                onChange={e => handleTitleChange(e.target.value)}
            />

            {isV2 && <VisualVersionWithHistoryButton type={VisualType.MODULE} config={module} />}

            <Typography variant="h6" gutterBottom>
                Application features
            </Typography>

            <FormControlLabel
                label="Network diagram"
                sx={{ display: 'block' }}
                control={
                    <BooleanSelect
                        checked={!hideNetworkDiagram}
                        color="primary"
                        onChange={() => setDraft(s => ({ ...s, hideNetworkDiagram: !s.hideNetworkDiagram }))}
                    />
                }
            />

            <FormControlLabel
                label="Business explorer"
                sx={{ display: 'block' }}
                control={
                    <BooleanSelect
                        checked={!hideBusinessExplorer}
                        color="primary"
                        onChange={() => setDraft(s => ({ ...s, hideBusinessExplorer: !s.hideBusinessExplorer }))}
                    />
                }
            />

            {isV2 && (
                <FormControlLabel
                    label="Public"
                    sx={{ display: 'block' }}
                    control={
                        <BooleanSelect
                            checked={active}
                            color="primary"
                            disabled={Boolean(draft?.draft)}
                            onChange={toggleActive}
                        />
                    }
                />
            )}

            {isV2 ? (
                <Button variant="contained" className={classes.deleteButton} onClick={handleDelete}>
                    Delete application
                </Button>
            ) : enableBuilder ? (
                <Button variant="contained" className={classes.convertButton} onClick={handleConvert}>
                    Convert application
                </Button>
            ) : null}
        </div>
    )
}
