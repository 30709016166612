import MoreIcon from '@mui/icons-material/MoreVertRounded'
import FavoriteIcon from '@mui/icons-material/StarBorderRounded'
import FilledFavoriteIcon from '@mui/icons-material/StarRounded'
import ViewComfyAltOutlinedIcon from '@mui/icons-material/ViewComfyAltOutlined'
import ViewComfyAltIcon from '@mui/icons-material/ViewComfyAlt'
import { ListItemIcon, ListItemText, MenuItem, MenuList, useTheme } from '@mui/material'
import { useSnackbar } from 'notistack'
import React, { useContext, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import QuestionAnswerRoundedIcon from '@mui/icons-material/QuestionAnswerRounded'
import ExpandLess from '@mui/icons-material/ExpandLessRounded'
import ExpandMore from '@mui/icons-material/ExpandMoreRounded'
import ChatRoundedIcon from '@mui/icons-material/ChatRounded'
import GroupRounded from '@mui/icons-material/GroupRounded'

import { MenuIcon, SwalContext } from 'genesis-suite/components'
import { CheckedCircle, TV } from 'genesis-suite/icons'
import { authCreators, moduleCreators, widgetCreators } from '../actions/creators'
import { logEvent } from '../lib/amplitudeClient'
import { applicationSelectors, authSelectors, moduleSelectors, widgetSelectors } from '../selectors'
import { PerspectiveContext } from './contexts/PerspectiveContext'
import { messengerSelectors } from '~/selectors/messenger.selectors'
import { messengerCreators } from '~/actions/creators/messenger.creators'

import NewChatDialog from './Messenger/NewChatDialog'

export default function PerspectiveToolbarMenuItems({ iconProps, buttonStyle, perspectiveLayout }) {
    const { confirm } = useContext(SwalContext)
    const { autoAdjustLayout, updateAutoAdjustLayout } = useContext(PerspectiveContext)
    const { enqueueSnackbar: showSnackbar } = useSnackbar()
    const autoLaunchCatch = () => showSnackbar('An error occurred updating your auto launch.', { variant: 'error' })

    const perspective = useSelector(widgetSelectors.getCurrentPerspectiveConfig)
    const perspectiveId = perspective?.Id
    const disabled = !perspectiveId
    const isModuleAutoLaunchPerspective = useSelector(widgetSelectors.getIsPerspectiveAutoLaunch)
    const userAutoLaunchPerspective = useSelector(authSelectors.getAutoLaunchPerspective)
    const isPowerUser = useSelector(authSelectors.getIsPowerUser)
    const moduleId = useSelector(moduleSelectors.getModuleId)
    const isFavorite = useSelector(state => authSelectors.getIsFavorite(state, 'widgetFavorites', perspectiveId))

    const appName = useSelector(applicationSelectors.getCurrentAppName)
    const { userId } = useSelector(authSelectors.getUser)
    const appDomainId = useSelector(state => applicationSelectors.getAppDomainId(state, appName))

    const chatList = useSelector(messengerSelectors.getMessageList)

    const conversationList = useMemo(() => {
        return chatList.filter(conversation =>
            conversation.participants.some(participant => participant.id === perspectiveId)
        )
    }, [chatList])

    const [openDialog, setOpenDialog] = useState(false)

    let isAutoLaunch = false
    let isAutoLaunchOverride = false
    let userAutoLaunchUpdateValue
    let userAutoLaunchUpdateResponse = 'added'
    if (isModuleAutoLaunchPerspective) {
        if (perspectiveId === userAutoLaunchPerspective || !userAutoLaunchPerspective) {
            isAutoLaunch = true
            userAutoLaunchUpdateValue = '__home'
            userAutoLaunchUpdateResponse = 'removed'
        } else {
            isAutoLaunchOverride = true
        }
    } else {
        if (perspectiveId === userAutoLaunchPerspective) {
            isAutoLaunch = true
            userAutoLaunchUpdateResponse = 'removed'
        } else {
            userAutoLaunchUpdateValue = perspectiveId
        }
    }

    const dispatch = useDispatch()
    const updateUserAutoLaunch = () =>
        dispatch(authCreators.updateAutoLaunchPerspective(userAutoLaunchUpdateValue))
            .then(() => showSnackbar(`Auto launch ${userAutoLaunchUpdateResponse}.`))
            .catch(autoLaunchCatch)

    const [open, setOpen] = useState(false)
    const [submenuOpen, setSubmenuOpen] = useState({})

    const theme = useTheme()

    const handleDialogOpen = () => setOpenDialog(true)
    const handleDialogClose = () => setOpenDialog(false)

    const autoLaunchIconColor = disabled
        ? theme.palette.grayscale.light
        : isAutoLaunch
        ? theme.palette.status.success
        : isAutoLaunchOverride
        ? theme.palette.status.warning
        : theme.palette.text.primary

    const autoLaunchIconProps = { ...iconProps, style: { ...iconProps.style, color: autoLaunchIconColor } }

    const handleAutoLaunch = async () => {
        if (isPowerUser) {
            const { IsGlobal, ParentVisorId, ParentVisorName } = perspective
            const notParentModule = IsGlobal && ParentVisorId !== moduleId

            if (notParentModule) {
                const response = await confirm(`To save for all, go to the parent portal ${ParentVisorName}`, {
                    title: 'Save just for you?',
                    type: 'question',
                    showCloseButton: false,
                    confirmButtonProps: { text: 'Just me' },
                })
                if (!response.isConfirmed) return

                updateUserAutoLaunch()
            } else {
                const response = await confirm(
                    'If you update for everyone, users will still be able to set their own Auto launch override if they choose.',
                    {
                        title: 'Just you or everyone?',
                        type: 'question',
                        showCloseButton: false,
                        cancelButtonProps: { text: 'Just me' },
                        confirmButtonProps: { text: 'Everyone' },
                    }
                )
                if (response.dismiss === 'backdrop') return

                if (response.value)
                    dispatch(moduleCreators.saveAutoLaunch(perspectiveId))
                        .then(() =>
                            showSnackbar(
                                `Auto launch ${isModuleAutoLaunchPerspective ? 'removed' : 'added'} for all users.`
                            )
                        )
                        .catch(autoLaunchCatch)
                else updateUserAutoLaunch()
            }
        } else {
            updateUserAutoLaunch()
        }

        logEvent(isAutoLaunch ? 'AUTO_LAUNCH_TOGGLED_OFF' : 'AUTO_LAUNCH_TOGGLED_ON')
    }

    const handleOpenChat = conversationId => {
        dispatch(messengerCreators.openChatById(conversationId))
    }

    let items = [
        {
            name: 'TV mode',
            click: () => dispatch(widgetCreators.toggleTVMode()),
            icon: <TV {...iconProps} />,
        },
        {
            name: 'Start Chat',
            click: () => handleDialogOpen(),
            hide: !appDomainId || !userId,
            icon: <QuestionAnswerRoundedIcon {...iconProps} />,
        },
        {
            name: 'Open Chat(s)',
            hide: !conversationList?.length > 0,
            icon: <ChatRoundedIcon {...iconProps} />,
            children: conversationList.map(conversation => {
                return {
                    name: conversation?.name,
                    icon: <GroupRounded {...iconProps} />,
                    click: () => handleOpenChat(conversation?.id),
                }
            }),
        },
        {
            name: 'Auto launch',
            click: handleAutoLaunch,
            icon: <CheckedCircle {...autoLaunchIconProps} />,
        },
        {
            name: 'Favorite',
            icon: isFavorite ? <FilledFavoriteIcon fontSize="small" /> : <FavoriteIcon fontSize="small" />,
            click: () => {
                logEvent('FAVORITE_WIDGET')
                dispatch(authCreators.saveFavorite('widgetFavorites', perspectiveId)).catch(() =>
                    showSnackbar('An error occurred saving your favorites.', { variant: 'error' })
                )
            },
        },
        {
            name: 'Auto Adjust Layout',
            click: () => updateAutoAdjustLayout(),
            icon: autoAdjustLayout ? <ViewComfyAltIcon /> : <ViewComfyAltOutlinedIcon />,
        },
    ]

    if (perspectiveLayout) {
        items = [
            {
                name: 'Auto launch',
                click: handleAutoLaunch,
                icon: <CheckedCircle {...autoLaunchIconProps} />,
            },
        ]
    }

    const toggleSubmenu = name => {
        setSubmenuOpen(prev => ({
            ...prev,
            [name]: !prev[name],
        }))
    }

    return (
        <>
            <MenuIcon
                icon={<MoreIcon {...iconProps} />}
                buttonProps={{ sx: buttonStyle }}
                disabled={disabled}
                open={open}
                onClick={() => setOpen(true)}
                onClose={() => {
                    setOpen(false)
                    setSubmenuOpen({})
                }}
                tooltip="More options"
                noPadding
            >
                <MenuList>
                    {items
                        .filter(i => !i.hide)
                        .map(item => (
                            <React.Fragment key={item.name}>
                                <MenuItem
                                    key={item.name}
                                    onClick={() => {
                                        if (item.children && item.children.length > 0) {
                                            toggleSubmenu(item.name)
                                        } else {
                                            item.click()
                                            setOpen(false)
                                        }
                                    }}
                                >
                                    <ListItemIcon>{item.icon}</ListItemIcon>
                                    <ListItemText>{item.name}</ListItemText>
                                    {item.children &&
                                        item.children.length > 0 &&
                                        (submenuOpen[item.name] ? <ExpandLess /> : <ExpandMore />)}
                                </MenuItem>
                                {item.children && item.children.length > 0 && submenuOpen[item.name] && (
                                    <MenuList sx={{ pl: 2 }}>
                                        {item.children.map(child => (
                                            <MenuItem
                                                key={child.name}
                                                onClick={() => {
                                                    child.click()
                                                    setSubmenuOpen({})
                                                    setOpen(false)
                                                }}
                                            >
                                                {child?.icon ? <ListItemIcon>{child?.icon}</ListItemIcon> : null}
                                                <ListItemText>{child?.name}</ListItemText>
                                            </MenuItem>
                                        ))}
                                    </MenuList>
                                )}
                            </React.Fragment>
                        ))}
                </MenuList>
            </MenuIcon>
            {openDialog && (
                <NewChatDialog
                    perspectiveName={perspective?.Title}
                    open={openDialog}
                    onClose={handleDialogClose}
                    isPerspectiveChat={true}
                />
            )}
        </>
    )
}
