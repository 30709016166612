import InfoIcon from '@mui/icons-material/InfoOutlined'
import {
    Box,
    Button,
    Checkbox,
    Collapse,
    DialogActions,
    FormControlLabel,
    MenuItem,
    Paper,
    TextField,
    Tooltip,
    Typography,
    useTheme,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classnames from 'classnames'
import isEmpty from 'lodash/isEmpty'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ReactToPrint from 'react-to-print'
import { useDebouncedCallback } from 'use-debounce'
import { dialogCreators } from '../actions/creators'
import tadaWatermark from '../assets/img/tada-watermark.png'
import ApplicationLogo from '../components/ApplicationLogo'
import WidgetInfoDescription from '../components/widget_info/WidgetInfoDescription'
import WidgetInfoGlobalFilters from '../components/widget_info/WidgetInfoGlobalFilters'
import WidgetInfoInlineFilters from '../components/widget_info/WidgetInfoInlineFilters'
import WidgetInfoPerspectiveFilters from '../components/widget_info/WidgetInfoPerspectiveFilters'
import WidgetInfoWidgetFilters from '../components/widget_info/WidgetInfoWidgetFilters'
import PrintNotes from '../components/widgets/PrintNotes'
import Widget from '../components/widgets/Widget'
import { showAppLogoWatermark } from '../components/widgets/lib/widgetExporting'
import { applicationSelectors, authSelectors, widgetSelectors } from '../selectors'
import Markdown from '~/components/Markdown'
import { filterSelectors } from '../selectors'

export const printContainerDialogKey = 'PRINT_DIALOG'
export const printNotesDialogKey = 'PRINT_NOTES'

const topBarHeight = 70
const noteWidth = 400

const tadaWatermarkStyles = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    opacity: 0.2,
    width: '30%',
    zIndex: 999999,
}

const useStyles = makeStyles(({ palette, spacing }) => {
    return {
        dialog: {
            width: '1400px',
            height: ({ layout, notes }) =>
                `${
                    layout === 'portrait'
                        ? notes.show
                            ? notes.location === 'multiple pages'
                                ? 900
                                : 900
                            : 900
                        : notes.show
                        ? notes.location === 'multiple pages'
                            ? 700
                            : 700
                        : 700
                }px`,
        },
        preview: {
            position: 'relative',
            width: '700px',
            height: ({ layout, notes }) =>
                `${
                    layout === 'portrait'
                        ? notes.show
                            ? notes.location === 'multiple pages'
                                ? 3100
                                : 1550
                            : 1550
                        : notes.show
                        ? notes.location === 'multiple pages'
                            ? 1845
                            : 922
                        : 922
                }px`,
        },
        printContent: {
            position: 'absolute',
            transformOrigin: 'top left',
            width: '1200px',
            maxHeight: ({ layout, notes }) =>
                `${
                    layout === 'portrait'
                        ? notes.show
                            ? notes.location === 'multiple pages'
                                ? 3100
                                : 1550
                            : 1550
                        : notes.show
                        ? notes.location === 'multiple pages'
                            ? 1840
                            : 922
                        : 922
                }px`,
            overflowY: 'auto',
            boxShadow:
                '0px 3px 3px -2px rgba(0,0,0,0.2), 0px 3px 4px 0px rgba(0,0,0,0.14), 0px 1px 8px 0px rgba(0,0,0,0.12)',
            '@media print': {
                // eslint-disable-line no-useless-computed-key
                boxShadow: 'none',
            },
        },
        paper: {
            height: ({ layout, notes }) =>
                `${
                    layout === 'portrait'
                        ? notes.show
                            ? notes.location === 'multiple pages'
                                ? 3097
                                : 1545
                            : 1545
                        : notes.show
                        ? notes.location === 'multiple pages'
                            ? 1839
                            : 917
                        : 917
                }px`,
            width: '1200px',
            display: 'flex',
            flexDirection: 'column',
            boxShadow:
                '0px 3px 3px -2px rgba(0,0,0,0.2), 0px 3px 4px 0px rgba(0,0,0,0.14), 0px 1px 8px 0px rgba(0,0,0,0.12)',
            '@media print': {
                boxShadow: 'none', // eslint-disable-line no-useless-computed-key
            },
        },
        details: {
            position: 'absolute',
            height: ({ layout, notes }) =>
                `${
                    layout === 'portrait'
                        ? notes.show
                            ? notes.location === 'multiple pages'
                                ? 1845
                                : 600
                            : 650
                        : notes.show
                        ? notes.location === 'multiple pages'
                            ? 1845
                            : 600
                        : 650
                }px`,
        },
        topBar: {
            backgroundColor: palette.background.topBar,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            height: topBarHeight,
        },
        logo: { paddingLeft: spacing(1) },
        title: {
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            color: 'white',
            textAlign: 'center',
            paddingLeft: spacing(1),
        },
        widgetNoteWrapper: {
            display: 'flex',
            width: '100%',
            position: 'relative',
            height: ({ layout, notes, lines }) =>
                `${
                    layout === 'portrait'
                        ? notes.show
                            ? notes.location === 'multiple pages'
                                ? 1476
                                : notes.location === 'right'
                                ? 1480
                                : 850
                            : 1476
                        : notes.show
                        ? notes.location === 'multiple pages'
                            ? 850
                            : notes.location === 'right'
                            ? 922
                            : lines.show > 0
                            ? 400
                            : 500
                        : 850
                }px`,
            overflowY: 'auto',
            boxShadow: ({ notes }) =>
                `${
                    notes.location === 'multiple pages'
                        ? '0px 3px 3px -2px rgba(0,0,0,0.2), 0px 3px 4px 0px rgba(0,0,0,0.14), 0px 1px 8px 0px rgba(0,0,0,0.12)'
                        : 'none'
                }`,
            '@media print': {
                // eslint-disable-line no-useless-computed-key
                boxShadow: 'none',
            },
        },
        widgetWrapper: {
            width: ({ notes }) => `calc(100% - ${notes.show && notes.location === 'right' ? noteWidth : 0}px)`,
            height: '100%',
            overflowX: 'auto',
        },
        checkbox: { marginTop: spacing() },
        checkboxIconStyle: { color: palette.text.primary },
        noteSelect: { paddingLeft: '30px' },
        filtersHeader: { fontSize: '16px', fontWeight: 'bold' },
        filtersHeight: { maxHeight: '700px', overflowY: 'auto' },
        filtersPosition: { paddingTop: '18px' },
        addNotesButton: {
            flex: '0 0 auto',
            display: 'flex',
            padding: '2px',
            marginTop: '15px',
            marginBottom: '25px',
            alignItems: 'flex-end',
            justifyContent: 'flex-end',
        },
        helpIcon: {
            marginTop: 2,
            display: 'inline-flex',
            verticalAlign: 'text-top',
        },
        tadaWatermark: tadaWatermarkStyles,
        tadaWatermarkFooter: {
            position: 'absolute',
            right: '0',
            bottom: '0',
            transform: 'translate(-50%, -50%)',
            opacity: 0.2,
            width: '15%',
            zIndex: 999999,
        },
    }
})

/** Component used to print a widget. It creates a wrapper for the widget based on the selected page size and scales it down so that it fits on the viewport
 * @param {string} props.configId widget config id
 */
export default function PrintContainer({ configId }) {
    const config = useSelector(state => widgetSelectors.getWidgetConfig(state, configId))
    const filters = useSelector(filterSelectors.currentFiltersConfiguration)
    const inlineFilters = useSelector(filterSelectors.getInlineFilters)
    const networkContext = useSelector(filterSelectors.getCoord)
    const appName = useSelector(applicationSelectors.getCurrentAppName)
    const userAlias = useSelector(authSelectors.getUserAlias)

    const [layout, setLayout] = useState('landscape')
    const [notes, setNotes] = useState({ show: false, location: 'bottom' })
    const [lines, showLines] = useState({ show: false })
    const [printNote, setPrintNotes] = useState('')
    const [printPreviewDim, setPrintPreviewDim] = useState()

    const currentDate = new Date()

    const year = currentDate.getFullYear()
    const month = (1 + currentDate.getMonth()).toString().padStart(2, '0')
    const day = currentDate.getDate().toString().padStart(2, '0')

    const dispatch = useDispatch()

    const printContent = useRef()
    const printButtonRef = useRef()
    const widgetRef = useRef()
    const contentContainer = useRef()

    const appLogoWatermark = showAppLogoWatermark()
    let printPreviewStyles = {}
    const theme = useTheme()

    const toggleNotes = () => {
        setNotes({ ...notes, location: 'bottom', show: !notes.show })
    }

    const toggleLines = () => {
        showLines({ ...lines, show: !lines.show })
    }

    const showPopup = () => {
        dispatch(
            dialogCreators.showDialog(printNotesDialogKey, PrintNotes, {
                hideTitle: true,
                componentProps: {
                    saveNotes: saveNotes,
                    printNote: printNote,
                },
            })
        )
    }

    const saveNotes = notes => {
        setPrintNotes(notes)
        dispatch(dialogCreators.hideDialog(printNotesDialogKey))
    }

    const handlePrintContainerResize = useCallback(() => {
        const dialogContent = document.querySelector(`[data-dialog-content=${printContainerDialogKey}]`)

        if (printContent.current && dialogContent) {
            setPrintPreviewDim({
                dialogContainer: { width: dialogContent.clientWidth, height: dialogContent.clientHeight },
                printContent: { width: printContent.current.clientWidth, height: printContent.current.clientHeight },
            })
        }
    }, [])

    const debouncedPrintContainerResize = useDebouncedCallback(handlePrintContainerResize, 200)

    const classes = useStyles({ layout, notes, config, lines })

    if (printPreviewDim) {
        printPreviewStyles = createPrintPreviewStyles(
            printPreviewDim.printContent,
            printPreviewDim.dialogContainer,
            layout,
            notes.location
        )
    }

    useEffect(() => {
        handlePrintContainerResize()
        widgetRef.current?.chart?.reflow()
    }, [layout, notes, lines, handlePrintContainerResize])

    useEffect(() => {
        window.addEventListener('resize', debouncedPrintContainerResize)
        return () => window.removeEventListener('resize', debouncedPrintContainerResize)
    }, [debouncedPrintContainerResize])

    if (isEmpty(config)) return null

    return (
        <div className={classes.dialog}>
            <div className={classes.preview}>
                <div style={printPreviewStyles.contentContainer} ref={contentContainer}>
                    <div style={printPreviewStyles.printContentWrapper}>
                        <div style={printPreviewStyles.printContentScaler}>
                            <div className={classes.printContent} ref={printContent}>
                                <Paper elevation={3} className={classes.paper}>
                                    <div className={classes.topBar}>
                                        <Typography variant="h5" className={classes.title}>
                                            {config.Title}
                                        </Typography>
                                        <ApplicationLogo classes={{ image: classes.logo }} />
                                        <Box sx={{ display: 'flex', alignItems: 'center', padding: '0 8px' }}>
                                            <Typography variant="h6" sx={{ paddingRight: '8px' }}>
                                                {userAlias}
                                            </Typography>
                                            <Typography>
                                                {year}/{month}/{day}
                                            </Typography>
                                        </Box>
                                    </div>
                                    <div className={classes.widgetNoteWrapper}>
                                        <div className={classes.widgetWrapper}>
                                            <Widget
                                                config={config}
                                                ref={widgetRef}
                                                interactions={{
                                                    disableContextMenu: true,
                                                }}
                                                filters={filters}
                                                networkContext={networkContext}
                                                inlineFilters={inlineFilters}
                                            />
                                        </div>
                                        {notes.show && notes.location === 'right' && (
                                            <Notes
                                                printNote={printNote}
                                                config={config}
                                                notesLocation={notes.location}
                                                lineShow={lines.show}
                                                layout={layout}
                                            />
                                        )}
                                        {appLogoWatermark && (
                                            <img
                                                className={classes.tadaWatermark}
                                                src={tadaWatermark}
                                                alt="Tada Watermark"
                                            />
                                        )}
                                    </div>
                                    {notes.show &&
                                        (notes.location === 'bottom' || notes.location === 'multiple pages') && (
                                            <Notes
                                                printNote={printNote}
                                                config={config}
                                                notesLocation={notes.location}
                                                lineShow={lines.show}
                                                layout={layout}
                                            />
                                        )}
                                    <div className={classes.topBar}>
                                        <Typography variant="h5" className={classes.title}>
                                            {appName}
                                        </Typography>
                                        <Typography sx={{ color: 'text.disabled', flexShrink: 0, padding: '0 8px' }}>
                                            Powered by Digital Duplicate &trade;
                                        </Typography>
                                    </div>
                                </Paper>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={classes.details} style={printPreviewStyles.details}>
                    <Typography variant="h6">Settings</Typography>
                    <TextField
                        sx={theme => ({
                            '& .MuiInputBase-root:before': {
                                borderBottomColor: theme.palette.text.primary,
                            },
                        })}
                        select
                        fullWidth
                        value={layout}
                        onChange={e => setLayout(e.target.value)}
                        SelectProps={{ classes: { icon: classes.checkboxIconStyle } }}
                    >
                        {layoutOptions.map(o => (
                            <MenuItem key={o.value} value={o.value}>
                                {o.label}
                            </MenuItem>
                        ))}
                    </TextField>
                    <FormControlLabel
                        className={classes.checkbox}
                        control={
                            <Checkbox
                                className={classes.checkboxIconStyle}
                                checked={notes.show}
                                onChange={toggleNotes}
                            />
                        }
                        label="Widget Info"
                    />
                    <Collapse in={notes.show}>
                        <TextField
                            select
                            fullWidth
                            className={classes.noteSelect}
                            value={notes.location}
                            onChange={e => setNotes(s => ({ ...s, location: e.target.value }))}
                            sx={theme => ({
                                '& .MuiInputBase-root:before': {
                                    borderBottomColor: theme.palette.text.primary,
                                },
                            })}
                            SelectProps={{ classes: { icon: classes.checkboxIconStyle } }}
                        >
                            {noteLocationOptions.map(o => (
                                <MenuItem key={o.value} value={o.value}>
                                    {o.label}
                                </MenuItem>
                            ))}
                        </TextField>
                        <FormControlLabel
                            className={classes.checkbox}
                            control={
                                <Checkbox
                                    className={classes.checkboxIconStyle}
                                    checked={lines.show}
                                    onChange={toggleLines}
                                />
                            }
                            label="Notes"
                            sx={{ pl: '30px' }}
                        />
                        <div className={classes.helpIcon}>
                            <Tooltip title="Notes are displayed only for print">
                                <InfoIcon className={classes.icon} htmlColor={theme.palette.text.primary} />
                            </Tooltip>
                        </div>
                        <div className={classes.addNotesButton}>
                            <Button variant="outlined" color="primary" onClick={showPopup}>
                                Edit
                            </Button>
                        </div>
                    </Collapse>
                    <Collapse in={lines.show}></Collapse>
                    <div>
                        <DialogActions>
                            <ReactToPrint
                                trigger={() => (
                                    <Button variant="contained" color="primary" ref={printButtonRef}>
                                        Submit
                                    </Button>
                                )}
                                content={() => printContent.current}
                            />
                        </DialogActions>
                    </div>
                </div>
            </div>
            <Orientation layout={layout} />
        </div>
    )
}

const layoutOptions = [
    { value: 'portrait', label: 'Portrait' },
    { value: 'landscape', label: 'Landscape' },
]

const noteLocationOptions = [
    { value: 'bottom', label: 'Bottom' },
    { value: 'right', label: 'Right' },
    { value: 'multiple pages', label: 'Multiple Pages' },
]

const Orientation = ({ layout }) => <style type="text/css">{`@media print{@page {size: ${layout} }`}</style>

const rowHeight = 30

const useNoteStyles = makeStyles(({ spacing }) => ({
    textForFilters: {
        color: 'grey',
        fontSize: '.875rem',
        marginRight: 'auto',
        paddingTop: 5,
        maxHeight: ({ notesLocation, lineShow, layout }) =>
            `${
                layout === 'portrait'
                    ? notesLocation === 'multiple pages'
                        ? lineShow
                            ? 300
                            : 450
                        : notesLocation === 'bottom'
                        ? lineShow
                            ? 100
                            : 150
                        : lineShow
                        ? 270
                        : 220
                    : notesLocation === 'multiple pages'
                    ? lineShow
                        ? 300
                        : 400
                    : notesLocation === 'bottom'
                    ? lineShow
                        ? 60
                        : 70
                    : lineShow
                    ? 100
                    : 220
            }px`,
        overflowY: 'auto',
    },
    containerForPrint: {
        display: 'block',
        height: ({ notesLocation, lineShow, layout }) =>
            `${
                layout === 'portrait'
                    ? notesLocation === 'multiple pages'
                        ? 70
                        : notesLocation === 'bottom'
                        ? lineShow
                            ? 65
                            : 65
                        : lineShow
                        ? 65
                        : 99
                    : notesLocation === 'multiple pages'
                    ? 65
                    : notesLocation === 'bottom'
                    ? lineShow
                        ? 60
                        : 60
                    : lineShow
                    ? 70
                    : 99
            }%`,
    },
    filterGrid: { width: '49%', display: 'inline-grid', overflowY: 'auto', paddingLeft: '20px' },
    notes: {
        flex: 1,
        height: ({ notesLocation, layout, lineShow }) =>
            `${
                layout === 'portrait'
                    ? notesLocation === 'multiple pages'
                        ? 1600
                        : notesLocation === 'right'
                        ? 1470
                        : 650
                    : notesLocation === 'multiple pages'
                    ? 955
                    : notesLocation === 'right'
                    ? 852
                    : lineShow > 0
                    ? 447
                    : 355
            }px`,
        pageBreakBefore: 'always',
        paddingTop: ({ notesLocation }) => `${notesLocation === 'multiple pages' ? 50 : 10}px`,
        paddingLeft: ({ notesLocation }) => `${notesLocation === 'right' ? 10 : 0}px`,
        position: 'relative',
    },
    notesPrint: {
        paddingLeft: 10,
        paddingRight: 10,
        height: ({ notesLocation, layout }) =>
            `${
                layout === 'portrait'
                    ? notesLocation === 'multiple pages'
                        ? 30
                        : notesLocation === 'right'
                        ? 33
                        : 35
                    : notesLocation === 'multiple pages'
                    ? 35
                    : notesLocation === 'right'
                    ? 28
                    : 40
            }%`,
        maxHeight: ({ notesLocation, layout }) =>
            `${
                layout === 'portrait'
                    ? notesLocation === 'multiple pages'
                        ? 1270
                        : notesLocation === 'right'
                        ? 1100
                        : 35
                    : notesLocation === 'multiple pages'
                    ? 680
                    : notesLocation === 'right'
                    ? 500
                    : 40
            }%`,
        overflowY: 'hidden',
    },
    widget: {
        fontWeight: 400,
        color: 'grey',
        wordWrap: 'break-word',
    },
    row: { borderBottom: `1px solid gray`, height: rowHeight, marginLeft: 0, marginRight: 20 },
    tadaWatermark: tadaWatermarkStyles,
}))

const Notes = ({ config, notesLocation, layout, printNote, lineShow }) => {
    const appLogoWatermark = showAppLogoWatermark()
    const classes = useNoteStyles({ notesLocation, layout, lineShow })
    const charLimit = notesLocation === 'multiple pages' ? 700 : 280

    return (
        <div className={classes.notes}>
            <div className={classes.containerForPrint}>
                <div
                    className={classnames({
                        [classes.filterGrid]:
                            notesLocation &&
                            (notesLocation.toLowerCase() === 'bottom' ||
                                notesLocation.toLowerCase() === 'multiple pages'),
                    })}
                >
                    <div className={classes.filters}>
                        <WidgetInfoDescription config={config} />
                    </div>
                    <div className={classes.filters}>
                        <Typography className={classes.textForFilters}>
                            <b>WIDGET FILTERS</b>
                        </Typography>
                        <WidgetInfoWidgetFilters config={config} charLimit={charLimit} />
                    </div>
                </div>
                <div
                    className={classnames({
                        [classes.filterGrid]:
                            notesLocation &&
                            (notesLocation.toLowerCase() === 'bottom' ||
                                notesLocation.toLowerCase() === 'multiple pages'),
                    })}
                >
                    <Typography className={classes.textForFilters}>
                        <b>GLOBAL FILTERS</b>
                    </Typography>
                    <WidgetInfoGlobalFilters />

                    <Typography className={classes.textForFilters}>
                        <b>PERSPECTIVE FILTERS</b>
                    </Typography>
                    <WidgetInfoPerspectiveFilters />

                    <div className={classes.filters}>
                        <Typography className={classes.textForFilters}>
                            <b>INLINE FILTERS</b>
                        </Typography>
                        <div className={classes.textForFilters}>
                            <WidgetInfoInlineFilters />
                        </div>
                    </div>
                </div>
            </div>

            {lineShow ? (
                <div className={classes.notesPrint}>
                    <Typography className={classes.textForFilters}>
                        <b>Notes</b>
                    </Typography>
                    {printNote.length > 0 ? (
                        <span className={classes.widget}>
                            <Markdown>{printNote}</Markdown>
                        </span>
                    ) : null}
                    {new Array(300).fill(0).map((row, rowIndex) => (
                        <div key={rowIndex} className={classes.row} />
                    ))}
                </div>
            ) : null}
            {notesLocation === 'multiple pages' && appLogoWatermark && (
                <img className={classes.tadaWatermark} src={tadaWatermark} alt="Tada Watermark" />
            )}
        </div>
    )
}

const hasHorizontalOverflow = (childDim, parentDim) => childDim.width > parentDim.width

const hasVerticalOverflow = (childDim, parentDim) => childDim.height > parentDim.height

const preserveAspectRatio = ({ width, height }, ratio) => {
    let correctedWidth
    let correctedHeight

    if (width) {
        correctedWidth = width
        correctedHeight = correctedWidth / ratio
    } else if (height) {
        correctedHeight = height
        correctedWidth = correctedHeight * ratio
    }

    return { width: correctedWidth, height: correctedHeight }
}

const createPrintPreviewStyles = (printContent, dialogContainer, layout, notesLocation) => {
    let correctedContent = {}
    const contentContainer = {}

    const dialogContainerPadding = 8
    const contentContainerPadding = 15
    const detailsMarginLeft = 10
    const contentContainerLength = 0.75

    contentContainer.width = (dialogContainer.width - dialogContainerPadding * 2) * contentContainerLength
    contentContainer.height = dialogContainer.height - contentContainerPadding * 2

    const contentRatio = printContent.width / printContent.height
    let detailsWidth = dialogContainer.width - contentContainer.width - dialogContainerPadding * 2

    const landscapeContent = preserveAspectRatio(
        { width: contentContainer.width - contentContainerPadding * 2 },
        contentRatio
    )
    const portraitContent = preserveAspectRatio({ height: contentContainer.height }, contentRatio)

    correctedContent = { width: landscapeContent.width, height: landscapeContent.height }

    if (layout === 'landscape') {
        if (notesLocation !== 'multiple pages' && hasVerticalOverflow(correctedContent, contentContainer)) {
            correctedContent = { width: portraitContent.width, height: portraitContent.height }
        }
    } else if (layout === 'portrait') {
        if (notesLocation !== 'multiple pages') {
            correctedContent = { width: portraitContent.width, height: portraitContent.height }
        }

        if (hasHorizontalOverflow(correctedContent, contentContainer)) {
            correctedContent = { width: landscapeContent.width, height: landscapeContent.height }
        }
    }

    const detailsLeft = contentContainer.width + detailsMarginLeft
    detailsWidth = detailsWidth - detailsMarginLeft

    const contentScaleX = correctedContent.width / printContent.width
    const contentScaleY = correctedContent.height / printContent.height

    return {
        contentContainer: {
            position: 'absolute',
            overflow: 'auto',
            height: dialogContainer.height,
            width: contentContainer.width,
            paddingBottom: contentContainerPadding * 2,
            backgroundColor: 'lightgray',
        },
        printContentWrapper: {
            width: correctedContent.width,
            height: correctedContent.height,
        },
        printContentScaler: {
            position: 'absolute',
            top: contentContainerPadding,
            left: hasHorizontalOverflow(correctedContent, contentContainer)
                ? 'auto'
                : `calc(50% - ${correctedContent.width / 2}px)`,
            transformOrigin: 'top left',
            transform: `scale(${contentScaleX},${contentScaleY})`,
        },
        details: { width: detailsWidth, left: detailsLeft },
    }
}
