import { forwardRef, lazy, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useIsMobile } from '~/hooks/useIsMobile'
import { widgetConstants } from '../../constants'
import { debugSelectors } from '../../selectors'
import SuspenseLoader from '../SuspenseLoader'
import useIsMobileControlSelect from './visuals/TableWidget/helpers/useIsMobileControlSelect'

const HighchartsComponent = lazy(() => import('./visuals/HighchartsComponent'))
const D3Widget = lazy(() => import('./visuals/D3Widget'))
const MapWidget = lazy(() => import('./visuals/MapWidget'))
const LabelWidget = lazy(() => import('./visuals/LabelWidget'))
const ElementWidget = lazy(() => import('./visuals/ElementWidget/ElementWidget'))
const TableWidget = lazy(() => import('./visuals/TableWidget/TableWidget'))
const StatusTrackerWidget = lazy(() => import('./visuals/statusTracker/StatusTrackerWidget'))
const ReportWidgetWrapper = lazy(() => import('../widgets2/reporting/ReportWidget'))
const FormWidgetWrapper = lazy(() => import('./visuals/FormWidget/FormWidgetWrapper'))
const MobileControlSelect = lazy(() => import('./visuals/MobileControlSelect'))
const HighChartMapWidget = lazy(() => import('./visuals/HighChartMapWidget/HighChartMapWidget'))
const TableFormWidget = lazy(() => import('./visuals/FormTableWidget/FormTableWidget'))
const AttachmentWidget = lazy(() => import('./visuals/AttachmentWidget/AttachmentWidget'))
const GanttWidget = lazy(() => import('./visuals/HighChartGanttWidget/GanttWidget'))
const TreeGraphWidget = lazy(() => import('./visuals/TreeGraphWidget/TreeGraphWidget'))
const MobileLabelWidget = lazy(() => import('./visuals/LabelWidget/MobileLabelWidget'))
const GroupTableWidget = lazy(() => import('./visuals/GroupTable/GroupTable'))
const TextWidgetVisual = lazy(() => import('./visuals/TextWidget/TextWidgetVisual'))

/**
 * Wrapper component that renders a specific visual type
 * @param {Object}  props.config - the widget config
 * @param {object} props.content - /widget/data api response including raw data, meta data, config, pagination info, etc
 */

const WidgetVisual = forwardRef(({ config, data, ...rest }, ref) => {
    const isMobileControlSelect = useIsMobileControlSelect(config)
    const isMobile = useIsMobile()

    useLogStats(config?.Id)
    if (!config) return null

    let Component
    switch (config.Type.toUpperCase()) {
        case 'CHART':
        case 'BUBBLECHART':
        case 'HISTOGRAM':
        case 'GAUGE':
        case 'FLOW':
        case 'BULLET':
        case 'HEATMAP':
        case 'TREEMAP':
        case 'XRANGE':
            Component = HighchartsComponent
            break
        case widgetConstants.Type.BUSINESS_ELEMENT:
            Component = ElementWidget
            break
        case 'TABLE':
            if (isMobileControlSelect) Component = MobileControlSelect
            else Component = TableWidget
            break
        case 'MAP':
            if (config?.MapConfig?.MapViewType?.toLowerCase() === 'standard') Component = HighChartMapWidget
            else Component = MapWidget
            break
        case 'LABEL':
            Component = isMobile ? MobileLabelWidget : LabelWidget
            break
        case 'PNL':
            Component = config?.PnLConfig?.LoadDynamically ? GroupTableWidget : D3Widget
            break
        case 'DYNAMICTABLE':
        case 'TWOBYTWO':
        case 'WEBVIEW':
        case 'IRIS':
            Component = D3Widget
            break
        case 'FORM':
            Component = TableFormWidget
            break
        case 'STATUSTRACKER':
            Component = StatusTrackerWidget
            break
        case 'REPORTWIDGET':
            Component = ReportWidgetWrapper
            break
        case 'ATTACHMENT':
            Component = AttachmentWidget
            break
        case 'TREEGRAPH':
        case 'SUNBURST':
            Component = TreeGraphWidget
            break
        case 'GANTT':
            Component = GanttWidget
            break
        case 'RICHTEXT':
            Component = TextWidgetVisual
            break
        default:
    }

    return (
        <SuspenseLoader>
            <Component ref={ref} config={config} content={data} {...rest} />
        </SuspenseLoader>
    )
})

export default WidgetVisual

function useLogStats(id) {
    const logStats = useSelector(debugSelectors.getLogStats)
    if (logStats && id) console.time(id)

    useEffect(() => {
        if (logStats && id) console.timeEnd(id)
    })
}
