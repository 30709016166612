import { useContext } from 'react'
import { ConfigContext } from '../ConfigContext'
import { useSelector } from 'react-redux'
import { widgetSelectors } from '~/selectors'
import { Autocomplete, FormControlLabel, Switch, TextField, Typography } from '@mui/material'
import { DebouncedTextField } from 'genesis-suite/components'

export default function CategoryEditor2({ parentProperty }) {
    const { selectedField, config, configStructure, dispatch, configKey } = useContext(ConfigContext)

    const categoryFields = configStructure?.[configKey]?.filter(field => field.ParentProperty === parentProperty) || []
    const perspectives = useSelector(widgetSelectors.getAllPerspectives)
    const widgetConfig = config[configKey]

    if (categoryFields.length === 0 || selectedField.type !== 'category') return null

    const handlePerspectiveChange = (name, newValue) => {
        const payload = {
            key: configKey,
            value: { [name]: newValue.Id },
        }
        dispatch({ type: 'UPDATE_WIDGET_CONFIG', payload })
    }

    const handleChange = (name, value) => {
        const payload = {
            key: configKey,
            value: { [name]: value },
        }
        dispatch({ type: 'UPDATE_WIDGET_CONFIG', payload })
    }

    return (
        <>
            {categoryFields.map((field, index) => {
                if (field.IsPerspectiveProperty)
                    return (
                        <div key={index}>
                            <Typography fontWeight={'bold'} marginTop={1} variant="body1">
                                {field.DisplayName}
                            </Typography>
                            <Autocomplete
                                fullWidth
                                options={perspectives}
                                getOptionLabel={option => option.Title}
                                value={perspectives.find(p => p.Id === widgetConfig[field.Name])}
                                onChange={(_, newValue) => handlePerspectiveChange(field.Name, newValue)}
                                renderInput={params => (
                                    <TextField
                                        {...params}
                                        variant="outlined"
                                        size="small"
                                        placeholder="Select a perspective"
                                        margin="normal"
                                        InputProps={{
                                            ...params.InputProps,
                                            endAdornment: <>{params.InputProps.endAdornment}</>,
                                        }}
                                    />
                                )}
                            />
                        </div>
                    )
                else if (field.Type.includes('Boolean')) {
                    return (
                        <div key={index} style={{ display: 'flex', alignItems: 'center' }}>
                            <Typography fontWeight={'bold'} variant="body1" style={{ marginRight: '1rem' }}>
                                {field.DisplayName}
                            </Typography>
                            <FormControlLabel
                                key={index}
                                control={
                                    <Switch
                                        onChange={e => handleChange(field.Name, e.target.checked)}
                                        name="isPopUp"
                                        color="primary"
                                        defaultChecked={false}
                                        checked={widgetConfig[field.Name] ?? false}
                                        inputProps={{ 'aria-label': `switch-${index}` }}
                                    />
                                }
                                label=""
                            />
                        </div>
                    )
                } else if (field.Type.includes('Int32')) {
                    return (
                        <div key={index}>
                            <Typography fontWeight={'bold'} marginTop={1} variant="body1">
                                {field.DisplayName}
                            </Typography>
                            <TextField
                                fullWidth
                                type="number"
                                margin="dense"
                                variant="outlined"
                                placeholder="Ticks"
                                value={widgetConfig[field.Name] ?? ''}
                                onChange={e =>
                                    handleChange(
                                        field.Name,
                                        e.target.value === '' ? undefined : parseInt(e.target.value, 10)
                                    )
                                }
                            />
                        </div>
                    )
                } else
                    return (
                        <div key={index}>
                            <Typography fontWeight={'bold'} marginTop={1} variant="body1">
                                {field.DisplayName}
                            </Typography>
                            <DebouncedTextField
                                debounceTime={500}
                                fullWidth
                                variant="outlined"
                                size="small"
                                value={widgetConfig[field.Name] ?? ''}
                                onChange={title => handleChange(field.Name, title)}
                            />
                        </div>
                    )
            })}
        </>
    )
}
