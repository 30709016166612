import { useContext } from 'react'
import { Tooltip, Select, MenuItem, TextField, IconButton, FormControl, Checkbox } from '@mui/material'
import { ConfigContext } from '../../ConfigContext'
import WidgetColorPicker from '../WidgetColorPicker'
import SettingsBackupRestoreRoundedIcon from '@mui/icons-material/SettingsBackupRestoreRounded'
import { typeSeriesKeyMapping } from '../SeriesEditor/SeriesEditor2'
import { isEmpty } from 'lodash'
import FieldLabel from '../FieldLabel'
import { typeFormatConfigKeyMapping } from './ChartFormatEditor'
interface Props {
    fieldsConfigKey: string
    updateSelectedRule: any
    removeSelectedRuleFormat: any
    selectedRuleIndex: any
}

export default function FormatColorEditor({
    selectedRuleIndex,
    fieldsConfigKey,
    updateSelectedRule,
    removeSelectedRuleFormat,
}: Props) {
    const { selectedField, configStructure, config, configKey } = useContext(ConfigContext)
    const seriesConfig = configStructure[fieldsConfigKey]
    const seriesKey = typeSeriesKeyMapping[config?.Type]
    const ConditionalFormatKey = typeFormatConfigKeyMapping[config.Type]

    const widgetConfig = config[configKey]
    const activeSeries = widgetConfig?.[seriesKey]?.[selectedField.index]
    if (!seriesConfig || !activeSeries) return null

    const activeFilter = activeSeries?.[ConditionalFormatKey]?.[selectedRuleIndex]?.FormatOption
    const handleChange = (key, value) => {
        updateSelectedRule({ FormatOption: { [key]: value } })
    }

    const handleRemove = key => {
        removeSelectedRuleFormat(key)
    }
    const sortedSeriesConfig = [...seriesConfig].sort(
        (a, b) => (a.DisplayOrder ?? Infinity) - (b.DisplayOrder ?? Infinity)
    )

    return (
        <>
            {sortedSeriesConfig.map((field, index) => {
                if (!isEmpty(field.List)) {
                    return (
                        <div key={index}>
                            <FieldLabel field={field} />
                            <FormControl fullWidth sx={{ my: 0 }} variant="outlined" size="small" margin="normal">
                                <Select
                                    value={activeFilter?.[field.Name] ?? 'None'}
                                    onChange={e => handleChange(field.Name, e.target.value)}
                                    sx={theme => ({
                                        border: '1px solid #888',
                                        borderRadius: '4px',
                                        '&:hover': {
                                            borderColor: `${theme.palette.text.primary}`,
                                        },
                                        '&.Mui-focused': {
                                            borderColor: `${theme.palette.text.primary}`,
                                        },
                                    })}
                                >
                                    {Object.entries(field.List as { [key: string]: string }).map(([key, value]) => (
                                        <MenuItem key={key} value={key}>
                                            {value}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>
                    )
                } else if (field.IsColor) {
                    return (
                        <div key={index} style={{ display: 'flex', alignItems: 'center' }}>
                            <FieldLabel field={field} />
                            <WidgetColorPicker
                                value={activeFilter?.[field.Name] ?? ''}
                                onChange={({ hex }) => handleChange(field.Name, hex)}
                            />
                            <Tooltip title="Reset back to default theme value">
                                <IconButton
                                    sx={{ marginLeft: 1 }}
                                    size="small"
                                    onClick={() => handleRemove(field.Name)}
                                >
                                    <SettingsBackupRestoreRoundedIcon />
                                </IconButton>
                            </Tooltip>
                        </div>
                    )
                } else if (field.Type.includes('Int32')) {
                    return (
                        <div key={index}>
                            <FieldLabel field={field} />
                            <TextField
                                variant="outlined"
                                id="outlined-number"
                                onChange={e => handleChange(field.Name, e.target.value)}
                                type="number"
                                inputProps={{ min: 0 }}
                                value={activeFilter?.[field.Name] ?? 0}
                            />
                        </div>
                    )
                } else if (field.Type.includes('Boolean')) {
                    return (
                        <div key={index} style={{ display: 'flex', alignItems: 'center' }}>
                            <div
                                key={index}
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                            >
                                <Checkbox
                                    onChange={e => handleChange(field.Name, e.target.checked)}
                                    name={field.Name}
                                    color="primary"
                                    defaultChecked={false}
                                    checked={activeFilter?.[field.Name] ?? false}
                                    inputProps={{ 'aria-label': `switch-${index}` }}
                                />
                                <FieldLabel field={field} />
                            </div>
                        </div>
                    )
                }
            })}
        </>
    )
}
