import makeStyles from '@mui/styles/makeStyles'

import { PropertyType } from 'genesis-suite/types/architectureTypes'
import { Property } from 'genesis-suite/types/networkTypes'
import { ConditionalFormat, DataField, FormatConfig } from 'genesis-suite/types/visualTypes'
import { ComparisonSelector } from 'genesis-suite/components'
import PropertyChip from '../../PropertyChip'
import { getDataFieldProperty, getDisplayProperty } from '../../utils'
import ValueField from '../../ValueField'
import DnDPropertySelector from '../../selectors/DnDPropertySelector'
import useWidgetColors from '../../../../hooks/useWidgetColors'

interface Props {
    selectedRule: FormatConfig | ConditionalFormat
    onUpdate: (update: Partial<FormatConfig>) => void
    onUpdateSource: (field: DataField) => void
    onUpdateTargetSource: (field: DataField) => void
    properties: Property[]
}

const useStyles = makeStyles(({ palette, spacing }) => ({
    borderedSection: {
        maxHeight: 200,
        display: 'flex',
        padding: spacing(0.5),
        flexFlow: 'column nowrap',
        border: `2px solid ${palette.grayscale.light}`,
    },
    ruleContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: spacing(0.5),
    },
}))

export default function ConditionalFormatSelectedEditor({
    selectedRule,
    onUpdateSource,
    onUpdate,
    onUpdateTargetSource,
    properties,
}: Props) {
    const selectedRuleProperty = selectedRule && getDataFieldProperty({ name: selectedRule.TableField }, properties)
    const selectedRuleTargetProperty =
        selectedRule?.TargetProperty && getDataFieldProperty({ name: selectedRule?.TargetProperty }, properties)
    const selectedRuleDisplayProperty = getDisplayProperty({ name: selectedRule?.TableField }, selectedRuleProperty)

    const selectedRuleTargetDisplayProperty = getDisplayProperty(
        { name: selectedRule?.TargetProperty },
        selectedRuleTargetProperty
    )

    const colors = useWidgetColors()
    const classes = useStyles()

    return (
        <DnDPropertySelector
            label="Rule definition"
            accept={propertyTypes}
            limit={1}
            onAdd={({ type, pointer, ...field }) => onUpdateSource(field)}
            onRemove={() => onUpdate({ TableField: null })}
            properties={selectedRuleDisplayProperty ? [{ ...selectedRuleDisplayProperty, color: colors[0] }] : []}
        >
            {selectedRuleDisplayProperty && (
                <div className={classes.ruleContainer}>
                    <ComparisonSelector
                        isAggregate
                        value={selectedRule?.FormatRule}
                        onChange={rule => onUpdate({ FormatRule: rule })}
                        semanticType={selectedRuleProperty?.semanticType}
                        disabled={!selectedRule?.TableField}
                    />

                    {selectedRule?.TargetProperty ? (
                        <PropertyChip
                            property={{ ...selectedRuleTargetDisplayProperty, color: colors[0] }}
                            accept={propertyTypes}
                            onAdd={({ type, pointer, ...field }) => onUpdateTargetSource(field)}
                            onAggregationChange={aggregation =>
                                onUpdate({
                                    TargetProperty: selectedRule?.TargetProperty,
                                })
                            }
                            onRemove={() => onUpdate({ TargetProperty: null })}
                        />
                    ) : (
                        <ValueField
                            comparison={selectedRule?.FormatRule}
                            field={selectedRule?.TableField}
                            values={selectedRule?.target}
                            onChange={target => onUpdate({ target })}
                            disabled={!selectedRule?.TableField}
                            onAdd={onUpdateTargetSource}
                            properties={properties}
                        />
                    )}
                </div>
            )}
        </DnDPropertySelector>
    )
}

const propertyTypes = [PropertyType.DEFINING, PropertyType.QUALITATIVE, PropertyType.QUANTITATIVE]
