import { ResourceType } from 'genesis-suite/types/networkTypes'
import { Category, ChartType, DataSource, HeatConfig, HeatSeries } from 'genesis-suite/types/visualTypes'
import { makeField, stringToFilters } from 'genesis-suite/utils'
import { WidgetConverterProps } from '../migrationTypes'
import {
    convertTooltip,
    getPropertyFromResource,
    makeAggregation,
    makeColorGradient,
    makeNavigation,
} from '../widgetConverterUtils'

export default async function heatmapConverter({
    config,
    dashboardIdByOldId,
    resourceManager,
}: WidgetConverterProps): Promise<HeatConfig> {
    const { Title, HeatmapConfig, NoDataMessage } = config
    const {
        Source,
        RowField,
        ColumnField,
        ValueField,
        CrumbMetaName,
        DefaultPerspective,
        AggregationType,
        ColorPalette,
    } = HeatmapConfig

    const insight = await resourceManager.getInsight(Source.ElementName)

    const category: Category = {
        field: makeField(insight.properties, RowField),
        navigation: makeNavigation(CrumbMetaName, DefaultPerspective, dashboardIdByOldId),
    }

    const value: DataSource = {
        field: makeField(insight.properties, ValueField),
        aggregation: makeAggregation(getPropertyFromResource(insight, ValueField), AggregationType),
    }

    const singleSeries: HeatSeries = {
        service: { type: ResourceType.INSIGHT, name: insight.name, id: insight.id },
        subSeries: { field: makeField(insight.properties, ColumnField) },
        values: [value],
        tooltip: convertTooltip(insight, HeatmapConfig),
        colorGradient: makeColorGradient(ColorPalette),
    }

    return {
        version: '2',
        type: ChartType.HEATMAP,
        title: Title,
        NoDataMessage: NoDataMessage,
        categories: [category],
        series: [singleSeries],
        filters: stringToFilters(insight.properties, Source.Filters),
        xAxis: { title: { text: undefined } },
        yAxis: [{ title: { text: undefined } }],
    }
}
