import { Autocomplete, Box, FormControl, InputLabel, TextField } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useDebouncedCallback } from 'use-debounce'
import { authCreators } from '~/actions/creators'
import { userService } from '~/lib/services'
import { applicationSelectors, authSelectors } from '~/selectors'

const useStyles = makeStyles(({ palette, spacing, border }) => ({
    formControl: {
        marginLeft: spacing(1),
        minWidth: 150,
    },
    divControl: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
    },
    label: {
        display: 'flex',
        fontWeight: 'bold',
    },
}))

export default function ActAsAnotherUser() {
    const classes = useStyles()
    const dispatch = useDispatch()

    const appName = useSelector(applicationSelectors.getCurrentAppName)
    const actAsUser = useSelector(authSelectors.getActAs)
    const currentUserId = useSelector(authSelectors.getUserId)

    const [userList, setUserList] = useState([])
    const [loading, setLoading] = useState(false)
    const [value, setValue] = useState(null)

    const getUserList = async q => {
        setLoading(true)
        const usersFetchRequest = {
            ModelName: appName,
            PageSize: 200,
            PageNumber: 1,
            loaddetails: true,
            superUsersOnly: false,
        }

        if (q) {
            usersFetchRequest['userIdSearch'] = q
        }

        try {
            const usersRes = await userService.getUsersV2(usersFetchRequest)

            const userList = usersRes?.Users?.map(user => ({
                userId: user.UserInfo.UserId,
                userName: user.UserInfo.ScreenAlias,
            }))

            if (userList.length > 0) {
                const filteredUserList = userList.filter(item => item.userId != currentUserId)
                setUserList(filteredUserList)
            }
        } catch (e) {
            console.warn(e)
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        getUserList('')
    }, [])

    useEffect(() => {
        let value = actAsUser
        if (actAsUser && !actAsUser?.userName) {
            value = userList.find(item => item.userId == actAsUser.userId)
        }
        setValue(value)
    }, [userList, actAsUser])

    const onSearch = useDebouncedCallback((searchText: string) => {
        if (searchText && !actAsUser) {
            getUserList(searchText)
        }
    }, 1000)

    const handleChange = async val => {
        if (val) {
            const params = {
                modelName: appName,
                userId: val.userId,
            }
            dispatch(authCreators.setActAs(val, params))
        } else {
            const params = {
                modelName: appName,
            }
            dispatch(authCreators.deleteActAs(params))
        }
    }

    return (
        <Box sx={{ py: 1 }}>
            <div className={classes.divControl}>
                <InputLabel
                    className={classes.label}
                    sx={{
                        color: 'text.primary',
                    }}
                >
                    Use as :
                </InputLabel>
                <FormControl className={classes.formControl}>
                    <Autocomplete
                        size="small"
                        onChange={(event, value) => handleChange(value)}
                        onInputChange={(event, value) => onSearch(value)}
                        value={value}
                        options={userList}
                        autoHighlight
                        disabled={actAsUser ? true : false}
                        getOptionLabel={option => option.userName}
                        loading={loading}
                        renderInput={params => <TextField label="Select a User" {...params} variant="outlined" />}
                        sx={{
                            '& .MuiFormLabel-root, .MuiAutocomplete-endAdornment svg': {
                                color: 'text.primary',
                            },
                            '& .MuiOutlinedInput-notchedOutline': {
                                borderColor: 'text.primary',
                            },
                        }}
                    ></Autocomplete>
                </FormControl>
            </div>
        </Box>
    )
}
