import { MenuIcon } from 'genesis-suite/components'
import BackupSelectorDialog from 'genesis-suite/components/BackupSelector/BackupSelectorDialog'
import { Backups } from 'genesis-suite/icons'
import { useSnackbar } from 'notistack'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { backupCreators } from '~/actions/creators'
import { perspectiveCreators } from '~/actions/creators/perspective.creators'
import { backupService } from '~/lib/services'
import { applicationSelectors, authSelectors, moduleSelectors } from '~/selectors'

export default function BackupSelectorButton({ iconStyle, buttonStyle }) {
    const accessKey = useSelector(authSelectors.getAccessKey)
    const cloudName = useSelector(moduleSelectors.getModuleCloud)
    const appInfo = useSelector(applicationSelectors.getAppInfo)
    const { enqueueSnackbar: showSnackbar } = useSnackbar()

    const dispatch = useDispatch()
    const [openModal, setOpenModal] = useState(false)

    const params = {
        accessKey: accessKey,
        cloudName: cloudName,
        appId: appInfo?.appId,
    }

    const handleClick = () => {
        setOpenModal(!openModal)
    }

    async function getBackupSchedulesService() {
        return await backupService.getBackupSchedules(params)
    }

    async function getBackupSchedulesByIdService(data) {
        return await backupService.getBackupSchedulesById(params, data)
    }

    const onClickInitialize = async state => {
        const selected_list_item = state.selectedScheduleList.filter(item => item.Id === state.selectedScheduleItem)
        try {
            await backupService.InitializeBackupJob(appInfo.appId, cloudName, selected_list_item[0].Id)
            dispatch(backupCreators.updateSelectedSchedules(selected_list_item))
            dispatch(perspectiveCreators.refreshPerspective())
        } catch (e) {
            showSnackbar('Unable to Initialize backup schedule. Try again later', { variant: 'error' })
        } finally {
            handleClick()
        }
    }
    return (
        <>
            <MenuIcon
                icon={<Backups sx={iconStyle} />}
                buttonProps={{ sx: buttonStyle }}
                onClick={handleClick}
                tooltip={'Backup Selector'}
            />
            <BackupSelectorDialog
                openModal={openModal}
                handleClick={handleClick}
                onClickInitialize={onClickInitialize}
                getBackupSchedulesService={getBackupSchedulesService}
                getBackupSchedulesByIdService={getBackupSchedulesByIdService}
            />
        </>
    )
}
