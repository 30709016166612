import { Box, useTheme } from '@mui/material'
import { useSelector } from 'react-redux'
import GlobalFiltersController from '../containers/GlobalFiltersController'
import { useIsMobile } from '../hooks/useIsMobile'
import { TourTag } from '../lib/tourSteps'
import { appearanceSelectors, authSelectors, moduleSelectors } from '../selectors'
import BackupSelectorButton from './BackupSelectorButton'
import BusinessExplorerButton from './BusinessExplorerButton'
import CookiesButton from './CookiesButton'
import DebugPanel from './DebugPanel'
import ForecastButton from './ForecastButton'
import FullScreenButton from './FullScreenButton'
import GenerateSimulationButton from './GenerateSimulationButton'
import { default as GlobalFiltersController2 } from './GlobalFiltersController2'
import HotKeysButton from './HotKeysButton'
import ManageButton from './ManageButton'
import ManageHomeButton from './ManageHomeButton'
import NetworkController from './NetworkController'
import SettingsButton from './SettingsButton'
import AnnouncementsButton from './announcements/AnnouncementsButton'
import NetworkFiltersController from './network-filters/NetworkFiltersController'
import NotificationController from './notifications/NotificationController'
import ScenarioButton from './scenarios/ScenarioButton'
import FontSizeButton from './settings/ManageAppearance/FontSizeButton'
import ThemeModeButton from './settings/ManageTheme/ThemeModeButton'

export default function AppMenu({ orientation = 'horizontal' }) {
    const isMobile = useIsMobile()
    const user = useSelector(authSelectors.getUser)
    const isAdmin = useSelector(authSelectors.getIsAdmin)
    const isSuperUser = useSelector(authSelectors.getIsPowerUser)
    const isV2 = useSelector(moduleSelectors.getIsV2)
    const showNeoClassic = useSelector(appearanceSelectors.getShowNeoClassicLayOut)
    const layout = useSelector(appearanceSelectors.getApplicationLayout)
    const showAiAnalysis = false

    if (isMobile) return null

    const { palette } = useTheme()
    const showDebug = process.env.NODE_ENV === 'development' || isAdmin
    const menuIconProps = {
        buttonStyle: { padding: '6px' },
        iconStyle: {
            fontSize: '18px',
            color:
                layout === 'classic'
                    ? 'text.primary'
                    : palette.getContrastText(showNeoClassic ? palette.background.sideNav : palette.background.topBar),
        },
    }

    if (orientation !== 'horizontal') delete menuIconProps.iconStyle.fontSize

    return (
        <Box
            data-tour={TourTag.AppOptions}
            sx={{
                display: 'flex',
                flexDirection: orientation === 'vertical' ? 'column' : 'row',
                gap: orientation === 'vertical' ? 1 : 0,
                alignItems: 'center',
            }}
        >
            <ManageHomeButton {...menuIconProps} />
            {isSuperUser && <ManageButton {...menuIconProps} />}
            {isAdmin && <ForecastButton {...menuIconProps} />}
            {isAdmin && showAiAnalysis && <GenerateSimulationButton {...menuIconProps} />}
            {!showNeoClassic &&
                (isV2 ? (
                    <GlobalFiltersController2 {...menuIconProps} />
                ) : (
                    <GlobalFiltersController {...menuIconProps} />
                ))}
            {!showNeoClassic && <NetworkFiltersController {...menuIconProps} />}
            {!showNeoClassic && <ScenarioButton {...menuIconProps} />}
            <BackupSelectorButton {...menuIconProps} />
            {!showNeoClassic && <NotificationController {...menuIconProps} />}
            <BusinessExplorerButton {...menuIconProps} />
            <NetworkController {...menuIconProps} />
            <AnnouncementsButton {...menuIconProps} />
            {showDebug && <DebugPanel {...menuIconProps} />}
            <FontSizeButton {...menuIconProps} />
            <CookiesButton {...menuIconProps} />
            <ThemeModeButton {...menuIconProps} />
            <FullScreenButton {...menuIconProps} />
            <HotKeysButton {...menuIconProps} />
            {!showNeoClassic && <SettingsButton {...menuIconProps} />}
        </Box>
    )
}
