import React, { useState, ReactNode } from 'react'
import { useDrop } from 'react-dnd'
import { Paper, TextField, useTheme } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { Autocomplete } from '@mui/material'

import { PropertyType } from 'genesis-suite/types/architectureTypes'
import { DragField } from './builderTypes'

const useStyles = makeStyles(({ palette }) => ({
    placeholder: {
        height: '5px',
        borderRadius: '2px',
        background: palette.primary.main,
    },
    options: { overflowX: 'hidden' },
}))

interface Props {
    label: string
    className?: string
    accept?: PropertyType[]
    children?: ReactNode
    showPlaceholder?: boolean
    onDrop: (field: DragField) => void
    hideSelect?: boolean
    options?: any
    required?: boolean
}

export default function Droppable({
    label,
    className,
    showPlaceholder,
    accept = [],
    onDrop,
    children,
    hideSelect,
    options,
    required,
}: Props) {
    const classes = useStyles()
    const { palette } = useTheme()
    const [autoCompleteInput, setAutoCompleteInput] = useState('')
    const [{ canDrop, isOver, isOverCurrent }, drop] = useDrop({
        accept,
        drop: (item: DragField, monitor) => {
            if (monitor.didDrop() || !accept.length) return
            onDrop(item)
        },
        collect: monitor => ({
            canDrop: monitor.canDrop(),
            isOver: monitor.isOver(),
            isOverCurrent: monitor.isOver({ shallow: true }),
        }),
    })

    const dropBorder = `2px ${isOver ? 'solid' : 'dashed'} ${isOver ? palette.primary.main : palette.grayscale.dark}`

    return (
        <Paper className={className} elevation={0} ref={drop} style={{ border: canDrop ? dropBorder : undefined }}>
            {children}

            <div
                className={classes.placeholder}
                style={{ display: canDrop && showPlaceholder && isOverCurrent ? 'inherit' : 'none' }}
            />

            {!hideSelect && options && options.length > 0 && (
                <Autocomplete
                    disableClearable
                    classes={{ listbox: classes.options }}
                    options={options}
                    getOptionLabel={(option: any) => option.label}
                    isOptionEqualToValue={(opt, val) => opt.id === val.id}
                    inputValue={autoCompleteInput}
                    onChange={(e, { value }) => onDrop(value)}
                    onInputChange={(e, value, reason) => {
                        if (reason === 'reset') setAutoCompleteInput('')
                        else setAutoCompleteInput(value)
                    }}
                    renderInput={params => <TextField {...params} placeholder={label} />}
                />
            )}
        </Paper>
    )
}
