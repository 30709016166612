import { useState } from 'react'
import { useSnackbar } from 'notistack'
import makeStyles from '@mui/styles/makeStyles'
import { FormSection } from 'genesis-suite/types/visualTypes'
import { Typography, Step, StepLabel, StepContent, Stepper, Button, Box, Tooltip } from '@mui/material'
import ExpandCircleDownRoundedIcon from '@mui/icons-material/ExpandCircleDownRounded'
import Section, { SectionProps } from './InputFormSection'
import { getFormBackground } from '../../../lib/formUtils'

const useStyles = makeStyles(({ spacing, palette }) => ({
    stepperRoot: { padding: spacing(1), overflow: 'auto' },
    stepLabel: {
        //@ts-ignore
        color: ({ background }) =>
            typeof background === 'string'
                ? `${palette.getContrastText(getFormBackground(background))} !important`
                : 'inherit',
    },
    activeLabel: { '& > p': { fontWeight: 'bold' } },
    submitBtn: { marginTop: spacing(1), marginLeft: spacing(1), alignSelf: 'flex-end' },
}))

interface WizardProps extends Omit<SectionProps, 'FormActions'> {
    sections: FormSection[]
}

export default function WizardForm({ sections, palette, isEditingLayout, ...sectionProps }: WizardProps) {
    const classes = useStyles({ ...palette })
    const [step, setStep] = useState(0)
    const [expandedSections, setExpandedSections] = useState<boolean[]>(sections?.map((_, index) => index === 0))
    const [taken, setTaken] = useState<number[]>([])

    const { enqueueSnackbar } = useSnackbar()
    const errSnack = (message: string) => enqueueSnackbar(message, { variant: 'error' })

    const toggleSection = (index: number) => {
        setExpandedSections(prev => prev.map((isExpanded, i) => (i === index ? !isExpanded : false)))
        setStep(prevStep => {
            // If clicking the same step, collapse it and remove it from the taken history
            if (prevStep === index) {
                setTaken(prevTaken => prevTaken.filter(step => step !== index))
                return -1 // No active step
            }

            // Otherwise, navigate to the clicked step and update the taken history
            setTaken(prevTaken => [...prevTaken, prevStep].filter(step => step !== -1))
            return index
        })
    }

    const collapseAll = () => setExpandedSections(sections.map(() => false))

    const handleBack = () => {
        const index = [...taken].pop()
        setTaken(taken.filter(i => i !== index))
        setStep(index || 0)
        setExpandedSections(prev => prev.map((_, i) => i === index))
    }

    const handleNext = (sectionId: string, fromIndex: number) => {
        const toIndex = sections.findIndex(s => s.id === sectionId)
        if (toIndex === -1) {
            errSnack('An error occurred presenting the form. Please contact support')
            console.error(
                'Section with section Id not found in config! Please check the configuration',
                `Requested Id: ${sectionId}`
            )
            return
        }
        setTaken([...taken, fromIndex])
        setStep(toIndex)
        setExpandedSections(prev => prev.map((_, i) => i === toIndex))
    }

    const curSection = sections[step]
    const completed = !sectionProps?.values?.isNew

    return (
        <>
            <Stepper
                className={classes.stepperRoot}
                style={{ background: 'transparent' }}
                activeStep={step}
                orientation="vertical"
                nonLinear
            >
                {sections.map(({ description, title, goTo }, index) => {
                    const isExpanded = expandedSections[index]
                    return (
                        <Step key={title} expanded={isExpanded} completed={completed}>
                            <StepLabel
                                sx={completed && { '&:hover': { cursor: 'pointer' } }}
                                onClick={completed ? () => toggleSection(index) : null}
                                classes={{ label: classes.stepLabel, active: classes.activeLabel }}
                            >
                                <Box display="flex" alignItems="center" gap={1}>
                                    <Typography>{title}</Typography>
                                    {index === 0 && (
                                        <Tooltip
                                            title={
                                                expandedSections?.some(isExpanded => isExpanded)
                                                    ? 'Collapse All'
                                                    : 'No Sections to Collapse'
                                            }
                                        >
                                            <ExpandCircleDownRoundedIcon
                                                onClick={event => {
                                                    event.stopPropagation() // Prevent triggering StepLabel click
                                                    if (!expandedSections?.some(isExpanded => isExpanded)) return
                                                    collapseAll()
                                                }}
                                                sx={{
                                                    cursor: expandedSections?.some(isExpanded => isExpanded)
                                                        ? 'pointer'
                                                        : 'not-allowed',
                                                    color: theme => theme.palette.primary.main,
                                                }}
                                            />
                                        </Tooltip>
                                    )}
                                </Box>
                                {description && <Typography variant="caption">{description} </Typography>}
                            </StepLabel>
                            {isExpanded && (
                                <StepContent>
                                    <Section
                                        {...sectionProps}
                                        palette={palette}
                                        section={sections[index]} // Use the current section
                                        FormActions={({ onSubmit, submitDisabled }) => (
                                            <Box display="flex">
                                                <Button
                                                    variant="outlined"
                                                    onClick={handleBack}
                                                    disabled={step === 0}
                                                    className={classes.submitBtn}
                                                >
                                                    Back
                                                </Button>
                                                <Button
                                                    color="primary"
                                                    variant="contained"
                                                    disabled={submitDisabled}
                                                    className={classes.submitBtn}
                                                    onClick={goTo ? () => handleNext(goTo, index) : onSubmit}
                                                >
                                                    {goTo ? 'Next' : 'Submit'}
                                                </Button>
                                            </Box>
                                        )}
                                    />
                                </StepContent>
                            )}
                        </Step>
                    )
                })}
            </Stepper>
        </>
    )
}
