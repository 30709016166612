//It's use for Ag grid cell editing
import { ColDef } from '@ag-grid-community/core'
import { Property } from 'genesis-suite/types/networkTypes'
import { DateFormat, FieldVisibility, FormColumn } from 'genesis-suite/types/visualTypes'
import { ColumnOptions } from '~/components/contexts/ColumnOptionsContext'
import moment from 'moment'
import clsx from 'classnames'
import FormTableCell from '~/components/widgets/visuals/FormTableWidget/FormTableCell'
import FormTableEditCell from '~/components/widgets/visuals/FormTableWidget/FormTableEditCell'
import { isEmpty } from 'lodash'
import UniversalCellEditor from '~/components/widgets/visuals/FormTableWidget/UniversalCellEditor'
import FormTableCustomHeader from '~/components/widgets2/form/FormTableCustomHeader'

export type LoadingCell = { rowId: string; fieldName: string; originalFieldName: string }
export type EditingCell = { rowId: string; fieldName: string; originalFieldName: string }

type DataType = 'number' | 'date' | 'dateTime' | 'string' | 'attachment'
export const getType = (property: Property, dateFormat: DateFormat): DataType => {
    if (!property) return 'string'
    const { dataTypeClass, semanticType } = property
    if (semanticType?.baseDataType === 'File') return 'attachment'
    switch (dataTypeClass) {
        case 'Number':
            return 'number'
        case 'DateTime':
            if (!dateFormat || !dateFormat.includes('h')) return 'date'
            else return 'dateTime'
        default:
            return 'string'
    }
}
const muiEditors = ['string', 'number', 'date', 'dateTime']

export function buildTableFormXGridColumns(
    config: any,
    flaggedColumns: any = {},
    customColDef: Partial<ColDef> = {},
    columnOptions: ColumnOptions[],
    editingCells: EditingCell[],
    loadingCells: LoadingCell[],
    SortOrders?: any
): ColDef[] {
    const { columns, transposeColumns, isTranspose, transposeSettings } = config
    const formColumn = isTranspose ? transposeColumns : (columns as FormColumn[])
    const colDefs = formColumn.map(column => {
        const {
            title,
            width,
            editable,
            property,
            visibility,
            dateFormat,
            customEditor,
            autogenerate,
            useTadaValues,
            numberFormat,
            displayProperty,
            rowJustification,
            autogeneratePrefix,
            headerJustification,
            conditionalFormatting,
        } = column

        if (isTranspose && column?.id === 'TransposeRow') {
            return createSpecialTransposeColumnConfig(column)
        }

        const { width: colOptWidth } = columnOptions?.find(opt => opt.colId === property.displayName) || {}
        const type = getType(displayProperty || property, dateFormat)
        const canEdit = editable && !autogenerate && !autogeneratePrefix && !property?.computed
        const colWidth = type === 'attachment' ? colOptWidth || 160 : colOptWidth || width || 125
        let sort, sortIndex
        if (!isEmpty(SortOrders)) {
            const sortDir = SortOrders[title]
            sort = sortDir === 'Ascending' ? 'asc' : sortDir === 'Descending' ? 'desc' : undefined
            sortIndex = sort ? Object.keys(SortOrders).findIndex(key => key === title) : undefined
        }
        return {
            ...column,
            colId: property.name,
            headerName: title,
            editable: params => {
                if (isTranspose) {
                    const { data: row } = params
                    return params?.colDef?.nonEditableColumn
                        ? canEdit
                        : getTransposeColumn(columns, row?.TransposeRow)?.editable ?? canEdit
                } else {
                    return canEdit
                }
            },
            field: property.name,
            // ...getFilterParams(true, type),
            width: colWidth,
            resizable: true,
            sort: sort,
            sortIndex: sortIndex,
            sortable: true,
            // cellDataType: type,
            columnTypes: type === 'attachment' ? 'string' : type,
            hide: visibility === FieldVisibility.FORM || visibility === FieldVisibility.HIDE,
            headerComponent: FormTableCustomHeader,
            headerComponentParams: { canEdit: canEdit, headerJustification: headerJustification },
            cellClass: params => {
                const {
                    data: { id },
                } = params
                const flagged = flaggedColumns[id] ? flaggedColumns[id].includes(title) : false
                return clsx('tadaCell', {
                    flaggedCell: flagged,
                })
            },
            cellRenderer: FormTableCell,
            cellRendererParams: params => {
                const isEditing = Boolean(
                    editingCells?.find(c => {
                        if (c.rowId === params?.data?.id) {
                            if (isTranspose) return c.originalFieldName === property.name
                            else return c.fieldName === property.name
                        }
                    })
                )
                const isLoading = Boolean(
                    loadingCells.find(c => {
                        if (c.rowId === params?.data?.id) {
                            if (isTranspose) return c.originalFieldName === property.name
                            else return c.fieldName === property.name
                        }
                    })
                )
                return {
                    ...params,
                    isEditing,
                    isLoading,
                    numberFormat,
                    conditionalFormatting,
                    isTranspose,
                    columns,
                    params,
                    rowJustification: rowJustification?.toLocaleLowerCase(),
                }
            },
            ...((useTadaValues || customEditor || !muiEditors.includes(type)) && {
                cellEditor: FormTableEditCell,
                cellEditorParams: params => {
                    return {
                        column,
                        columns,
                        params,
                        isTranspose,
                        validator: params => {
                            let error = null
                            const {
                                props: { value },
                            } = params
                            const minDate = new Date('1/1/1900')
                            if (type === 'date' && value && moment(value as string).isBefore(moment(minDate)))
                                error = 'Minimum date is 01/01/1900'
                            else if (property.isPrimary && !value && value !== 0) error = true
                            return { valid: !error, message: error }
                        },
                    }
                },
            }),
            ...(!(useTadaValues || customEditor || !muiEditors.includes(type)) && {
                cellEditor: UniversalCellEditor,
                cellEditorParams: params => {
                    const _column = isTranspose ? getTransposeColumn(columns, params?.data?.TransposeRow) : column
                    const dataType = getType(_column.displayProperty || _column.property, _column.dateFormat)
                    return {
                        column,
                        params,
                        type: dataType === 'attachment' ? 'string' : dataType,
                    }
                },
            }),
            // this config for transposeData
            ...(isTranspose && {
                rowSpan: params => {
                    return params?.colDef?.nonEditableColumn
                        ? getRowSpan(params, property.name, transposeSettings?.transposeRows?.length)
                        : 1
                },
                cellClassRules: {
                    'cell-span': (params: any) => {
                        return params?.colDef?.nonEditableColumn
                            ? getRowSpan(params, property.name, transposeSettings?.transposeRows?.length) > 1
                            : false
                    },
                },
                suppressRowTransform: true,
            }),
            ...customColDef,
        }
    })
    if (columnOptions?.length === colDefs.length) {
        const colOptionIds = new Set(columnOptions.map(opt => opt.colId))
        const ordered = columnOptions
            .map(opt => colDefs.find(col => col.colId === opt.colId))
            ?.filter(col => col !== undefined)
        const unmatchedColDefs = colDefs.filter(col => !colOptionIds.has(col.colId))

        return [...ordered, ...unmatchedColDefs]
    } else return colDefs
}

const getFilterParams = (show: boolean, type: DataType) => {
    const filterObject = {
        filter: null,
        filterParams: {
            buttons: ['apply', 'clear'],
            maxNumConditions: 1,
            trimInput: true,
            closeOnApply: true,
            defaultOption: null,
            filterOptions: null,
        },
    }

    if (!show) return filterObject

    switch (type) {
        case 'date':
            filterObject.filter = 'agDateColumnFilter'
            filterObject.filterParams.defaultOption = 'inRange'
            filterObject.filterParams.filterOptions = ['inRange']
            break
        case 'number':
            filterObject.filter = 'agNumberColumnFilter'
            filterObject.filterParams.defaultOption = 'contains'
            filterObject.filterParams.filterOptions = [
                'contains',
                'notContains',
                'equals',
                'notEqual',
                'startsWith',
                'endsWith',
            ]
            break
        case 'string':
            filterObject.filter = 'agTextColumnFilter'
            filterObject.filterParams.defaultOption = 'contains'
            filterObject.filterParams.filterOptions = [
                'contains',
                'notContains',
                'equals',
                'notEqual',
                'startsWith',
                'endsWith',
            ]
            break
    }

    return filterObject
}

export const isMultipleEdit = (globalMultiEditEnabled, formLevalMultipleEdit) => {
    // if (globalMultiEditEnabled && !formLevalMultipleEdit) {
    //     return false
    // } else if (!globalMultiEditEnabled && formLevalMultipleEdit) {
    //     return true
    // } else if (!globalMultiEditEnabled && !formLevalMultipleEdit) {
    //     return false
    // } else {
    //     return true
    // }
    return globalMultiEditEnabled
}
function getRowSpan(params, fieldName, transposeRowCount = null) {
    if (params.node.group) {
        return 1
    }

    const rowIndex = params.node.rowIndex
    const data = params.api.getDisplayedRowAtIndex(rowIndex).data
    if (!data) return 1
    const currentValue = data[fieldName]

    let span = 1
    let nextRowIndex = rowIndex + 1
    let nextRowNode = params.api.getDisplayedRowAtIndex(nextRowIndex)

    while (nextRowNode && nextRowNode.data && nextRowNode.data[fieldName] === currentValue) {
        transposeRowCount !== null ? (span = transposeRowCount) : span++ // That is use for transpose data
        nextRowIndex++
        nextRowNode = params.api.getDisplayedRowAtIndex(nextRowIndex)
    }

    // If this is the first row in the span
    if (rowIndex === 0 || params.api.getDisplayedRowAtIndex(rowIndex - 1)?.data?.[fieldName] !== currentValue) {
        return span
    } else {
        return 0
    }
}
export const checkIsTranspose = transposeSettings => {
    if (
        !isEmpty(transposeSettings) ||
        !isEmpty(transposeSettings?.transposeRows) ||
        !isEmpty(transposeSettings?.transposeColumn)
    )
        return true
    return false
}
export const makeTransposeColumns = (data, config, transColumns) => {
    if (isEmpty(data) || isEmpty(config)) return config

    const { columns, transposeSettings } = config
    const { transposeColumn, transposeRows } = transposeSettings

    // Prepare a set of fields used for transposition to check inclusion
    const transposeFieldsSet = new Set([transposeColumn.formField, ...transposeRows.map(row => row.formField)])
    let targetTransposeColumn = null

    // Separate columns into transposed and non-transposed while identifying the target column
    const nonTransposeColumns = columns
        ?.filter(column => {
            if (transposeFieldsSet?.has(column.property.id)) {
                if (column.property.id === transposeColumn.formField) {
                    targetTransposeColumn = { ...column }
                    return false
                }
                return false
            }
            return true
        })
        .map(column => ({
            ...column,
            nonEditableColumn: true,
            pinned: 'left',
        }))

    const ignoreKeysSet = new Set([
        'TransposeRow',
        'TransposeRowKey',
        'id',
        ...nonTransposeColumns.map(col => col.property.name),
    ])

    const keysToBeConsideredForTranspose = JSON.parse(transColumns ?? '[]')
    const transformedColumns = Object.keys(data).reduce((acc, key) => {
        if (!ignoreKeysSet.has(key) && keysToBeConsideredForTranspose.includes(key)) {
            acc.push({
                ...targetTransposeColumn,
                property: {
                    ...targetTransposeColumn.property,
                    name: key,
                    displayName: key,
                },
                title: key,
                editable: true,
                isTransposeColumn: true
            })
        }
        return acc
    }, [])

    // TransposeRow is comming in the data so this based we  created the column
    nonTransposeColumns?.splice(nonTransposeColumns?.length, 0, {
        headerName: '',
        field: 'TransposeRow',
        id: 'TransposeRow',
    })
    return {
        ...config,
        transposeColumns: [...nonTransposeColumns, ...transformedColumns],
        canAddNew: false,
        canDelete: false,
        canInputEdit: false,
    }
}

function createSpecialTransposeColumnConfig(column): ColDef | null {
    if (!column || !column.id) {
        return null
    }
    return {
        colId: column.id,
        headerName: '',
        field: 'transposeRowDisplayName',
        sortable: false,
        editable: false,
        cellStyle: { 'font-weight': '400', 'text-align': 'center' },
        width: 50,
        suppressMenu: true,
        pinned: 'left',
    }
}
export function getTransposeColumn(columns, field) {
    return columns.find(item => item.property.name === field)
}
