import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import { Box, Skeleton, Tooltip, Typography, useTheme } from '@mui/material'
import { ChopText } from 'genesis-suite/components'
import { ExclamationCircle } from 'genesis-suite/icons'
import moment from 'moment'
import { useLayoutEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import chartUtils from 'tadacharts/es/utils/chart-utils'
import formatNumber from '~/components/widgets2/utils/formatNumber'
import { navigationCreators } from '../../../../actions/creators'
import { widgetConstants } from '../../../../constants'
import { filterSelectors, widgetSelectors } from '../../../../selectors'
import GridLabelAccordion from './GridLabelCategory'
const { DRILLDOWN } = widgetConstants.Interactions

const { FETCHING, ERROR } = widgetConstants.DataStatus

export default function GridLabel({
    labelsConfig,
    labelConfig,
    content,
    status,
    itemWidth,
    labelStyle,
    slider = false,
    editMode = false,
}) {
    const {
        SeriesName,
        IconUrl,
        DefaultPerspective,
        LabelField,
        ShowVartiation,
        VariationViewSettings,
        HideCategories,
        FormatOptions,
    } = labelConfig

    const data = makeData(labelConfig, content)

    const value = makeValue(labelConfig, data)
    const {
        palette: { text },
    } = useTheme()
    const context = useSelector(filterSelectors.getCoord)
    const [displayValueStyle, setDisplayValueStyle] = useState({ wordBreak: 'break-word', lineHeight: '1.3' })
    const [summarisedValueStyle, setSummarisedValueStyle] = useState({ lineHeight: '1.3' })
    const displayValue = makeDisplayValue(labelsConfig, labelConfig, value)

    const summarisedValue = getSummarisedValue(VariationViewSettings, displayValue)

    const dispatch = useDispatch()
    const containerRef = useRef(null)
    const [anchorEl, setAnchorEl] = useState(null)

    const interactionType = useSelector(widgetSelectors.getInteractionType)
    const inlineFilters = useSelector(filterSelectors.getInlineFilters)

    const commonTextProps = {
        showEllipsis: true,
        color: 'inherit',
        tooltipProps: { placement: 'bottom' },
        fontFamily: 'segoe UI',
    }

    const valueProps = {
        ...commonTextProps,
        position: 'absolute',
        maxWidth: 'calc(100% - 20px)',
        width: 'auto',
        bottom: '4px',
        right: '8px',
    }

    const mobileValueProps = {
        ...commonTextProps,
        maxWidth: 'calc(100% - 20px)',
        maxHeight: '100%',
        width: 'auto',
        marginTop: '32px',
    }

    const summarisedValueDivProps = {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        position: 'absolute',
        bottom: '4px',
    }

    const summarisedValueProps = {
        ShowVartiation,
        VariationViewSettings,
        summarisedValue,
        summarisedValueStyle,
        itemWidth,
    }

    useLayoutEffect(() => {
        if (itemWidth) {
            const textFontSize = getFontSize(displayValue, itemWidth)

            let size
            if (FormatOptions) {
                size =
                    !FormatOptions[0]?.FontSize || FormatOptions[0]?.FontSize === 0
                        ? textFontSize
                        : `${FormatOptions[0]?.FontSize}px`
            }

            const summarisedValueSize = getSummarisedValueSize(summarisedValue, itemWidth)

            setDisplayValueStyle({
                ...displayValueStyle,
                fontSize: !size ? textFontSize : size,
            })

            setSummarisedValueStyle({
                ...summarisedValueStyle,
                fontSize: summarisedValueSize,
            })
        }
    }, [displayValue, itemWidth])

    const onLabelClick = () => {
        if (editMode) return
        if (!DefaultPerspective) return

        if (status === FETCHING || status === ERROR) return

        const carryOverFilters = interactionType === DRILLDOWN

        dispatch(navigationCreators.goToPerspective(DefaultPerspective, { context, carryOverFilters, inlineFilters }))
    }

    const handleClick = event => {
        setAnchorEl(anchorEl ? null : event.currentTarget)
    }

    const customValueProps = slider ? mobileValueProps : valueProps

    return (
        <>
            {LabelField && data?.length > 1 && !HideCategories ? (
                <Box
                    flex={1}
                    height="100%"
                    overflow="hidden"
                    display="flex"
                    flexDirection="column"
                    justifyContent="space-between"
                    sx={{ cursor: 'pointer' }}
                >
                    <div ref={containerRef} aria-describedby={SeriesName} onClick={handleClick}>
                        <GridLabelAccordion
                            key={Math.random()}
                            labelConfig={labelConfig}
                            content={content}
                            displayValue={displayValue}
                            displayValueStyle={displayValueStyle}
                            onLabelClick={onLabelClick}
                            anchorEl={anchorEl}
                            commonProps={commonTextProps}
                            valueProps={customValueProps}
                            summarisedValueDivProps={summarisedValueDivProps}
                            summarisedValueProps={summarisedValueProps}
                            data={data}
                            labelStyle={labelStyle}
                        />
                    </div>
                </Box>
            ) : (
                <Box
                    flex={1}
                    height="100%"
                    overflow="hidden"
                    display="flex"
                    flexDirection="column"
                    justifyContent="space-between"
                    onClick={onLabelClick}
                >
                    <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }} ref={containerRef}>
                        <Box display="flex" justifyContent="space-between">
                            <ChopText
                                {...commonTextProps}
                                sx={{ fontSize: slider ? '1rem' : '0.8rem', lineHeight: '1.3 !important', p: 1 }}
                                variant="body1"
                            >
                                {SeriesName}
                            </ChopText>
                        </Box>
                        {status === FETCHING ? (
                            <Box
                                sx={{ display: 'flex', justifyContent: 'flex-end', fontWeight: 600, p: 1 }}
                                {...customValueProps}
                            >
                                <Skeleton variant="rectangular" width={80} height={32} />
                            </Box>
                        ) : status === ERROR ? (
                            <Box {...valueProps} display="flex" alignItems="center" gap="8px">
                                <ExclamationCircle />
                                <Typography>Error</Typography>
                            </Box>
                        ) : (
                            <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                                {ShowVartiation && !isNaN(displayValue) && (
                                    <Box
                                        sx={theme => ({
                                            color:
                                                labelStyle?.backgroundColor && labelStyle?.backgroundColor !== '#a6a6a6'
                                                    ? theme.palette.getContrastText(labelStyle?.backgroundColor)
                                                    : displayValue > parseInt(VariationViewSettings?.TargetValue, 10)
                                                    ? '#008000'
                                                    : '#ff0000',
                                            top: itemWidth <= 120 ? 0 : null,
                                        })}
                                        {...summarisedValueDivProps}
                                    >
                                        <Tooltip title={VariationViewSettings?.Text || summarisedValue}>
                                            <>
                                                <Typography
                                                    sx={{
                                                        fontSize: '10px',
                                                        fontWeight:
                                                            VariationViewSettings?.FontWeight === 'lighter'
                                                                ? 200
                                                                : VariationViewSettings?.FontWeight,
                                                        paddingLeft: '8px',
                                                    }}
                                                    style={summarisedValueStyle}
                                                >
                                                    {summarisedValue}
                                                </Typography>
                                                <span>
                                                    {displayValue > parseInt(VariationViewSettings?.TargetValue, 10) ? (
                                                        <ArrowUpwardIcon fontSize="small" />
                                                    ) : (
                                                        <ArrowDownwardIcon fontSize="small" />
                                                    )}
                                                </span>
                                            </>
                                        </Tooltip>
                                    </Box>
                                )}

                                <Box>
                                    <ChopText
                                        variant="h5"
                                        sx={{
                                            textAlign: 'right',
                                            fontWeight:
                                                FormatOptions &&
                                                (FormatOptions[0]?.FontWeight !== 'Unknown' ||
                                                    FormatOptions[0]?.FontWeight !== null)
                                                    ? FormatOptions && FormatOptions[0]?.FontWeight
                                                    : 600,
                                        }}
                                        style={displayValueStyle}
                                        {...customValueProps}
                                    >
                                        {displayValue}
                                    </ChopText>
                                </Box>
                            </div>
                        )}
                    </div>
                </Box>
            )}
        </>
    )
}

function makeData(labelConfig, content) {
    if (!content) return

    const dataString = content.seriesData[labelConfig.SeriesName]
    if (!dataString) return

    return JSON.parse(dataString)
}

function makeValue(labelConfig, data) {
    if (!data) return

    const { SeriesName, ValueField, LabelField } = labelConfig
    return LabelField ? data.find(d => Boolean(d[`Total-${SeriesName}`]))?.[`Total-${SeriesName}`] : data[0][ValueField]
}

function makeDisplayValue(labelsConfig, labelConfig, value) {
    if (value == null) return labelConfig.NoDataMessage ?? 'No data'

    const { BaseFormat, ValueFormat, CurrencySymbol } = labelsConfig
    const { BaseFormat: seriesBase, ValueFormat: seriesValueFormat, CurrencySymbol: seriesCurrency } = labelConfig

    const baseFormat = seriesBase && seriesBase !== '' ? seriesBase : BaseFormat
    const valueFormat = seriesValueFormat && seriesValueFormat !== '' ? seriesValueFormat : ValueFormat
    const currency = seriesCurrency && seriesCurrency !== '' ? seriesCurrency : CurrencySymbol

    try {
        let formatted_value = chartUtils.format(value, valueFormat, currency, value, baseFormat)
        if (valueFormat && moment(formatted_value, valueFormat).isValid()) {
            formatted_value = moment(formatted_value).format(valueFormat)
        }
        return formatted_value
    } catch (e) {
        console.warn(`failed to format value: ${value}, displaying raw value as fallback`)
        return value
    }
}

function getSummarisedValue(VariationViewSettings, displayValue) {
    if (isNaN(displayValue) || !VariationViewSettings) return

    const { AsPercentage, TargetValue, Prefix = '', Suffix = '', Base } = VariationViewSettings

    const format = {
        base: Base,
    }
    let value
    value = displayValue - TargetValue

    const summarisedValue = formatNumber(value, format)
    if (AsPercentage) {
        value = (Math.abs(displayValue - TargetValue) / TargetValue) * 100

        return Prefix + value + '%' + Suffix
    }

    return Prefix + summarisedValue + Suffix
}

function getSummarisedValueSize(displayValue, itemWidth) {
    const maxFontSize = 0.65
    const minFontSize = 0.3

    if (!itemWidth || !displayValue) return maxFontSize + 'rem'

    const newFontSize = ((itemWidth * 2) / displayValue?.length + 0.5) / 22

    return newFontSize >= maxFontSize
        ? maxFontSize + 'rem'
        : newFontSize <= minFontSize
        ? minFontSize + 'rem'
        : newFontSize + 'rem'
}

function getFontSize(displayValue, itemWidth) {
    const maxFontSize = 1.7
    const minFontSize = 0.9

    if (!itemWidth || !displayValue) return maxFontSize + 'rem'

    const newFontSize = ((itemWidth * 2) / displayValue.length + 0.5) / 22

    return newFontSize >= maxFontSize
        ? maxFontSize + 'rem'
        : newFontSize <= minFontSize
        ? minFontSize + 'rem'
        : newFontSize + 'rem'
}
