import { Box, Tooltip, IconButton } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { SideNavCollapse } from 'genesis-suite/icons'

const useStyles = makeStyles({
    alignRight: { right: 0, borderRadius: '20px 0 0 20px' },
    alignLeft: { left: 0, borderRadius: '0px 20px 20px 0px' },
})
export default function SideNavCollapseToggle({ open, onClick, invert = false, position = 'right' }) {
    const classes = useStyles()
    return (
        <Box
            onClick={onClick}
            sx={{
                display: 'flex',
                cursor: 'pointer',
                bgcolor: 'background.sideNav',
                position: 'absolute',
                bottom: 45,
                zIndex: 10,
                '&:hover': { '.toggle-shim': { width: 15 } },
            }}
            className={position === 'left' ? classes.alignLeft : classes.alignRight}
        >
            <Tooltip title={`${open ? 'Collapse' : 'Show'} panel`}>
                <IconButton sx={{ color: 'text.primary' }} size="small">
                    <SideNavCollapse
                        sx={{
                            transform: `rotate(${open ? (invert ? '0' : '180') : invert ? '180' : '0'}deg)`,
                            transition: 'all 250ms',
                        }}
                    />
                </IconButton>
            </Tooltip>
            <Box className="toggle-shim" sx={{ width: open ? 15 : 0, transition: 'all 250ms' }} />
        </Box>
    )
}
