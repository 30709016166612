import { isEmpty } from 'lodash'

import { PropertyType, SemanticType, SemanticTypeById } from 'genesis-suite/types/architectureTypes'
import { BareResource, Property, Resource } from 'genesis-suite/types/networkTypes'
import {
    Aggregation,
    BaseSeries,
    DataField,
    DataSource,
    RawDataField,
    RawDataSource,
    SeriesConfig,
} from 'genesis-suite/types/visualTypes'
import pickFromCarousel from '../widgets2/utils/pickFromCarousel'
import { DisplayProperty, FieldPointer } from './builderTypes'

export function dataSourceIsEqual(a: RawDataSource, b: RawDataSource) {
    if (!a || !b) return false
    if (!dataFieldIsEqual(a.field, b.field)) return false
    if (a.aggregation !== b.aggregation) return false

    return true
}

export function dataFieldIsEqual(a: RawDataField, b: RawDataField) {
    if (!a || !b) return false
    if (a.name !== b.name) return false
    if (a.resourceName && b.resourceName && a.resourceName !== b.resourceName) return false

    return true
}

export function getDataFieldProperty(field: DataField, properties: Property[]): Property {
    if (!field) return

    const prop = properties?.find(p => p.name === field.name)
    return { ...field, ...prop, displayName: prop?.displayName ?? prop?.name }
}

export function getDefaultAggregation(semanticType: SemanticType, hasAggregates?: boolean) {
    if (hasAggregates) return Aggregation.NONE
    return semanticType?.type === PropertyType.QUANTITATIVE ? Aggregation.SUM : Aggregation.COUNT
}

export const getBoxPlotAggregations = () => {
    return [Aggregation.MIN, Aggregation.Q1, Aggregation.MEDIAN, Aggregation.Q3, Aggregation.MAX]
}

export const getSeriesColor = (series: BaseSeries[], currentIndex: number, colors: string[]): string => {
    const currentSeries = series[currentIndex]
    const seriesTitle = currentSeries.values[0].field.name
    return currentSeries?.colors?.[seriesTitle]?.series ?? pickFromCarousel(colors, currentIndex)
}

/** get category or value field from a pointer */
export function getSourceFromPointer(config: SeriesConfig, pointer: FieldPointer): DataSource {
    if (!pointer) return

    const { categories, series } = config

    switch (pointer.type) {
        case 'category':
            return { field: categories[pointer.index].field, aggregation: Aggregation.NONE }
        case 'subseries':
            return { field: series[pointer.index].subSeries.field, aggregation: Aggregation.NONE }
        case 'series':
            return series[pointer.index].values[pointer.valueIndex]
    }
}

export function parseProperty(prop: any, semanticTypeById: SemanticTypeById, container?: BareResource): Property {
    const displayName = prop.DisplayName ?? prop.Description ?? prop.Name
    const semanticType = prop.SemanticType ?? prop.SemanticDataType
    const semanticTypeId = semanticType?.DataTypeId || 1
    const computed = prop.IsComputed || Boolean(prop.Expression)
    const definitionType = prop.DefinitionType || 1

    return {
        ...(container && { container }),
        id: prop.Id,
        displayName,
        name: prop.Name,
        ...(prop.IsPrimary && { isPrimary: prop.IsPrimary }),
        dataTypeClass: prop.DataTypeClass,
        computed,
        ...(computed && {
            computeDefinition:
                definitionType === 1 ? { type: 'expression', value: prop.Expression } : { type: null, value: null },
            hasAggregates: prop.HasAggregates,
            isOptional: prop.IsOptional,
            pathwayString: prop.pathwayString,
        }),
        semanticType: semanticTypeById?.[semanticTypeId],
        referenceAttributeId: prop.ReferenceAttributeId,
        dateResolution: semanticType?.DateResolution ? semanticType?.DateResolution : null,
    }
}

export const propertyIsDate = (property: Property): boolean => {
    if (!property) return false
    else if (
        property.dataTypeClass === 'DateTime' ||
        property.semanticType.baseDataType === 'Date' ||
        property.semanticType.baseDataType === 'Time'
    )
        return true
    else return false
}

export const canUseAutogenPrefix = (property: Property): boolean => {
    if (!property) return false
    else if (property.semanticType.baseDataType === 'String') return true
    else return false
}

export function getDisplayProperty(source: any, property: Property): DisplayProperty {
    if (!source || !property) return

    const { field, aggregation } = source
    const { semanticType, displayName } = property

    return { type: semanticType?.type, title: displayName, field, aggregation, selected: false }
}

export function configHasProperties(config: SeriesConfig) {
    const { categories, series } = config
    if (categories.length) return true
    if (series.length) return true
    return false
}

export function getAllProperties(resourceById: { [id: string]: Resource }) {
    if (isEmpty(resourceById)) return

    return Object.values(resourceById).reduce<Array<Property>>((acc, currData) => {
        return [...acc, ...currData.properties]
    }, [])
}
