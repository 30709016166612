import { cleanDirectory } from 'genesis-suite/utils'
import { matchPath } from 'react-router-dom'

const rootPath = cleanDirectory(process.env.PUBLIC_URL)
const APP = `${rootPath}/:appName`
const BASE = `${APP}/:moduleName`
const PERSPECTIVE = '/perspective/:perspectiveID'
const COMPARE_PERSPECTIVE = '/compare-perspective/:perspectiveID'
const WIDGET = '/widget/:widgetID'

export const subPaths = {
    PERSPECTIVE,
    COMPARE_PERSPECTIVE,
    WIDGET,
}

export const routePaths = {
    ROOT: `${rootPath}/`,
    LOGIN: `${rootPath}/login`,
    CALLBACK: `${rootPath}/callback`,
    SILENT_RENEW: `${rootPath}/silent_renew`,
    SELECT: `${rootPath}/select`,
    APP,
    MODULE_SELECT: `${APP}/select`,
    BASE,
    EDIT: `${BASE}/edit/:widgetID`,
    ANALYTICS: `${BASE}/analytics`,
    BUSINESS_EXPLORER: `${BASE}/business-explorer`,
    HOME: `${BASE}/home`,
    MANAGE_HOME: `${BASE}/home-dashboard`,
    MANAGE: `${BASE}/manage`,
    NETWORK: `${BASE}/network`,
    NOTIFICATIONS: `${rootPath}/notifications`,
    PERSPECTIVE: `${BASE}${PERSPECTIVE}`,
    COMPARE_PERSPECTIVE: `${BASE}${COMPARE_PERSPECTIVE}`,
    WIDGET: `${BASE}${WIDGET}`,
    FULLSCREEN: `${BASE}${PERSPECTIVE}/fullscreen`,
    TV: `${BASE}${PERSPECTIVE}/tv`,
    ELEMENT: `${BASE}/element/:elementName`,
    MENU: `${BASE}/menu`,
    CHANGE_REVIEW: `${BASE}/change-review`,
    TREE: `${BASE}/tree/:treeName`,
    PUBLIC: `${rootPath}/_public`,
    AI_ANALYSIS: `${BASE}/ai-analysis`,
    PRIVATE: `${rootPath}/_private`,
    STORY_BOARD: `${BASE}/story-board`,
}

const publicRoutes = [
    routePaths.ROOT,
    routePaths.LOGIN,
    routePaths.CALLBACK,
    routePaths.SILENT_RENEW,
    `${routePaths.PUBLIC}/*`,
]
const mobileRoutes = [routePaths.FULLSCREEN, routePaths.MENU]

export function requiresAuthentication(pathname) {
    const found = publicRoutes.find(path => matchPath(path, pathname))
    return found == null
}

export function requiresMobile(pathname) {
    const found = mobileRoutes.find(path => matchPath(path, pathname))
    return found != null
}
