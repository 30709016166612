import merge from 'deepmerge'
import { defaultTypography, muiTheme } from 'genesis-suite/theme'

import { createTheme } from '@mui/material'
import { getFontSize } from '~/constants'
import { getPreference } from '../lib/browserPreferences'
import { ThemeMode } from '../types/ThemeTypes'
import { darkTheme, lightTheme } from './tadaThemes'

const fontSize = getFontSize()
const themeMode: ThemeMode = getPreference('themeMode') || ThemeMode.DARK
const { backgrounds, borders, highcharts, fonts, components } = getThemePalette(themeMode)
const defaultTextColor = lightTheme.fonts.primary
const defaultTheme = createTheme()
const getContrastText = defaultTheme.palette.getContrastText

const vision = {
    mode: themeMode,
    palette: {
        mode: themeMode,
        background: { ...backgrounds, topBar: '#828282' },
        border: borders,
        text: { primary: fonts.primary, default: defaultTextColor, disabled: fonts.disabled },
        getContrastText: (background: string) => {
            const muiColor = getContrastText(background)
            if (muiColor === '#000000') return defaultTextColor
            else return muiColor
        },
        highcharts,
    },
    custom: {
        sideNav: { width: 282, collapsedWidth: 60 },
        topNav: { height: 60, mobileHeight: 80 },
        builderContainer: {
            borderRadius: 8,
            background: '#FFF',
            border: `1px solid #BDBDBD`,
        },
        perspectiveToolbar: {
            fontSize: '18px',
            padding: '6px',
        },
        widgetToolbar: {
            fontSize: '18px',
            padding: '6px',
        },
    },
    components: {
        ...components,
        MuiCardContent: {
            styleOverrides: {
                root: {
                    padding: 16,
                    '&:last-child': {
                        paddingBottom: 16,
                    },
                },
            },
        },
        MuiTooltip: { defaultProps: { disableInteractive: true } },
    },
}

export default { ...merge(muiTheme(), vision), typography: { fontSize, ...defaultTypography } }

export function getThemePalette(themeMode: ThemeMode) {
    switch (themeMode) {
        case ThemeMode.DARK:
            return darkTheme

        case ThemeMode.LIGHT:
        default:
            return lightTheme
    }
}
