import { Box } from '@mui/material'
import { useSelector } from 'react-redux'

import { ChopText, CognitiveIcon } from 'genesis-suite/components'
import { filterSelectors, menuSelectors, perspectiveSelectors } from '../selectors'

/**
 * get title for perspective, business element, fullscreen, etc.
 * @param {=string} props.className optional className
 * @returns
 */
const NavTitle = ({ className, isPublic = false }) => {
    const context = useSelector(filterSelectors.getCoord)
    let title = useSelector(perspectiveSelectors.getTitle)
    if (!title) title = 'Story Board'
    if (isPublic) {
        const contextValue = context?.DisplayValue || context?.Value
        title = contextValue ? `${title} - ${contextValue}` : title
    }

    const icon = useSelector(menuSelectors.getIcon)

    return (
        <Box display="flex" alignItems="center" gap="8px">
            <CognitiveIcon icon={icon} />
            <ChopText variant="h5" showEllipsis className={className}>
                {title}&nbsp;
            </ChopText>
        </Box>
    )
}

export default NavTitle
