import { useState, useMemo, useEffect, useContext } from 'react'
import { useSelector } from 'react-redux'
import { Grid, Tooltip, IconButton, Typography, FormControl, Select, MenuItem, Box } from '@mui/material'
import { ArrowBackIosNewOutlined, ArrowForwardIosOutlined } from '@mui/icons-material'
import { PerspectiveContext } from '~/components/contexts/PerspectiveContext'
import { filterService } from '~/lib/services'
import { applicationSelectors } from '~/selectors'
import { getDateData, getStartEndDateOfWeek, WEEK, DAY, HOUR } from './ResourceData'
import { useTheme } from '@mui/styles'
import moment from 'moment'

export default function GanttInterval({ config, options }) {
    const { appName } = useSelector(applicationSelectors.getAppInfo)
    const theme = useTheme()
    const { firstDay, lastDay } = getStartEndDateOfWeek(new Date())
    const [current, setCurrent] = useState({
        day: new Date(),
        hour: new Date(new Date().setHours(0, 0, 0, 0)),
        week: {
            start: firstDay,
            end: lastDay,
        },
    })
    const { filterByWidgetId, setFilterByWidgetId } = useContext(PerspectiveContext)
    const [timeSelect, setTimeSelect] = useState('W')
    const [Aggregates, setAggregates] = useState(null)
    const iconStyle = { fontSize: theme.custom.widgetToolbar?.fontSize, color: theme.palette.text.primary }
    const buttonStyle = {
        padding: theme.custom.widgetToolbar?.padding,
        '&.Mui-disabled': {
            cursor: 'default',
            pointerEvents: 'none',
            opacity: 0.5,
        },
    }

    let emptyFilter = {
        IsTemporal: true,
        Values: [],
        DisplayValues: [],
        Range: {},
        UseLastRefreshDate: false,
        Operator: 'EqualTo',
        PostCalculation: false,
    }

    const categoryOption = options?.find(o => o.displayName === 'Default')
    if (categoryOption?.container) {
        emptyFilter = {
            ...emptyFilter,
            FilterName: `search|${categoryOption.name}`,
            ResourceName: categoryOption.container.name,
            ResourceType: categoryOption.container.type,
            PropertyName: categoryOption.name,
        }
    }

    const minMaxDateAPI = async () => {
        try {
            const ae = `MaxD=Max(${config?.GanttConfig?.Parents[0]?.StartDateProperty});MinD=Min(${config?.GanttConfig?.Parents[0]?.StartDateProperty})`
            const data = await filterService.getMinMaxDate(
                appName,
                config?.GanttConfig?.Parents[0]?.StartDateProperty,
                ae
            )
            if (new Date() > new Date(data.Aggregates.MaxD) || new Date() < new Date(data.Aggregates.MinD)) {
                const currentData = { ...current }
                const { firstDay, lastDay } = getStartEndDateOfWeek(new Date(data.Aggregates.MinD))
                currentData['day'] = new Date(data.Aggregates.MinD)
                currentData['hour'] = new Date(new Date(data.Aggregates.MinD).setMinutes(0))
                currentData['week'].start = firstDay
                currentData['week'].end = lastDay
                setCurrent(currentData)
            }
            updateChartByInterval(timeSelect)
            setAggregates(data.Aggregates)
        } catch (e) {
            console.error(e)
        }
    }

    useEffect(() => {
        minMaxDateAPI()
    }, [])

    const intervalOptions = useMemo(() => {
        let options = []
        switch (categoryOption?.dateResolution) {
            case 'Hour':
            case 'Minute':
            case 'Second':
                options = [WEEK, DAY, HOUR]
                break
            case 'Day':
                options = [WEEK, DAY]
                break
            default:
                options = [WEEK]
                break
        }
        return options
    }, [categoryOption])

    function getRangeFromPeriod(fromDate, toDate) {
        const fromDateData = getDateData(fromDate)
        const newToDate = new Date(toDate.getTime() + 60 * 60 * 24 * 1000)
        const toDateData = getDateData(newToDate)

        return {
            MinValue: fromDateData.month + '/' + fromDateData.day + '/' + fromDateData.year,
            MaxValue: toDateData.month + '/' + toDateData.day + '/' + toDateData.year,
        }
    }

    const updateData = (fromDate, toDate) => {
        emptyFilter['Range'] = getRangeFromPeriod(fromDate, toDate)
        setFilterByWidgetId(s => ({
            ...s,
            [config.Id]: { ...filterByWidgetId?.[config.Id], temporal: [emptyFilter] },
        }))
    }

    const updateDataByHour = (fromDate, toDate) => {
        const dateData = getDateData(fromDate)
        const newDateData = getDateData(toDate)
        emptyFilter['Range'] = {
            MinValue:
                dateData.year +
                '-' +
                dateData.month +
                '-' +
                dateData.day +
                'T' +
                dateData.hour +
                ':' +
                dateData.min +
                ':00',
            MaxValue:
                newDateData.year +
                '-' +
                newDateData.month +
                '-' +
                newDateData.day +
                'T' +
                newDateData.hour +
                ':' +
                newDateData.min +
                ':00',
        }
        setFilterByWidgetId(s => ({
            ...s,
            [config.Id]: { ...filterByWidgetId?.[config.Id], temporal: [emptyFilter] },
        }))
    }

    const onClickPrev = () => {
        const data = { ...current }
        if (timeSelect == 'W') {
            const week = 7 * 60 * 60 * 24 * 1000
            const startWeek = new Date(data.week.start.getTime() - week)
            const endWeek = new Date(data.week.end.getTime() - week)
            updateData(startWeek, endWeek)
            data['week'].start = startWeek
            data['week'].end = endWeek
        } else if (timeSelect == 'D') {
            const day = 60 * 60 * 24 * 1000
            const newDate = new Date(data.day.getTime() - day)
            updateData(newDate, newDate)
            data['day'] = newDate
        } else {
            const hour = 60 * 60 * 1000
            const newDate = new Date(data.hour.getTime() - hour)
            updateDataByHour(newDate, data.hour)
            data['hour'] = newDate
        }
        setCurrent(data)
    }

    const onClickNext = () => {
        const data = { ...current }
        if (timeSelect == 'W') {
            const week = 7 * 60 * 60 * 24 * 1000
            const startWeek = new Date(data.week.start.getTime() + week)
            const endWeek = new Date(data.week.end.getTime() + week)
            updateData(startWeek, endWeek)
            data['week'].start = startWeek
            data['week'].end = endWeek
        } else if (timeSelect == 'D') {
            const day = 60 * 60 * 24 * 1000
            const newDate = new Date(data.day.getTime() + day)
            updateData(newDate, newDate)
            data['day'] = newDate
        } else {
            const hour = 60 * 60 * 1000
            const newDate = new Date(data.hour.getTime() + hour)
            const newDateTo = new Date(newDate.getTime() + hour)
            updateDataByHour(newDate, newDateTo)
            data['hour'] = newDate
        }
        setCurrent(data)
    }

    const updateChartByInterval = value => {
        if (value == 'W') {
            updateData(current.week.start, current.week.end)
        } else if (value == 'D') {
            updateData(current.day, current.day)
        } else {
            const hour = 60 * 60 * 1000
            const newDate = new Date(current.hour.getTime())
            const newDateTo = new Date(newDate.getTime() + hour)
            updateDataByHour(newDate, newDateTo)
        }
    }

    const onChangeInterval = value => {
        updateChartByInterval(value)
        setTimeSelect(value)
    }

    const onPrevDisabled = () => {
        let bool = false
        if (timeSelect == 'W' && current.week.start <= new Date(Aggregates?.MinD)) {
            bool = true
        }
        if (timeSelect == 'D' && current.day <= new Date(Aggregates?.MinD)) {
            bool = true
        }
        if (timeSelect == 'H' && current.hour <= new Date(Aggregates?.MinD)) {
            bool = true
        }
        return bool
    }

    const onNextDisabled = () => {
        let bool = false
        if (timeSelect == 'W' && current.week.end >= new Date(Aggregates?.MaxD)) {
            bool = true
        }
        if (timeSelect == 'D' && current.day >= new Date(Aggregates?.MaxD)) {
            bool = true
        }
        if (timeSelect == 'H' && current.hour >= new Date(Aggregates?.MaxD)) {
            bool = true
        }
        return bool
    }

    return (
        <>
            <Grid container justifyContent="center" spacing={2}>
                <Grid item key={1}>
                    <Box sx={{ minWidth: 60 }}>
                        <FormControl fullWidth>
                            <Select
                                id="date-filter-select"
                                sx={theme => ({
                                    '& .MuiOutlinedInput-notchedOutline': {
                                        borderColor: theme.palette.text.primary,
                                    },
                                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                        borderColor: theme.palette.text.primary,
                                    },
                                })}
                                value={timeSelect}
                                onChange={e => onChangeInterval(e.target.value)}
                            >
                                {intervalOptions?.map(item => (
                                    <MenuItem value={item.value}>{item.name}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Box>
                </Grid>
                <Grid item key={2}>
                    <Tooltip title={'Prev'}>
                        <IconButton sx={buttonStyle} onClick={onClickPrev} disabled={onPrevDisabled()}>
                            <ArrowBackIosNewOutlined sx={iconStyle} />
                        </IconButton>
                    </Tooltip>
                </Grid>
                <Grid item key={3} sx={{ textAlign: 'center' }}>
                    <Typography variant="h6">
                        {timeSelect == 'W'
                            ? moment(current.week.start).format('LL') + ' - ' + moment(current.week.end).format('LL')
                            : timeSelect == 'H'
                            ? moment(current.hour).format('LLL')
                            : moment(current.day).format('LL')}
                    </Typography>
                </Grid>
                <Grid item key={4}>
                    <Tooltip title={'Next'}>
                        <IconButton sx={buttonStyle} onClick={onClickNext} disabled={onNextDisabled()}>
                            <ArrowForwardIosOutlined sx={iconStyle} />
                        </IconButton>
                    </Tooltip>
                </Grid>
            </Grid>
        </>
    )
}
