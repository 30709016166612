import { Box, Skeleton, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { ChopText, InputTags } from 'genesis-suite/components'
import { useUpdateEffect } from 'genesis-suite/hooks'
import { ResourceType } from 'genesis-suite/types/networkTypes'
import { stringToFilters } from 'genesis-suite/utils'
import { FilterSourceType } from '~/types/FilterTypes'
import { useNodeData } from '../hooks/useNodeData'
import useResourceMeta from '../hooks/useResourceMeta'
import { searchService } from '../lib/services'
import { applicationSelectors } from '../selectors'
import AsyncResourceValueSelect from './AsyncResourceValueSelect'
import DateFilter from './DateFilter'
import FilterOperatorButton, { isSelect } from './FilterOperatorButton'

const useStyles = makeStyles(({ spacing, palette }) => ({
    filterLabel: { fontWeight: 'bold', display: 'inline', marginRight: spacing(0.5) },
}))

export default function FilterInput(props) {
    const {
        filter,
        selectedValues,
        onOperatorChange,
        sx,
        contextFilters,
        labelFontSize,
        limitTags,
        showCheckBoxes = false,
        filterSelectedOptions = true,
        maxNumberOfTags,
        inputStyleProps,
    } = props
    const {
        Name,
        Title,
        IsTemporal,
        Source,
        KeyPropertyName,
        DisplayPropertyName,
        AllowMultiple = true,
        DefaultSort,
        DefaultFirstValue,
    } = filter
    const { range, rangeOffset, clickRangeName, values = [], operator = 'EqualTo', startDate, endDate } = selectedValues

    const appName = useSelector(applicationSelectors.getCurrentAppName)
    const [resource] = useResourceMeta(ResourceType.NODE, Source.ElementName)

    const classes = useStyles()

    const [optionCount, setOptionCount] = useState(null)
    const [totalOptionCount, setTotalOptionCount] = useState(null)

    const [clickRangeValues, setClickRangeValues] = useState(null)
    useEffect(() => {
        if (!clickRangeName) {
            if (clickRangeValues) setClickRangeValues(null)
            return
        }

        searchService.getRangeFilterBoundaries(clickRangeName, appName).then(({ Min: min, Max: max }) => {
            if (!min || !max) return
            setClickRangeValues({ min, max })
        })
    }, [appName, clickRangeName])

    const handleEnterMultiple = e => {
        const value = e.target.value.trim()
        if (e.key !== 'Enter' || !value) return
        if (typeof value === 'string' && value.includes(',')) {
            const newValues = value.split(',').reduce((acc, v) => {
                const value = v.trim()
                if (value) acc.push({ label: value, value })
                return acc
            }, [])
            props.onValueChange(filter, newValues, 'CHANGE_VALUE')
        }
    }

    let selectedLabel = ''
    let input
    if (!resource) {
        input = <Skeleton variant="rectangular" width="100%" height="35px" />
    } else if (IsTemporal) {
        if (range && (range.min || range.max)) selectedLabel = getDateLabel(range.min, range.max)
        else if (rangeOffset?.min && rangeOffset?.max) {
            selectedLabel = getDateLabel(startDate, endDate)
        } else if (clickRangeValues) selectedLabel = getDateLabel(clickRangeValues.min, clickRangeValues.max)
        input = <DateInput {...props} />
    } else if (isSelect(operator)) {
        selectedLabel =
            optionCount != null
                ? `(${values.length.toLocaleString()}/${optionCount}${totalOptionCount > optionCount ? '+' : ''})`
                : '(...)'

        input = (
            <AsyncResourceValueSelect
                cacheFirstPage
                resourceName={Source.ElementName}
                resourceType={Source.ElementType}
                keyPropertyName={KeyPropertyName}
                filters={stringToFilters(resource.properties, Source.Filters)}
                networkFilters={Source.NetworkFilters?.map(f => f.split('.')[1])}
                contextFilters={contextFilters}
                displayPropertyName={DisplayPropertyName}
                defaultSort={DefaultSort}
                onOptions={(options, totalOptionCount) => {
                    setOptionCount(options.length)
                    setTotalOptionCount(totalOptionCount)
                }}
                value={AllowMultiple ? values : values[0] || null}
                onChange={(e, values) => props.onValueChange(filter, values, 'CHANGE_VALUE')}
                multiple={AllowMultiple}
                data-cy={props['data-cy']}
                textFieldProps={{
                    onKeyDown: AllowMultiple ? handleEnterMultiple : undefined,
                    helperText: DefaultFirstValue ? (
                        <Typography color="text.primary" variant="caption">
                            This filter cannot be saved as default filter as first value is used as default
                        </Typography>
                    ) : (
                        ''
                    ),
                }}
                limitTags={limitTags ?? -1}
                showCheckBoxes={showCheckBoxes}
                filterSelectedOptions={filterSelectedOptions}
                maxNumberOfTags={maxNumberOfTags}
                inputStyleProps={inputStyleProps}
                placeholder={Title}
            />
        )
    } else {
        input = (
            <InputTags
                values={values?.length > 0 ? values.map(v => v.value) : []}
                onChange={values =>
                    props.onValueChange(
                        filter,
                        values.map(v => ({ label: v, value: v })),
                        'CHANGE_VALUE'
                    )
                }
                limitTags={maxNumberOfTags}
            />
        )
    }

    return (
        <Box key={Name} sx={sx}>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    marginTop: IsTemporal ? 1.25 : 0,
                }}
            >
                <ChopText
                    showEllipsis
                    tooltipProps={{ placement: 'top' }}
                    className={classes.filterLabel}
                    fontSize={labelFontSize ?? 'auto'}
                >
                    {Title}
                </ChopText>
                <Typography className={classes.filterLabel} fontSize={labelFontSize ?? 'auto'}>
                    {selectedLabel}
                </Typography>
                <Box flex={1} />
                {!IsTemporal && <FilterOperatorButton value={operator} onChange={v => onOperatorChange(filter, v)} />}
            </Box>

            {input}
            {selectedValues.filterSource && (
                <Typography color="text.primary" variant="caption">
                    {selectedValues.filterSource === FilterSourceType.USER_DEFAULTS
                        ? 'You are seeing this filter because you have saved them to your user preferences.'
                        : selectedValues.filterSource === FilterSourceType.WIDGET_DEFAULTS
                        ? 'You are seeing this filter because they were set by your system administrator.'
                        : ''}
                </Typography>
            )}
        </Box>
    )
}

function DateInput(props) {
    const { filter } = props
    const {
        Source: { ElementName: node, Filters, NetworkFilters },
        DisplayPropertyName,
        KeyPropertyName,
        IsTemporal,
    } = filter

    const fieldNames =
        DisplayPropertyName === KeyPropertyName ? KeyPropertyName : `${KeyPropertyName},${DisplayPropertyName}`

    const makeSearch = () => ({
        controlField: DisplayPropertyName,
        sort: 'a',
        filters: Filters,
        fieldNames,
        networkFilters: NetworkFilters?.map(f => f.split('.')[1]),
        ...(IsTemporal && { pageSize: 100, pageIndex: 1 }),
    })

    const [search, setSearch] = useState(makeSearch)

    useUpdateEffect(() => {
        setSearch(makeSearch())
    }, [filter])

    const { loading, values } = useNodeData(node, search)

    return <DateFilter rawValues={values ?? []} loading={loading} {...props} />
}

export function getDateLabel(startDate, endDate) {
    const firstDate = moment(startDate)
    const secondDate = moment(endDate)

    const dateMap = {
        years: 0,
        months: 0,
        days: 0,
    }
    //@ts-ignore
    Object.keys(dateMap).forEach(key => {
        //@ts-ignore
        const res = secondDate.diff(firstDate, key)
        //@ts-ignore
        firstDate.add(res, key)
        dateMap[key] = res
    })

    return Object.entries(dateMap).reduce((acc, [k, v]) => {
        if (v) {
            return acc.concat(`${v} ${k} `)
        }
        return acc
    }, '')
}
