import { makeStyles } from '@mui/styles'
import { useDispatch } from 'react-redux'
import { IconButton } from '@mui/material'
import { useMatch } from 'react-router-dom'
import { goBack } from 'redux-first-history'
import { ArrowBackRounded } from '@mui/icons-material'

import MobileMenu from './MobileMenu'
import { routePaths } from '../lib/routes'

const useStyles = makeStyles(({ palette }) => ({
    button: { backgroundColor: 'transparent', padding: 0 },
    icon: { color: palette.getContrastText(palette.background.topBar) },
}))

const QuickNavButton = ({ isNavOpen, onNavClose, anchorEl }) => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const atFullScreen = useMatch(routePaths.FULLSCREEN)

    if (atFullScreen) {
        return (
            <IconButton className={classes.button} onClick={() => dispatch(goBack())}>
                <ArrowBackRounded className={classes.icon} />
            </IconButton>
        )
    }

    return <MobileMenu isNavOpen={isNavOpen} onNavClose={onNavClose} anchorEl={anchorEl} />
}

export default QuickNavButton
