import React from 'react'
import { Typography, Button, Box } from '@mui/material'
import { Search } from 'genesis-suite/icons'

/**
 * Displays a widget empty state
 * @param {*=} props.onResetFilters
 */
export default function WidgetEmpty({ onResetFilters, NoDataMessage }) {
    const customNoDataMessage = (
        <Typography gutterBottom align="center">
            {NoDataMessage}
        </Typography>
    )

    const defaultNoDataMessage = (
        <>
            <Typography variant="caption" gutterBottom>
                No Data Avaliable
            </Typography>

            <Typography gutterBottom align="center">
                Try adjusting your filter parameters to find what you’re looking for.
            </Typography>

            {onResetFilters && (
                <Button color="primary" onClick={onResetFilters}>
                    Reset filters
                </Button>
            )}
        </>
    )

    return (
        <Box height="100%" p={1} display="flex" flexDirection="column" alignItems="center" justifyContent="center">
            <Box m={1}>
                <Search />
            </Box>
            {NoDataMessage ? customNoDataMessage : defaultNoDataMessage}
        </Box>
    )
}
