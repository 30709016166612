import { Dispatch, SetStateAction, createContext, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { authCreators } from '~/actions/creators'
import { authSelectors } from '~/selectors'
import { WidgetFilter } from '~/types/FilterTypes'
import { WidgetDataStatus } from '../../types/WidgetTypes'

type ContextProps = {
    controlWidget: ControlWidget
    filterByWidgetId: FilterByWidgetId
    id: string
    topLabelData: IData
    setTopLabelData: Dispatch<SetStateAction<IData>>
    setFilterByWidgetId: Dispatch<SetStateAction<FilterByWidgetId>>
    updateControlWidgetStatus: (status: WidgetDataStatus) => void
    updateControlWidgetValue: (value: string) => void
    perspectiveDataById: any
    updateWPerspectiveDataById: (name: string, data: any) => void
    hiddenWidgets: string[]
    onHideWidget: (widgetId: string) => void
    onUnHideWidget: (widgetId: string) => void
    showAllHiddenWidgets: () => void
    autoAdjustLayout: boolean
    updateAutoAdjustLayout: () => void
}

interface FilterByWidgetId {
    [widgetId: string]: WidgetFilter
}

interface ControlWidget {
    /** id of the widget in the perspective that has control */
    id: string
    /** status of the control widget. Starts at fetching and goes to done or no-data based on result */
    status: WidgetDataStatus
    /** selectedvalue of the control widget  */
    value: string
}

interface IData {
    [key: string]: string
}

const PerspectiveContext = createContext<Partial<ContextProps>>({})

function PerspectiveProvider({ configs, children, id }) {
    const dispatch = useDispatch()
    const controlWidgetId = configs.find(c => c.ForControl)?.Id
    const [filterByWidgetId, setFilterByWidgetId] = useState<FilterByWidgetId>({})
    const [topLabelData, setTopLabelData] = useState<IData>({})
    const [controlWidget, setControlWidget] = useState<ControlWidget>(
        controlWidgetId ? { id: controlWidgetId, status: 'fetching', value: null } : null
    )
    const [perspectiveDataById, setPerspectiveDataById] = useState({})
    const [autoAdjustLayout, setAutoAdjustLayout] = useState(false)
    const hiddenWidgetsPref = useSelector(state => authSelectors.getPreference(state, 'hiddenWidgets'))
    const hiddenWidgets = hiddenWidgetsPref?.[id] || []
    const savePreferences = prefs => dispatch(authCreators.saveUserPreferencesForCurrentApplication(prefs))
    const onHideWidget = widgetId => {
        const newHiddenWidgets = [...hiddenWidgets, widgetId]
        const newPrefs = {
            Id: null,
            Key: 'hiddenWidgets',
            Value: JSON.stringify({ ...(hiddenWidgetsPref || {}), [id]: newHiddenWidgets }),
        }
        savePreferences([newPrefs])
    }

    const onUnHideWidget = async widgetId => {
        const newHiddenWidgets = hiddenWidgets.filter(w => w !== widgetId)
        const newPrefs = {
            Id: null,
            Key: 'hiddenWidgets',
            Value: JSON.stringify({ ...(hiddenWidgetsPref || {}), [id]: newHiddenWidgets }),
        }
        await savePreferences([newPrefs])
    }

    const showAllHiddenWidgets = async () => {
        const newHiddenWidgetsPref = { ...(hiddenWidgetsPref || {}) }
        delete newHiddenWidgetsPref[id]
        const newPrefs = {
            Id: null,
            Key: 'hiddenWidgets',
            Value: JSON.stringify(newHiddenWidgetsPref),
        }
        await savePreferences([newPrefs])
    }

    const updateAutoAdjustLayout = () => {
        setAutoAdjustLayout(!autoAdjustLayout)
    }

    return (
        <PerspectiveContext.Provider
            value={{
                controlWidget,
                filterByWidgetId,
                id,
                topLabelData,
                setTopLabelData,
                setFilterByWidgetId,
                updateControlWidgetStatus: status => setControlWidget(s => ({ ...s, status })),
                updateControlWidgetValue: value => setControlWidget(s => ({ ...s, value })),
                perspectiveDataById,
                updateWPerspectiveDataById: (name, data) => setPerspectiveDataById(s => ({ ...s, [name]: data })),
                hiddenWidgets,
                onHideWidget,
                onUnHideWidget,
                showAllHiddenWidgets,
                autoAdjustLayout,
                updateAutoAdjustLayout,
            }}
        >
            {children}
        </PerspectiveContext.Provider>
    )
}

export { PerspectiveContext, PerspectiveProvider }
