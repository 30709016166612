import { useEffect, useState, ReactNode, createContext, Dispatch, useReducer, useRef, useCallback } from 'react'
import { useLocation, matchPath } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { isEmpty, isEqual } from 'lodash'

import { Resource, ResourceType } from 'genesis-suite/types/networkTypes'
import { SeriesConfig, Service, TooltipType } from 'genesis-suite/types/visualTypes'
import { letMeMap } from 'genesis-suite/types/utilTypes'
import {
    applicationSelectors,
    authSelectors,
    businessExplorerSelectors,
    deploymentSelectors,
    insightSelectors,
    widgetSelectors,
} from '../../selectors'
import builderReducer, { BuilderAction, BuilderStore, initialState } from './builderReducer'
import { FieldPointer, CalculatedPropertyEditor, ServiceWithColor, AdvancedFilterEditor } from './builderTypes'
import { routePaths } from '../../lib/routes'
import { widgetConstants } from '../../constants'
import { visualService } from '../../lib/services'
import { businessExplorerCreators } from '../../actions/creators'
import { fetchResource } from '../../hooks/useResourceMeta'
import isSeriesWidget from '../widgets2/utils/isSeriesWidget'
import { useSemanticTypeById } from '../../hooks/useSemanticTypes'
import { ParsedResponse } from '../../types/WidgetTypes'

interface Props {
    children: ReactNode
}

type ContextProps = {
    advancedFilterEditor: AdvancedFilterEditor
    calculatedPropertyEditor: CalculatedPropertyEditor
    config: SeriesConfig
    selectedField?: FieldPointer
    selectedInsights: Array<ServiceWithColor>
    tooltipType: TooltipType
    dispatch: Dispatch<BuilderAction>
    refreshProperties: (resourceId: string) => void
    dataResponse: ParsedResponse
    init: (newStore?: Partial<BuilderStore>) => void
    insights: Array<{ id: string; name: string; nodeNames: string[] }>
    resources: { selectedId: string; byId: { [id: string]: Resource } }
    isDirty: boolean
    onReset: () => void
    configStructure?: any
    configKey: 'ChartConfig' | 'TableConfig'
}

const ConfigContext = createContext<Partial<ContextProps>>({})

/** State management for editing Series widgets (have a series property for data) */
const ConfigProvider = ({ children }: Props) => {
    const location = useLocation()
    const pathname = location.pathname
    const state = location.state as any

    const modelName = useSelector(applicationSelectors.getCurrentAppName)
    const accessKey = useSelector(authSelectors.getAccessKey)
    const viewFlag = useSelector(deploymentSelectors.getDeploymentViewFlag)
    const selectedBusinessExplorerConfigIndex = useSelector(businessExplorerSelectors.getSelectedConfigId)
    const selectedBusinessExplorerConfig = useSelector(businessExplorerSelectors.getSelectedConfig)
    const reduxDispatch = useDispatch()
    const [initialConfig, setInitialConfig] = useState<SeriesConfig>(null)
    const [store, dispatch] = useReducer(builderReducer, initialState)
    const userWidgets = useSelector(widgetSelectors.getUserWidgets)
    const {
        advancedFilterEditor,
        dataResponse,
        calculatedPropertyEditor,
        config,
        tooltipType,
        selectedField,
        service,
        configStructure,
        configKey,
    } = store
    const allInsights = useSelector(insightSelectors.getList)
    const insights = state?.focalPoint ? allInsights.filter(i => i.nodeNames.includes(state?.focalPoint)) : allInsights
    const isDataWidget = !state?.type || isSeriesWidget(state.type)
    const { resources, refreshProperties } = useConfigResources(service)

    let selectedInsights = []

    if (config[configKey]?.Series)
        selectedInsights = config[configKey]?.Series?.reduce((acc: any, value: any) => {
            const id = value?.Source?.id

            if (!id) return acc

            if (!acc.some(insight => insight.id === id)) {
                return [...acc, { ...value.Source }]
            } else return acc
        }, selectedInsights)

    const lastRoute = useRef<Route>()
    useEffect(() => {
        if (!isDataWidget) return

        const route = getRoute(pathname)

        switch (route) {
            case 'edit':
                handleInitEditRoute(lastRoute.current && config)
                break

            case 'business-explorer':
                if (!lastRoute.current) init({ config: selectedBusinessExplorerConfig })
                break

            case 'other':
                if (lastRoute.current === 'edit') init()
                else if (lastRoute.current === 'business-explorer' && selectedBusinessExplorerConfigIndex != null)
                    saveBusinessExplorerEditing()
                break
        }

        lastRoute.current = route
    }, [pathname])

    useEffect(() => {
        if (!isDataWidget) return
        if (!initialConfig || service.selectedId || !insights?.length) return

        const { id, name } = insights[0]

        dispatch({
            type: 'SET_SERVICE',
            payload: { byId: { [id]: { ElementType: ResourceType.INSIGHT, id, ElementName: name } }, selectedId: id },
        })
    }, [initialConfig, insights, service])

    useEffect(() => {
        console.log('config', config)
    }, [config])

    useEffect(() => {
        console.log('selectedField', selectedField)
    }, [selectedField])

    useEffect(() => {
        console.log('service', service)
    }, [service])

    function saveBusinessExplorerEditing() {
        reduxDispatch(businessExplorerCreators.updateSelectedConfig(config))
        reduxDispatch(businessExplorerCreators.setSelectedConfig(null))
        init()
    }

    async function handleInitEditRoute(config?: SeriesConfig) {
        let newConfig = config
        const widgetID = matchPath(routePaths.EDIT, pathname).params?.widgetID

        switch (widgetID) {
            case widgetConstants.Edit.NEW_ROUTE:
                return init()

            case widgetConstants.Edit.BUSINESS_EXPLORER_ROUTE:
                if (!newConfig) newConfig = selectedBusinessExplorerConfig
                break
            case newConfig?.id:
                break
            default:
                newConfig = userWidgets?.[widgetID] as SeriesConfig
        }

        let initialId = ''

        let serviceById = {} as any
        if (newConfig[configKey]?.Series)
            serviceById = letMeMap(newConfig[configKey]?.Series).reduce((acc: any, currentSeries: any) => {
                const { id } = currentSeries.Source
                if (!initialId) initialId = id
                if (acc[id]) return acc
                else {
                    return { ...acc, [id]: { ...currentSeries.Source } }
                }
            }, {} as { [id: string]: Service })

        init({
            config: newConfig,
            service: { byId: serviceById, selectedId: initialId },
            selectedField: { type: 'series', index: 0, valueIndex: 0 },
        })
    }

    function init(newState?: Partial<BuilderStore>) {
        const config = newState?.config ?? initialState.config
        dispatch({ type: 'INIT', payload: { ...newState, config, modelName, accessKey } })
        setInitialConfig(config)
    }

    const onReset = useCallback(() => {
        dispatch({ type: 'SET_SELECTED_FIELD', payload: null })
        dispatch({ type: 'SET_CONFIG', payload: initialConfig })
    }, [initialConfig])

    return (
        <ConfigContext.Provider
            value={{
                advancedFilterEditor,
                calculatedPropertyEditor,
                config,
                dispatch,
                selectedField,
                tooltipType,
                dataResponse,
                init,
                insights,
                selectedInsights,
                refreshProperties,
                resources: { byId: resources, selectedId: service?.selectedId },
                isDirty: !isEqual(config, initialConfig),
                onReset,
                configStructure,
                configKey,
            }}
        >
            {children}
        </ConfigContext.Provider>
    )
}

type Route = 'edit' | 'business-explorer' | 'other'

function getRoute(pathname: string): Route {
    if (matchPath(routePaths.EDIT, pathname)) return 'edit'
    if (matchPath(routePaths.BUSINESS_EXPLORER, pathname)) return 'business-explorer'
    return 'other'
}

function useConfigResources(services: BuilderStore['service']) {
    const [resources, setResources] = useState<{ [id: string]: Resource }>({})
    const appName = useSelector(applicationSelectors.getCurrentAppName)
    const semanticTypeById = useSemanticTypeById()

    useEffect(() => {
        if (isEmpty(semanticTypeById)) return

        for (const id in services?.byId) {
            if (resources[id]) continue
            getResourceById(id)
        }
    }, [services, semanticTypeById])

    const getResourceById = id => {
        const service = services.byId[id]
        fetchResource(appName, ResourceType.INSIGHT, service.ElementName, semanticTypeById).then(insight =>
            setResources(s => ({ ...s, [insight.id]: { ...insight } }))
        )
    }

    const refreshProperties = id => {
        getResourceById(id)
    }

    return { resources, refreshProperties }
}

export { ConfigProvider, ConfigContext }
