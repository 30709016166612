import { Box, Typography } from '@mui/material'
import { useContext } from 'react'
import { WidgetType } from 'genesis-suite/types/visualTypes'

import { DefaultErrorBoundary } from 'genesis-suite/components'
import { ConfigContext } from '../ConfigContext'
import ChartSelector from '../selectors/ChartSelector'
import DescriptionEditor from './DescriptionEditor'
import WidgetBase from './WidgetBase'
import WidgetAction from '../selectors/WidgetAction'
import DynamicFiltersAction from '../selectors/DynamicFiltersAction'
import WidgetTitleEditor from './WidgetTitleEditor'
import WidgetDisplaySettings from '../selectors/WidgetDisplaySettings'
import PivotColumnEditor from './PivotColumnEditor'
import EditorWrapper from './EditorWrapper'
import LegendEditor from './LegendEditor'
import Orientation from './Orientation'

const BasicFields = ({ className }) => {
    const { config } = useContext(ConfigContext)
    const { Type } = config

    // const getComponents = () => {
    //     switch (type) {
    //         case ChartType.COMBO:
    //             return [
    //                 <FilterSelector key="0" root />,
    //                 <CategorySelector key="1" limit={1} label="Category axis" />,
    //                 <SeriesSelector key="2" label="Series" />,
    //                 <TrellisSelector key="3" label="Trellis" />,
    //                 <EditorWrapper key="4" header="Orientation">
    //                     <Orientation />
    //                 </EditorWrapper>,
    //                 <LegendEditor key="5" />,
    //             ]
    //         case ChartType.SORTED:
    //             return [
    //                 <FilterSelector key="0" />,
    //                 <CategorySelector key="1" limit={1} label="Category" />,
    //                 <ValuesSelector key="2" limit={1} label="Values" />,
    //                 <TrellisSelector key="3" label="Trellis" />,
    //                 <LegendEditor key="4" />,
    //             ]
    //         case ChartType.HEATMAP:
    //             return [
    //                 <FilterSelector key="0" />,
    //                 <CategorySelector key="1" limit={1} label="X Axis" />,
    //                 <SubSeriesSelector key="2" label="Y Axis" />,
    //                 <ValuesSelector key="3" limit={1} label="Values" />,
    //                 <LegendEditor key="4" />,
    //             ]
    //         case ChartType.LABEL:
    //             return [
    //                 <FilterSelector key="0" />,
    //                 <ValuesSelector key="2" limit={1} label="Value" />,
    //                 <TrellisSelector key="3" label="Trellis" />,
    //             ]
    //         case ChartType.MAP:
    //             return [<FilterSelector key="0" />, <MapSelector key="1" />]
    //         case ChartType.PIE:
    //             return [
    //                 <FilterSelector key="0" />,
    //                 <CategorySelector key="1" limit={1} label="Category" />,
    //                 <ValuesSelector key="2" limit={1} label="Values" />,
    //                 <TrellisSelector key="3" label="Trellis" />,
    //                 <LegendEditor key="4" />,
    //             ]
    //         case ChartType.PACKED_BUBBLE:
    //             return [
    //                 <FilterSelector key="0" />,
    //                 <CategorySelector key="1" limit={1} label="Category" />,
    //                 <ValuesSelector key="2" limit={1} label="Values" />,
    //                 <TrellisSelector key="3" label="Trellis" />,
    //                 <LegendEditor key="4" />,
    //             ]
    //         case ChartType.TABLE:
    //             return [
    //                 <FilterSelector key="0" />,
    //                 <ValuesSelector key="1" label="Columns" />,
    //                 <TableAggregationSelector key="2" />,
    //                 <DynamicTableEditor key="3" />,
    //                 <SortOrderEditor key="4" />,
    //                 <PaginationEditor key="5" />,
    //             ]
    //         case ChartType.BOX_PLOT:
    //             return [
    //                 <FilterSelector key="0" />,
    //                 <CategorySelector key="1" limit={1} label="Category axis" />,
    //                 <BoxPlotSeriesSelector key="2" label="Series" />,
    //                 <EditorWrapper key="3" header="Orientation">
    //                     <Orientation />
    //                 </EditorWrapper>,
    //                 <LegendEditor key="4" />,
    //             ]

    //         case ChartType.BULLET:
    //             return [
    //                 <FilterSelector key="0" />,
    //                 <SeriesSelector key="1" limit={categories.length > 0 ? 1 : undefined} label="Series" />,
    //                 <TrellisSelector key="2" label="Trellis" />,
    //             ]

    //         case ChartType.STATUS_TRACKER:
    //             return [<ValuesSelector key="0" label="Status" />]
    //         default:
    //             return [<div key="coming">Coming soon...</div>]
    //     }
    // }

    return (
        <DefaultErrorBoundary>
            <div className={className}>
                <Typography gutterBottom variant="h6">
                    Widget config
                </Typography>
                <Box overflow="auto" flex="1">
                    <WidgetTitleEditor key="0" />
                    <ChartSelector key="1" />
                    {/* <FilterSelector key="2" /> */}
                    <WidgetBase key="3" />
                    {Type === WidgetType.CHART && (
                        <EditorWrapper key="4" header="Orientation">
                            <Orientation />
                        </EditorWrapper>
                    )}
                    {Type === WidgetType.TABLE && (
                        <EditorWrapper key="4" header="Pivot Column">
                            <PivotColumnEditor />
                        </EditorWrapper>
                    )}
                    <WidgetAction key="5" />
                    <DynamicFiltersAction key="6" />
                    <WidgetDisplaySettings key="7" />
                    <LegendEditor key="8" />
                    <DescriptionEditor key="9" />
                </Box>
            </div>
        </DefaultErrorBoundary>
    )
}

export default BasicFields
