import { useContext } from 'react'
import { ConfigContext } from '../ConfigContext'
import CategorySelector from '../selectors/CategorySelector'
import SeriesSelector from '../selectors/SeriesSelector'

export default function WidgetBase() {
    const { config, configStructure, configKey } = useContext(ConfigContext)
    const fieldsToOmit = ['title', 'widgetdescription', 'source']

    if (!configStructure || !configStructure[configKey]) return null

    return (
        <>
            {configStructure[configKey].map((field, index) => {
                if (fieldsToOmit.includes(field.Name.toLowerCase())) return null

                if (field.IsComplexType && !field.IsValueSeries) return null

                if (field.PropertyGroupName === 'Display Settings') return null

                const isMultiSelect = field.IsMultiSelect || field.IsComplexType

                if (field.IsSourceProperty && config.Type === 'Chart')
                    return (
                        <CategorySelector key={field.Name} limit={isMultiSelect ? null : 1} label={field.DisplayName} />
                    )

                if (field.IsValueSeries)
                    return (
                        <SeriesSelector
                            key={field.Name}
                            limit={isMultiSelect ? null : 1}
                            label={field.DisplayName}
                            isPivot={false}
                        />
                    )
            })}
        </>
    )
}
