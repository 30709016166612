import { useDispatch, useSelector } from 'react-redux'
import ExploreIcon from '@mui/icons-material/ExploreOutlined'

import { MenuIcon } from 'genesis-suite/components'
import { routePaths } from '../lib/routes'
import { appearanceCreators, navigationCreators } from '../actions/creators'
import { useFeature } from '../lib/featureFlags'
import { appearanceSelectors, moduleSelectors } from '../selectors'
import { logEvent } from '../lib/amplitudeClient'
import { widgetConstants } from '../constants'

export default function BusinessExplorerButton({ iconStyle, buttonStyle }) {
    const [enable] = useFeature('business_explorer')
    const sideNavOpen = useSelector(appearanceSelectors.getSideNavState)
    const showBusinessExplorer = useSelector(moduleSelectors.showBusinessExplorer)
    const dispatch = useDispatch()

    if (!enable || !showBusinessExplorer) return null

    function handleClick() {
        logEvent('OPEN_BUSINESS_EXPLORER')
        if (sideNavOpen) dispatch(appearanceCreators.toggleSideNav())
        //dispatch(navigationCreators.goTo(routePaths.BUSINESS_EXPLORER))
        dispatch(navigationCreators.goTo(routePaths.EDIT, widgetConstants.Edit.NEW_ROUTE))
    }

    return (
        <MenuIcon
            icon={<ExploreIcon sx={iconStyle} />}
            buttonProps={{ sx: buttonStyle }}
            onClick={handleClick}
            tooltip="Business explorer"
        />
    )
}
