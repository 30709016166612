import React, { useContext } from 'react'
import { BarChartRounded } from '@mui/icons-material'

import { ConfigContext } from '../ConfigContext'
import LabeledToggleButton from '../../LabeledToggleButton'

export default () => {
    const { config, dispatch, configKey } = useContext(ConfigContext)
    if (!config || !configKey) return null
    const { ChartType } = config[configKey] || {}

    const options = [
        { label: <BarChartRounded />, value: 'vertical', tip: 'Vertical' },
        { label: <BarChartRounded style={{ transform: 'rotate(90deg)' }} />, value: 'horizontal', tip: 'Horizontal' },
    ]

    return (
        <LabeledToggleButton
            value={ChartType === 'Bar' ? 'horizontal' : 'vertical'}
            options={options}
            onChange={(e, invertAxis) => {
                if (!invertAxis) return
                dispatch({
                    type: 'UPDATE_WIDGET_CONFIG',
                    payload: { key: configKey, value: { ChartType: invertAxis === 'horizontal' ? 'Bar' : 'Column' } },
                })
            }}
        />
    )
}
