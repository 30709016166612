import { Box } from '@mui/system'
import { isEqual } from 'lodash'
import { useSnackbar } from 'notistack'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

import { ChartType, FormConfig, TextConfig, TreeConfig, WebviewConfig, Widget } from 'genesis-suite/types/visualTypes'
import { businessExplorerCreators, navigationCreators, widgetCreators } from '../actions/creators'
import EditForm from '../components/edit_form/EditForm'
import EditSeriesWidget from '../components/EditSeriesWidget'
import EditTextWidget from '../components/EditTextWidget'
import EditTreeWidget from '../components/EditTreeWidget'
import EditWebviewWidget from '../components/EditWebviewWidget'
import { getExportType } from '../components/widgets/lib/widgetExporting'
import { widgetConstants } from '../constants'
import { useHasSemanticTypes } from '../hooks/useSemanticTypes'
import { updateDashboardOrCreateDraft, updateWidgetOrCreateDraft } from '../lib/manageUtils'
import { routePaths } from '../lib/routes'
import { visualService } from '../lib/services'
import {
    applicationSelectors,
    authSelectors,
    deploymentSelectors,
    lockoutSelectors,
    moduleSelectors,
    widgetSelectors,
} from '../selectors'

export default function EditWidgetView() {
    const navigate = useNavigate()
    const { widgetID } = useParams()
    const location = useLocation()
    const state = (location.state || {}) as any
    const { dashboardId, type } = state
    const { enqueueSnackbar: showSnackbar } = useSnackbar()

    const userId = useSelector(authSelectors.getUserId)
    const lockoutWidgets = useSelector(lockoutSelectors.getItems)
    const appName = useSelector(applicationSelectors.getCurrentAppName)
    const liveModuleId = useSelector(moduleSelectors.getLiveId)
    const isV2Module = useSelector(moduleSelectors.getIsV2)
    const viewFlag = useSelector(deploymentSelectors.getDeploymentViewFlag)
    const hasSemanticTypes = useHasSemanticTypes()
    const dispatch = useDispatch()
    const userWidgets = useSelector(widgetSelectors.getUserWidgets)

    const [config, setConfig] = useState<Widget>(undefined)

    const isNew = widgetID === widgetConstants.Edit.NEW_ROUTE
    const isBE = widgetID === widgetConstants.Edit.BUSINESS_EXPLORER_ROUTE
    const widgetType = isNew || isBE ? type || 'default' : config?.Type

    // useEffect(() => {
    //     if (isNew || isBE) return

    //     const lockedWidget = lockoutWidgets.find(l => l.widgetId === widgetID)
    //     const currentUserHasWidgetLocked = lockedWidget?.user.id === userId
    //     if (!lockedWidget) {
    //         dispatch(lockoutCreators.lock(widgetID))
    //     } else if (!currentUserHasWidgetLocked) {
    //         showSnackbar('Widget is locked out. Navigating to home.', { variant: 'error' })
    //         sleep(1000).then(() => dispatch(navigationCreators.goTo(routePaths.HOME)))
    //     }

    //     return () => {
    //         if (currentUserHasWidgetLocked) dispatch(lockoutCreators.unlock(widgetID))
    //     }
    // }, [widgetID, lockoutWidgets, userId])

    useEffect(() => {
        if (!appName) {
            // Prevent user from directly navigating to edit route
            dispatch(navigationCreators.goTo(routePaths.SELECT))
            return null
        }
        if (isNew || isBE) return

        setConfig(userWidgets[widgetID])

        // visualService
        //     .getWidgetById(appName, widgetID, viewFlag)
        //     .then(setConfig)
        //     .catch(() => {
        //         showSnackbar('Failed to get widget config', { variant: 'error' })
        //     })
    }, [widgetID])

    async function handleDone(body: Widget) {
        if (isBE) {
            dispatch(businessExplorerCreators.updateSelectedConfig(config))
            dispatch(navigationCreators.goTo(routePaths.BUSINESS_EXPLORER))
            return
        }

        if (!body || isEqual(config, body)) return navigate(-1)

        let id = widgetID

        try {
            if (isNew) {
                const widget = await visualService.createWidget(
                    appName,
                    { ...body, ...(isV2Module && { moduleId: liveModuleId }) },
                    viewFlag
                )
                id = widget.id

                if (dashboardId) {
                    const dashboard = await visualService.getDashboardById(appName, dashboardId, false, viewFlag)
                    const widgets = [...(dashboard.widgets ?? []), { id: widget.id }]
                    await updateDashboardOrCreateDraft(appName, dashboard.id, { widgets }, viewFlag)
                }
            } else {
                const updated = await updateWidgetOrCreateDraft(appName, widgetID, body, viewFlag)
                id = updated.id
            }

            if (getExportType(body)) dispatch(widgetCreators.addThumbnailRequest(id))
            navigate(-1)
        } catch (error) {
            console.error(error)
            showSnackbar('Whoops! An error occurred', { variant: 'error' })
        }
    }

    if (!hasSemanticTypes) return null

    const commonProps = { isNew, onDone: handleDone }

    const getContent = () => {
        if (!widgetType) return null

        switch (widgetType) {
            case ChartType.FORM:
                return <EditForm {...commonProps} config={config as FormConfig} />
            case ChartType.TEXT:
                return <EditTextWidget {...commonProps} config={config as TextConfig} />
            case ChartType.WEBVIEW:
                return <EditWebviewWidget {...commonProps} config={config as WebviewConfig} />
            case ChartType.TREE:
                return <EditTreeWidget {...commonProps} config={config as TreeConfig} />
            default:
                return <EditSeriesWidget {...commonProps} />
        }
    }

    return (
        <Box flex={1} overflow="hidden" display="flex">
            {getContent()}
        </Box>
    )
}
