import { ResourceType } from 'genesis-suite/types/networkTypes'
import { Category, ChartType, DataSource, LabelConfig, LabelSeries } from 'genesis-suite/types/visualTypes'
import { makeField, stringToFilters } from 'genesis-suite/utils'
import { getPropertyFromResource, makeAggregation, ResourceManager } from '../widgetConverterUtils'

export default async function labelConverter(config, series, resourceManager: ResourceManager): Promise<LabelConfig> {
    const { NoDataMessage } = config
    const { AggregationType, Source, ValueField, LabelField, SeriesName } = series

    const insight = await resourceManager.getInsight(Source.ElementName)

    const category: Category = LabelField
        ? {
              field: makeField(insight.properties, LabelField),
          }
        : null

    const value: DataSource = {
        field: makeField(insight.properties, ValueField),
        aggregation: makeAggregation(getPropertyFromResource(insight, ValueField), AggregationType),
    }

    const singleSeries: LabelSeries = {
        service: { type: ResourceType.INSIGHT, name: insight.name, id: insight.id },
        values: [value],
    }

    return {
        version: '2',
        type: ChartType.LABEL,
        title: SeriesName,
        NoDataMessage: NoDataMessage,
        ...(category && { categories: [category], trellis: { enabled: true } }),
        series: [singleSeries],
        filters: stringToFilters(insight.properties, Source.Filters),
    }
}
