import { combineReducers } from 'redux'
import { moduleTypes, applicationTypes } from '../actions/types'

const _360 = (state = [], action) => {
    const { payload } = action
    switch (action.type) {
        case moduleTypes.MODULE_SUCCESS:
            const {
                appModule: { Name, Model, PowerNavConfig: config },
            } = payload
            if (!config) return []

            let { PowerNavItems: items } = config
            if (!items) return []
            return items.map(i => get360MenuConfigHelper(i, Model, Name))
        case applicationTypes.APPLICATION_SELECT:
        case moduleTypes.MODULE_CHANGE_REQUEST:
            return []
        default:
            return state
    }
}

const get360MenuConfigHelper = (_360Item, appName, moduleName) => {
    if (!_360Item) return

    return {
        text: _360Item.Title,
        type: 'viewId',
        to: _360Item.PowerNavItems ? null : _360Item.WidgetId,
        icon: { file: _360Item.IconPath },
        subMenuConfig: _360Item.PowerNavItems
            ? _360Item.PowerNavItems.map(i => get360MenuConfigHelper(i, appName, moduleName))
            : null,
    }
}

const topNav = (state = [], action) => {
    const { payload } = action
    switch (action.type) {
        case moduleTypes.MODULE_SUCCESS:
            return payload.appModule.Elements?.map(convertMenuConfig) ?? []
        case applicationTypes.APPLICATION_SELECT:
        case moduleTypes.MODULE_CHANGE_REQUEST:
            return []
        default:
            return state
    }
}

const convertMenuConfig = element => {
    let type, to
    if (element.WidgetId && element.WidgetId !== 'null') {
        type = 'viewId'
        to = element.WidgetId
    } else if (element.DataUrl) {
        type = 'elementName'
        to = element.ServiceName
    }

    return {
        text: element.DisplayName,
        icon: { file: element.IconPath },
        type,
        to,
        treeId: element?.TreeId ?? null,
        serviceName: element.ServiceName,
        serviceType: element.ServiceType,
    }
}

export default combineReducers({ _360, topNav })
