import CloseIcon from '@mui/icons-material/Close'
import { Box, Button, IconButton, MenuItem, TextField, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { useContext } from 'react'
import { useDispatch } from 'react-redux'

import { letMeMap } from 'genesis-suite/types/utilTypes'
import { ChartType, SeriesConfig } from 'genesis-suite/types/visualTypes'
import { navigationCreators } from '../../actions/creators'
import { widgetConstants } from '../../constants'
import { routePaths } from '../../lib/routes'
import { ConfigContext } from '../edit_widget'
import { FieldPointer } from '../edit_widget/builderTypes'
import EditorWrapper from '../edit_widget/config_fields/EditorWrapper'
import SortEditor from '../edit_widget/config_fields/SortEditor'
import ChartSelector from '../edit_widget/selectors/ChartSelector'
import isSeriesWidget from '../widgets2/utils/isSeriesWidget'
import ComboFormatter from './ChartFormatters/ComboFormatter'
import PieFormatter from './ChartFormatters/PieFormatter'
import SortedFormatter from './ChartFormatters/SortedFormatter'
import AggregationSelect from '../edit_widget/AggregationSelect'

const useStyles = makeStyles(({ spacing, palette }) => ({
    formatterContainer: {
        padding: spacing(1, 0, 1, 1),
        borderLeft: `1px solid ${palette.grayscale.light}`,
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
    },
}))

const omittedCharts = [ChartType.HEATMAP, ChartType.TEXT]

export default function BusinessExplorerFormatter({ onDone }) {
    const context = useContext(ConfigContext)
    const config = context.config as SeriesConfig
    const reduxDispatch = useDispatch()

    const classes = useStyles()

    function handleAdvancedClick() {
        reduxDispatch(navigationCreators.goTo(routePaths.EDIT, widgetConstants.Edit.BUSINESS_EXPLORER_ROUTE))
    }

    const editSupported =
        isSeriesWidget(config.type) && config.series.length === 1 && config.series[0].values.length === 1

    let ChartFormatter = () => null
    switch (config.type) {
        case ChartType.COMBO:
            ChartFormatter = ComboFormatter
            break
        case ChartType.PIE:
            ChartFormatter = PieFormatter
            break
        case ChartType.SORTED:
            ChartFormatter = SortedFormatter
            break
    }

    return (
        <div className={classes.formatterContainer}>
            <Box display="flex" alignItems="center" justifyContent="space-between" pr={1}>
                <Typography variant="h6">Formatting</Typography>
                <IconButton onClick={onDone} size="small">
                    <CloseIcon fontSize="small" />
                </IconButton>
            </Box>

            {editSupported && (
                <Box overflow="auto" display="flex" flexDirection="column" flex={1} pr={1}>
                    <ChartSelector />
                    <AggregationSelect />
                    <SorterEditor />
                    <ChartFormatter />
                </Box>
            )}

            <Box mt={1} pr={1} display="flex" justifyContent="flex-end">
                <Button variant="contained" color="primary" onClick={handleAdvancedClick}>
                    Advanced
                </Button>
            </Box>
        </div>
    )
}

function SorterEditor() {
    const { dispatch, ...rest } = useContext(ConfigContext)
    const config = rest.config as SeriesConfig
    const pointer = getSortPointer(config)

    function handleTypeChange({ target }) {
        const pointer: FieldPointer = { type: target.value, index: 0 }
        dispatch({ type: 'UPDATE_SORT', payload: { pointer, sort: 'ascending' } })
    }

    return (
        <EditorWrapper header="Sorting">
            <TextField margin="dense" select value={pointer.type} onChange={handleTypeChange}>
                {sortTypeOptions.map(({ value, label }) => (
                    <MenuItem key={value} value={value}>
                        {label}
                    </MenuItem>
                ))}
            </TextField>

            <SortEditor pointer={pointer} />
        </EditorWrapper>
    )
}

const sortTypeOptions: { value: FieldPointer['type']; label: string }[] = [
    { value: 'category', label: 'Category' },
    { value: 'series', label: 'Series' },
]

function getSortPointer(config: SeriesConfig): FieldPointer {
    const { categories, series } = config

    for (let i = 0; i < series.length; i++) {
        if (letMeMap(series[i].values).some(v => Boolean(v.sort))) return { type: 'series', index: i }
        else if (series[i].subSeries?.field) return { type: 'subseries', index: i }
    }

    for (let i = 0; i < categories.length; i++) {
        if (categories[i].sort) return { type: 'category', index: i }
    }

    return { type: 'category', index: 0 }
}
