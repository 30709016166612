import PropertiesIcon from '@mui/icons-material/List'
import { Box, List, ListItem, Switch, TextField } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { useEffect, useRef, useState } from 'react'

import { MenuIcon } from 'genesis-suite/components'
import { isSelect } from 'genesis-suite/components/ComparisonSelector'
import { PropertyType, SemanticType } from 'genesis-suite/types/architectureTypes'
import { Property } from 'genesis-suite/types/networkTypes'
import { ComparisonOperator, DataField } from 'genesis-suite/types/visualTypes'
import AsyncResourceValueSelect from '../AsyncResourceValueSelect'
import PropertyController from './selectors/PropertyController'
import { getDataFieldProperty } from './utils'

const useStyles = makeStyles(({ spacing, palette }) => ({
    borderedSection: { padding: spacing(0.5), border: `2px solid ${palette.grayscale.light}` },
}))

interface Props {
    comparison: ComparisonOperator
    field: any /** change to proper type */
    onChange: any
    properties: Property[]
    onAdd: (field: DataField) => void
    values?: string | number | string[]
    disabled?: boolean
}

/** Provide async select or debounced input for field with semantic type formatting */
export default function ValueField({ comparison, field, onChange, properties, onAdd, values, disabled }: Props) {
    const [draft, setDraft] = useState<string>()
    const { semanticType } = getDataFieldProperty(field, properties) || {}
    const { baseDataType, minValue, maxValue } = semanticType || {}
    const textType = getTextType(baseDataType)

    const classes = useStyles()
    useEffect(() => {
        if (isSelect(comparison) || values === draft) return

        setDraft(typeof values === 'number' ? values.toString() : (values as string))
    }, [values])
    console.log(comparison, 'comparison')
    console.log(semanticType, 'sema')
    console.log(field, 'field')

    const timeout = useRef(null)
    useEffect(() => {
        clearTimeout(timeout.current)
        timeout.current = setTimeout(() => {
            if (draft == null || draft === values) return

            onChange(draft)
        }, 500)
    }, [draft])

    function handleBooleanChange({ target }) {
        let values = baseDataType === 'Binary' ? 0 : 'False'
        if (target.checked) values = baseDataType === 'Binary' ? 1 : 'True'
        onChange(values)
    }

    return isSelect(comparison) ? (
        <AsyncResourceValueSelect
            sx={{ mt: 1, minWidth: 100 }}
            textFieldProps={{ autoFocus: true, variant: 'standard' }}
            requestStart="focus"
            resourceName={field.resourceName}
            keyPropertyName={field.name}
            multiple
            value={
                !values
                    ? []
                    : typeof values === 'object'
                    ? values.map(v => ({ value: v, label: v })) || []
                    : [String(values)]
            }
            onChange={(e, values) => onChange(values.map(v => (typeof v === 'string' ? v : v.value)))}
        />
    ) : textType === 'boolean' ? (
        <Switch checked={values === 'True' || values === 1} onChange={handleBooleanChange} />
    ) : (
        <PropertyController
            hideSelect
            className={classes.borderedSection}
            accept={[PropertyType.DEFINING, PropertyType.QUALITATIVE, PropertyType.QUANTITATIVE]}
            onDrop={({ type, pointer, ...field }) => onAdd(field)}
        >
            <Box display="flex" alignItems="center">
                <TextField
                    fullWidth
                    placeholder="Value or field"
                    type={textType}
                    inputProps={{ min: minValue, max: maxValue }}
                    value={draft ?? ''}
                    onChange={({ target }) => setDraft(target.value)}
                    disabled={disabled}
                />

                <FieldIconButton properties={properties} onAdd={onAdd} />
            </Box>
        </PropertyController>
    )
}

const useFieldIconButton = makeStyles({
    fieldItem: { whiteSpace: 'nowrap', textOverflow: 'ellipsis' },
})

interface FieldIconButtonProps {
    properties: Property[]
    onAdd: (field: DataField) => void
}

function FieldIconButton({ properties, onAdd }: FieldIconButtonProps) {
    const [searchString, setSearchString] = useState('')

    const options = properties?.filter(p =>
        p.displayName.toLocaleLowerCase().includes(searchString.toLocaleLowerCase())
    )
    const classes = useFieldIconButton()

    function handleClick({ id, name, container, semanticType }: Property) {
        onAdd({
            id,
            name,
            resourceType: container.type,
            resourceId: container.id,
            resourceName: container.name,
            dataTypeId: semanticType.id,
        })
    }

    return (
        <MenuIcon
            buttonProps={{ size: 'small' }}
            icon={<PropertiesIcon fontSize="small" />}
            popoverProps={{
                PaperProps: {
                    sx: {
                        height: '200px',
                        width: '200px',
                        overflow: 'hidden',
                        padding: 0,
                        display: 'flex',
                        flexDirection: 'column',
                    },
                },
            }}
            tooltip="Properties"
        >
            <TextField
                autoFocus
                fullWidth
                onChange={e => setSearchString(e.target.value.toLowerCase())}
                placeholder="Search"
                sx={{ px: 1 }}
                value={searchString}
            />

            <List sx={{ overflow: 'auto' }}>
                {options?.map((p, index) => (
                    <ListItem
                        key={`${p.id}${index}`}
                        button
                        dense
                        className={classes.fieldItem}
                        onClick={() => handleClick(p)}
                    >
                        {p.displayName}
                    </ListItem>
                ))}
            </List>
        </MenuIcon>
    )
}

function getTextType(baseType: SemanticType['baseDataType']): 'text' | 'boolean' | 'number' | 'date' | 'email' | 'tel' {
    if (!baseType) return 'text'

    switch (baseType) {
        case 'Binary':
        case 'True - False':
            return 'boolean'
        case 'Currency':
        case 'Decimal':
        case 'Integer':
        case 'Large Number':
        case 'Percentage':
        case 'Small Number':
            return 'number'
        case 'Date':
            return 'date'
        case 'Phone':
            return 'tel'
        case 'String':
        case 'Time':
            return 'text'
    }
}
