import { Box, Collapse, MenuItem, TextField } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import produce from 'immer'
import { useContext, useState } from 'react'

import { ChartType, DataField, FormatConfig, ComparisonOperator, SeriesConfig } from 'genesis-suite/types/visualTypes'
import useWidgetColors from '../../../../hooks/useWidgetColors'
import { ParsedResponse } from '../../../../types/WidgetTypes'
import pickFromCarousel from '../../../widgets2/utils/pickFromCarousel'
import { FieldPointer } from '../../builderTypes'
import { ConfigContext } from '../../ConfigContext'
import { getAllProperties, getDataFieldProperty } from '../../utils'
import EditorWrapper from '../EditorWrapper'
import { typeConfigKeyMapping, typeSeriesKeyMapping } from '../SeriesEditor/SeriesEditor2'
import { ConditionalFormatListEditor } from './ConditionalFormatListEditor'
import ConditionalFormatSelectedEditor from './ConditionalFormatSelectedEditor'
import FormatColorEditor from './FormatColorEditor'
import FormatEditorColorSelector from './FormatEditorColorSelector'

const useStyles = makeStyles(({ palette }) => ({
    select: { minWidth: '100px' },
    disabledSelect: { color: palette.text.primary, '&::before': { borderBottomStyle: 'solid !important' } },
}))

export const typeFormatConfigKeyMapping = {
    Chart: 'FormatConfig',
    Table: 'conditionalFormats',
}
export default function ChartFormatEditor() {
    const { dispatch, dataResponse, selectedField, resources, configKey, ...rest } = useContext(ConfigContext)
    const config = rest.config as SeriesConfig
    const widgetColors = useWidgetColors()
    const classes = useStyles()
    const ConditionalFormatKey = typeFormatConfigKeyMapping[config.Type]
    const [selectedRuleIndex, setSelectedRuleIndex] = useState<number>(null)
    const fieldsConfigKey = typeConfigKeyMapping[config?.Type]
    const seriesKey = typeSeriesKeyMapping[config?.Type]
    const activeSeriesIndex = selectedField.index ?? 0
    const activeSeries = config[configKey]?.[seriesKey]?.[activeSeriesIndex]
    if (!activeSeries) return null

    const { subSeries, colors } = activeSeries
    const FormatConfig = activeSeries[ConditionalFormatKey]

    const properties = getAllProperties(resources.byId)
    const selectedRule = selectedRuleIndex != null && FormatConfig?.[selectedRuleIndex]
    const hasSeriesColor =
        config.type === ChartType.COMBO ||
        config.type === ChartType.BOX_PLOT ||
        (config.type === ChartType.PACKED_BUBBLE && Boolean(subSeries))
    let subSeriesNames = [activeSeries.FieldName]
    let selectedSubSeries = activeSeries.FieldName
    if (subSeries && dataResponse) {
        subSeriesNames = dataResponse?.groupNames?.bySeries?.[activeSeriesIndex]
            ? [...dataResponse.groupNames.bySeries[activeSeriesIndex]].sort()
            : []
        selectedSubSeries =
            (selectedField.type === 'series' && selectedField.subSeries) ||
            (subSeriesNames.indexOf(selectedSubSeries) > -1 ? selectedSubSeries : subSeriesNames[0])
    }
    const seriesPoints =
        dataResponse?.data?.[activeSeriesIndex]?.data
            .filter(p => !subSeries || p.children?.some(c => c.group === selectedSubSeries))
            .map(p => p.group) ?? []
    const selectedSeriesPoint = (selectedField.type === 'series' && selectedField.categories?.[0]) ?? seriesPoints[0]

    const seriesDefault = !Boolean(colors?.[selectedSubSeries]?.series)
    const seriesColor =
        colors?.[selectedSubSeries]?.series || pickFromCarousel(widgetColors, getHcIndex(dataResponse, selectedField))
    const pointDefault = !Boolean(colors?.[selectedSubSeries]?.point?.[selectedSeriesPoint])
    const pointColor =
        colors?.[selectedSubSeries]?.point?.[selectedSeriesPoint] ||
        (hasSeriesColor ? seriesColor : pickFromCarousel(widgetColors, seriesPoints.indexOf(selectedSeriesPoint)))

    function handleRuleDragEnd(newItems) {
        dispatch({ type: 'UPDATE_ACTIVE_SERIES', payload: { [ConditionalFormatKey]: newItems } })
    }

    function handleAddRule() {
        const newRule: FormatConfig = {
            FormatRule: ComparisonOperator.EQUAL,
            TableField: activeSeries.FieldName,
            FormatOption: {},
            // source: { field: activeField, aggregation: getDefaultAggregation(activeProperty.semanticType) },
            // rule: ComparisonOperator.EQUAL,
            // format: {},
        }

        dispatch({
            type: 'UPDATE_ACTIVE_SERIES',
            payload: { [ConditionalFormatKey]: [...(FormatConfig ?? []), newRule] },
        })

        setSelectedRuleIndex(FormatConfig?.length ?? 0)
    }

    function handleDeleteRule(index) {
        dispatch({
            type: 'UPDATE_ACTIVE_SERIES',
            payload: { [ConditionalFormatKey]: FormatConfig.filter((c, i) => i !== index) },
        })
        setSelectedRuleIndex(null)
    }

    function handleUpdateSelectedRuleSource(field: DataField) {
        const fields = getDataFieldProperty(field, properties)
        console.log('semantic', fields)
        console.log('sema', activeSeries)
        updateSelectedRule({
            FormatRule: null,
            TableField: activeSeries.FieldName,
            target: null,
            // source: { field, aggregation: getDefaultAggregation(semanticType) },
            // rule: ComparisonOperator.EQUAL,
            // target: null,
        })
    }
    function handleSelectedRuleTargetSource(field: DataField) {
        const filedss = getDataFieldProperty(field, properties)
        console.log('fileds', filedss)
        updateSelectedRule({
            TargetProperty: filedss?.name,
            // targetSource: { field, aggregation: getDefaultAggregation(semanticType) },
        })
    }

    function updateSelectedRule(update: Partial<FormatConfig>) {
        dispatch({
            type: 'UPDATE_ACTIVE_SERIES',
            payload: {
                [ConditionalFormatKey]: FormatConfig.map((c, i) =>
                    i === selectedRuleIndex
                        ? {
                              ...c,
                              ...update,
                              FormatOption: update.FormatOption
                                  ? { ...c.FormatOption, ...update.FormatOption }
                                  : c.FormatOption,
                          }
                        : c
                ),
            },
        })
    }

    function removeSelectedRuleFormat(formatProp: keyof FormatConfig['FormatOption']) {
        if (!selectedRule) return
        const { [formatProp]: _, ...FormatOption } = selectedRule.FormatOption
        updateSelectedRule({ FormatOption })
    }

    function handleSelectSubSeries(e) {
        const subSeries = e.target.value
        dispatch({ type: 'SET_SELECTED_FIELD', payload: { ...selectedField, type: 'series', subSeries } })
    }

    function handleSelectPoint(e) {
        const categories = [e.target.value]
        dispatch({ type: 'SET_SELECTED_FIELD', payload: { ...selectedField, type: 'series', categories } })
    }

    function handleSeriesDefaultChange(e) {
        const seriesColor = produce(colors[selectedSubSeries], draft => {
            delete draft.series
        })
        dispatch({
            type: 'UPDATE_ACTIVE_SERIES',
            payload: { colors: { ...colors, [selectedSubSeries]: seriesColor } },
        })
    }

    function handlePointDefaultChange(e) {
        const seriesColor = produce(colors[selectedSubSeries], draft => {
            delete draft.point[selectedSeriesPoint]
        })
        dispatch({
            type: 'UPDATE_ACTIVE_SERIES',
            payload: { colors: { ...colors, [selectedSubSeries]: seriesColor } },
        })
    }

    function handleSeriesChange(color) {
        const seriesColor = produce(colors?.[selectedSubSeries] ?? {}, draft => {
            draft.series = color
        })
        dispatch({
            type: 'UPDATE_ACTIVE_SERIES',
            payload: { colors: { ...colors, [selectedSubSeries]: seriesColor } },
        })
    }

    function handlePointChange(color) {
        const seriesColor = produce(colors?.[selectedSubSeries] ?? {}, draft => {
            if (!draft.point) draft.point = {}
            draft.point[selectedSeriesPoint] = color
        })
        dispatch({
            type: 'UPDATE_ACTIVE_SERIES',
            payload: { colors: { ...colors, [selectedSubSeries]: seriesColor } },
        })
    }
    console.log('finalFormatConfig', FormatConfig)
    console.log('seelctedRul', selectedRule)
    return (
        <EditorWrapper header="Format">
            <ConditionalFormatListEditor
                FormatConfig={FormatConfig}
                selectedIndex={selectedRuleIndex}
                onIndexSelect={setSelectedRuleIndex}
                onDragEnd={handleRuleDragEnd}
                onAdd={handleAddRule}
                onDelete={handleDeleteRule}
            />

            <Box mt={1} />

            <Collapse in={Boolean(selectedRule)}>
                <ConditionalFormatSelectedEditor
                    selectedRule={selectedRule}
                    onUpdate={updateSelectedRule}
                    onUpdateSource={handleUpdateSelectedRuleSource}
                    onUpdateTargetSource={handleSelectedRuleTargetSource}
                    properties={properties}
                />

                {/* <FormatEditorColorSelector
                    value={selectedRule?.FormatOption?.backgroundColor}
                    onChange={c =>
                        updateSelectedRule({ FormatOption: { ...selectedRule.FormatOption, backgroundColor: c } })
                    }
                    resetButtonProps={{
                        disabled: !selectedRule?.FormatOption?.backgroundColor,
                        onClick: () => removeSelectedRuleFormat('backgroundColor'),
                    }}
                /> */}
                <FormatColorEditor
                    fieldsConfigKey="FormatOptionConfig"
                    updateSelectedRule={updateSelectedRule}
                    removeSelectedRuleFormat={removeSelectedRuleFormat}
                    selectedRuleIndex={selectedRuleIndex}
                />
            </Collapse>

            <Collapse in={!selectedRule}>
                {hasSeriesColor && (
                    <>
                        {subSeriesNames.length > 1 && (
                            <TextField
                                select
                                className={classes.select}
                                label="Sub-series"
                                size="small"
                                InputProps={{ classes: { disabled: classes.disabledSelect } }}
                                value={selectedSubSeries || ''}
                                onChange={handleSelectSubSeries}
                            >
                                {subSeriesNames.map(n => (
                                    <MenuItem key={n} value={n}>
                                        {n}
                                    </MenuItem>
                                ))}
                            </TextField>
                        )}
                        {/* <FormatEditorColorSelector
                            value={seriesColor}
                            onChange={handleSeriesChange}
                            resetButtonProps={{ disabled: seriesDefault, onClick: handleSeriesDefaultChange }}
                        /> */}

                        <Box mt={2} />
                    </>
                )}

                <TextField
                    select={seriesPoints.length > 1}
                    className={classes.select}
                    label="Point"
                    size="small"
                    InputProps={{ classes: { disabled: classes.disabledSelect } }}
                    disabled={seriesPoints.length < 2}
                    value={selectedSeriesPoint || ''}
                    onChange={handleSelectPoint}
                >
                    {seriesPoints.map(n => (
                        <MenuItem key={n} value={n}>
                            {n}
                        </MenuItem>
                    ))}
                </TextField>

                <FormatEditorColorSelector
                    value={pointColor}
                    onChange={handlePointChange}
                    resetButtonProps={{ disabled: pointDefault, onClick: handlePointDefaultChange }}
                />
            </Collapse>
        </EditorWrapper>
    )
}

/** return the highcharts series index of the selected field */
function getHcIndex(response: ParsedResponse, selectedField: FieldPointer) {
    if (!response || selectedField.type !== 'series') return

    const { data, groupNames } = response
    const { index, subSeries } = selectedField

    let hcIndex = 0
    for (const dataIndex in data) {
        const seriesIndex = parseInt(dataIndex)
        const subSeriesNames = subSeries && groupNames?.bySeries[dataIndex]

        if (subSeriesNames) {
            if (seriesIndex === index) return hcIndex + subSeriesNames.indexOf(subSeries)
            hcIndex += subSeriesNames.length
        } else {
            if (seriesIndex === index) return hcIndex
            hcIndex++
        }
    }
}
