import { ArrowRight } from '@mui/icons-material'
import Arrow from '@mui/icons-material/ArrowDropDown'
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    ListItem,
    ListItemButton,
    ListItemText,
    MenuList,
    Popover,
    Tooltip,
    Typography,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classnames from 'classnames'
import { ChopText } from 'genesis-suite/components'
import { useRef, useState } from 'react'
import { useSelector } from 'react-redux'

import useApplications from '~/hooks/useApplications'
import { logEvent } from '../lib/amplitudeClient'
import { TourTag } from '../lib/tourSteps'
import { authSelectors } from '../selectors'
import ModuleController from './ModuleController'

const useStyles = makeStyles(({ palette, spacing }) => ({
    popoverTitle: {
        padding: spacing(1),
        position: 'sticky',
        top: 0,
        fontWeight: 'bold',
        height: 37,
    },
    moduleList: {
        flex: 1,
        overflowY: 'auto',
        minWidth: 300,
        maxWidth: '100%',
        paddingTop: 0,
    },
    selected: {
        fontWeight: 'bold',
    },
    container: {
        margin: spacing(0, 1),
        display: 'flex',
        color: 'white',
    },
    accordionSummary: {
        flexDirection: 'row-reverse',
        maxHeight: 35,
        minHeight: 35,
        padding: 0,
        paddingLeft: spacing(0.5),
        justifyContent: 'flex-end',
        '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
            transform: 'rotate(90deg)',
        },
        '& .MuiAccordionSummary-content .Mui-expanded': {
            fontWeight: 'bold',
        },
        '& .MuiAccordionSummary-content': {
            maxWidth: '80%',
            marginLeft: spacing(1),
        },
        '& .MuiAccordionSummary-expandIconWrapper': {
            '& .MuiSvgIcon-root': {
                color: palette.text.primary,
            },
        },
    },
    accordionDetails: {
        marginLeft: spacing(2),
    },
    accordion: {
        '&.MuiAccordion-root:before': {
            display: 'none',
        },
    },
    moduleTitle: {
        overflow: 'hidden',
        lineHeight: '1.1',
        fontSize: '1rem',
        fontWeight: 700,
        textOverflow: 'ellipsis',
        maxWidth: '150px',
        paddingLeft: '4px',
        '-webkitLineClamp': '3',
        display: '-webkit-box',
        '-webkitBoxOrient': 'vertical',
    },
}))

export default function ApplicationController({ moduleTitle, textColor }) {
    const classes = useStyles()
    const buttonRef = useRef()

    const [applications] = useApplications()

    const [open, setOpen] = useState(false)
    const [enableSelect, setEnableSelect] = useState(true)
    const actAsUser = useSelector(authSelectors.getActAs)

    if (!applications.length) return null

    return (
        <div data-tour={TourTag.ModuleController} className={classes.container}>
            <Tooltip title={moduleTitle || ''}>
                <ListItem ref={buttonRef} disablePadding>
                    <ListItemButton
                        dense
                        disableGutters
                        // disabled={actAsUser ? true : !enableSelect}
                        classes={{ root: classes.selectButton, disabled: classes.selectButtonDisabled }}
                        onClick={() => {
                            logEvent('OPEN_UNIFIED_APP_SELECTOR')
                            setOpen(true)
                        }}
                    >
                        <ListItemText disableTypography sx={{ color: textColor }}>
                            <Typography className={classes.moduleTitle} variant="subtitle1" color="inherit">
                                {moduleTitle}
                            </Typography>
                        </ListItemText>
                        {enableSelect && <Arrow htmlColor={textColor} />}
                    </ListItemButton>
                </ListItem>
            </Tooltip>
            <Popover
                anchorEl={buttonRef.current}
                open={open}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                onClose={() => setOpen(false)}
                transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                PaperProps={{
                    sx: { display: 'flex', flexFlow: 'column', height: 400, width: 300, overflow: 'hidden' },
                }}
            >
                <Typography variant="h6" className={classes.popoverTitle}>
                    My Applications
                </Typography>
                <ApplicationList
                    onModules={data => setEnableSelect(applications?.length > 1 || data?.length > 1)}
                    onClose={() => setOpen(false)}
                />
            </Popover>
        </div>
    )
}

export const ApplicationList = ({ onModules = () => null, onClose = () => null, Header }) => {
    const classes = useStyles()
    const [applications, currAppName] = useApplications()

    const [appName, setAppName] = useState(currAppName)
    const [expanded, setExpanded] = useState(0)

    if (!applications.length) return null

    const handleExpand = (panel, appName) => (e, newExpanded) => {
        setExpanded(newExpanded ? panel : false)
        setAppName(appName)
    }

    return (
        <Box sx={{ display: 'flex', flexFlow: 'column', height: '100%' }}>
            {Header && (
                <Header>
                    <Typography variant="h6">My Applications</Typography>
                </Header>
            )}
            <MenuList className={classes.moduleList} sx={{ flex: 1, overflow: 'auto' }}>
                <div>
                    {applications?.map((app, i) => (
                        <Accordion
                            disableGutters
                            expanded={expanded === i}
                            onChange={handleExpand(i, app.AppName)}
                            className={classes.accordion}
                            elevation={0}
                            key={app.AppName}
                        >
                            <AccordionSummary
                                expandIcon={<ArrowRight htmlColor="black" />}
                                className={classnames(classes.accordionSummary, {
                                    [classes.selected]: app.AppName === appName,
                                })}
                                value={app.AppName}
                            >
                                <ChopText showEllipsis> {app.AppName}</ChopText>
                            </AccordionSummary>
                            <AccordionDetails className={classes.accordionDetails}>
                                {app.AppName === appName && (
                                    <ModuleController
                                        appName={app.AppName}
                                        onClose={onClose}
                                        currentApplication={currAppName}
                                        onExpand={() => setExpanded(0)}
                                        onModules={data => onModules(data)}
                                    />
                                )}
                            </AccordionDetails>
                        </Accordion>
                    ))}
                </div>
            </MenuList>
        </Box>
    )
}
