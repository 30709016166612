import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Dialog,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    Typography,
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import { useContext, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Slideshow from '~/components/Slideshow'
import { authSelectors, filterSelectors, widgetSelectors } from '../../selectors'
import FavoriteWidget from './FavoriteWidget'
import { navigationCreators } from '~/actions/creators'
import { useIsMobile } from '~/hooks/useIsMobile'
import { ArrowDropDown } from '@mui/icons-material'
import FullScreenContainer from '~/containers/FullScreenContainer'
import NavTitle from '~/containers/NavTitle'
import { Close } from 'genesis-suite/icons'
import { DashboardContext } from '~/components/widgets2/dashboard/DashboardContext'
import { ChartType } from 'genesis-suite/types/visualTypes'

const useStyles = makeStyles(({ palette, spacing }) => ({
    emptyState: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexFlow: 'column nowrap',
        '& p': {
            maxWidth: 500,
            '& svg': {
                verticalAlign: 'text-bottom',
            },
        },
    },
    svg: {
        height: '125px',
    },
    comingSoonText: {
        margin: spacing(2, 0),
        fontWeight: 750,
    },
    grid: {
        maxHeight: '100%',
        overflow: 'scroll',
        padding: spacing(0, 1),
    },
    accordion: {
        background: palette.background.main,
        boxShadow: 'none',
        '&.Mui-expanded': {
            margin: 0,
        },
    },
    accordionSummary: {
        padding: spacing(0, 1),
        flexDirection: 'row',
        width: '100%',
        '&.MuiAccordionSummary-root': {
            minHeight: '48px',
        },
        '&.MuiAccordionSummary-root.Mui-expanded .MuiAccordionSummary-content': {
            margin: 0,
        },
    },
    dialogTitle: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: spacing(1, 1),
    },
    dialogContent: {
        padding: spacing(1, 0),
    },
    slideIndicatorWrapper: {
        display: 'flex',
        justifyContent: 'center',
        position: 'absolute',
        width: '100%',
        marginTop: '20px',
    },
    slideIndicator: {
        background: '#000000',
        color: '#ffffff',
        width: 86,
        height: 28,
        borderRadius: 10,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
}))

export default function Favorites({ className }) {
    const classes = useStyles()
    const dispatch = useDispatch()
    const configs = useSelector(widgetSelectors.getWidgetConfigs)
    const favorites = useSelector(state => authSelectors.getPreference(state, 'widgetFavorites'))
    const isMobile = useIsMobile()

    const [slideShowIndex, setSlideshowIndex] = useState<number>(null)
    const [inputValue, setInputValue] = useState(0)

    const networkContext = useSelector(filterSelectors.getCoord)

    const favoriteWidgets = favorites
        ? favorites.reduce((acc, id) => {
              if (configs[id]) acc.push(configs[id])
              return acc
          }, [])
        : []

    const widgets = favoriteWidgets.filter(w => !w.ContainerConfig)
    const perspectives = favoriteWidgets.filter(w => w.ContainerConfig)

    const {
        widgets: layoutWidget,
        toggleExpandCollapseWidget,
        expanded,
        expandedWidgetRefs,
    } = useContext(DashboardContext)
    const layoutWidgetIndex = layoutWidget?.find(X => X.type === ChartType.FAVORITES)?.id
    const handleToggle = key => {
        toggleExpandCollapseWidget(key)
    }

    if (!favoriteWidgets.length) return null

    return (
        <div className={className}>
            {isMobile ? (
                <>
                    <Accordion
                        className={classes.accordion}
                        expanded={!!expanded[layoutWidgetIndex]}
                        onChange={() => (isMobile ? handleToggle(layoutWidgetIndex) : null)}
                    >
                        <AccordionSummary className={classes.accordionSummary} expandIcon={<ArrowDropDown />}>
                            <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                                <Typography>Favorites ({favoriteWidgets.length})</Typography>
                            </div>
                        </AccordionSummary>
                        <AccordionDetails
                            sx={{ height: '100%', overflow: 'scroll', padding: '0 8px' }}
                            ref={el => {
                                expandedWidgetRefs.current[layoutWidgetIndex] = el
                            }}
                        >
                            {perspectives?.length > 0 &&
                                perspectives.map((config, index) => (
                                    <FavoriteWidget
                                        key={index}
                                        config={config}
                                        onLaunch={() => dispatch(navigationCreators.goToPerspective(config.Id))}
                                    />
                                ))}
                            {widgets?.length > 0 &&
                                widgets.map((config, index) => (
                                    <FavoriteWidget
                                        key={index}
                                        config={config}
                                        onLaunch={() => {
                                            setSlideshowIndex(index)
                                            setInputValue(index)
                                        }}
                                    />
                                ))}
                        </AccordionDetails>
                    </Accordion>
                    <Dialog
                        open={slideShowIndex !== null}
                        onClose={() => setSlideshowIndex(null)}
                        sx={{ height: '100%' }}
                        PaperProps={{
                            sx: {
                                height: 'calc(100% - 20px)',
                                maxHeight: 'none',
                            },
                        }}
                    >
                        <DialogTitle className={classes.dialogTitle}>
                            <NavTitle />
                            <IconButton onClick={() => setSlideshowIndex(null)}>
                                <Close />
                            </IconButton>
                        </DialogTitle>
                        <DialogContent className={classes.dialogContent}>
                            <Box sx={{ position: 'relative' }}>
                                <FullScreenContainer
                                    widgets={widgets}
                                    networkContext={networkContext}
                                    goTo={Math.round(slideShowIndex)}
                                />
                            </Box>
                            <div className={classes.slideIndicatorWrapper}>
                                {widgets.length > 1 && (
                                    <input
                                        onChange={e => {
                                            setInputValue(e.target.value as unknown as number)
                                            setSlideshowIndex(Math.round(e.target.value as unknown as number))
                                        }}
                                        onTouchEnd={(e: any) => {
                                            setInputValue(Math.round(e.target.value))
                                            setSlideshowIndex(Math.round(e.target.value))
                                        }}
                                        onMouseUp={(e: any) => {
                                            setInputValue(Math.round(e.target.value))
                                            setSlideshowIndex(Math.round(e.target.value))
                                        }}
                                        value={inputValue}
                                        type="range"
                                        min={0}
                                        max={widgets.length - 1}
                                        step={0.0001}
                                        style={{ marginBottom: '8px' }}
                                    />
                                )}
                                <div className={classes.slideIndicator}>
                                    {slideShowIndex + 1} of {widgets ? widgets.length : 1}
                                </div>
                            </div>
                        </DialogContent>
                    </Dialog>
                </>
            ) : (
                <>
                    <Typography variant="h6" gutterBottom>
                        Favorites ({favoriteWidgets.length})
                    </Typography>

                    <Grid className={classes.grid} spacing={2} container>
                        {perspectives?.length > 0 &&
                            perspectives.map((config, index) => (
                                <Grid item key={config.Id} md={3} sm={12} xs={12}>
                                    <FavoriteWidget
                                        config={config}
                                        onLaunch={() => dispatch(navigationCreators.goToPerspective(config.Id))}
                                    />
                                </Grid>
                            ))}
                        {widgets?.length > 0 &&
                            widgets.map((config, index) => (
                                <Grid item key={config.Id} md={3} sm={12} xs={12}>
                                    <FavoriteWidget config={config} onLaunch={() => setSlideshowIndex(index)} />
                                </Grid>
                            ))}
                    </Grid>
                    <Slideshow
                        configs={widgets}
                        open={slideShowIndex != null}
                        onClose={() => setSlideshowIndex(null)}
                        startIndex={slideShowIndex}
                    />
                </>
            )}
        </div>
    )
}
