import { ChartType, Dashboard } from 'genesis-suite/types/visualTypes'

const defaultMobileHomePage: Omit<Dashboard, 'id'> = {
    homePage: true,
    title: 'homepage',
    widgets: [
        {
            id: 'mobile',
            inline: { type: ChartType.FAVORITES },
            positions: {
                large: { shrink: true, x: 0, y: 0, w: 18, h: 9 },
                small: { shrink: true, x: 0, y: 5, w: 4, h: 6, static: false },
                extrasmall: { shrink: true, x: 0, y: 5, w: 4, h: 2, static: false },
            },
        },
        {
            id: 'mobile',
            inline: { type: ChartType.BUSINESS_ELEMENTS },
            positions: {
                large: { shrink: true, x: 0, y: 0, w: 18, h: 9 },
                small: { shrink: true, x: 0, y: 5, w: 4, h: 6, static: false },
                extrasmall: { shrink: true, x: 0, y: 5, w: 4, h: 2, static: false },
            },
        },
        {
            id: 'mobile',
            inline: { type: ChartType.VIEWS },
            positions: {
                large: { shrink: true, x: 0, y: 0, w: 18, h: 9 },
                small: { shrink: true, x: 0, y: 5, w: 4, h: 6, static: false },
                extrasmall: { shrink: true, x: 0, y: 5, w: 4, h: 2, static: false },
            },
        },
        {
            id: 'mobile',
            inline: { type: ChartType.APPLICATIONS },
            positions: {
                large: { shrink: true, x: 0, y: 0, w: 18, h: 9 },
                small: { shrink: true, x: 0, y: 5, w: 4, h: 6, static: false },
                extrasmall: { shrink: true, x: 0, y: 5, w: 4, h: 2, static: false },
            },
        },
    ],
}

export default defaultMobileHomePage
