import {
    ColDef,
    FirstDataRenderedEvent,
    GetRowIdParams,
    GridReadyEvent,
    IsServerSideGroupOpenByDefaultParams,
    ModuleRegistry,
    RowClassParams,
    RowGroupOpenedEvent,
    RowHeightParams,
    StatusPanelDef,
    IsServerSideGroup,
    GetServerSideGroupKey,
    MenuItemDef,
    GetContextMenuItemsParams,
} from '@ag-grid-community/core'
import { AgGridReact } from '@ag-grid-community/react'
import { ColumnsToolPanelModule } from '@ag-grid-enterprise/column-tool-panel'
import { LicenseManager } from '@ag-grid-enterprise/core'
import { ExcelExportModule } from '@ag-grid-enterprise/excel-export'
import { MenuModule } from '@ag-grid-enterprise/menu'
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping'
import { ServerSideRowModelModule } from '@ag-grid-enterprise/server-side-row-model'
import { StatusBarModule } from '@ag-grid-enterprise/status-bar'
import { forwardRef, useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { themeSelectors } from '../../../selectors'
import { ThemeMode } from '../../../types/ThemeTypes'

import '@ag-grid-community/styles/ag-grid.css'
import '@ag-grid-community/styles/ag-theme-alpine.css'
import { agGridConstants } from '../../../constants/aggrid.constants'

ModuleRegistry.registerModules([
    ServerSideRowModelModule,
    RowGroupingModule,
    StatusBarModule,
    ExcelExportModule,
    MenuModule,
    ColumnsToolPanelModule,
])

LicenseManager.setLicenseKey(
    'Using_this_{AG_Grid}_Enterprise_key_{AG-070843}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{CGN_AND_ASSCOCIATES_INC}_is_granted_a_{Multiple_Applications}_Developer_License_for_{2}_Front-End_JavaScript_developers___All_Front-End_JavaScript_developers_need_to_be_licensed_in_addition_to_the_ones_working_with_{AG_Grid}_Enterprise___This_key_has_been_granted_a_Deployment_License_Add-on_for_{1}_Production_Environment___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{4_January_2026}____[v3]_[01]_MTc2NzQ4NDgwMDAwMA==1e2a1f0bfc91a65f122949b8c5818174'
)

export interface IBaseRowData {
    key: string
    show?: boolean
    [key: string]: any
}

interface BaseAgGridTableProps {
    createColumnDefs: ColDef<any>[]
    defaultColDef: ColDef
    autoGroupColumnDef: ColDef
    onGridReady: (event: GridReadyEvent<any>) => void
    pagination: boolean
    paginationPageSize?: number
    cacheBlockSize?: number
    pivotMode: boolean
    onRowGroupOpened?: (event: RowGroupOpenedEvent<any>) => void
    isServerSideGroupOpenByDefault?: (params: IsServerSideGroupOpenByDefaultParams) => boolean
    getRowId?: (params: GetRowIdParams<any>) => string
    hideEmptyRows?: boolean
    statusBar?: { statusPanels: StatusPanelDef[] }
    handleFirstDataRendered?: (event: FirstDataRenderedEvent<any>) => void
    rowBuffer?: number
    isServerSideGroup?: (params: IsServerSideGroup) => boolean
    getServerSideGroupKey?: (params: GetServerSideGroupKey) => string
    treeData?: boolean
    getContextMenuItems?: (params: GetContextMenuItemsParams) => (string | MenuItemDef)[]
}

export const BaseAgGridTable = forwardRef<AgGridReact, BaseAgGridTableProps>(
    (
        {
            createColumnDefs,
            defaultColDef,
            autoGroupColumnDef,
            onGridReady,
            pagination,
            paginationPageSize,
            cacheBlockSize,
            pivotMode,
            onRowGroupOpened,
            isServerSideGroupOpenByDefault,
            getRowId,
            hideEmptyRows,
            statusBar,
            handleFirstDataRendered,
            rowBuffer,
            isServerSideGroup,
            getServerSideGroupKey,
            treeData,
            getContextMenuItems,
        },
        ref
    ) => {
        const containerStyle = useMemo(() => ({ width: '100%', height: '100%' }), [])
        const gridStyle = useMemo(() => ({ height: '100%', width: '100%' }), [])
        const themeMode = useSelector(themeSelectors.getThemeMode)

        const getRowHeight = useCallback((params: RowHeightParams<IBaseRowData>) => {
            return params.data?.show ? null : 0
        }, [])

        const getRowStyle = useCallback((params: RowClassParams<IBaseRowData>) => {
            return params.data?.show ? undefined : { borderBottomWidth: '0px' }
        }, [])

        const excelStyles = [
            {
                id: 'roundedValue',
                numberFormat: {
                    format: '0.00', // Specify the desired format
                },
            },
        ]

        return (
            <div style={containerStyle}>
                <div
                    style={gridStyle}
                    className={themeMode === ThemeMode.LIGHT ? agGridConstants.LIGHT_THEME : agGridConstants.DARK_THEME}
                >
                    <AgGridReact
                        pagination={pagination}
                        paginationPageSize={paginationPageSize || agGridConstants.PAGE_SIZE}
                        columnDefs={createColumnDefs}
                        defaultColDef={defaultColDef}
                        autoGroupColumnDef={autoGroupColumnDef}
                        rowModelType={agGridConstants.ROW_MODEL_TYPE}
                        suppressAggFuncInHeader={true}
                        cacheBlockSize={cacheBlockSize || agGridConstants.CACHE_BLOCK_SIZE}
                        maxBlocksInCache={agGridConstants.MAX_BLOCKS_IN_CACHE}
                        rowBuffer={rowBuffer || agGridConstants.ROW_BUFFER}
                        animateRows={true}
                        onGridReady={onGridReady}
                        ref={ref}
                        pivotMode={pivotMode}
                        onRowGroupOpened={onRowGroupOpened}
                        isServerSideGroupOpenByDefault={isServerSideGroupOpenByDefault}
                        enableCellChangeFlash={true}
                        getRowId={getRowId}
                        getRowHeight={hideEmptyRows ? getRowHeight : null}
                        getRowStyle={hideEmptyRows ? getRowStyle : null}
                        statusBar={statusBar}
                        allowContextMenuWithControlKey={true}
                        onFirstDataRendered={handleFirstDataRendered}
                        excelStyles={excelStyles}
                        isServerSideGroup={isServerSideGroup}
                        getServerSideGroupKey={getServerSideGroupKey}
                        treeData={treeData}
                        getContextMenuItems={getContextMenuItems}
                    ></AgGridReact>
                </div>
            </div>
        )
    }
)
