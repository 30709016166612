import { useContext } from 'react'

import { Typography } from '@mui/material'
import { ConfigContext } from '../ConfigContext'
import ActionEditor from './ActionEditor'
import CategoryEditor2 from './CategoryEditor2'
import DynamicFilterEditor from './DynamicFilterEditor'
import SeriesEditor2 from './SeriesEditor/SeriesEditor2'

export default function FieldEditor2({ className }) {
    const { selectedField, configKey } = useContext(ConfigContext)

    if (!selectedField) return null
    if (selectedField?.type === 'pivot') return null

    const editor =
        selectedField?.type === 'series' ? (
            <SeriesEditor2 parentProperty="FieldName" />
        ) : selectedField?.type === 'action' ? (
            <ActionEditor key={selectedField?.index} />
        ) : selectedField?.type === 'dynamicFilter' ? (
            <DynamicFilterEditor key={selectedField?.index} />
        ) : selectedField?.type === 'category' ? (
            // <CategoryEditor2 key={selectedField?.index} fieldsConfigKey={configKey} parentProperty="LabelField" />
            <CategoryEditor2 key={selectedField?.index} parentProperty="LabelField" />
        ) : null

    return (
        <div className={className}>
            <Typography gutterBottom variant="h6">
                Properties
            </Typography>
            {editor}
        </div>
    )
}
