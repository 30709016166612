import BarIcon from '@mui/icons-material/BarChartRounded'
import AreaIcon from '@mui/icons-material/NetworkCell'
import LineIcon from '@mui/icons-material/ShowChart'
import { Box } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { useContext } from 'react'

import { DebouncedTextField } from 'genesis-suite/components'
import { PropertyType } from 'genesis-suite/types/architectureTypes'
import { ColumnSeries, LineSeries } from 'genesis-suite/types/visualTypes'
import { isEmpty } from 'lodash'
import LabeledToggleButton from '../../../LabeledToggleButton'
import AggregationSelect from '../../AggregationSelect'
import { ConfigContext } from '../../ConfigContext'
import DnDPropertySelector from '../../selectors/DnDPropertySelector'
import FieldLabel from '../FieldLabel'
import ChartFormatEditor from '../FormatEditor/ChartFormatEditor'
import SeriesFormatEditor from './SeriesFormatEditor'
import SeriesTooltipEditor from './SeriesTooltipEditor'
import TableCellFormatEditor from './TableCellFormatEditor'
import TableHyperlinkEdtor from './TableHyperlinkEdtor'

const useStyles = makeStyles(({ spacing }) => ({
    group: { marginBottom: spacing() },
    stackingInput: { width: '85px' },
}))

type Shape = LineSeries['shape'] | ColumnSeries['type']

const comboOptions: Array<{ label: any; value: Shape; tip: string }> = [
    { label: <BarIcon />, value: 'bar', tip: 'Bar' },
    { label: <LineIcon />, value: 'spline', tip: 'Smooth' },
    { label: <LineIcon />, value: 'line', tip: 'Straight' },
    { label: <AreaIcon />, value: 'area', tip: 'Area' },
]

const NO_STACK = 'None'

export const typeConfigKeyMapping = {
    Chart: 'ChartSeriesConfig',
    Table: 'TableFieldConfig',
}

export const typeSeriesKeyMapping = {
    Chart: 'Series',
    Table: 'Fields',
}

export default function SeriesEditor2({ parentProperty }) {
    const { selectedField, dispatch, configStructure, config, resources, configKey } = useContext(ConfigContext)
    const fieldsConfigKey = typeConfigKeyMapping[config?.Type]
    const seriesKey = typeSeriesKeyMapping[config?.Type]
    const seriesConfig = configStructure[fieldsConfigKey]
    const classes = useStyles()

    if (!seriesConfig || !config || !selectedField) return null

    if (selectedField?.type !== 'series') return null
    const widgetConfig = config[configKey]
    const seriesFields = seriesConfig.filter(field => field.ParentProperty === parentProperty)
    const activeSeries = widgetConfig?.[seriesKey]?.[selectedField.index]

    if (isEmpty(seriesFields) || !activeSeries) return null
    const handleTypeChange = (e, val: Shape) => {
        const smoothCurves = val === 'spline'
        const type = val === 'spline' ? 'line' : val
        updateActiveSeries({ SeriesType: type, SmoothCurves: smoothCurves })
    }

    function updateActiveSeries(payload: Partial<LineSeries | ColumnSeries>) {
        dispatch({ type: 'UPDATE_ACTIVE_SERIES', payload })
    }

    function handleAdd(field) {
        console.log('add', field)
    }

    function handleRemove(index) {
        console.log('remove', index)
    }

    return (
        <>
            <Box overflow="auto" flex="1">
                {seriesFields.map((field, index) => {
                    if (field.Name === 'SeriesType')
                        return (
                            <div className={classes.group}>
                                <FieldLabel field={field} />
                                <LabeledToggleButton
                                    value={
                                        activeSeries.SeriesType === 'line' && activeSeries.SmoothCurves
                                            ? 'spline'
                                            : activeSeries.SeriesType
                                    }
                                    label=""
                                    onChange={handleTypeChange}
                                    options={comboOptions}
                                />
                            </div>
                        )

                    if (field.IsDisplayName) {
                        return (
                            <Box py={1}>
                                <FieldLabel field={field} />

                                <DebouncedTextField
                                    size="small"
                                    variant="outlined"
                                    label={field.DisplayName}
                                    fullWidth
                                    value={activeSeries?.[field.Name] ?? ''}
                                    onChange={title => updateActiveSeries({ [field.Name]: title })}
                                />
                            </Box>
                        )
                    }

                    if (field.Name === 'AggregationType') return <AggregationSelect />

                    if (field.IsSourceProperty)
                        return (
                            <DnDPropertySelector
                                label={field.DisplayName}
                                accept={[PropertyType.DEFINING, PropertyType.QUALITATIVE, PropertyType.QUANTITATIVE]}
                                properties={[]}
                                limit={field.IsMultiSelect ? null : 1}
                                onAdd={handleAdd}
                                onRemove={handleRemove}
                            />
                        )
                })}
                <SeriesFormatEditor parentProperty="ValueFormat" />
                <TableHyperlinkEdtor fieldsConfigKey="TableFieldConfig" />
                <TableCellFormatEditor fieldsConfigKey="CellFormat" />
                <ChartFormatEditor />
                <SeriesTooltipEditor />
            </Box>
        </>
    )
}
