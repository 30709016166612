import { useContext } from 'react'
import { ConfigContext } from '../ConfigContext'
import { DebouncedTextField } from 'genesis-suite/components'
import { Typography } from '@mui/material'

export default function WidgetTitleEditor() {
    const { config, dispatch } = useContext(ConfigContext)

    return (
        <>
            <Typography fontWeight={'bold'} marginTop={1} variant="body1">
                Widget Title
            </Typography>
            <DebouncedTextField
                debounceTime={350}
                fullWidth
                InputProps={{ sx: { typography: 'h6' } }}
                value={config?.Title ?? ''}
                onChange={Title => dispatch({ type: 'UPDATE_CONFIG', payload: { Title } })}
                placeholder="Widget title"
                autoFocus={!config?.title}
                required
                variant="outlined"
                size="small"
                sx={{ marginBottom: 1 }}
                helperText={!config?.title && 'Title is required'}
            />
        </>
    )
}
