import InfoSharpIcon from '@mui/icons-material/InfoSharp'
import {
    Box,
    Checkbox,
    FormControl,
    FormControlLabel,
    IconButton,
    MenuItem,
    Select,
    Switch,
    TextField,
    Tooltip,
    Typography,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { useContext } from 'react'
import EditorWrapper from '../config_fields/EditorWrapper'
import { ConfigContext } from '../ConfigContext'
import { isEmpty } from 'lodash'
import FieldLabel from '../config_fields/FieldLabel'

const useStyles = makeStyles(({ spacing, palette }) => ({
    actionContainer: {
        border: `2px solid ${palette.grayscale.light}`,
        padding: spacing(0.5),
        borderRadius: spacing(0.5),
        marginTop: spacing(0.5),
    },
    chipContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        gap: spacing(0.5),
    },
    inputBox: {
        display: 'flex',
        alignItems: 'center',
    },
}))

export default function WidgetDisplaySettings() {
    const classes = useStyles()
    const { dispatch, configStructure, configKey, config } = useContext(ConfigContext)

    if (isEmpty(configStructure) || !configKey || isEmpty(configStructure[configKey])) return null

    const widgetDisplaySettingsFields = configStructure[configKey].filter(f => f.ParentProperty === 'Display Settings')

    if (widgetDisplaySettingsFields?.length === 0) return null

    const updateDisplaySettings = (key, value) => {
        dispatch({
            type: 'UPDATE_CONFIG',
            payload: { [key]: value },
        })
    }
    const sortedWidgetConfig = [...widgetDisplaySettingsFields].sort(
        (a, b) => (a.DisplayOrder ?? Infinity) - (b.DisplayOrder ?? Infinity)
    )
    return (
        <EditorWrapper header="Display Settings">
            <Box className={classes.actionContainer}>
                {sortedWidgetConfig.map((field, index) => {
                    if (!isEmpty(field.List)) {
                        return (
                            <div key={index}>
                                <FieldLabel field={field} />
                                <FormControl fullWidth variant="outlined" size="small" margin="normal">
                                    <Select
                                        variant="outlined"
                                        value={config[field.Name] ?? field.List[0]}
                                        onChange={e => updateDisplaySettings(field.Name, e.target.value)}
                                    >
                                        {Object.entries(field.List as { [key: string]: string }).map(([key, value]) => (
                                            <MenuItem key={key} value={key}>
                                                {value}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </div>
                        )
                    } else if (field.Type.includes('Boolean')) {
                        return (
                            <div
                                key={index}
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                            >
                                <Checkbox
                                    onChange={e => updateDisplaySettings(field.Name, e.target.checked)}
                                    name={field.Name}
                                    color="primary"
                                    checked={config[field.Name] ?? false}
                                    inputProps={{ 'aria-label': `checkbox-${index}` }}
                                />
                                <FieldLabel field={field} />
                            </div>
                        )
                    } else {
                        return (
                            <div key={index}>
                                <FieldLabel field={field} />
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    size="small"
                                    value={config[field.Name]}
                                    onChange={e => updateDisplaySettings(field.Name, e.target.value)}
                                />
                            </div>
                        )
                    }
                })}
            </Box>
        </EditorWrapper>
    )
}
