import makeStyles from '@mui/styles/makeStyles'
import { Box, Typography } from '@mui/material'
import { AppLogo } from './sidenav/shared'
import { useSelector } from 'react-redux'
import { themeSelectors, appearanceSelectors, moduleSelectors, applicationSelectors } from '../selectors'
import { ThemeMode } from '~/types/ThemeTypes'
import ModelLastUpdated from '~/components/ModelLastUpdated'
import classnames from 'classnames'
import { useLocation, matchPath } from 'react-router-dom'
import { buildRoute } from 'genesis-suite/utils'
import { routePaths } from '~/lib/routes'
import { appearanceConstants } from '~/constants'
import { useIsMobile } from '~/hooks/useIsMobile'

const useStyles = makeStyles(({ spacing, palette, custom }) => ({
    footer: ({ bgColor, isMobile }: any) => ({
        display: 'flex',
        justifyContent: isMobile ? 'center' : 'space-between',
        alignItems: 'center',
        borderTop: `1px solid ${palette.border?.main}`,
        gap: spacing(),
        height: '33px',
        backgroundColor: bgColor || palette.background?.sideNav,
    }),
    sideNavFooter: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: custom.sideNav.width,
        gap: spacing(),
    },
    sideNavCollapsed: { width: custom.sideNav.collapsedWidth },
    logo: { height: '16px' },
    logoSmall: { height: 24 },
}))
export default function AppFooter() {
    const isMobile = useIsMobile()
    const themeMode = useSelector(themeSelectors.getThemeMode)
    const sideNavOpen = useSelector(appearanceSelectors.getSideNavState)
    const layout = useSelector(appearanceSelectors.getApplicationLayout)
    const appName = useSelector(state => applicationSelectors.getCurrentAppName(state))
    const appInfo = useSelector(state => applicationSelectors.getAppInfo(state))

    const { footer } = appInfo ?? {}
    const bgColor = footer?.BackgroundColor

    const classes = useStyles({ bgColor, isMobile })

    const moduleName = useSelector(state => moduleSelectors.getModuleName(state))
    const location = useLocation()
    const perspectiveRoute = buildRoute(routePaths.PERSPECTIVE, appName, moduleName)
    const isPerspective = matchPath(perspectiveRoute, location.pathname) ? true : false

    const getFontWeight = footer => {
        switch (footer?.FontWeight) {
            case 1:
                return 'normal'
            case 2:
                return 'bold'
            case 3:
                return 'bolder'
            case 4:
                return 'lighter'
            case 5:
                return 'inherit'
            default:
                return 'normal'
        }
    }

    if (isMobile) {
        return (
            <footer>
                <Box className={classes.footer}>
                    <Typography fontWeight={getFontWeight(footer)} color={footer?.Color}>
                        {footer?.Text}
                    </Typography>
                </Box>
            </footer>
        )
    }

    return (
        <footer style={{ zIndex: 300 }}>
            <Box className={classes.footer}>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                    {layout === appearanceConstants.Layout.MODERN && (
                        <Box
                            className={classnames(classes.sideNavFooter, { [classes.sideNavCollapsed]: !sideNavOpen })}
                        >
                            <AppLogo classes={classes} collapse={!sideNavOpen} bgColor={bgColor} />
                            {sideNavOpen && (
                                <Typography
                                    sx={{
                                        color: footer?.Color
                                            ? footer?.Color
                                            : themeMode === ThemeMode.LIGHT
                                            ? 'tada.purple'
                                            : undefined,
                                        fontSize: '11px',
                                        fontStyle: 'italic',
                                        lineHeight: 1,
                                    }}
                                >
                                    secures your data with <span style={{ whiteSpace: 'nowrap' }}>end-to-end</span>{' '}
                                    encryption.
                                </Typography>
                            )}
                        </Box>
                    )}
                    {isPerspective && <ModelLastUpdated />}
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Typography fontWeight={getFontWeight(footer)} color={footer?.Color}>
                        {footer?.Text}
                    </Typography>
                </Box>
                <Box>
                    <Typography
                        sx={{
                            color: footer?.Color ? footer?.Color : 'text.disabled',
                            flexShrink: 0,
                            padding: '0 8px',
                        }}
                    >
                        Powered by Digital Duplicate &trade;
                    </Typography>
                </Box>
            </Box>
        </footer>
    )
}
