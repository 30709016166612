import produce from 'immer'
import { matchPath } from 'react-router-dom'
import { push } from 'redux-first-history'

import buildRoute from 'genesis-suite/utils/buildRoute'
import { logEvent } from '../../lib/amplitudeClient'
import { updateModuleOrCreateDraft } from '../../lib/manageUtils'
import { routePaths } from '../../lib/routes'
import { architectureService, moduleService, visualService, widgetService } from '../../lib/services'
import { isCustomized } from '../../lib/tadaThemes'
import { createSlug } from '../../lib/utils'
import {
    applicationSelectors,
    authSelectors,
    deploymentSelectors,
    moduleSelectors,
    widgetSelectors,
} from '../../selectors'
import {
    authCreators,
    dialogCreators,
    hubCreators,
    insightCreators,
    linksCreators,
    logoCreators,
    navigationCreators,
    notificationCreators,
    scenarioCreators,
    themeCreators,
    tourCreators,
    userNavCreators,
    widgetCreators,
} from '../creators'
import { moduleTypes, widgetTypes } from '../types'
import { messengerCreators } from './messenger.creators'

const moduleChangeRequest = () => ({
    type: moduleTypes.MODULE_CHANGE_REQUEST,
})

const moduleReloadRequest = () => ({
    type: moduleTypes.MODULE_RELOAD_REQUEST,
})

const moduleSuccess = appModule => ({
    type: moduleTypes.MODULE_SUCCESS,
    payload: { appModule },
})

const moduleFailure = error => ({
    type: moduleTypes.MODULE_FAILURE,
    payload: { error },
    error: true,
})

const moduleUpdateDetected = () => ({
    type: moduleTypes.MODULE_UPDATE_DETECTED,
})

const moduleUpdateDismissed = () => ({
    type: moduleTypes.MODULE_UPDATE_DISMISSED,
})

const appModulesRequest = () => ({
    type: moduleTypes.APP_MODULES_REQUEST,
})

const appModulesSuccess = modules => ({
    type: moduleTypes.APP_MODULES_SUCCESS,
    payload: { modules },
})

const appModulesFailure = error => ({
    type: moduleTypes.APP_MODULES_FAILURE,
    payload: { error },
    error: true,
})

const updateTheme = (moduleId, theme) => ({
    type: moduleTypes.UPDATE_THEME,
    payload: { moduleId, theme },
})

const updateFilters = filters => ({
    type: moduleTypes.UPDATE_FILTERS,
    payload: { filters },
})

const toggleMigration = () => ({ type: moduleTypes.TOGGLE_MIGRATION })

const updateDetected = message => (dispatch, getState) => {
    const state = getState()
    const currentModuleId = moduleSelectors.getModuleId(state)
    const isSavingWidgets = widgetSelectors.getIsSavingWidgets(state)
    if (isSavingWidgets) return

    const {
        MessageContents: { VisorId },
    } = message
    if (VisorId !== currentModuleId) {
        return
    }

    dispatch(moduleUpdateDetected())
}

/**
 * Find a module with given perspective
 * @param {object} perspective desired perspective
 * @param {string} perspective.id id of perspective
 * @param {object=} perspective.context optional. desired context for perspective
 */
const getModuleWithPerspective = perspective => async (dispatch, getState) => {
    const appName = applicationSelectors.getCurrentAppName(getState())
    const modules = await dispatch(getAppModules(appName))
    const appModule = modules.find(v => v.Widgets.findIndex(config => config.Id === perspective.id) > -1)

    if (appModule) dispatch(push(buildRoute(routePaths.PERSPECTIVE, appName, appModule.Name, perspective.id)))
}

/**
 * Process the redux state based on a chosen module
 * @param {string} appName
 * @param {object} appModule chosen module config
 */
const processModule = (appName, appModule) => async dispatch => {
    const correctedModule = await correctModuleWithArchitectureIcons(appName, appModule)
    dispatch(themeCreators.updateAll(correctedModule.Theme))
    await dispatch(moduleSuccess(correctedModule))
    dispatch(userNavCreators.get())
}

/** Add icons from architecture to elements */
async function correctModuleWithArchitectureIcons(appName, appModule) {
    if (!appModule.Elements?.some(e => e.WidgetId == null)) return appModule

    const { elements } = await architectureService.getNetwork(appName)
    const elementsWithIcons = appModule.Elements.map(e => {
        if (e.WidgetId != null || e.IconPath) return e
        const architectNode = elements?.find(n => n.Name === e.ServiceName)
        if (!architectNode?.IconFileName) return e
        return { ...e, IconPath: architectNode.IconFileName }
    })

    return { ...appModule, Elements: elementsWithIcons }
}

/**
 * Fetches all modules for a given appname
 * @param {string} appName - the name of the app to fetch the modules for
 */
const getAppModules = (appName, basic, allDetails) => (dispatch, getState) => {
    const state = getState()
    const viewFlag = deploymentSelectors.getDeploymentViewFlag(state)

    dispatch(appModulesRequest())
    return Promise.allSettled([moduleService.getAll(appName, basic, allDetails)]).then(([visorModules]) => {
        let modules = []

        if (visorModules.status === 'fulfilled')
            modules = modules.concat(
                visorModules.value.map(m => ({
                    active: true,
                    appName: m.Model,
                    BackgroundFileToken: m.BackgroundFileToken,
                    createdAt: m.CreationDate,
                    createdBy: m.CreatedBy,
                    id: m.Id,
                    name: m.Name,
                    theme: m.Theme,
                    title: m.Title,
                    updatedAt: m.ModifiedDate,
                    updatedBy: m.ModifiedBy,
                    version: '1',
                    modules: m.Groups,
                }))
            )

        dispatch(appModulesSuccess(modules))
        return modules
    })
}

/** Change module based on name. CAUTION! Modules are controlled via url route, so you should probably not use this */
let _prevAppName = ''
const changeModule = moduleName => async (dispatch, getState) => {
    const state = getState()
    let getResources = false
    const appName = applicationSelectors.getCurrentAppName(state)
    if (appName !== _prevAppName) {
        _prevAppName = appName
        getResources = true
    }
    const moduleMeta = moduleSelectors.getAllModules(state)?.find(m => m.name === moduleName)
    const viewFlag = deploymentSelectors.getDeploymentViewFlag(state)
    if (!moduleMeta) return dispatch(moduleFailure('Application does not exist. Please check name'))

    dispatch(moduleChangeRequest())

    try {
        if (moduleMeta?.version === '2') {
            const appModule = await visualService.getModuleById(appName, moduleMeta.id, viewFlag)
            dispatch(themeCreators.updateAll(appModule.theme))
            dispatch({ type: moduleTypes.MODULE_2_SUCCESS, payload: { body: appModule, viewFlag } })
            dispatch(userNavCreators.get())
        } else {
            const appModule = await moduleService.getByName(appName, moduleName)
            const userWidgets = await widgetService.getUserWidgets(appName, appModule.Id)
            dispatch(setUserWidgets(userWidgets))
            dispatch(processModule(appName, appModule))
        }

        if (getResources) {
            dispatch(linksCreators.get(appName))
            dispatch(insightCreators.getList(appName))
        }
    } catch (error) {
        console.error(error)
        return dispatch(moduleFailure(error))
    }
}

const setUserWidgets = userWidgets => (dispatch, getState) => {
    const widgets = (userWidgets || []).reduce((acc, widget) => {
        acc[widget.Id] = widget
        return acc
    }, {})

    dispatch({ type: widgetTypes.SET_USER_WIDGETS, payload: { widgets } })
}

const reloadModule = () => async (dispatch, getState) => {
    const appName = applicationSelectors.getCurrentAppName(getState())
    const moduleName = moduleSelectors.getModuleName(getState())

    dispatch(moduleReloadRequest())
    dispatch(dialogCreators.showLoading('module-reload', 'Reloading your application...', { hideBackdrop: false }))

    try {
        const appModule = await moduleService.getByName(appName, moduleName)
        dispatch(dialogCreators.hideDialog('module-reload'))
        dispatch(processModule(appName, appModule))
    } catch (error) {
        dispatch(dialogCreators.hideDialog('module-reload'))
        dispatch(moduleFailure(error))
    }
}

/**
 * This is to do post-processing when a module is loaded and displayed to a user
 * This could be when an api request is successful or when a user closes/reopens the app
 * or refreshes a page, etc
 */

const appModuleChanged = isMobile => (dispatch, getState) => {
    const state = getState()
    dispatch(hubCreators.connect())
    dispatch(notificationCreators.getNotifications())
    dispatch(scenarioCreators.getUserScenario())
    dispatch(messengerCreators.getUserMessages())
    const appOptions = JSON.parse(moduleSelectors.getAppOptions(state))
    const isDisabledAutoLaunch = appOptions?.isAutoLaunchDisabled
    const mobileAutoLaunchPerspecitveId = appOptions?.autoLaunchPerspectiveId
    const applicationName = applicationSelectors.getCurrentAppName(state)
    const moduleName = moduleSelectors.getModuleName(state)
    dispatch(logoCreators.getModuleLogo(applicationName, moduleName))
    dispatch(tourCreators.showTourIfNew())

    const currentRoute = state.router.location.pathname
    const path = buildRoute(routePaths.HOME, applicationName, moduleName)
    const useCurrentRoute = !matchPath(path, currentRoute)
    if (useCurrentRoute) return

    const userAutoLaunchPerspective = authSelectors.getAutoLaunchPerspective(state)
    if (userAutoLaunchPerspective === '__home') return

    if (isMobile && isDisabledAutoLaunch) {
        if (mobileAutoLaunchPerspecitveId === '_home') return
        dispatch(navigationCreators.goToPerspective(mobileAutoLaunchPerspecitveId))
        return
    }

    const autoLaunchPerspectiveId = widgetSelectors.getAutoLaunchPerspectiveId(state)

    const autoLaunchId =
        userAutoLaunchPerspective && !!widgetSelectors.getWidgetConfig(state, userAutoLaunchPerspective)
            ? userAutoLaunchPerspective
            : autoLaunchPerspectiveId && !!widgetSelectors.getWidgetConfig(state, autoLaunchPerspectiveId)
            ? autoLaunchPerspectiveId
            : undefined
    if (!autoLaunchId) return

    dispatch(navigationCreators.goToPerspective(autoLaunchId))
}

const saveTheme = (selectedModule, draft, setSelectedModule, setDraft) => async (dispatch, getState) => {
    const state = getState()
    const appName = applicationSelectors.getCurrentAppName(state)
    const currentModule = moduleSelectors.getCurrentModule(state)
    const viewFlag = deploymentSelectors.getDeploymentViewFlag(state)

    const isCurrentModule = currentModule.id === selectedModule.id
    const { imageSrc, ...draftTheme } = draft
    const theme = draftTheme

    if (selectedModule.version === '2') {
        const updated = await updateModuleOrCreateDraft(appName, selectedModule.id, { theme }, viewFlag)
        if (updated.id !== selectedModule.id) {
            dispatch({ type: moduleTypes.MODULE_CREATE, payload: updated })
        } else {
            dispatch({ type: moduleTypes.ALL_MODULE_UPDATE, payload: { id: selectedModule.id, body: updated } })
            if (isCurrentModule) dispatch({ type: moduleTypes.CURRENT_MODULE_UPDATE, payload: { body: updated } })
        }
    } else {
        await moduleService.saveTheme(appName, selectedModule.id, theme)

        dispatch({
            type: moduleTypes.ALL_MODULE_UPDATE,
            payload: { id: selectedModule.id, body: { ...selectedModule, theme } },
        })

        if (isCurrentModule) {
            dispatch({
                type: moduleTypes.CURRENT_MODULE_UPDATE,
                payload: { body: { ...selectedModule, theme } },
            })
        }
    }

    setSelectedModule({ showSettings: true, data: { ...selectedModule, theme } })
    setDraft(theme)
    logEvent('UPDATE_THEME', { name: theme.name, custom: isCustomized(theme) })
}

/**
 * Updates module Autolaunch
 * @param {string} perspectiveId id of the perspective
 */
const saveAutoLaunch = perspectiveId => async (dispatch, getState) => {
    const widgetConfigs = widgetSelectors.getWidgetConfigs(getState())
    const autoLaunchPerspectiveConfig = Object.values(widgetConfigs).find(c => c.ContainerConfig?.AutoLaunch)

    const togglePerspectiveAutolaunch = config =>
        produce(config, draft => {
            draft.ContainerConfig.AutoLaunch = !draft.ContainerConfig.AutoLaunch
        })

    // turn off
    if (autoLaunchPerspectiveConfig)
        await dispatch(widgetCreators.saveWidget(togglePerspectiveAutolaunch(autoLaunchPerspectiveConfig)))

    if (autoLaunchPerspectiveConfig?.Id === perspectiveId) return

    // turn on
    const selectedConfig = widgetConfigs[perspectiveId]
    await dispatch(widgetCreators.saveWidget(togglePerspectiveAutolaunch(selectedConfig)))
}

/** Create a builder module where body.name is required */
const createModule = body => async (dispatch, getState) => {
    if (!body.name) return

    const appName = applicationSelectors.getCurrentAppName(getState())
    const viewFlag = deploymentSelectors.getDeploymentViewFlag(getState())
    const appModule = await visualService.createModule(appName, body, viewFlag)
    dispatch({ type: moduleTypes.MODULE_CREATE, payload: appModule })
}

const updateModule = (id, body, isV2) => async (dispatch, getState) => {
    const state = getState()
    const appName = applicationSelectors.getCurrentAppName(state)
    const viewFlag = deploymentSelectors.getDeploymentViewFlag(state)
    const currentModuleId = moduleSelectors.getModuleId(state)
    const currentModuleName = moduleSelectors.getModuleName(state)

    const isCurrentModule = currentModuleId === id

    if (isV2) {
        const updated = await updateModuleOrCreateDraft(appName, id, body, viewFlag)
        if (updated.id !== id) {
            dispatch({ type: moduleTypes.MODULE_CREATE, payload: updated })
        } else {
            dispatch({ type: moduleTypes.ALL_MODULE_UPDATE, payload: { id, body: updated } })
            if (isCurrentModule) dispatch({ type: moduleTypes.CURRENT_MODULE_UPDATE, payload: { body: updated } })
        }
    } else {
        const { name, title, hideNetworkDiagram, hideBusinessExplorer } = body

        const updated = {
            ...(name && { Name: name }),
            ...(title && { Title: title }),
            ...(hideNetworkDiagram != null && { ShowNetworkDiagram: !hideNetworkDiagram }),
            ...(hideBusinessExplorer != null && { ShowBusinessExplorer: !hideBusinessExplorer }),
        }

        await moduleService.update(appName, id, updated)

        dispatch({ type: moduleTypes.ALL_MODULE_UPDATE, payload: { id, body } })

        if (isCurrentModule) {
            dispatch({ type: moduleTypes.CURRENT_MODULE_UPDATE, payload: { body } })
        }
    }

    if (isCurrentModule) {
        if (body.name && body.name !== currentModuleName) dispatch(navigationCreators.goToModule(body.name))
    }
}

const updateCurrentModule = body => (dispatch, getState) => {
    const state = getState()
    const isV2 = moduleSelectors.getIsV2(state)
    const id = moduleSelectors.getModuleId(state)

    return dispatch(updateModule(id, body, isV2))
}

const deleteModule = id => async (dispatch, getState) => {
    const state = getState()
    const appName = applicationSelectors.getCurrentAppName(state)
    const currentModule = moduleSelectors.getCurrentModule(state)
    const viewFlag = deploymentSelectors.getDeploymentViewFlag(state)
    const defaultModuleName = authSelectors.getDefaultModule(state)
    const allModules = moduleSelectors.getAllModules(state)

    const moduleToDelete = allModules.find(m => m.id === id)
    const nextModule = allModules.find(m => m.id !== moduleToDelete.id)?.name

    try {
        await visualService.deleteModule(appName, id, viewFlag)

        if (allModules.length === 1) {
            const tempTitle = 'Temp App'
            const tempName = createSlug(
                tempTitle,
                allModules.map(m => m.name)
            )
            dispatch(moduleCreators.createModule({ title: tempTitle, name: tempName }))
            dispatch(authCreators.updateDefaultModule())
            dispatch(navigationCreators.goToModule(tempName))
        } else if (defaultModuleName === moduleToDelete.name && moduleToDelete.name === currentModule.name) {
            dispatch(authCreators.updateDefaultModule())
            dispatch(navigationCreators.goToModule(nextModule))
        } else if (defaultModuleName === moduleToDelete.name) {
            dispatch(authCreators.updateDefaultModule())
        } else if (moduleToDelete.name === currentModule.name) {
            dispatch(navigationCreators.goToModule(nextModule))
        }

        dispatch({ type: moduleTypes.MODULE_DELETE, payload: { id } })
    } catch (error) {
        console.error(error)
    }
}

const updateModuleOptions = options => (dispatch, getState) => {
    const currentModule = moduleSelectors.getCurrentModule(getState())
    dispatch({
        type: moduleTypes.ALL_MODULE_UPDATE,
        payload: { id: options.visorId, body: { ...currentModule, appOptions: options.appOptions } },
    })
    dispatch({
        type: moduleTypes.CURRENT_MODULE_UPDATE,
        payload: { body: { ...currentModule, appOptions: options.appOptions } },
    })
}

export const moduleCreators = {
    appModuleChanged,
    changeModule,
    createModule,
    deleteModule,
    getAppModules,
    getModuleWithPerspective,
    moduleUpdateDismissed,
    reloadModule,
    saveAutoLaunch,
    saveTheme,
    updateDetected,
    updateModule,
    updateCurrentModule,
    toggleMigration,
    processModule,
    updateModuleOptions,
}
