import { MouseEventHandler, useContext, useEffect, useMemo, useRef, useState } from 'react'
import Measure from 'react-measure'
import classNames from 'classnames'
import { startCase } from 'lodash'
import { useDispatch } from 'react-redux'
import { useDebouncedCallback } from 'use-debounce'

import {
    Box,
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    IconButton,
    ListItemButton,
    ListItemText,
    MenuItem,
    Select,
    Tooltip,
    Typography,
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import {
    AddCircleOutlineRounded,
    CloseRounded,
    ExpandMoreRounded,
    DragIndicator as DragIcon,
    AccessTime,
} from '@mui/icons-material'

import { sleep } from 'genesis-suite/utils'
import { EditBox, Trash } from 'genesis-suite/icons'
import { Property } from 'genesis-suite/types/networkTypes'
import { DragItem, DragList, SwalContext } from 'genesis-suite/components'

import useResourceMeta from '~/hooks/useResourceMeta'
import { widgetCreators } from '../../actions/creators'
import { PerspectiveContext } from '../contexts/PerspectiveContext'
import { computeHiddenCount, getRageOffSetForTimePeriod } from '~/lib/utils'

import MenuButton from '../MenuButton'
import ConfigureAction from './ConfigureAction'
import WidgetActionButton from './WidgetActionButton'
import WidgetActionMenuItem from './WidgetActionMenuItem'
import TemporalDateSelect, { temporalDateSelectOptions } from '../TemporalDateSelect'

const useStyles = makeStyles(({ spacing, palette }) => ({
    root: { marginTop: spacing(0.5) },
    actionDialog: { '& .MuiDialog-paper': { width: '100%', maxWidth: 400, paddingBottom: spacing(1.8) } },
    widgetModifyDialog: { '& .MuiDialog-paper': { width: '100%', maxWidth: 400, paddingBottom: spacing(1.8) } },
    actionHeader: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        padding: spacing(0.5, 1),
        justifyContent: 'space-between',
    },
    hideIcon: {
        display: 'none',
    },
    ConfigureButtonIconStyle: { color: palette.text.primary },
    icon: {
        fill: palette.text.primary,
    },
}))

const generateKey = () => Math.random().toString(36).substr(2, 9)

export default function WidgetFooterController({
    config,
    isEditing,
    visualRef,
    filters,
    inlineFilters,
    networkContext,
    temporalConfig,
    onUpdate,
    categoryDateFormat,
    showTemporalSelector,
    originalCategoryPropertyName,
}) {
    const classes = useStyles({})
    const dispatch = useDispatch()
    const parentRef = useRef<HTMLDivElement>(null)

    const { confirm } = useContext(SwalContext)
    const { filterByWidgetId, setFilterByWidgetId } = useContext(PerspectiveContext)

    const { Actions, version, Type } = config
    const restProps = { config, filters, inlineFilters, networkContext }

    const categoryPropertyName = temporalConfig?.ChartConfig?.LabelField
    const { ElementType, ElementName } = temporalConfig?.ChartConfig?.Source || {}

    const options = useOptions(ElementType, ElementName, categoryPropertyName)

    const [open, setOpen] = useState(false)
    const [layingOut, setLayingOut] = useState(true)
    const [recompute, setRecompute] = useState(false)
    const [hiddenCount, setHiddenCount] = useState(0)
    const [editAction, setEditAction] = useState(null)
    const [actions, setActions] = useState(Actions ? Actions.map(a => ({ ...a, id: generateKey() })) : [])

    const categoryOption = options?.find(o => o.displayName === 'Default')

    let emptyFilter: any = {
        IsTemporal: true,
        Values: [],
        DisplayValues: [],
        RangeOffset: {},
        UseLastRefreshDate: false,
        Operator: 'EqualTo',
        PostCalculation: false,
    }

    if (categoryOption?.container) {
        emptyFilter = {
            ...emptyFilter,
            FilterName: `search|${categoryOption.name}`,
            ResourceName: categoryOption.container.name,
            ResourceType: categoryOption.container.type,
            PropertyName: categoryOption.name,
        }
    }

    function handleUpdate(labelField) {
        onUpdate({
            ...temporalConfig,
            ChartConfig: {
                ...temporalConfig.ChartConfig,
                LabelField: labelField,
                CategoryDateFormat: labelField === originalCategoryPropertyName ? categoryDateFormat : null,
            },
        })
    }

    function handleFilterUpdate(id) {
        if (id === '0') {
            setFilterByWidgetId(s => ({
                ...s,
                [temporalConfig.Id]: { ...filterByWidgetId?.[temporalConfig.Id], temporal: [] },
            }))
        } else {
            const selectedOption = temporalDateSelectOptions.find(obj => obj.id === id)
            emptyFilter['RangeOffset'] = getRageOffSetForTimePeriod(selectedOption?.id)
            setFilterByWidgetId(s => ({
                ...s,
                [temporalConfig.Id]: { ...filterByWidgetId?.[temporalConfig.Id], temporal: [emptyFilter] },
            }))
        }
    }

    useEffect(() => {
        if (editAction && !open) setOpen(true)
    }, [editAction])

    useEffect(() => {
        if (Type === 'Chart') visualRef.current?.chart?.reflow()
    }, [isEditing])

    const [newDialogOpen, setNewDialogOpen] = useState(false)
    const handleResize = useDebouncedCallback(resetLayout, 500)

    useEffect(() => {
        let hiddenCount = computeHiddenCount(parentRef, 1, 32)
        const count2Show = parentRef.current?.children?.length - 1 - hiddenCount
        hiddenCount = actions.length - count2Show
        setHiddenCount(hiddenCount)
        setLayingOut(false)
    }, [recompute, options.length, showTemporalSelector])

    const visibleItems = useMemo(() => {
        return layingOut ? actions : actions.slice(0, actions.length - hiddenCount)
    }, [layingOut, actions, hiddenCount])
    const hiddenItems = useMemo(() => actions.slice(-hiddenCount), [actions, hiddenCount])

    if (!actions.length && options.length <= 1 && !isEditing) return null

    const handleSave = newActions => {
        const Actions = newActions.map(a => {
            const { id, ...rest } = a
            return rest
        })
        //@ts-ignore
        return dispatch(widgetCreators.saveWidget({ ...config, Actions })).then(() => {
            setActions(newActions)
            reset()
        })
    }

    async function resetLayout() {
        setLayingOut(true)
        await sleep()
        setRecompute(s => !s)
    }

    const reset = () => {
        setOpen(false)
        setEditAction(null)
    }
    const handleClick: MouseEventHandler<HTMLDivElement> = event => {
        event.stopPropagation()
    }

    const handleDragEnd = newItems => {
        setActions(newItems)
    }

    const handleAddNew = () => {
        setEditAction({ id: generateKey() })
    }

    const openNewDialog = () => {
        setNewDialogOpen(true)
    }

    const closeNewDialog = () => {
        setNewDialogOpen(false)
    }

    const handleClose = async () => {
        const response = await confirm('You will lose all changes on your action, continue?')
        if (response.dismiss) return
        reset()
    }

    const confirmDelete = async id => {
        const response = await confirm('Delete this action?')
        if (response.dismiss) return
        const newActions = actions.filter(a => a.id !== id)

        return handleSave(newActions)
    }

    const handleSubmit = actionDraft => {
        const editing = actions.find(a => a.id === actionDraft.id)
        const newActions = editing
            ? actions.map(a => {
                  if (a.id === actionDraft.id) return actionDraft
                  return a
              })
            : [...actions, actionDraft]
        return handleSave(newActions)
    }

    const itemHeight = 50
    const itemWidth = 350
    return (
        <Box
            className={classes.root}
            sx={{
                width: '100%',
                display: 'flex',
                minHeight: '25px',
                alignItems: 'center',
                justifyContent: 'flex-start',
                gap: 1,
            }}
        >
            {options.length > 1 && showTemporalSelector && (
                <Box
                    sx={{
                        minWidth: '120px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <AccessTime />
                    <Select
                        onChange={e => handleUpdate(e.target.value as string)}
                        sx={theme => ({
                            '& .MuiSelect-select': {
                                pl: 1,
                                py: 0.2,
                            },
                            '& .MuiOutlinedInput-notchedOutline': {
                                borderColor: theme.palette.text.primary,
                            },
                            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                borderColor: theme.palette.text.primary,
                            },
                        })}
                        value={categoryPropertyName}
                        variant="outlined"
                        inputProps={{
                            classes: {
                                icon: classes.icon,
                            },
                        }}
                    >
                        {options.map(option => (
                            <MenuItem key={option.name} value={option.name}>
                                {option.displayName}
                            </MenuItem>
                        ))}
                    </Select>
                </Box>
            )}

            <Measure client innerRef={parentRef} onResize={handleResize}>
                {({ measureRef }) => (
                    <Box
                        ref={measureRef}
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'flex-start',
                            width: options.length > 1 ? 'calc(100% - 220px)' : '90%',
                        }}
                    >
                        {visibleItems.map(action => (
                            <WidgetActionButton
                                key={action.id}
                                action={action}
                                onClick={isEditing ? setEditAction : null}
                                hidden={layingOut}
                                {...restProps}
                            />
                        ))}

                        <Box sx={{ ml: 1 }}>
                            {hiddenCount > 0 && (
                                <MenuButton title={<ExpandMoreRounded />}>
                                    {hiddenItems.map(a => (
                                        <WidgetActionMenuItem key={a.id} action={a} {...restProps} />
                                    ))}
                                </MenuButton>
                            )}
                            {isEditing && (
                                <Tooltip title="Configure Widget Actions">
                                    {/* z-index set higher than widget edit cover */}
                                    <IconButton
                                        sx={{ zIndex: 3 }}
                                        onClick={() => {
                                            openNewDialog()
                                        }}
                                    >
                                        <EditBox className={classes.ConfigureButtonIconStyle} />
                                    </IconButton>
                                </Tooltip>
                            )}
                        </Box>
                    </Box>
                )}
            </Measure>

            {options.length > 1 && (
                <Box sx={{ minWidth: '90px', marginLeft: 'auto' }}>
                    <TemporalDateSelect handleFilterUpdate={handleFilterUpdate} />
                </Box>
            )}

            <Dialog
                className={classNames('noDrag', classes.widgetModifyDialog)}
                open={newDialogOpen}
                onClose={closeNewDialog}
            >
                <DialogTitle className={classes.actionHeader}>
                    <Typography variant="h6">Configure Actions</Typography>
                    <IconButton onClick={closeNewDialog}>
                        <CloseRounded />
                    </IconButton>
                </DialogTitle>
                <DialogContent onClick={handleClick}>
                    {actions.length ? (
                        <>
                            <ListItemText
                                sx={{ pl: 1 }}
                                primary="Actions in this box will appear as buttons on the widget."
                            />
                            <Box
                                sx={{
                                    position: 'absolute',
                                    bgcolor: 'grayscale.lighter',
                                    border: 1,
                                    borderRadius: 1,
                                    height: itemHeight * Math.min(actions.length, 3),
                                    width: itemWidth,
                                }}
                            />
                        </>
                    ) : (
                        <ListItemText primary='No actions found. Click "New Action" to add.' />
                    )}
                    <DragList onDragEnd={handleDragEnd} items={actions}>
                        {actions.map((action, index) => (
                            <WidgetActionDragItem
                                key={action.id}
                                onClick={() => setEditAction(action)}
                                width={itemWidth}
                                height={itemHeight}
                                action={action}
                                index={index}
                                onDelete={confirmDelete}
                            />
                        ))}
                    </DragList>
                </DialogContent>
                <div style={{ marginLeft: '16px' }}>
                    <Tooltip title="Add New">
                        <Button
                            onClick={handleAddNew}
                            variant="outlined"
                            color="secondary"
                            startIcon={<AddCircleOutlineRounded />}
                        >
                            New Action
                        </Button>
                    </Tooltip>
                </div>
            </Dialog>

            <Dialog className={classes.actionDialog} open={open} onClose={handleClose}>
                <div className={classes.actionHeader}>
                    <Typography variant="h6">Configure Action</Typography>
                    <IconButton onClick={handleClose}>
                        <CloseRounded />
                    </IconButton>
                </div>
                <DialogContent>
                    <ConfigureAction
                        action={editAction}
                        onSubmit={handleSubmit}
                        onDelete={editAction?.ActionName ? confirmDelete : null}
                    />
                </DialogContent>
            </Dialog>
        </Box>
    )
}

const WidgetActionDragItem = ({ action, index, onDelete, height, width, onClick }) => {
    return (
        <DragItem key={action.id} itemId={action.id} index={index}>
            <Tooltip title="Click to Edit" placement="top">
                <ListItemButton
                    sx={{
                        width,
                        height,
                        padding: '8px',
                        display: 'flex',
                        borderRadius: '4px',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        border: '1px solid transparent',
                        transition: 'all 350ms ease',
                        '& .hide': {
                            opacity: 0,
                            transition: 'all 350ms ease',
                        },
                        '&:hover': {
                            borderColor: 'black',
                            '& .hide': { opacity: 1 },
                        },
                    }}
                    onClick={onClick}
                >
                    <Typography variant="h6">{action.ShortName}</Typography>
                    <DragIcon className="hide" sx={{ cursor: 'grab' }} />
                    <IconButton
                        className="hide"
                        size="small"
                        onClick={e => {
                            e.stopPropagation()
                            onDelete(action.id)
                        }}
                        sx={{ color: 'text.primary' }}
                    >
                        <Trash />
                    </IconButton>
                </ListItemButton>
            </Tooltip>
        </DragItem>
    )
}

interface Option extends Property {
    displayName: string
}

function useOptions(resourceType, resourceName, categoryPropertyName): Option[] {
    const [metaDataSource] = useResourceMeta(resourceType, resourceName)

    if (!metaDataSource?.properties) return []

    let categoryInsightProperty = metaDataSource.properties.find(n => n.name == categoryPropertyName)

    if (categoryInsightProperty?.referenceAttributeId) {
        categoryInsightProperty = metaDataSource?.properties?.find(
            n => n.id == categoryInsightProperty.referenceAttributeId
        )
    }

    return metaDataSource.properties
        ?.filter(
            p =>
                (p != null &&
                    p.computed &&
                    categoryInsightProperty?.id &&
                    p.referenceAttributeId == categoryInsightProperty?.id &&
                    !p.name.toLowerCase().endsWith('halfyearname')) ||
                p.id === categoryInsightProperty?.id
        )
        ?.map(p => ({
            ...p,
            displaySortOrder: getTemporalPropertyOrder(p.name, categoryInsightProperty?.name),
            displayName: startCase(getTemporalPropertyName(p.name, categoryInsightProperty?.name)),
        }))
        ?.sort((a, b) => {
            if (a.displaySortOrder > b.displaySortOrder) return 1
            if (a.displaySortOrder < b.displaySortOrder) return -1
            return 0
        })
}

function getTemporalPropertyName(temporalPropertyName, referencePropertyName) {
    if (!temporalPropertyName || temporalPropertyName.length < 1) return 'Default'
    if (!referencePropertyName || referencePropertyName.length < 1) return temporalPropertyName
    if (temporalPropertyName === referencePropertyName) return 'Default'
    return temporalPropertyName.substring(referencePropertyName.length)
}

function getTemporalPropertyOrder(temporalPropertyName, referencePropertyName) {
    if (!temporalPropertyName || temporalPropertyName.length < 1) return 0
    if (!referencePropertyName || referencePropertyName.length < 1) return 0
    temporalPropertyName = temporalPropertyName.substring(referencePropertyName.length)

    switch (temporalPropertyName.toLowerCase()) {
        case 'year':
            return 1
        case 'halfyear':
            return 2
        case 'quarter':
            return 3
        case 'quartername':
            return 4
        case 'monthyear':
            return 5
        case 'month':
            return 6
        case 'monthname':
            return 7
        case 'fullmonthname':
            return 8
        case 'week':
            return 9
        case 'isoweek':
            return 10
        case 'day':
            return 11
        case 'hour':
            return 12
        case 'minute':
            return 13
        case 'second':
            return 14
        default:
            return 0
    }
}
