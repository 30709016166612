import { Autocomplete, Checkbox, TextField } from '@mui/material'
import { ResourceType } from 'genesis-suite/types/networkTypes'
import { useContext, useEffect, useState } from 'react'
import useResourceMeta from '~/hooks/useResourceMeta'
import { ConfigContext } from '../ConfigContext'
import FieldLabel from './FieldLabel'

export default function DynamicFilterEditor() {
    const { configStructure, dispatch, insights, selectedInsights, selectedField, config } = useContext(ConfigContext)

    const dynamicFilterFields = configStructure?.DynamicFiltersConfig
    const fieldsToOmit = ['Title']
    const [sourceName, setSourceName] = useState('')
    const [insight] = useResourceMeta(ResourceType.INSIGHT, sourceName)

    useEffect(() => {
        if (selectedField?.type !== 'dynamicFilter') return

        const activeFilter = config?.DynamicFiltersConfig[selectedField.index]
        setSourceName(activeFilter?.Source?.ElementName)
    }, [selectedField])

    if (dynamicFilterFields?.length === 0 || selectedField?.type !== 'dynamicFilter') return null

    const activeFilter = config?.DynamicFiltersConfig[selectedField.index]
    let properties = []

    const updateActiveAction = (key, value) => {
        dispatch({ type: 'UPDATE_SELECTED_DYNAMIC_FILTER', payload: { [key]: value } })
    }

    const unusedInsights = insights?.filter(({ id }) => !selectedInsights.some(i => i.id === id))
    const allInsight = selectedInsights?.map(i => i)
    properties = insight?.properties?.map(p => ({
        name: p.name,
        id: p.id,
    }))
    const sortedDynamicConfig = [...dynamicFilterFields].sort(
        (a, b) => (a.DisplayOrder ?? Infinity) - (b.DisplayOrder ?? Infinity)
    )
    return (
        <>
            {sortedDynamicConfig.map((field, index) => {
                if (fieldsToOmit.includes(field.Name)) return null

                if (field.IsSource) {
                    return (
                        <div key={index}>
                            <FieldLabel field={field} />
                            <Autocomplete
                                fullWidth
                                options={unusedInsights}
                                isOptionEqualToValue={(option, value) => option?.name == value?.name}
                                getOptionLabel={option => option.name}
                                value={
                                    unusedInsights.find(p => p.name === activeFilter[field.Name]?.ElementName) ?? null
                                }
                                onChange={(_, newValue) => {
                                    updateActiveAction(field.Name, {
                                        ElementName: newValue?.name,
                                        ElementType: ResourceType.INSIGHT,
                                        Filters: '',
                                    })
                                    setSourceName(newValue?.name)
                                }}
                                renderInput={params => (
                                    <TextField
                                        variant="outlined"
                                        size="small"
                                        margin="normal"
                                        {...params}
                                        sx={{ my: 0 }}
                                    />
                                )}
                            />
                        </div>
                    )
                } else if (field.IsSourceProperty) {
                    return (
                        <div key={index}>
                            <FieldLabel field={field} />
                            <Autocomplete
                                options={properties}
                                value={properties?.find(p => p.name === activeFilter[field.Name]) ?? null}
                                isOptionEqualToValue={(option, value) => option?.name == value?.name}
                                getOptionLabel={option => option.name}
                                onChange={(_, newValue) => {
                                    updateActiveAction('PropertyId', newValue?.id)
                                    updateActiveAction(field.Name, newValue?.name)
                                }}
                                renderInput={params => (
                                    <TextField
                                        {...params}
                                        variant="outlined"
                                        size="small"
                                        margin="normal"
                                        sx={{ my: 0 }}
                                    />
                                )}
                                disabled={!insight}
                            />
                        </div>
                    )
                } else if (field.Type.includes('Boolean')) {
                    return (
                        <div key={index} style={{ display: 'flex', alignItems: 'center' }}>
                            <div
                                key={index}
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                            >
                                <Checkbox
                                    onChange={e => updateActiveAction(field.Name, e.target.checked)}
                                    name={field.Name}
                                    color="primary"
                                    defaultChecked={false}
                                    checked={activeFilter[field.Name] ?? false}
                                    inputProps={{ 'aria-label': `switch-${index}` }}
                                />
                                <FieldLabel field={field} />
                            </div>
                        </div>
                    )
                } else if (field.Type.includes('Single')) {
                    return (
                        <div key={index}>
                            <FieldLabel field={field} />
                            <TextField
                                variant="outlined"
                                id="outlined-number"
                                onChange={e => updateActiveAction(field.Name, e.target.value)}
                                type="number"
                                inputProps={{ min: 0 }}
                                value={activeFilter[field.Name] ?? 0}
                            />
                        </div>
                    )
                }

                return null
            })}
        </>
    )
}
