import React, { Fragment, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useDebouncedCallback } from 'use-debounce'
import classNames from 'classnames'
import { debounce } from 'lodash'
import moment from 'moment'

import makeStyles from '@mui/styles/makeStyles'
import {
    CloseRounded,
    ArrowDownwardRounded,
    PersonAddRounded,
    EditRounded,
    CheckRounded,
    GroupRounded,
    LaunchRounded,
    QuestionAnswerRounded,
} from '@mui/icons-material'

import {
    Autocomplete,
    Avatar,
    AvatarGroup,
    Badge,
    Box,
    Divider,
    Fab,
    IconButton,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Popover,
    TextField,
    Tooltip,
    Typography,
} from '@mui/material'

import { TextEditorV2 } from 'genesis-suite/components'
import { messageService, userService } from '~/lib/services'
import { messengerSelectors } from '~/selectors/messenger.selectors'
import { messengerCreators } from '~/actions/creators/messenger.creators'
import {
    ChatMessagesRequest,
    UsersRequest,
    Message,
    ChatsAddParticipantRequest,
    ChatsDeleteParticipantRequest,
} from 'genesis-suite/types/visualTypes'
import {
    applicationSelectors,
    authSelectors,
    collaborationSelectors,
    moduleSelectors,
    widgetSelectors,
} from '~/selectors'

import Markdown from '../Markdown'
import {
    MessageConversationType,
    MessageLoadDirection,
    MessageParticipantType,
    MessageTagItemType,
} from '~/types/messageTypes'

import { ConversationGroupName } from './MessagesBox'
import { CircularSpinner } from './MessageBar'
import { navigationCreators } from '~/actions/creators'

const useStyles = makeStyles(({ palette }) => ({
    chatContent: {
        color: '#000',
    },
    messageList: {
        overflowY: 'auto',
        overflowX: 'hidden',
        maxHeight: '335px',
        minHeight: '335px',
        marginTop: '6px',
    },
    messageBox: {
        display: 'flex',
        justifyContent: 'center',
        gap: '10px',
        position: 'absolute',
        bottom: '5px',
        width: '100%',
        padding: '0px 8px',
    },
    chatBubble: {
        display: 'flex',
        alignItems: 'flex-start',
        padding: '10px',
        marginBottom: '8px',
        width: 'max-content',
        maxWidth: 'calc(100% - 50px)',
        marginLeft: '5px',
        borderRadius: '0px 10px 10px 10px',
        backgroundColor: palette.primary.main,
        color: palette.primary.contrastText,
        '& .mention': {
            fontWeight: 'bold',
            backgroundColor: palette.secondary.main,
            color: palette.secondary.contrastText,
            borderRadius: '4px',
            padding: '0px 2px',
        },
        '& p': {
            margin: 0,
        },
        '& pre': {
            margin: 0,
            whiteSpace: 'normal',
        },
    },
    chatBubbleRight: {
        marginLeft: 'auto',
        marginRight: '5px',
        borderRadius: '10px 0 10px 10px',
        backgroundColor: palette.secondary.main,
        color: palette.secondary.contrastText,
        '& .mention': {
            backgroundColor: palette.primary.main,
            color: palette.primary.contrastText,
        },
    },
    chatGroupStyle: {
        display: 'flex',
        marginLeft: '8px',
    },
    chatDate: {
        display: 'flex',
        justifyContent: 'flex-start',
    },
    chatDateRight: {
        justifyContent: 'flex-end',
    },
    chatUnread: {
        color: 'red',
        '&::before, &::after': {
            borderTop: '1px solid',
        },
    },
}))

const ChatBox = ({ classes, conversationData, onClose }) => {
    const chatClasses = useStyles()
    const chatRef = useRef(null)
    const dispatch = useDispatch()

    const appInfo = useSelector(applicationSelectors.getAppInfo)
    const tModule = useSelector(moduleSelectors.getCurrentModule)
    const chatList = useSelector(messengerSelectors.getMessageList)
    const { userId } = useSelector(authSelectors.getUser)
    const { cloudId, appId, partitionId: modelPartId, appName } = appInfo
    const appDomainId = useSelector(state => applicationSelectors.getAppDomainId(state, appName))
    const messages = useSelector(messengerSelectors.getChatByUserId(conversationData?.id))
    const users = useSelector(collaborationSelectors.collaborators)
    const userList = useSelector(messengerSelectors.getUserList)

    const [unReadCount, setUnReadCount] = useState(0)
    const [isLoading, setIsLoading] = useState(false)
    const [isLoadingBelow, setIsLoadingBelow] = useState(false)
    const [showScroll, setShowScroll] = useState(false)
    const paginationRef = useRef({
        loadCount: 10,
        hasMoreUp: true,
        hasMoreDown: true,
    })

    const [isOpen, setIsOpen] = useState(true)
    const [openClass, setOpenClass] = useState(true)

    const checkStatus = useCallback((screenAlias: string) => users.some(user => user.name === screenAlias), [users])

    const isGroup = conversationData?.type !== MessageConversationType.DIRECTMESSAGE
    const isOnline = !isGroup ? checkStatus(conversationData?.user?.ScreenAlias) : false

    const getNewChat = useDebouncedCallback(direction => getChatMessages(false, direction), 500)
    const updateConversation = useDebouncedCallback(messageId => updateLastReadMessage(messageId), 5000)

    const scrollToBottom = useDebouncedCallback((snap = false) => {
        chatRef.current.scrollTo({
            top: chatRef.current.scrollHeight,
            behavior: snap ? 'auto' : 'smooth',
        })
    }, 200)

    const scrollToId = useDebouncedCallback((id, snap = true) => {
        const element = document.getElementById(id)
        if (element) {
            element.scrollIntoView(snap)
        }
    }, 200)

    const allParticipants = useMemo(() => {
        if (!isGroup) return
        return userList.filter(user =>
            conversationData?.participants.some(
                participant =>
                    participant.type !== MessageParticipantType.ITEM &&
                    participant.id !== userId &&
                    user.UserId === participant.id
            )
        )
    }, [userList, conversationData])

    const chatUserOptions = useMemo(() => {
        if (!isGroup) return []

        const participantIds = new Set(conversationData?.participants.map(p => p.id))
        return userList
            .filter(user => !participantIds.has(user.UserId) && user.UserId !== userId && user.DomainId !== '')
            .map(user => ({
                label: user?.ScreenAlias,
                value: user?.UserId,
                id: user?.UserId,
            }))
    }, [userList, conversationData])

    const checkScroll = () => {
        if (chatRef.current) {
            const { scrollTop, scrollHeight, clientHeight } = chatRef.current

            if (scrollTop < scrollHeight - clientHeight - 200) {
                setShowScroll(true)
            } else {
                setShowScroll(false)
            }

            if (scrollTop <= 100 && !isLoading && paginationRef.current.hasMoreUp) {
                getNewChat(MessageLoadDirection.UP)
            }

            if (
                scrollTop + clientHeight >= scrollHeight - 100 &&
                !isLoadingBelow &&
                paginationRef.current.hasMoreDown
            ) {
                getNewChat(MessageLoadDirection.DOWN)
            }
        }
    }

    useEffect(() => {
        if (messages.length > 0 && !showScroll && chatRef.current) {
            const { scrollTop, scrollHeight, clientHeight } = chatRef.current
            if (scrollTop < scrollHeight - clientHeight) {
                setShowScroll(true)
            }
        }
    }, [messages])

    useEffect(() => {
        setTimeout(() => setOpenClass(false), 700)
        getChatMessages(true)

        if (chatRef.current) {
            chatRef.current.addEventListener('scroll', checkScroll)
        }
        return () => {
            if (chatRef.current) {
                chatRef.current.removeEventListener('scroll', checkScroll)
            }
        }
    }, [])

    useEffect(() => {
        if (!messages.length) return

        const handleVisibility = () => {
            if (!chatRef.current) return

            const container = chatRef.current
            const containerRect = container.getBoundingClientRect()
            const children = container.children

            let lastVisible = null

            Array.from(children).forEach(child => {
                const childRect = (child as HTMLElement).getBoundingClientRect()
                if (childRect.top >= containerRect.top && childRect.bottom <= containerRect.bottom) {
                    lastVisible = JSON.parse(JSON.stringify((child as HTMLElement).dataset))
                }
            })

            if (lastVisible?.isunread === 'true' && lastVisible.messageid) {
                updateConversation(lastVisible.messageid)
            }
        }

        const handleScroll = () => {
            handleVisibility()
        }

        const container = chatRef.current

        if (container?.scrollHeight > container.clientHeight) {
            container.addEventListener('scroll', handleScroll)
        }

        handleVisibility()
        return () => {
            container?.removeEventListener('scroll', handleScroll)
        }
    }, [messages])

    const toggleChatBox = () => {
        setIsOpen(!isOpen)
    }

    const handleClose = () => {
        onClose(conversationData?.id)
        const { chatData } = conversationData
        if (chatData?.lastReadMessage?.id !== chatData?.latestMessage?.id) {
            updateLastReadMessage(messages.at(-1)?.id)
        }
    }

    async function updateLastReadMessage(messageId) {
        if (!isLoading && messageId) {
            const request = {
                conversationId: conversationData?.id,
                messageId: messageId,
            }
            dispatch(messengerCreators.updateUserConversation(request?.conversationId, request?.messageId))
        }
    }

    async function getChatMessages(isInitial = false, direction = MessageLoadDirection.BOTH) {
        if (!isLoading) {
            if (isInitial || direction === MessageLoadDirection.UP) {
                setIsLoading(true)
            }
            if (direction === MessageLoadDirection.DOWN) {
                setIsLoadingBelow(true)
            }

            const tempPagination = { ...paginationRef.current }
            const chatReq: ChatMessagesRequest = {
                domainId: appDomainId,
                conversationId: conversationData?.id,
                loadCount: tempPagination.loadCount,
                direction: direction,
                ...(direction === MessageLoadDirection.UP && messages.length > 0
                    ? { messageId: messages.at(0)?.id }
                    : {}),
                ...(direction === MessageLoadDirection.DOWN && messages.length > 0
                    ? { messageId: messages.at(-1)?.id }
                    : {}),
            }

            const chatMessages = await messageService.getChatMessages(chatReq).finally(() => {
                setIsLoading(false)
                setIsLoadingBelow(false)
            })

            if (isInitial && direction === MessageLoadDirection.BOTH) {
                const lastReadId = conversationData?.chatData?.lastReadMessage?.id

                let finalMessages = []
                const updatedPagination = { ...tempPagination }

                if (lastReadId) {
                    const splitIndex = chatMessages.findIndex(item => item.id === lastReadId)
                    const readMessages = chatMessages.slice(0, splitIndex).reverse()
                    const unreadMessages = chatMessages.slice(splitIndex).map((item, index) => ({
                        ...item,
                        unRead: index > 0,
                    }))

                    updatedPagination.hasMoreUp = readMessages.length >= tempPagination?.loadCount
                    updatedPagination.hasMoreDown = unreadMessages.length >= tempPagination?.loadCount + 1

                    finalMessages = [...readMessages, ...unreadMessages]
                } else {
                    const unreadMessages = chatMessages.map(item => ({
                        ...item,
                        unRead: true,
                    }))

                    updatedPagination.hasMoreUp = false
                    updatedPagination.hasMoreDown = unreadMessages.length >= tempPagination?.loadCount

                    finalMessages = [...unreadMessages]
                }

                if (lastReadId) scrollToId(lastReadId)

                paginationRef.current = updatedPagination
                dispatch(messengerCreators.updateUserChatsList(finalMessages, conversationData.id))
            }

            if (direction === MessageLoadDirection.UP) {
                const updatedPagination = { ...tempPagination }

                if (chatMessages.length < updatedPagination.loadCount) {
                    updatedPagination.hasMoreUp = false
                }

                if (chatMessages.length) scrollToId(messages[0]?.id)

                paginationRef.current = updatedPagination
                dispatch(
                    messengerCreators.updateUserChatsList([...chatMessages.reverse(), ...messages], conversationData.id)
                )
            }

            if (direction === MessageLoadDirection.DOWN) {
                const updatedPagination = { ...tempPagination }

                const unreadMessages = chatMessages.map(item => ({
                    ...item,
                    unRead: true,
                }))

                if (unreadMessages.length < updatedPagination.loadCount) {
                    updatedPagination.hasMoreDown = false
                }

                paginationRef.current = updatedPagination
                dispatch(messengerCreators.updateUserChatsList([...messages, ...unreadMessages], conversationData.id))
            }

            // setUnReadCount(2) // Add unread or New message
        }
    }

    const onSendMessage = message => {
        const processedData = extractDataFromHtml(message, userId)

        const messageRequest: Message = {
            id: createGuid(),
            sender: userId,
            conversationId: conversationData?.id,
            content: processedData?.transformedHtml,
            cloudId,
            modelPartId,
            appId,
            portalId: tModule.id,
            domainId: appDomainId,
            tags: processedData?.tags,
            mentions: processedData?.mentions,
        }

        messageService.sendMessage(messageRequest)
        dispatch(
            messengerCreators.pushMessageToUserChat(
                {
                    ...messageRequest,
                    createdAt: new Date().toISOString(),
                    senderId: userId,
                    conversationId: messageRequest.conversationId,
                },
                messageRequest.conversationId
            )
        )

        scrollToBottom()

        const existingChat = chatList.find(chat => chat.id === conversationData?.id)
        const chatMessage = {
            ...existingChat,
            id: conversationData?.id,
            participants: conversationData?.participants,
            type: conversationData?.type,
            latestMessage: { ...messageRequest, createdAt: new Date().toISOString() },
        }

        let newChatList = chatList.filter(chat => !(chat.id === conversationData?.id))
        newChatList = [chatMessage, ...newChatList]
        dispatch(messengerCreators.setUserMessages(newChatList))
    }

    const onAddParticipants = async user => {
        const updateReq: ChatsAddParticipantRequest = {
            domainId: appDomainId,
            id: conversationData?.id,
            body: { id: user?.value, type: MessageParticipantType.USER },
        }

        const conversationDetail = await messageService.addParticipantToChat(updateReq)
        dispatch(messengerCreators.updateConversationParticipants(conversationDetail))
    }

    const onDeleteParticipants = async user => {
        const updateReq: ChatsDeleteParticipantRequest = {
            domainId: appDomainId,
            id: conversationData?.id,
            participantId: user?.UserId,
        }

        const conversationDetail = await messageService.deleteParticipantFromChat(updateReq)
        dispatch(messengerCreators.updateConversationParticipants(conversationDetail))
    }

    return (
        <Box
            className={classNames(classes.chatBoxContainer, {
                [classes.chatBoxOpen]: isOpen,
                'slide-in-bottom': openClass,
            })}
        >
            <Box className={classNames(classes.chatBoxToggle, classes.chatHeader)} onClick={toggleChatBox}>
                <UserAvatars
                    isOnline={isOnline}
                    isGroup={isGroup}
                    unReadCount={unReadCount}
                    allParticipants={allParticipants}
                    conversationData={conversationData}
                    chatUserOptions={chatUserOptions}
                    onAddParticipants={onAddParticipants}
                    onDeleteParticipants={onDeleteParticipants}
                    classes={classes}
                />
                <Box sx={{ display: 'flex' }}>
                    <IconButton
                        onClick={() => handleClose()}
                        className={classes.buttonStyle}
                        size="small"
                        data-cy="close-chat"
                    >
                        <CloseRounded />
                    </IconButton>
                </Box>
            </Box>
            <Box className={`${classes.chatBox} ${chatClasses.chatContent} ${isOpen ? classes.chatBoxOpen : ''}`}>
                <div ref={chatRef} className={chatClasses?.messageList}>
                    {isLoading && <CircularSpinner />}
                    {!isLoading && allParticipants?.length === 0 ? (
                        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                            Add users in group to send message
                        </Box>
                    ) : (
                        <>
                            {!isLoading && messages?.length === 0 && (
                                <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                                    Send message to start conversation
                                </Box>
                            )}

                            {messages?.map((message, i) => (
                                <Fragment key={message?.id}>
                                    {unReadCount !== 0 && i === messages.length - unReadCount && (
                                        <Divider
                                            component="span"
                                            role="presentation"
                                            className={chatClasses?.chatUnread}
                                        >
                                            New Messages
                                        </Divider>
                                    )}
                                    <MessageItem
                                        message={message}
                                        userId={userId}
                                        isGroup={isGroup}
                                        classes={classes}
                                    />
                                </Fragment>
                            ))}
                        </>
                    )}
                    {isLoadingBelow && <CircularSpinner />}
                </div>
                {showScroll && (
                    <Fab
                        sx={{
                            position: 'absolute',
                            bottom: 65,
                            right: 20,
                            minHeight: 35,
                            height: 35,
                            width: 35,
                            fontSize: '20px',
                        }}
                        size="small"
                        onClick={() => scrollToBottom()}
                        aria-label="Scroll Bottom"
                    >
                        <Badge badgeContent={unReadCount} color="secondary">
                            <ArrowDownwardRounded />
                        </Badge>
                    </Fab>
                )}
                {allParticipants?.length !== 0 && <SendMessage onSendMessage={onSendMessage} />}
            </Box>
        </Box>
    )
}

const decodeDataToMarkup = (content = '', tags = [], mentions = []) => {
    let decodedHtml = content

    mentions.forEach(mention => {
        const mentionPlaceholder = `\${mention_${mention.userId}}`
        const mentionHtml = `<mention class="mention" type="userNames" userId="${mention.userId}">@${mention.userName}</mention>`
        decodedHtml = decodedHtml.replace(mentionPlaceholder, mentionHtml)
    })

    tags.forEach(tag => {
        const tagPlaceholder = `\${tag_${tag.id}}`
        const tagHtml = `<mention class="mention" type="widgetTags" itemId="${tag.itemId}">#${tag.itemName}</mention>`
        decodedHtml = decodedHtml.replace(tagPlaceholder, tagHtml)
    })

    return decodedHtml
}

const UserPopover = ({ userid, userData, children, handleOpenChat }) => {
    const [anchorEl, setAnchorEl] = useState(null)

    const handlePopoverOpen = event => {
        setAnchorEl(event.currentTarget)
    }

    const handlePopoverClose = () => {
        setAnchorEl(null)
    }

    const open = Boolean(anchorEl)

    return (
        <>
            <span
                className="mention"
                data-type="userNames"
                data-id={userid}
                onClick={handlePopoverOpen}
                style={{ cursor: 'pointer' }}
            >
                {children}
            </span>
            <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={handlePopoverClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <div style={{ display: 'flex', padding: '8px' }}>
                    <div>
                        <span>User Name: {userData?.ScreenAlias}</span>
                        <br />
                        <span>User Email: {userData?.Email}</span>
                    </div>
                    <IconButton
                        style={{ height: '40px', width: '40px' }}
                        onClick={() => handleOpenChat(userid, userData)}
                        size="small"
                    >
                        <QuestionAnswerRounded fontSize="small" />
                    </IconButton>
                </div>
            </Popover>
        </>
    )
}

const MessageItem = ({ message, userId, isGroup, classes }) => {
    const dispatch = useDispatch()
    const chatClasses = useStyles()
    const isCurrentUser = message?.senderId === userId
    const userList = useSelector(messengerSelectors.getUserList)
    const processedMessage = decodeDataToMarkup(message?.content, message?.tags ?? [], message?.mentions ?? [])

    const onTagIconClick = itemId => {
        dispatch(navigationCreators.goToPerspective(itemId))
    }

    const handleOpenChat = (userId, userData) => {
        dispatch(messengerCreators.openDirectChat(userId, userData))
    }

    const CustomMention = ({ children, type, userid, itemID, ...rest }) => {
        if (type === 'userNames') {
            const userData = userList.find(user => user.UserId === userid)
            return (
                <UserPopover userid={userid} userData={userData} handleOpenChat={handleOpenChat}>
                    {children}
                </UserPopover>
            )
        }
        if (type === 'widgetTags') {
            return (
                <>
                    <span className="mention" data-type="widgetTags" data-id={itemID}>
                        {children}
                        <Tooltip title="Open Perspective/Widget">
                            <IconButton
                                style={{ color: 'primary.text' }}
                                onClick={() => onTagIconClick(itemID)}
                                size="small"
                            >
                                <LaunchRounded fontSize="small" />
                            </IconButton>
                        </Tooltip>
                    </span>
                </>
            )
        }
        return { children }
    }

    return (
        <Box
            id={message?.id}
            data-messageid={message?.id}
            data-iscurrentuser={isCurrentUser}
            data-isunread={message.unRead ? true : false}
            data-sender={isCurrentUser ? message?.senderId : userId}
            className={classNames({ [chatClasses.chatGroupStyle]: !isCurrentUser && isGroup })}
        >
            {!isCurrentUser && isGroup && (
                <Avatar className={classes.avatar}>{message?.senderId[0].toUpperCase()}</Avatar>
            )}
            <div
                className={classNames(chatClasses.chatBubble, {
                    [chatClasses.chatBubbleRight]: isCurrentUser,
                })}
            >
                <Box>
                    <Typography
                        variant="caption"
                        className={classNames(chatClasses.chatDate, {
                            [chatClasses.chatDateRight]: isCurrentUser,
                        })}
                    >
                        {moment(message.createdAt).format('MM/DD hh:mm a')}
                    </Typography>
                    <Markdown
                        components={{
                            // @ts-ignore
                            mention: CustomMention,
                        }}
                    >
                        {processedMessage}
                    </Markdown>
                </Box>
            </div>
        </Box>
    )
}

const UserAvatars = ({
    conversationData,
    isOnline,
    unReadCount,
    classes,
    isGroup,
    allParticipants = [],
    chatUserOptions = [],
    onAddParticipants,
    onDeleteParticipants,
}) => {
    const [anchorEl, setAnchorEl] = useState(null)
    const [isEditing, setIsEditing] = useState(false)
    const [isAddUser, setIsAddUser] = useState(false)
    const [chatParticipants, setChatParticipants] = useState(null)
    const [alias, setAlias] = useState(!isGroup ? conversationData?.user?.ScreenAlias : conversationData?.id)

    const isPopoverOpen = Boolean(anchorEl)

    const handlePopoverOpen = event => {
        event.stopPropagation()
        setAnchorEl(event.currentTarget)
    }

    const handlePopoverClose = event => {
        event.stopPropagation()
        setAnchorEl(null)
    }

    const handleEditStart = event => {
        event.stopPropagation()
        setIsEditing(true)
    }
    const handleAliasChange = event => setAlias(event.target.value)

    const handleEditCancel = event => {
        event.stopPropagation()
        setAlias(isGroup ? conversationData?.user?.ScreenAlias : conversationData?.id)
        setIsEditing(false)
    }

    const handleEditSave = event => {
        event.stopPropagation()
        // Can add API call here
        setIsEditing(false)
    }

    const handleAddSave = () => {
        onAddParticipants(chatParticipants)
        handleAddClose()
    }

    const handleAddClose = () => {
        setChatParticipants(null)
        setIsAddUser(false)
    }

    const handleAddUsers = event => {
        setIsAddUser(true)
    }

    const handleRemoveUsers = user => {
        onDeleteParticipants(user)
        handleAddClose()
    }

    return (
        <Box sx={{ display: 'flex', gap: 0.5, alignItems: 'center', ml: 1 }}>
            <Tooltip title={isGroup ? 'Users' : 'User'}>
                <AvatarGroup
                    className={classes.avatarGroup}
                    max={2}
                    sx={{ cursor: 'pointer' }}
                    onClick={isGroup ? handlePopoverOpen : null}
                >
                    {allParticipants?.length ? (
                        allParticipants.map(participant => (
                            <Badge
                                overlap="circular"
                                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                variant="dot"
                                invisible={!isOnline}
                                color={'success'}
                                key={participant?.UserId}
                            >
                                <Avatar className={classes.avatar} style={{ fontSize: 20 }}>
                                    {participant?.ScreenAlias[0].toUpperCase()}
                                </Avatar>
                            </Badge>
                        ))
                    ) : isGroup ? (
                        <Avatar className={classes.avatar}>
                            <GroupRounded />
                        </Avatar>
                    ) : (
                        <Badge
                            overlap="circular"
                            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                            variant="dot"
                            invisible={!isOnline}
                            color={'success'}
                        >
                            <Avatar className={classes.avatar}>
                                {conversationData?.user?.ScreenAlias?.[0]?.toUpperCase()}
                            </Avatar>
                        </Badge>
                    )}
                </AvatarGroup>
            </Tooltip>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                {isEditing && isGroup ? (
                    <TextField
                        onClick={e => e.stopPropagation()}
                        value={alias}
                        onChange={handleAliasChange}
                        size="small"
                        sx={{ width: '120px' }}
                    />
                ) : (
                    <Typography variant="body1" sx={{ fontSize: '0.85rem', fontWeight: 500, marginRight: '10px' }}>
                        {isGroup ? (
                            <ConversationGroupName
                                participants={conversationData?.participants}
                                chatType={conversationData?.type}
                                groupName={conversationData?.chatData?.name}
                                chat={conversationData?.chatData}
                            />
                        ) : (
                            conversationData?.user?.ScreenAlias
                        )}
                    </Typography>
                )}
                {isGroup && false && (
                    <Box sx={{ marginLeft: 'auto' }}>
                        {isEditing ? (
                            <Box sx={{ display: 'flex' }}>
                                <IconButton onClick={handleEditSave} color="success">
                                    <CheckRounded />
                                </IconButton>
                                <IconButton onClick={handleEditCancel} color="error">
                                    <CloseRounded />
                                </IconButton>
                            </Box>
                        ) : (
                            <IconButton onClick={handleEditStart}>
                                <EditRounded />
                            </IconButton>
                        )}
                    </Box>
                )}
            </Box>
            <Badge badgeContent={unReadCount} color="secondary" />
            <Popover
                open={isPopoverOpen}
                anchorEl={anchorEl}
                onClose={handlePopoverClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                onClick={e => {
                    e.stopPropagation()
                }}
            >
                <Box sx={{ minWidth: 250 }}>
                    <List>
                        <ListItem
                            key={'AddUser'}
                            sx={{ padding: '0px 16px', alignItems: 'center', color: 'text.primary' }}
                        >
                            {isAddUser ? (
                                <>
                                    <Autocomplete
                                        fullWidth
                                        multiple={false}
                                        disableClearable
                                        options={chatUserOptions}
                                        getOptionLabel={option => option.label}
                                        value={chatParticipants}
                                        renderInput={params => (
                                            <TextField
                                                {...params}
                                                label="Users"
                                                className={classes.textFieldStyle}
                                                InputLabelProps={{ sx: { color: 'text.primary' } }}
                                            />
                                        )}
                                        onChange={(_, value) => setChatParticipants(value)}
                                    />
                                    <Box sx={{ display: 'flex' }}>
                                        <IconButton onClick={handleAddSave} color="success">
                                            <CheckRounded />
                                        </IconButton>
                                        <IconButton onClick={handleAddClose} color="error">
                                            <CloseRounded />
                                        </IconButton>
                                    </Box>
                                </>
                            ) : (
                                <>
                                    <ListItemText primary="Add User" />
                                    <Tooltip title="Add User">
                                        <IconButton onClick={handleAddUsers}>
                                            <PersonAddRounded />
                                        </IconButton>
                                    </Tooltip>
                                </>
                            )}
                        </ListItem>
                        <Divider />
                        {allParticipants.map(user => {
                            return (
                                <React.Fragment key={user?.UserId}>
                                    <ListItem key={user?.UserId} sx={{ alignItems: 'center', color: 'text.primary' }}>
                                        <ListItemAvatar sx={{ minWidth: '40px', margin: 0 }}>
                                            <Badge
                                                overlap="circular"
                                                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                                variant="dot"
                                                invisible={true}
                                                color={'success'}
                                            >
                                                <Avatar className={classes.avatar}>
                                                    {user?.ScreenAlias[0].toUpperCase()}
                                                </Avatar>
                                            </Badge>
                                        </ListItemAvatar>
                                        <ListItemText primary={user?.ScreenAlias} />
                                        {/* <Tooltip title="Remove User">
                                            <IconButton color="error" onClick={() => handleRemoveUsers(user)}>
                                                <DeleteRounded />
                                            </IconButton>
                                        </Tooltip> */}
                                    </ListItem>
                                    <Divider />
                                </React.Fragment>
                            )
                        })}
                    </List>
                </Box>
            </Popover>
        </Box>
    )
}

const SendMessage = ({ onSendMessage }) => {
    const chatClasses = useStyles()
    const [message, setMessage] = useState(null)

    const accessKey = useSelector(authSelectors.getAccessKey)
    const widgets = useSelector(widgetSelectors.getAllWidgets)
    const modelName = useSelector(applicationSelectors.getCurrentAppName)

    const getUserList = debounce(async (q: string) => {
        const usersFetchRequest: UsersRequest = {
            ModelName: modelName,
            PageSize: 10,
            PageNumber: 1,
            loaddetails: true,
            superUsersOnly: false,
            userIdSearch: q,
        }

        const usersRes = await userService.getUsersV2(usersFetchRequest)

        const userList = usersRes?.Users?.map(user => ({
            userId: user.UserInfo.UserId,
            userName: user.UserInfo.ScreenAlias,
        }))

        return userList || []
    }, 500)

    const getWidgetList = async (q: string) => {
        const widgetList = widgets.map(widget => {
            return {
                name: widget.Name,
                title: widget.Title,
                id: widget.Id,
            }
        })
        return widgetList || []
    }

    const onChange = event => {
        setMessage(event)
    }

    return (
        <Box className={chatClasses?.messageBox}>
            <Box width={'100%'}>
                <TextEditorV2
                    editable={true}
                    isChat={true}
                    initialValue={message}
                    userAccessKey={accessKey || null}
                    onChange={onChange}
                    getUserList={getUserList}
                    getWidgetList={getWidgetList}
                    onHandleSubmit={onSendMessage}
                />
            </Box>
        </Box>
    )
}

const extractDataFromHtml = (htmlString, user) => {
    const parser = new DOMParser()
    const doc = parser.parseFromString(htmlString, 'text/html')

    const mentions = []
    const tags = []

    const spanElements = doc.querySelectorAll('span.mention')

    spanElements.forEach(span => {
        const dataType = span.getAttribute('data-type')
        const dataId = span.getAttribute('data-id')
        const dataLabel = span.getAttribute('data-label')

        if (dataType === 'userNames') {
            mentions.push({
                userId: dataId,
                userName: dataLabel,
            })

            span.replaceWith(doc.createTextNode(`\${mention_${dataId}}`))
        } else if (dataType === 'widgetTags') {
            tags.push({
                id: dataId,
                itemName: dataLabel,
                itemId: dataId,
                itemType: MessageTagItemType.WIDGET,
            })

            span.replaceWith(doc.createTextNode(`\${tag_${dataId}}`))
        }
    })
    const transformedHtml = doc.body.innerHTML

    return {
        mentions,
        tags,
        transformedHtml,
    }
}

const createGuid = () => {
    let d = new Date().getTime()
    const guid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        const r = (d + Math.random() * 16) % 16 | 0
        d = Math.floor(d / 16)
        return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16)
    })
    return guid
}

export default ChatBox
