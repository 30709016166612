import { messengerCreators } from '~/actions/creators/messenger.creators'
import { toCamel } from 'genesis-suite/utils'
import { authSelectors } from '~/selectors/auth.selectors'

export const subscribeToMessageNotifications = (userId, domainId) => {
    const { $ } = window
    const { connection } = $

    const subscriber = {
        id: connection.hub.id,
        Filters: [
            {
                key: 'userId',
                value: userId,
            },
            {
                key: 'domainId',
                value: domainId,
            },
        ],
    }
    connection.ChatNotificationsHub.server.subscribe('ChatRoom', subscriber)
}

const processedMessages = new Map()

const debounceMessage = (messageId, delay) => {
    const now = Date.now()
    if (processedMessages.has(messageId)) {
        const lastProcessedTime = processedMessages.get(messageId)
        if (now - lastProcessedTime < delay) {
            return false
        }
    }
    processedMessages.set(messageId, now)
    return true
}

export const registerMessagesClientFunctions = (state, dispatch) => {
    const { $ } = window
    const messagesHub = $.connection.ChatNotificationsHub
    const currentUserId = authSelectors.getUserId(state)

    if (messagesHub) {
        messagesHub.client.notify = message => {
            console.log('Message received:', message)
            console.log('Message received at:', new Date().toISOString())
            const { Type } = message
            switch (Type) {
                case 'UserMessage':
                    let chatMessage = JSON.parse(message.MessageAsJsonText)
                    chatMessage = toCamel(chatMessage)
                    if (chatMessage.senderId === currentUserId) return

                    dispatch(messengerCreators.addChatNotification(chatMessage))
                    // if (debounceMessage(chatMessage.conversationId, 2000)) {
                    //     2000 ms debounce delay
                    //     updating messages to show latest chat on top
                    //     dispatch(messengerCreators.addMessageToChat(chatMessage))
                    //     updating user chat to show latest chat message
                    //     dispatch(messengerCreators.pushMessageToUserChat(chatMessage, chatMessage.conversationId))
                    // }
                    break
            }
        }
    }
}
