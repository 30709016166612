import { appearanceTypes, applicationTypes, moduleTypes, widgetTypes } from '../actions/types'
import { appearanceConstants } from '../constants'
import { getPreference } from '../lib/browserPreferences'

const { Layout, TopNav } = appearanceConstants

const initState = {
    layout: getPreference('layout') || Layout.MODERN,
    rightNavTab: '',
    sideNavOpen: true,
    showLoader: false,
    showSettings: false,
    showCookieConsent: false,
    showComparePerspectiveFilterPanel: false,
    showPerspectiveFilterPopover: false,
    showPerspectiveCompareFilterPopover: false,
    showGlobalFilters: false,
    showHelp: false,
    topNav: getPreference('topNav') || TopNav.ICONS_AND_TEXT,
    hideAppContent: false,
    showNeoClassicLayout: getPreference('showNeoClassicLayout') || false,
    neoClassicGlobalFiltersOpen: false,
    showApplicationController: false,
    betaTurnedOn: false,
}

export default (state = initState, action) => {
    const { type, payload } = action
    switch (type) {
        case appearanceTypes.SET_LAYOUT:
            return { ...state, layout: payload.layout, sideNavOpen: payload.layout === Layout.MODERN }
        case appearanceTypes.TOGGLE_LOADER:
            return { ...state, showLoader: !state.showLoader }
        case appearanceTypes.TOGGLE_SETTINGS:
            return { ...state, showSettings: !state.showSettings }
        case appearanceTypes.TOOGLE_COOKIE_CONSENT:
            return { ...state, showCookieConsent: !state.showCookieConsent }
        case appearanceTypes.TOGGLE_SIDE_NAV:
            return { ...state, sideNavOpen: !state.sideNavOpen }
        case appearanceTypes.CLOSE_RIGHT_NAV:
            return { ...state, sideNavOpen: payload?.layout === Layout.MODERN, rightNavTab: '' }
        case moduleTypes.MODULE_CHANGE_REQUEST:
        case applicationTypes.APPLICATION_SELECT:
            return { ...state, rightNavTab: '' }
        case appearanceTypes.OPEN_RIGHT_NAV:
            return { ...state, sideNavOpen: false, rightNavTab: payload.tab }
        case widgetTypes.SET_COMMENTING_WIDGET:
            return { ...state, sideNavOpen: false, rightNavTab: 'comments' }
        case appearanceTypes.TOGGLE_COMPARE_PERSPECTIVE_FILTER_PANEL:
            return { ...state, showComparePerspectiveFilterPanel: !state.showComparePerspectiveFilterPanel }
        case appearanceTypes.TOGGLE_PERSPECTIVE_FILTER_POPOVER:
            return { ...state, showPerspectiveFilterPopover: !state.showPerspectiveFilterPopover }
        case appearanceTypes.TOGGLE_PERSPECTIVE_COMPARE_FILTER_POPOVER:
            return { ...state, showPerspectiveCompareFilterPopover: !state.showPerspectiveCompareFilterPopover }
        case appearanceTypes.TOGGLE_GLOBAL_FILTERS:
            return { ...state, showGlobalFilters: !state.showGlobalFilters }
        case appearanceTypes.TOGGLE_HELP:
            return { ...state, showHelp: !state.showHelp }
        case appearanceTypes.SET_TOP_NAV:
            return { ...state, topNav: payload.topNav }
        case appearanceTypes.CLOSE_SIDE_NAV:
            return { ...state, sideNavOpen: false }
        case appearanceTypes.TOGGLE_APP_CONTENT:
            return { ...state, hideAppContent: payload.hide }
        case appearanceTypes.SET_SHOW_NEO_CLASSIC_LAYOUT:
            return { ...state, showNeoClassicLayout: payload.showNeoClassicLayout ?? false }
        case appearanceTypes.TOGGLE_NEO_CLASSIC_GLOBAL_FILTERS:
            return { ...state, neoClassicGlobalFiltersOpen: !state.neoClassicGlobalFiltersOpen }
        case moduleTypes.MODULE_SUCCESS:
            const appOptions = payload?.appModule?.AppOptions ? JSON.parse(payload.appModule.AppOptions) : null
            if (appOptions) {
                const { layout, showNeoClassic, defaultInteraction } = appOptions
                return {
                    ...state,
                    showNeoClassicLayout: showNeoClassic,
                    layout: layout,
                    defaultInteraction: defaultInteraction,
                }
            } else {
                return state
            }
        case appearanceTypes.SET_SHOW_APPLICATION_CONTROLLER:
            return { ...state, showApplicationController: payload.showApplicationController }
        case appearanceTypes.TOGGLE_BETA:
            return { ...state, betaTurnedOn: payload.betaTurnedOn }
        default:
            return state
    }
}
