import { Accordion, AccordionDetails, AccordionSummary, Skeleton, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import isEmpty from 'lodash/isEmpty'

import { ArrowDropDown } from '@mui/icons-material'
import { ChopText } from 'genesis-suite/components'
import { NodeResource } from 'genesis-suite/types/networkTypes'

const itemHeight = 52

const useStyles = makeStyles(({ spacing, palette }) => {
    const gridGap = spacing()

    return {
        profileRootNode: { margin: spacing(0.5) },
        title: { flex: 1, marginLeft: spacing() },
        grid: {
            display: 'grid',
            gridTemplateColumns: `repeat(auto-fit, minmax(120px, 1fr))`,
            gridGap,
            overflow: 'auto',
            maxHeight: (p: any) => (p.hasLinked ? itemHeight * 2 + gridGap : ''),
        },
        propertyContainer: {
            height: itemHeight,
            background: palette.background.main,
            padding: spacing(),
            borderRadius: '4px',
        },
        icon: {
            color: palette.text.primary,
        },
        accordion: {
            background: palette.background.main,
            boxShadow: 'none',
            '&.Mui-expanded': {
                margin: 0,
            },
            '&::before': {
                height: 0,
            },
        },
        accordionSummary: {
            padding: spacing(0, 1),
            flexDirection: 'row',
            width: '100%',
            '&.MuiAccordionSummary-root': {
                minHeight: '24px',
            },
        },
    }
})

interface Props {
    node: NodeResource
    data: { [propertyName: string]: number | string }
    hasLinked: boolean
    loading: boolean
}

export default function ProfileRootNode({ node, data, hasLinked, loading }: Props) {
    const { properties, profile } = node || {}

    const displayData = profile?.properties
        .filter(p => data && p.name in data)
        .map(({ id, name }) => ({ name: properties.find(p => p.id === id)?.displayName, value: data[name] }))
    const classes = useStyles({ hasLinked })

    const content = loading ? (
        <div className={classes.grid}>
            <SkeletonLoader />
        </div>
    ) : isEmpty(data) ? (
        <Typography>No profile data available.</Typography>
    ) : (
        <Accordion className={classes.accordion} defaultExpanded>
            <AccordionSummary className={classes.accordionSummary} expandIcon={<ArrowDropDown />}></AccordionSummary>
            <AccordionDetails sx={{ height: '100%', overflow: 'scroll', padding: '0 8px' }}>
                <div className={classes.grid}>
                    {displayData?.map(({ name, value }) => (
                        <div key={name} className={classes.propertyContainer}>
                            <ChopText showEllipsis variant="caption" tooltipProps={{ placement: 'top' }}>
                                {name}
                            </ChopText>
                            <ChopText showEllipsis tooltipProps={{ placement: 'bottom' }}>
                                {value}
                            </ChopText>
                        </div>
                    ))}
                </div>
            </AccordionDetails>
        </Accordion>
    )

    return <div className={classes.profileRootNode}>{content}</div>
}

function SkeletonLoader() {
    return (
        <>
            <div>
                <Skeleton variant="text" width="60%" height={24} />
                <Skeleton variant="text" width="80%" height={28} />
            </div>
            <div>
                <Skeleton variant="text" width="30%" height={24} />
                <Skeleton variant="text" width="90%" height={28} />
            </div>
        </>
    )
}
