import { ResourceType } from 'genesis-suite/types/networkTypes'
import {
    ChartType,
    Category,
    DataSource,
    Axis,
    OneSeries,
    BoxPlotConfig,
    LineSeries,
    ColumnSeries,
    BoxPlotSeries,
} from 'genesis-suite/types/visualTypes'
import { makeField, stringToFilters } from 'genesis-suite/utils'
import { getBoxPlotAggregations } from '../../edit_widget/utils'
import { WidgetConverterProps } from '../migrationTypes'
import {
    makeAggregation,
    convertBaseFormat,
    convertTooltip,
    convertLegend,
    getPropertyFromResource,
    makeNavigation,
} from '../widgetConverterUtils'

export default async function boxPlotConverter({
    config,
    dashboardIdByOldId,
    resourceManager,
}: WidgetConverterProps): Promise<BoxPlotConfig> {
    const { Title, ChartConfig, NoDataMessage } = config
    const {
        Source,
        Series,
        XAxisField,
        LabelField,
        CrumbMetaName,
        DefaultPerspective,
        SecondaryAxisLabel,
        GridLines,
        HideXAxis,
        HideYAxis,
        SortAxis,
        InverseSort,
        DisplayCount,
        LabelName,
        ValueName,
        YAxisNames,
    } = ChartConfig

    const invertAxis = ChartConfig.ChartType === 'Bar'
    const baseInsight = await resourceManager.getInsight(Source.ElementName)
    const boxPlotAggregations = getBoxPlotAggregations()

    const category: Category = {
        field: makeField(baseInsight.properties, invertAxis ? LabelField : XAxisField),
        navigation: makeNavigation(CrumbMetaName, DefaultPerspective, dashboardIdByOldId),
        ...(SortAxis && { sort: InverseSort ? 'descending' : 'ascending' }),
    }

    let hasSecondaryAxis = false
    let primaryTickAmount: number
    let secondaryTickAmount: number

    const series = await (async () => {
        return Promise.all<OneSeries>(
            Series.filter(s => !s.IsTrendLine).map(async (s, index) => {
                const {
                    Source,
                    SeriesType,
                    AggregationType,
                    FieldName,
                    SeriesName,
                    PlotSecondaryAxis,
                    TickCount,
                    IgnoreBlanks,
                    SecondaryAxisTickCount,
                    ShowLabels,
                    SmoothCurves,
                    BaseFormat,
                    Color,
                } = s

                const insight = await resourceManager.getInsight(Source.ElementName)

                if (PlotSecondaryAxis) hasSecondaryAxis = true
                if (TickCount) primaryTickAmount = TickCount
                if (SecondaryAxisTickCount) secondaryTickAmount = SecondaryAxisTickCount

                let values: DataSource[]

                if (index === 0) {
                    values = boxPlotAggregations.map(aggregation => {
                        return {
                            field: makeField(insight.properties, FieldName),
                            aggregation,
                        }
                    })
                } else {
                    values = [
                        {
                            field: makeField(insight.properties, FieldName),
                            aggregation: makeAggregation(getPropertyFromResource(insight, FieldName), AggregationType),
                        },
                    ]
                }

                const hasTrendLine = Series.some(s => s.IsTrendLine && s.FieldName === FieldName)

                const baseSeries: BoxPlotSeries = {
                    title: SeriesName,
                    service: { type: ResourceType.INSIGHT, name: insight.name, id: insight.id },
                    values,
                    yAxis: PlotSecondaryAxis ? 1 : 0,
                    ...(DisplayCount && { limit: DisplayCount }),
                    ...(ShowLabels && { dataLabels: { value: true, format: { base: convertBaseFormat(BaseFormat) } } }),
                    ...(hasTrendLine && { trendLines: [{ show: true }] }),
                    ignoreBlanks: IgnoreBlanks,
                    filters: stringToFilters(insight.properties, Source.Filters),
                    colors: { FieldName: Color },
                }

                const singleSeries: ColumnSeries | LineSeries =
                    SeriesType === 'Column' || SeriesType === 'Bar'
                        ? { ...baseSeries, type: 'column', tooltip: convertTooltip(insight, s) }
                        : SeriesType === 'Line'
                        ? {
                              ...baseSeries,
                              type: 'line',
                              shape: SeriesType === 'Area' ? 'area' : SmoothCurves ? 'spline' : 'line',
                              tooltip: convertTooltip(insight, s),
                          }
                        : { ...baseSeries }

                return singleSeries
            })
        )
    })()

    const xAxis: Axis = {
        title: { text: invertAxis ? YAxisNames[0] : LabelName },
        label: { enabled: !HideXAxis },
        gridLines: GridLines === 'Both' || GridLines === 'XAxis',
    }

    const yAxis: Axis[] = [
        {
            title: { text: invertAxis ? LabelName : ValueName },
            label: { enabled: !HideYAxis },
            tickAmount: primaryTickAmount,
            gridLines: GridLines === 'Both' || GridLines === 'YAxis',
        },
    ]
    if (hasSecondaryAxis)
        yAxis.push({ opposite: true, title: { text: SecondaryAxisLabel }, tickAmount: secondaryTickAmount })

    const builderConfig: BoxPlotConfig = {
        version: '2',
        type: ChartType.BOX_PLOT,
        title: Title,
        NoDataMessage: NoDataMessage,
        categories: [category],
        series,
        xAxis,
        yAxis,
        invertAxis,
        filters: stringToFilters(baseInsight.properties, Source.Filters),
        legend: convertLegend(ChartConfig),
    }

    return builderConfig
}
