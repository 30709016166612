import { Autocomplete, Checkbox, FormControl, MenuItem, Select, TextField } from '@mui/material'
import { DebouncedTextField } from 'genesis-suite/components'
import { isEmpty } from 'lodash'
import { useContext, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { widgetSelectors } from '~/selectors'
import { ConfigContext } from '../ConfigContext'
import IconSelector from '../selectors/IconSelector'
import FieldLabel from './FieldLabel'

export default function ActionEditor() {
    const { configStructure, selectedField, dispatch, config } = useContext(ConfigContext)
    const actionFields = configStructure?.WidgetAction
    const fieldsToOmit = ['ActionName']
    const [selectedPerspectiveId, setSelectedPerspectiveId] = useState<string | null>(null)
    const perspectives = useSelector(widgetSelectors.getAllPerspectives)
    const widgets = useSelector(s => widgetSelectors.getWidgetConfigsForPerspective(s, selectedPerspectiveId))

    useEffect(() => {
        if (selectedField?.type !== 'action') return

        const activeAction = config?.Actions[selectedField.index]
        setSelectedPerspectiveId(activeAction['PerspectiveId'])
    }, [selectedField])

    if (actionFields?.length === 0 || selectedField?.type !== 'action') return null

    const activeAction = config?.Actions[selectedField.index]

    const updateActiveAction = (key, value) => {
        dispatch({ type: 'UPDATE_SELECTED_ACTION', payload: { [key]: value } })
    }
    console.log('activeAction', activeAction)
    const sortedActionConfig = [...actionFields].sort(
        (a, b) => (a.DisplayOrder ?? Infinity) - (b.DisplayOrder ?? Infinity)
    )
    return (
        <>
            {sortedActionConfig.map((field, index) => {
                if (fieldsToOmit.includes(field.Name)) return null

                if (field.Name === 'ShortName' && field.Type.includes('String')) {
                    return (
                        <div key={index}>
                            <FieldLabel field={field} />
                            <TextField
                                fullWidth
                                variant="outlined"
                                size="small"
                                value={activeAction[field.Name]}
                                onChange={e => updateActiveAction(field.Name, e.target.value)}
                                placeholder="Enter Short Name"
                                margin="normal"
                            />
                        </div>
                    )
                }

                if (field.IsPerspectiveProperty) {
                    return (
                        <div key={index}>
                            <FieldLabel field={field} />
                            <Autocomplete
                                fullWidth
                                options={perspectives}
                                isOptionEqualToValue={(option, value) => option?.Id == value?.Id}
                                getOptionLabel={option => option.Title}
                                value={perspectives.find(p => p.Id === activeAction[field.Name]) ?? null}
                                onChange={(_, newValue) => {
                                    updateActiveAction(field.Name, newValue?.Id)
                                    setSelectedPerspectiveId(newValue?.Id)
                                }}
                                renderInput={params => (
                                    <TextField
                                        {...params}
                                        variant="outlined"
                                        size="small"
                                        placeholder="Select a perspective"
                                        margin="normal"
                                        sx={{ my: 0 }}
                                        InputProps={{
                                            ...params.InputProps,
                                            endAdornment: <>{params.InputProps.endAdornment}</>,
                                        }}
                                    />
                                )}
                            />
                        </div>
                    )
                }

                if (field.IsWidgetProperty) {
                    return (
                        <div key={index}>
                            <FieldLabel field={field} />
                            <Autocomplete
                                fullWidth
                                options={widgets}
                                getOptionLabel={option => option.Title}
                                isOptionEqualToValue={(option, value) => option?.Id == value?.Id}
                                value={widgets.find(p => p.Id === activeAction[field.Name]) ?? null}
                                onChange={(_, newValue) => updateActiveAction(field.Name, newValue?.Id)}
                                renderInput={params => (
                                    <TextField
                                        {...params}
                                        variant="outlined"
                                        size="small"
                                        placeholder="Select a Widget"
                                        margin="normal"
                                        sx={{ my: 0 }}
                                        InputProps={{
                                            ...params.InputProps,
                                            endAdornment: <>{params.InputProps.endAdornment}</>,
                                        }}
                                    />
                                )}
                            />
                        </div>
                    )
                }
                if (field.Type.includes('Boolean')) {
                    return (
                        <div key={index} style={{ display: 'flex', alignItems: 'center' }}>
                            <div
                                key={index}
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                            >
                                <Checkbox
                                    onChange={e => updateActiveAction(field.Name, e.target.checked)}
                                    name={field.Name}
                                    color="primary"
                                    defaultChecked={false}
                                    checked={activeAction[field.Name] ?? false}
                                    inputProps={{ 'aria-label': `switch-${index}` }}
                                />
                                <FieldLabel field={field} />
                            </div>
                        </div>
                    )
                }
                if (!isEmpty(field.List)) {
                    return (
                        <div key={index}>
                            <FieldLabel field={field} />
                            <FormControl fullWidth sx={{ my: 0 }} variant="outlined" size="small" margin="normal">
                                <Select
                                    value={activeAction[field.Name] ?? 'None'}
                                    onChange={e => updateActiveAction(field.Name, e.target.value)}
                                >
                                    {Object.entries(field.List as { [key: string]: string }).map(([key, value]) => (
                                        <MenuItem key={key} value={key}>
                                            {value}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>
                    )
                }

                if (field.IsIcon) {
                    return (
                        <div key={index} style={{ display: 'flex', gap: '4px' }}>
                            <FieldLabel field={field} />
                            <IconSelector
                                icon={activeAction[field.Name]}
                                onChange={iconName => updateActiveAction(field.Name, iconName)}
                            />
                        </div>
                    )
                }

                if (field.Type.includes('String')) {
                    return (
                        <div key={index}>
                            <FieldLabel field={field} />
                            <DebouncedTextField
                                debounceTime={500}
                                fullWidth
                                variant="outlined"
                                size="small"
                                value={activeAction[field.Name]}
                                onChange={value => updateActiveAction(field.Name, value)}
                                margin="normal"
                                sx={{ my: 0 }}
                            />
                        </div>
                    )
                }

                return null
            })}
        </>
    )
}
