import { useFeature } from '@optimizely/react-sdk'
import { useSelector } from 'react-redux'
import useSWR, { useSWRConfig } from 'swr'

import { filterVisualObjectsByViewMode } from '../lib/manageUtils'
import { moduleService, visualService } from '../lib/services'
import { applicationSelectors, deploymentSelectors } from '../selectors'

export default function useModules(appName) {
    const viewFlag = useSelector(deploymentSelectors.getDeploymentViewFlag)
    const [builderEnabled] = useFeature('builder')

    const { data, mutate } = useSWR(
        appName ? makeKey(appName, builderEnabled, viewFlag) : null,
        ([_, appName, builderEnabled, viewFlag]) => getModules(appName, builderEnabled, viewFlag),
        { revalidateOnFocus: false, dedupingInterval: 10 * 60 * 1000 }
    )

    return [data, mutate]
}

export function useRevalidateModules() {
    const { mutate } = useSWRConfig()
    const appName = useSelector(applicationSelectors.getCurrentAppName)
    const viewFlag = useSelector(deploymentSelectors.getDeploymentViewFlag)
    const [builderEnabled] = useFeature('builder')

    return () => mutate(makeKey(appName, builderEnabled, viewFlag))
}

const makeKey = (appName, builderEnabled, viewFlag) => ['getModules', appName, builderEnabled, viewFlag]

function getModules(appName, builderEnabled, viewFlag) {
    const promises = [moduleService.getAll(appName, true)]
    // if (builderEnabled) promises.push(visualService.getModulesByApp(appName, { active: true }, viewFlag))

    const visorIndex = 0

    return Promise.allSettled(promises).then(promiseResults => {
        return promiseResults.reduce((modules, currPromiseResult, currIndex) => {
            if (currPromiseResult.status === 'fulfilled') {
                if (currIndex === visorIndex) {
                    modules = modules.concat(
                        currPromiseResult.value.map(m => ({
                            id: m.Id,
                            name: m.Name,
                            title: m.Title,
                            appName: m.Model,
                            BackgroundFileToken: m.BackgroundFileToken,
                            theme: m.Theme,
                            modules: m.Groups,
                        }))
                    )
                } else {
                    modules = modules.concat(currPromiseResult.value)
                }
                modules = filterVisualObjectsByViewMode(modules)
            }
            return modules
        }, [])
    })
}
