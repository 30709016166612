import { AppsRounded, ChatRounded, CloseRounded } from '@mui/icons-material'
import TaskIcon from '@mui/icons-material/AssignmentTurnedInOutlined'
import CalculateIcon from '@mui/icons-material/CalculateOutlined'
import StarRounded from '@mui/icons-material/StarRounded'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import { Badge, Box, IconButton, Tab, Tooltip } from '@mui/material'
import { useHotKey } from 'genesis-suite/hooks'
import { FlowChart, Profile as ProfileIcon, Link as ShortcutsIcon, SideNavOpen, Scenario } from 'genesis-suite/icons'
import { ComponentType, FC, ReactNode, useContext, useEffect, useState, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useSearchParams } from 'react-router-dom'
import { appearanceCreators } from '../../actions/creators'
import { ApplicationList } from '../../components/ApplicationController'
import RightNavShortcuts from '../../components/RightNavShortcuts'
import CalculatorController from '../../components/calculator/CalculatorController'
import CalculatorHistory from '../../components/calculator/CalculatorHistory'
import { PopoutContext } from '../../components/contexts/PopoutWidgetContext'
import FlowchartController from '../../components/flowchart/FlowchartController'
import Profile from '../../components/profile/Profile'
import TaskListsController from '../../components/tasks/TaskListsController'
import { useIsMobile } from '../../hooks/useIsMobile'
import { logEvent } from '../../lib/amplitudeClient'
import { useBrowserPreferences } from '../../lib/browserPreferences'
import { useFeature } from '../../lib/featureFlags'
import { HotKeys, createTip } from '../../lib/hotkeys'
import { TourTag } from '../../lib/tourSteps'
import {
    appearanceSelectors,
    authSelectors,
    moduleSelectors,
    scenarioSelectors,
    widgetSelectors,
} from '../../selectors'
import FavoritesList from '../FavoritesList'
import Chatbox from './Chatbox'
import { AIIcon } from 'genesis-suite/icons'
import AIChatBox from './AIChatBox'
import SideNavCollapseToggle from '../../components/SideNavCollapseToggle'
import { notificationSelectors } from '../../selectors'
import { Notification } from 'genesis-suite/icons'
import NotificationController2 from '~/components/notifications/NotificationController2'
import { ScenarioComponent } from './ScenarioComponent'
import AIChatDialog from './AIChatDialog'

interface RightNavTab {
    badge?: string | number
    Component: ComponentType<RightNavComponentProps>
    title: string
    Icon: ComponentType
    value: string
    disabled?: boolean
    hide?: boolean
}
export interface RightNavComponentProps {
    Header?: FC<{ children?: ReactNode }>
    onClose?: () => void
}

const tabsWidth = 60
const panelWidth = 330
const emptyTab = '__empty'
const RIGHT_NAV_QUERY = 'rightNavTab'

const RightNav = () => {
    const isMobile = useIsMobile()
    const [expand, setExpand] = useState(false)
    const [rightNavSettings, setRightNavSettings] = useBrowserPreferences('rightNav')
    const collapsed = rightNavSettings?.collapse
    const perspectiveId = useSelector(widgetSelectors.getCurrentPerspectiveId)
    const tabVal = useSelector(appearanceSelectors.getRightNavTab)
    const layout = useSelector(appearanceSelectors.getApplicationLayout)
    const isAdmin = useSelector(authSelectors.getIsAdmin)
    const enableAIChat = useSelector(moduleSelectors.getEnableAIChat)
    const showNeoClassic = useSelector(appearanceSelectors.getShowNeoClassicLayOut)
    const notifications = useSelector(notificationSelectors.getNotifications)
    const moduleId = useSelector(moduleSelectors.getCurrentModule)?.id
    const isPublishScenario = useSelector(authSelectors.getCanPublishScenario)
    const isApproveScenario = useSelector(authSelectors.getCanApproveScenario)
    const toApprove = useSelector(scenarioSelectors.getToApproveByCurrentUser)
    const [openAiChat, setOpenAiChat] = useState(false)
    const isBetaTurnedOn = useSelector(appearanceSelectors.getIsBetaTurnedOn)

    const [showProfiles] = useFeature('profiles')
    const [showComments] = useFeature('comments')
    const [showTasks] = useFeature('tasks')

    const [searchParams, setSearchParams] = useSearchParams()
    const rightNavTab = searchParams.get(RIGHT_NAV_QUERY)

    const togglePanel = () => {
        logEvent('RIGHT_NAV_COLLAPSE')
        setRightNavSettings(s => ({ ...s, collapse: !s?.collapse }))
    }
    const toggleExpand = () => setExpand(prev => !prev)

    const handleHotKey = (value: string) => {
        if (tabVal !== value) setTabVal(value)
        else if (tabVal === value) setTabVal('')
    }
    useHotKey(HotKeys.Applications, () => handleHotKey('applications'))
    const appTip = createTip(HotKeys.Applications)
    useHotKey(HotKeys.TadaGPT, isAdmin ? () => handleHotKey('tadagpt') : () => null)
    const gptTip = createTip(HotKeys.TadaGPT)

    const { popoutWidget } = useContext(PopoutContext)

    function openPopout() {
        const popOutProps = {
            Id: 'CALCULATOR-POPOUT',
            isComponent: true,
            width: 300,
            height: 500,
            Title: 'Calculator',
        }
        popoutWidget({
            ...popOutProps,
            component: <CalculatorHistory />,
        })
    }

    useHotKey(HotKeys.Calculator, openPopout)
    const calcTip = createTip(HotKeys.Calculator)

    const dispatch = useDispatch()
    const setTabVal = val => {
        if (val) {
            if (isBetaTurnedOn && val === 'explainableAI') {
                setOpenAiChat(true)
                return
            }
            dispatch(appearanceCreators.openRightNav(val))
            if (val === 'explainableAI') {
                setExpand(true)
                dispatch(appearanceCreators.toggleAppContent(true))
            } else {
                dispatch(appearanceCreators.toggleAppContent(false))
            }
            if (val === 'tadagpt') setExpand(true)
            if (val === 'scenario') setExpand(true)
        } else {
            setExpand(false)
            dispatch(appearanceCreators.closeRightNav())
            dispatch(appearanceCreators.toggleAppContent(false))
        }
    }
    const handleTabClick = (e, val) => {
        if (val === tabVal) setTabVal('')
        else setTabVal(val)
    }
    const handleClose = () => setTabVal('')

    useEffect(() => {
        if (rightNavTab) {
            const selectedTab = tabs.find(tab => tab.value === rightNavTab)
            if (selectedTab && !selectedTab.hide && !selectedTab.disabled) {
                setTabVal(rightNavTab)

                // clear query from nav to close/change tab
                searchParams.delete(RIGHT_NAV_QUERY)
                setSearchParams(searchParams)
            }
        }
    }, [rightNavTab, handleTabClick])

    const notificationsCount = useMemo(() => {
        return notifications.reduce((sum, obj) => sum + obj.Count, 0)
    }, [notifications])

    const tabs: RightNavTab[] = [
        {
            badge: notificationsCount > 0 ? `${notificationsCount}` : null,
            Component: NotificationController2,
            hide: !showNeoClassic,
            title: `Notifications`,
            Icon: Notification,
            value: 'Notifications',
        },
        {
            Component: ApplicationList,
            title: `Applications ${appTip}`,
            Icon: AppsRounded,
            value: 'applications',
        },
        {
            Component: FavoritesList,
            title: 'Favorites',
            Icon: StarRounded,
            value: 'favorites',
        },
        {
            Component: RightNavShortcuts,
            title: 'Shortcuts',
            Icon: ShortcutsIcon,
            value: 'shortcuts',
            hide: layout === 'modern',
        },
        {
            Component: Profile,
            title: 'Profile',
            Icon: ProfileIcon,
            value: 'profile',
            disabled: !perspectiveId,
            hide: !showProfiles,
        },
        {
            Component: TaskListsController,
            title: 'Tasks',
            Icon: TaskIcon,
            value: 'tasks',
            hide: !showTasks,
        },
        {
            Component: CalculatorController,
            title: `Calculator ${calcTip}`,
            Icon: CalculateIcon,
            value: 'calculator',
        },
        {
            Component: FlowchartController,
            title: 'Flowchart',
            Icon: FlowChart,
            value: 'flowchart',
        },
        {
            badge: toApprove.length > 0 ? `${toApprove.length}` : null,
            Component: ScenarioComponent,
            hide: !(isPublishScenario || isApproveScenario),
            Icon: Scenario,
            title: 'Scenario',
            value: 'scenario',
        },
        {
            badge: 'beta',
            Component: Chatbox,
            hide: !isAdmin,
            Icon: ChatRounded,
            title: `TadaGPT ${gptTip}`,
            value: 'tadagpt',
        },
        {
            Component: AIChatBox,
            hide: !isAdmin || !enableAIChat,
            Icon: AIIcon,
            title: `Explainable AI`,
            value: 'explainableAI',
        },
        // This prevents MUI errors when no tab is selected and must be at the end
        {
            Component: () => null,
            title: '',
            Icon: () => null,
            value: emptyTab,
        },
    ]

    if (isMobile) return null
    return (
        <>
            <TabContext value={tabVal || emptyTab}>
                <Box
                    sx={{
                        display: collapsed ? 'none' : 'block',
                        width: tabsWidth,
                        bgcolor: 'background.sideNav',
                        boxShadow: '-6px 0 5px -4px #888',
                        flex: 0,
                    }}
                >
                    <TabList
                        orientation="vertical"
                        data-tour={TourTag.ModuleTools}
                        sx={{ '& .MuiTabs-indicator': { display: 'none' } }}
                    >
                        {tabs
                            .filter(t => !t.hide)
                            .map(({ badge, title, value, Icon, disabled }) => (
                                <Tab
                                    key={value}
                                    value={value}
                                    disabled={disabled}
                                    onClick={e => handleTabClick(e, value)}
                                    icon={
                                        <Tooltip key={value} placement="left" title={title}>
                                            <span>
                                                <Badge
                                                    badgeContent={badge}
                                                    color="primary"
                                                    sx={{ '& .MuiBadge-badge': { right: '20%', top: -2, height: 20 } }}
                                                >
                                                    <Icon />
                                                </Badge>
                                            </span>
                                        </Tooltip>
                                    }
                                    style={{ visibility: value === emptyTab ? 'hidden' : 'inherit' }}
                                    sx={{
                                        alignSelf: 'center',
                                        bgcolor: tabVal === value ? 'background.paper' : 'inherit',
                                        color: 'text.primary',
                                        minWidth: tabsWidth,
                                        pointerEvents: 'auto',
                                    }}
                                />
                            ))}
                    </TabList>
                </Box>
                {tabVal && (
                    <Box
                        sx={{ width: panelWidth, display: 'flex', flex: expand || tabVal === 'explainableAI' ? 1 : 0 }}
                    >
                        {tabs.map(({ Component, value }) => {
                            return (
                                <TabPanel
                                    key={value}
                                    value={value}
                                    sx={{
                                        p: 0.5,
                                        position: 'relative',
                                        minWidth: panelWidth,
                                        width: '100%',
                                        bgcolor: 'background.paper',
                                    }}
                                >
                                    <Component
                                        onClose={handleClose}
                                        Header={({ children }) => (
                                            <RightNavHeader
                                                onClose={handleClose}
                                                onExpand={toggleExpand}
                                                expanded={expand}
                                                hideExpand={tabVal === 'explainableAI'}
                                            >
                                                {children}
                                            </RightNavHeader>
                                        )}
                                    />
                                </TabPanel>
                            )
                        })}
                    </Box>
                )}
                {!tabVal && <SideNavCollapseToggle open={!collapsed} onClick={togglePanel} />}
            </TabContext>
            <AIChatDialog openChatDialog={openAiChat} closeDialog={() => setOpenAiChat(false)}></AIChatDialog>
        </>
    )
}

export default RightNav

interface RightNavHeaderProps {
    children?: ReactNode
    onClose: () => void
    onExpand: () => void
    expanded: boolean
    hideExpand?: boolean
}
const RightNavHeader: FC<RightNavHeaderProps> = ({ children, onClose, onExpand, expanded, hideExpand }) => {
    return (
        <Box display="flex" alignItems="center">
            {!hideExpand && (
                <IconButton onClick={onExpand} size="large">
                    <SideNavOpen
                        fontSize="small"
                        sx={{
                            color: 'text.primary',
                            transition: `all 200ms`,
                            transform: _p => `rotateY(${expanded ? 0 : 180}deg)`,
                        }}
                    />
                </IconButton>
            )}
            {children}
            <IconButton onClick={onClose} size="large" sx={{ ml: 'auto', color: 'text.primary' }}>
                <CloseRounded fontSize="small" />
            </IconButton>
        </Box>
    )
}
