import { combineReducers } from 'redux'
import { applicationTypes, authTypes } from '../actions/types'

const initState = {
    current: '',
    appInfo: null,
    lastUpdateDate: { isPending: false, date: null },
    all: [],
    isPending: false,
    authorizedApps: [],
}

const current = (state = initState.current, action) => {
    const { payload } = action
    switch (action.type) {
        case applicationTypes.APPLICATION_SELECT:
            const { appName } = payload
            return appName
        default:
            return state
    }
}

const appInfo = (state = initState.appInfo, action) => {
    const { payload } = action
    switch (action.type) {
        case applicationTypes.APPLICATION_INFO_SUCCESS:
            const { info } = payload
            return {
                cloudId: info.CloudId,
                cloudName: info.CloudName,
                appId: info.AppId,
                appName: info.AppName,
                appDisplayName: info.AppDisplayName,
                partitionName: info.PartitionName,
                partitionId: info.PartitionId,
                companyId: info.CompanyId,
                domainId: info.DomainId,
                footer: info.Footer,
            }
        default:
            return state
    }
}

const lastUpdateDate = (state = initState.lastUpdateDate, action) => {
    const { payload } = action
    switch (action.type) {
        case applicationTypes.APPLICATION_SELECT:
            return initState.lastUpdateDate
        case applicationTypes.APPLICATION_INFO_REQUEST:
            return initState.lastUpdateDate
        case applicationTypes.APPLICATION_INFO_SUCCESS:
            const { info } = payload
            return info.LastDataRefresh
        default:
            return state
    }
}

const all = (state = initState.all, action) => {
    const { payload } = action
    switch (action.type) {
        case authTypes.LOGIN_SUCCESS:
            const {
                user: { profile },
            } = payload
            const { Permissions } = JSON.parse(profile.TADAUserDetails)
            return Object.keys(Permissions).sort()
        default:
            return state
    }
}

const isPending = (state = initState.isPending, action) => {
    switch (action.type) {
        case applicationTypes.APPLICATION_INFO_REQUEST:
            return true
        case applicationTypes.APPLICATION_INFO_SUCCESS:
        case applicationTypes.APPLICATION_INFO_FAILURE:
            return false
        default:
            return state
    }
}

const publicAccessEnabled = (state = false, action) => {
    switch (action.type) {
        case applicationTypes.APPLICATION_INFO_SUCCESS:
            return action.payload.info.IsPublicAccessEnabled ?? false
        default:
            return state
    }
}

const authorizedApps = (state = initState.authorizedApps, action) => {
    const { payload } = action
    switch (action.type) {
        case applicationTypes.APPLICATION_FETCH_SUCCESS:
            return payload.apps
        default:
            return state
    }
}

export default combineReducers({
    current,
    appInfo,
    lastUpdateDate,
    all,
    isPending,
    publicAccessEnabled,
    authorizedApps,
})
