const getApplicationLayout = state => state.appearance.layout

const getRightNavOpen = state => state.appearance.rightNavOpen

const getRightNavTab = state => state.appearance.rightNavTab

const getSideNavState = state => state.appearance.sideNavOpen

const getShowLoader = state => state.appearance.showLoader

const getShowSettings = state => state.appearance.showSettings

const getShowCookieConsent = state => state.appearance.showCookieConsent

const getShowComparePerspectiveFilterPanel = state => state.appearance.showComparePerspectiveFilterPanel

const getShowPerspectiveFilterPopover = state => state.appearance.showPerspectiveFilterPopover

const getShowPerspectiveCompareFilterPopover = state => state.appearance.showPerspectiveCompareFilterPopover

const getShowGlobalFilters = state => state.appearance.showGlobalFilters

const getShowHelp = state => state.appearance.showHelp

const getTopNav = state => state.appearance.topNav

const getHideAppContent = state => state.appearance.hideAppContent

const getShowNeoClassicLayOut = state => state.appearance.layout === 'classic' && state.appearance.showNeoClassicLayout

const isNeoClassicGlobalFiltersOpen = state => state.appearance.neoClassicGlobalFiltersOpen

const getShowApplicationController = state => state.appearance.showApplicationController

const getIsBetaTurnedOn = state =>
    state.appearance.layout === 'classic' && state.appearance.showNeoClassicLayout && state.appearance.betaTurnedOn

export const appearanceSelectors = {
    getApplicationLayout,
    getRightNavOpen,
    getRightNavTab,
    getSideNavState,
    getShowLoader,
    getShowSettings,
    getShowComparePerspectiveFilterPanel,
    getShowPerspectiveFilterPopover,
    getShowPerspectiveCompareFilterPopover,
    getShowGlobalFilters,
    getShowHelp,
    getTopNav,
    getShowCookieConsent,
    getHideAppContent,
    getShowNeoClassicLayOut,
    isNeoClassicGlobalFiltersOpen,
    getShowApplicationController,
    getIsBetaTurnedOn,
}
