import { createSelector } from 'reselect'

const getUserList = (store: any) => store.messenger.userList || []

const getMessageList = (store: any) => store.messenger.messageList || []

const getLoader = (store: any) => store.messenger.showLoader

const getAllUserChats = (store: any) => store.messenger.userChatList || []

const getChatByUserId = conversationId =>
    createSelector([getAllUserChats], allChat => {
        const chat = allChat.find(chat => chat?.conversationData?.id === conversationId)
        if (!chat || !chat.chatMessages) return []

        return chat.chatMessages
    })

const getChatByConversationId = (state, conversationId) => {
    const allChat = state.messenger.userChatList || []

    const chat = allChat.find(chat => chat?.conversationData?.id === conversationId)
    if (!chat || !chat.chatMessages) return []

    return chat.chatMessages
}

export const messengerSelectors = {
    getLoader,
    getUserList,
    getMessageList,
    getAllUserChats,
    getChatByUserId,
    getChatByConversationId,
}
