import { Box, Popover, Typography } from '@mui/material'
import { makeStyles, useTheme } from '@mui/styles'
import { useRef, useState } from 'react'
import { useSelector } from 'react-redux'

import { SearchBar } from 'genesis-suite/components'
import { logEvent } from '../../lib/amplitudeClient'
import { moduleSelectors } from '../../selectors'
import SearchListByNode from './SearchListByNode'

const useStyles = makeStyles(theme => ({
    paper: {
        width: '90vw',
    },
    icon: {
        color: theme.palette.text.primary,
        fontSize: '20px',
    },
    loaderContainer: {
        position: 'relative',
        flexGrow: 1,
    },
    resultsContainer: {
        height: '45vh',
        display: 'flex',
        flexDirection: 'column',
    },
    resultsLabel: {
        padding: theme.spacing(1),
    },
    searchBar: {
        border: `1px solid ${theme.palette.grayscale.lighter}`,
        '& .MuiInput-input': { color: theme.palette.text.default },
    },
    searchButton: {
        minWidth: 'unset',
    },
}))

export default function MobileSearchController() {
    const modulesPending = useSelector(moduleSelectors.getModulesPending)
    const enabled = useSelector(moduleSelectors.getEnableSearch)
    const theme = useTheme()
    const searchBarRef = useRef()

    const [openSearch, setOpenSearch] = useState(false)
    const [text, setText] = useState('')
    const classes = useStyles()

    if (!enabled) return

    function handleText(val) {
        setOpenSearch(false)
        setText(val)
    }

    function handlePerformSearch(query, usedVoice) {
        if (!query) return
        if (usedVoice) logEvent('VOICE_EVENT', { result: query })
        setOpenSearch(true)
    }

    function handleDone() {
        setOpenSearch(false)
        setText('')
    }

    const searchColor = theme.palette.getContrastText(theme.palette.background.topBar)

    return (
        <>
            <Box
                sx={{
                    width: '100%',
                }}
            >
                <SearchBar
                    className={classes.searchBar}
                    InputProps={{ autoFocus: true, disableUnderline: true }}
                    ref={searchBarRef}
                    placeholder="Ask Tada"
                    height="small"
                    onEnter={handlePerformSearch}
                    disabled={modulesPending}
                    value={text}
                    onChange={handleText}
                    radius
                    inputColor={searchColor}
                    iconColor={searchColor}
                    autoFocus={false}
                    collapsable={false}
                />

                <Popover
                    open={openSearch}
                    anchorEl={searchBarRef.current}
                    onClose={handleDone}
                    classes={{ paper: classes.paper }}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    disableAutoFocus
                    disableEnforceFocus
                >
                    <div className={classes.resultsContainer}>
                        <Typography variant="h6" className={classes.resultsLabel}>
                            Search Results
                        </Typography>
                        <SearchListByNode text={text} />
                    </div>
                </Popover>
            </Box>
        </>
    )
}
