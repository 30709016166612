import { ResourceType } from 'genesis-suite/types/networkTypes'
import {
    Category,
    ChartType,
    DataSource,
    PackedBubbleConfig,
    PackedBubbleSeries,
} from 'genesis-suite/types/visualTypes'
import { makeField, stringToFilters } from 'genesis-suite/utils'
import { WidgetConverterProps } from '../migrationTypes'
import { convertTooltip, getPropertyFromResource, makeAggregation, makeNavigation } from '../widgetConverterUtils'

export default async function packedBubbleConverter({
    config,
    dashboardIdByOldId,
    resourceManager,
}: WidgetConverterProps): Promise<PackedBubbleConfig> {
    const { Title, BubbleChartConfig, NoDataMessage } = config
    const {
        Source,
        AreaNameField,
        AreaValueField,
        CrumbMetaName,
        DefaultPerspective,
        AggregationType,
        DisplayCount,
        ShowLabels,
    } = BubbleChartConfig

    const insight = await resourceManager.getInsight(Source.ElementName)

    const category: Category = {
        field: makeField(insight.properties, AreaNameField),
        navigation: makeNavigation(CrumbMetaName, DefaultPerspective, dashboardIdByOldId),
    }

    const value: DataSource = {
        field: makeField(insight.properties, AreaValueField),
        aggregation: makeAggregation(getPropertyFromResource(insight, AreaValueField), AggregationType),
    }

    const singleSeries: PackedBubbleSeries = {
        service: { type: ResourceType.INSIGHT, name: insight.name, id: insight.id },
        values: [value],
        dataLabels: { ...(ShowLabels ? { value: true } : { categories: true }) },
        tooltip: convertTooltip(insight, BubbleChartConfig),
        ...(DisplayCount && { limit: DisplayCount }),
    }

    return {
        version: '2',
        type: ChartType.PACKED_BUBBLE,
        title: Title,
        NoDataMessage: NoDataMessage,
        categories: [category],
        series: [singleSeries],
        filters: stringToFilters(insight.properties, Source.Filters),
    }
}
