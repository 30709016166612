import produce from 'immer'
import { isEmpty, isEqual } from 'lodash'

import { sleep } from 'genesis-suite/utils'
import { dialogCreators, navigationCreators, snackbarCreators } from '.'
import { logEvent } from '../../lib/amplitudeClient'
import { setPreference } from '../../lib/browserPreferences'
import { routePaths } from '../../lib/routes'
import { widgetService } from '../../lib/services'
import { applicationSelectors, filterSelectors, moduleSelectors, widgetSelectors } from '../../selectors'
import { widgetTypes } from '../types'

const changeCurrentLayout = colNum => ({
    type: widgetTypes.CHANGE_CURRENT_LAYOUT,
    payload: { colNum },
})

const changePreviousLayout = colNum => ({
    type: widgetTypes.CHANGE_PREVIOUS_LAYOUT,
    payload: { colNum },
})

const changeSelectedLayout = colNum => ({
    type: widgetTypes.CHANGE_SELECTED_LAYOUT,
    payload: { colNum },
})

const startEditingPerspective = () => (dispatch, getState) => {
    const perspectiveId = widgetSelectors.getCurrentPerspectiveId(getState())
    dispatch({ type: widgetTypes.START_PERSPECTIVE_EDITING, payload: perspectiveId })
}

const startChangingToPerspectiveLayout = data => (dispatch, getState) => {
    const perspectiveId = widgetSelectors.getCurrentPerspectiveId(getState())
    dispatch({ type: widgetTypes.START_CHANGING_PERSPECTIVE_LAYOUT, payload: { id: perspectiveId, data: data } })
}

const resetEditingPerspective = () => async (dispatch, getState) => {
    const state = getState()
    const id = widgetSelectors.getCurrentPerspectiveId(state)
    const editingId = widgetSelectors.getEditingPerspective(state)
    if (editingId && editingId !== id) return dispatch({ type: widgetTypes.STOP_PERSPECTIVE_EDITING })
}

const stopEditingPerspective = () => async dispatch => {
    dispatch({ type: widgetTypes.START_SAVING_WIDGETS })
    dispatch(dialogCreators.showLoading('per-save', 'Saving your perspective...', { hideBackdrop: false }))

    try {
        await dispatch(saveLayout())
        await dispatch(saveTitles())
        await dispatch(saveTopLabelWidget())
    } catch (err) {
        console.error(err)
        dispatch(snackbarCreators.error('Failed to save perspective'))
    }

    dispatch(dialogCreators.hideDialog('per-save'))
    dispatch({ type: widgetTypes.STOP_PERSPECTIVE_EDITING })

    // prevent visor update detect box
    await sleep(3000)
    dispatch({ type: widgetTypes.STOP_SAVING_WIDGETS })
}

const savePerspectiveLayout = obj => async dispatch => {
    dispatch({ type: widgetTypes.START_SAVING_WIDGETS })
    dispatch(dialogCreators.showLoading('per-save', 'Saving your perspective layout...', { hideBackdrop: false }))

    try {
        await dispatch(savePerspectiveLayoutLayoutWithTabs(obj))
        await dispatch(saveTopLabelWidget())
    } catch (err) {
        console.error(err)
        dispatch(snackbarCreators.error('Failed to save perspective layout'))
    }

    dispatch(dialogCreators.hideDialog('per-save'))
    dispatch({ type: widgetTypes.STOP_CHANGING_PERSPECTIVE_LAYOUT })
    // prevent visor update detect box
    await sleep(3000)
    dispatch({ type: widgetTypes.STOP_SAVING_WIDGETS })
}

const deletePerspectiveLayout = Id => async (dispatch, getState) => {
    dispatch(
        dialogCreators.showLoading('per-save', 'Converting your perspective layout to perspective...', {
            hideBackdrop: false,
        })
    )
    try {
        const state = getState()
        const perspectiveConfig = { ...state.widgets.configs[Id] }
        const updatedConfig = produce(perspectiveConfig, draft => {
            draft.ContainerConfig.Layout = null
        })
        await dispatch(saveWidget(updatedConfig))
    } catch (err) {
        console.error(err)
        dispatch(snackbarCreators.error('Failed to convert perspective layout to perspective'))
    }

    dispatch(dialogCreators.hideDialog('per-save'))
    dispatch({ type: widgetTypes.STOP_CHANGING_PERSPECTIVE_LAYOUT })
}

const stopChangingToPerspectiveLayout = () => (dispatch, getState) => {
    dispatch({ type: widgetTypes.STOP_CHANGING_PERSPECTIVE_LAYOUT })
}

const changePerspectiveWidth = value => ({ type: widgetTypes.SET_PERSPECTIVE_WIDTH, payload: { value } })

const changePerspectiveLayouts = layouts => async dispatch => {
    dispatch({ type: widgetTypes.SET_DRAFT_PERSPECTIVE_LAYOUTS, payload: { value: layouts } })
}

const changeLabelWidgetLayouts = payload => async dispatch => {
    dispatch({ type: widgetTypes.SET_LABEL_WIDGET_LAYOUTS, payload: payload })
}

const deleteLabelWidgetLayouts = () => async dispatch => {
    dispatch({ type: widgetTypes.SET_LABEL_WIDGET_LAYOUTS_TO_NULL })
}

const changeTopLabelWidgetLayouts = payload => async dispatch => {
    dispatch({ type: widgetTypes.SET_TOP_LABEL_WIDGET_LAYOUTS, payload: payload })
}

const deleteTopLabelWidgetLayouts = () => async dispatch => {
    dispatch({ type: widgetTypes.SET_TOP_LABEL_WIDGET_LAYOUTS_TO_NULL })
}

const changeTitle = (id, value) => ({ type: widgetTypes.SET_DRAFT_TITLE, payload: { id, value } })

const changeInteractionType = interactionType => dispatch => {
    setPreference('interactionMode', interactionType)
    dispatch({ type: widgetTypes.INTERACTION_CHANGE, payload: { interactionType } })
}

const toggleTVMode = () => {
    logEvent('TV_MODE_TOGGLED')

    return (dispatch, getState) => {
        const state = getState()
        const perspectiveId = widgetSelectors.getCurrentPerspectiveId(state)
        const crumb = filterSelectors.getCoord(state)
        return dispatch(navigationCreators.goTo(routePaths.TV, perspectiveId, { context: crumb }))
    }
}

const saveSuccess = (id, config) => {
    return {
        type: widgetTypes.WIDGET_SAVE_SUCCESS,
        payload: { id, config },
    }
}

const saveWidget = config => {
    let widgetConfig = config
    return (dispatch, getState) => {
        const state = getState()
        const moduleId = moduleSelectors.getModuleId(state)
        const applicationName = applicationSelectors.getCurrentAppName(state)

        if (config?.EditLabelWidget) {
            const { EditLabelWidget, ...rest } = config
            widgetConfig = rest
        }
        if (config?.EditTopLabelWidget) {
            const { EditTopLabelWidget, ...rest } = config
            widgetConfig = rest
        }
        const data = { ...config, Context: null }
        return widgetService
            .save(moduleId, applicationName, data)
            .then(response => {
                if (config?.EditLabelWidget) {
                    dispatch(deleteLabelWidgetLayouts())
                }
                if (config?.EditTopLabelWidget) {
                    dispatch(deleteTopLabelWidgetLayouts())
                }
                dispatch(saveSuccess(response.Id, response))
                return Promise.resolve()
            })
            .catch(error => {
                console.error(error)
                return Promise.reject()
            })
    }
}

const LabelWidgetSave = () => async dispatch => {
    dispatch({ type: widgetTypes.START_SAVING_WIDGETS })
    await dispatch(saveLabelWidget())
    dispatch({ type: widgetTypes.STOP_SAVING_WIDGETS })
}

const saveLabelWidget = () => (dispatch, getState) => {
    const state = getState()
    const labelWidgetLayouts = widgetSelectors.getLabelWidgetLayouts(state)
    if (Object?.keys(labelWidgetLayouts?.payload || {}).length == 0) return
    Object.keys(labelWidgetLayouts?.payload).forEach(key => {
        let matchedConfig = { ...state.widgets.configs[key] }
        const updatedConfig = matchedConfig.LabelConfig.LabelSeries.map(item => {
            const getLGLabelSeries = matchWithSeriesName(labelWidgetLayouts?.payload?.[key]?.lg, item)
            return { ...item, Layout: getLGLabelSeries }
        })
        const updatedLabelConfig = produce(matchedConfig, draft => {
            draft.LabelConfig.LabelSeries = updatedConfig
            draft.EditLabelWidget = true
        })
        return dispatch(saveWidget(updatedLabelConfig))
    })
}

const saveTopLabelWidget = () => (dispatch, getState) => {
    const state = getState()
    const topLabelWidgetLayouts = widgetSelectors.getTopLabelWidgetLayouts(state)
    if (topLabelWidgetLayouts?.payload == undefined) return
    const topLabelWidgetConfigs = topLabelWidgetLayouts?.payload?.configs
    const updatedConfig = matchWidgetWithLayout(topLabelWidgetConfigs, topLabelWidgetLayouts)
    const promises = updatedConfig.map(({ Data, ...rest }) => {
        const updatedTopLabelConfig = produce(rest, draft => {
            draft.EditTopLabelWidget = true
        })
        return dispatch(saveWidget(updatedTopLabelConfig))
    })
    return Promise.all(promises)
}

const matchWidgetWithLayout = (topLabelWidgets, topLabelWidgetLayouts) => {
    return topLabelWidgets.map(item => {
        const updated_series = item.LabelConfig.LabelSeries.map(each => {
            let each_id = item.Id + '_' + each.SeriesName.replaceAll(' ', '')
            let find_layout = topLabelWidgetLayouts?.payload?.lg.find(layout => layout.i === each_id)
            return { ...each, Layout: find_layout }
        })
        return {
            ...item,
            LabelConfig: { ...item.LabelConfig, LabelSeries: updated_series },
        }
    })
}

const matchWithSeriesName = (data, item) => {
    if (data == undefined) return {}
    return data?.find(each => each.i == item.SeriesName.replaceAll(' ', ''))
}

const saveLayout = () => (dispatch, getState) => {
    const state = getState()
    const hasEditedLayouts = widgetSelectors.getHasEditedPerspectiveLayouts(state)
    if (!hasEditedLayouts) return

    const config = widgetSelectors.getCurrentPerspectiveConfig(state)
    const draftLayouts = widgetSelectors.getDraftPerspectiveLayouts(state)

    const updatedCells = config.ContainerConfig.Cells.map(c => {
        if (!c.WidgetId) return c

        const sm = draftLayouts.sm.find(l => l.i === c.WidgetId)
        const lg = draftLayouts.lg.find(l => l.i === c.WidgetId)

        return {
            ...c,
            ...(sm && { Small: { x: sm.x, y: sm.y, w: sm.w, h: sm.h } }),
            ...(lg && { Large: { x: lg.x, y: lg.y, w: lg.w, h: lg.h } }),
        }
    })

    const updatedConfig = produce(config, draft => {
        draft.ContainerConfig.Cells = updatedCells
    })

    logEvent('SAVE_PERSPECTIVE_LAYOUT')
    return dispatch(saveWidget(updatedConfig))
}

const savePerspectiveLayoutLayoutWithTabs = obj => (dispatch, getState) => {
    const state = getState()
    const perspectiveConfig = { ...state.widgets.configs[obj.perspectiveId] }
    const Tabs = obj.tabs.map(tab => {
        const TabPerspectives = tab?.perspective?.map(p => {
            let TabPerspectivePosition = {}
            if (p?.Position?.sm?.length > 0) {
                const sm = layoutMapping(p?.Position?.sm)
                TabPerspectivePosition['Small'] = sm
            } else {
                TabPerspectivePosition['Small'] = null
            }
            if (p?.Position?.lg?.length > 0) {
                const lg = layoutMapping(p?.Position?.lg)
                TabPerspectivePosition['Large'] = lg
            } else {
                TabPerspectivePosition['Large'] = null
            }
            return { Id: p.Id, Position: TabPerspectivePosition, Order: p.Order }
        })
        return { Name: tab.name, Perspectives: TabPerspectives, Order: tab.Order }
    })
    const perspectiveLayout = {
        Name: obj.perspectiveLayoutName,
        WidgetId: obj.layoutWidget,
        Tabs: Tabs,
    }

    if (isEqual(perspectiveLayout, perspectiveConfig?.ContainerConfig?.Layout)) return

    const updatedConfig = produce(perspectiveConfig, draft => {
        draft.ContainerConfig.Layout = perspectiveLayout
    })

    return dispatch(saveWidget(updatedConfig))
}

const layoutMapping = obj => {
    return obj.map(ele => {
        return {
            WidgetId: ele.i,
            Xpos: ele.x,
            Ypos: ele.y,
            Width: ele.w,
            Height: ele.h,
        }
    })
}

const saveTitles = () => (dispatch, getState) => {
    const state = getState()
    const draftTitles = widgetSelectors.getDraftTitles(state)
    if (isEmpty(draftTitles)) return

    const promises = Object.keys(draftTitles).map(widgetId => {
        const config = widgetSelectors.getWidgetConfig(state, widgetId)
        return dispatch(saveWidget({ ...config, Title: draftTitles[widgetId] }))
    })

    logEvent('SAVE_WIDGET_NAMES')
    return Promise.all(promises)
}

const setCommentingWidget = widgetId => ({
    type: widgetTypes.SET_COMMENTING_WIDGET,
    payload: { widgetId },
})

const setShouldRefresh = widgetIds => ({
    type: widgetTypes.SET_SHOULD_REFRESH,
    payload: { widgetIds },
})

const clearShouldRefresh = () => ({ type: widgetTypes.CLEAR_SHOULD_REFRESH })

const setDiagnostics = (id, time, trace) => ({ type: widgetTypes.SET_DIAGNOSTICS, payload: { id, time, trace } })

const addThumbnailRequest = widgetId => ({ type: widgetTypes.ADD_THUMBNAIL_REQUEST, payload: { id: widgetId } })
const removeThumbnailRequest = widgetId => ({ type: widgetTypes.REMOVE_THUMBNAIL_REQUEST, payload: { id: widgetId } })
const saveUserWidget = config => ({ type: widgetTypes.SAVE_USER_WIDGET, payload: { config } })

export const widgetCreators = {
    changeCurrentLayout,
    changeSelectedLayout,
    startEditingPerspective,
    stopEditingPerspective,
    startChangingToPerspectiveLayout,
    stopChangingToPerspectiveLayout,
    resetEditingPerspective,
    changePerspectiveWidth,
    changePerspectiveLayouts,
    changeTitle,
    changeInteractionType,
    toggleTVMode,
    saveWidget,
    LabelWidgetSave,
    setCommentingWidget,
    setShouldRefresh,
    clearShouldRefresh,
    setDiagnostics,
    addThumbnailRequest,
    removeThumbnailRequest,
    changePreviousLayout,
    changeLabelWidgetLayouts,
    deleteLabelWidgetLayouts,
    changeTopLabelWidgetLayouts,
    deleteTopLabelWidgetLayouts,
    savePerspectiveLayout,
    deletePerspectiveLayout,
    saveUserWidget,
}
