import { SemanticTypeById } from 'genesis-suite/types/architectureTypes'
import {
    InsightResource,
    LinkResource,
    NodeResource,
    ProfileConfig,
    Property,
    ResourceType,
    ServerProfileConfig,
    ServerProfileProperty,
} from 'genesis-suite/types/networkTypes'
import { getInsightPropertyAggregation } from 'genesis-suite/utils/networkUtils'
import { uniqBy } from 'lodash'
import { useSnackbar } from 'notistack'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import useSWR, { SWRConfiguration } from 'swr'
import { parseProperty } from '../components/edit_widget/utils'
import { architectureService } from '../lib/services'
import { applicationSelectors, getSelectedBackup } from '../selectors'
import { useSemanticTypeById } from './useSemanticTypes'

export type ResourceGeneric<T extends ResourceType> = T extends ResourceType.NODE
    ? NodeResource
    : T extends ResourceType.LINK
    ? LinkResource
    : InsightResource

const swrOptions: SWRConfiguration = {
    revalidateOnFocus: false,
    errorRetryCount: 2,
    dedupingInterval: 3600 * 1000,
}

/** Get resource by name with refresh callback */
export default function useResourceMeta<T extends ResourceType>(
    type: T,
    name: string
): [ResourceGeneric<T>, () => void, any] {
    const appName = useSelector(applicationSelectors.getCurrentAppName)
    const selectedBackup = useSelector(getSelectedBackup)

    const semanticTypeById = useSemanticTypeById()

    const { data, error, mutate } = useSWR(
        name && semanticTypeById ? ['resource-meta', appName, name] : null,
        ([_, appName, name]) => fetchResource(appName, type, name, semanticTypeById, selectedBackup?.[0]?.Id),
        swrOptions
    )
    const { enqueueSnackbar: showSnackbar } = useSnackbar()

    useEffect(() => {
        if (error) {
            console.error(error)
            showSnackbar('An error occurred getting resource', { variant: 'error' })
        }
    }, [error])

    return [data, mutate, error]
}

interface backupObj {
    Id: string
}

export function fetchResource<T extends ResourceType>(
    appName: string,
    type: T,
    name: string,
    semanticTypeById: SemanticTypeById,
    backupId?: string
): Promise<ResourceGeneric<T>> {
    return architectureService.getResourceMeta(appName, name, backupId).then(resource => {
        switch (type) {
            case ResourceType.INSIGHT:
                return parseInsight(resource, semanticTypeById) as ResourceGeneric<T>
            case ResourceType.UNKNOWN:
                return parseInsight(resource, semanticTypeById) as ResourceGeneric<T>
            case ResourceType.LINK:
                return parseLink(resource, semanticTypeById) as ResourceGeneric<T>
            case ResourceType.NODE:
                return parseNode(resource, semanticTypeById) as ResourceGeneric<T>
        }
    })
}

function parseInsight(rawInsight, semanticTypeById: SemanticTypeById): InsightResource {
    let properties = rawInsight.Attributes.map((a: any): Property => {
        const { AttributeContainerName, AttributeType, AttributeId } = a
        const resourceArray = AttributeType === 'Attribute' ? rawInsight.Nodes : rawInsight.Links
        const nodeOrLink = resourceArray.find(r => r.Name === AttributeContainerName)
        if (!nodeOrLink) return undefined

        const type = AttributeType === 'Attribute' ? ResourceType.NODE : ResourceType.LINK
        const container = { type, id: nodeOrLink.Id, name: AttributeContainerName }
        const attribute = nodeOrLink.Attributes?.find(a => a.Id === AttributeId)
        if (attribute) return { container, ...parseProperty(attribute, semanticTypeById) }

        const _function = nodeOrLink.Functions?.find(f => f.Id === AttributeId)
        if (_function) return { container, ...parseProperty(_function, semanticTypeById) }

        return undefined
    })

    const container = { type: ResourceType.INSIGHT, id: rawInsight.Id, name: rawInsight.Name }
    rawInsight.Functions?.forEach(f => {
        properties.push({ container, ...parseProperty(f, semanticTypeById) })
    })

    // HACK - server is sending duplicate Attributes?
    properties = uniqBy(
        properties.filter(p => Boolean(p)).sort((a, b) => a.name.localeCompare(b.name)),
        'id'
    )

    return {
        aggregationByPropertyId: getInsightPropertyAggregation(rawInsight.Attributes),
        id: rawInsight.Id,
        name: rawInsight.Name,
        description: rawInsight.Description,
        type: ResourceType.INSIGHT,
        nodeIds: rawInsight.Nodes?.map(n => n.Id),
        nodeNames: rawInsight.Nodes?.map(n => n.Name),
        linkIds: rawInsight.Links?.map(l => l.Id),
        properties,
        removeDuplicates: rawInsight.RemoveDuplicates,
        isPersisted: rawInsight.IsPersisted,
    }
}

function parseLink(rawLink, semanticTypeById: SemanticTypeById): LinkResource {
    const { nodes, properties } = rawLink.Nodes?.map(node => parseNode(node, semanticTypeById)).reduce(
        (acc, node) => {
            const { properties, ...rest } = node
            acc.nodes.push(rest)
            acc.properties = acc.properties.concat(properties)
            return acc
        },
        { nodes: [], properties: [] }
    ) ?? { nodes: [], properties: [] }

    return {
        id: rawLink.Id,
        name: rawLink.Name,
        description: rawLink.Description,
        type: ResourceType.LINK,
        nodes,
        properties: [...properties, ...makeProperties(ResourceType.LINK, rawLink, semanticTypeById)].sort((a, b) =>
            a.name.localeCompare(b.name)
        ),
    }
}

function parseNode(rawNode, semanticTypeById: SemanticTypeById): NodeResource {
    const profile = parseProfile(rawNode.EntityProfileConfig)

    return {
        id: rawNode.Id,
        name: rawNode.Name,
        description: rawNode.Description,
        type: ResourceType.NODE,
        properties: makeProperties(ResourceType.NODE, rawNode, semanticTypeById).sort((a, b) =>
            a.name.localeCompare(b.name)
        ),
        profile,
    }
}

function makeProperties(resourceType: ResourceType, nodeOrLink, semanticTypeById: SemanticTypeById): Property[] {
    const container = {
        type: resourceType,
        id: nodeOrLink.Id,
        name: nodeOrLink.Name,
        displayName: nodeOrLink.DisplayName,
    }
    const attributes = nodeOrLink.Attributes?.map(a => parseProperty(a, semanticTypeById, container)) ?? []
    const functions = nodeOrLink.Functions?.map(a => parseProperty(a, semanticTypeById, container)) ?? []

    return [...attributes, ...functions]
}

function parseProfile(rawProfile: ServerProfileConfig): ProfileConfig {
    if (!rawProfile) return

    const { ProfileProperties, LinkedEntities } = rawProfile

    const makeProfileProperty = (p: ServerProfileProperty) => ({ id: p.PropertyId, name: p.PropertyName })

    return {
        properties: ProfileProperties?.map(makeProfileProperty),
        linkedNodes:
            LinkedEntities?.map(n => ({
                id: n.ChildNodeId,
                name: n.ChildNodeName,
                properties: n.Properties?.map(makeProfileProperty),
            })) ?? [],
    }
}
