import { hubService } from '../../api'
import { collaborationTypes } from '../types'
import { registerCollaborationClientFunctions } from '../../lib/collaborationClient'
import { applicationSelectors, authSelectors, moduleSelectors } from '../../selectors'
import { subscribeToNotifications, registerNotificationClientFunctions } from '../../lib/notificationClient'
import { subscribeToMessageNotifications, registerMessagesClientFunctions } from '../../lib/messagesClient'

const connectionRequest = () => {
    return {
        type: collaborationTypes.COLLABORATION_CONNECTION_REQUEST,
    }
}

const connectionSuccess = hubID => {
    return {
        type: collaborationTypes.COLLABORATION_CONNECTION_SUCCESS,
        payload: {
            hubID,
        },
    }
}

const connectionFailure = error => {
    return {
        type: collaborationTypes.COLLABORATION_CONNECTION_FAILURE,
        payload: {
            error,
        },
        error: true,
    }
}

const connect = () => {
    return (dispatch, getState) => {
        const state = getState()
        registerCollaborationClientFunctions(dispatch)
        registerNotificationClientFunctions(dispatch)
        registerMessagesClientFunctions(state, dispatch)
        dispatch(connectionRequest())

        const user = authSelectors.getUser(state)
        const appName = applicationSelectors.getCurrentAppName(state)
        const moduleId = moduleSelectors.getModuleId(state)
        const moduleName = moduleSelectors.getModuleName(state)
        const appDomainId = applicationSelectors.getAppDomainId(state, appName)
        const { alias, userId, domainId } = user

        const subscribeToHub = hubID => {
            const moduleCloud = moduleSelectors.getModuleCloud(state)
            subscribeToNotifications(appName, moduleCloud, moduleId, moduleName)
            subscribeToMessageNotifications(userId, appDomainId)
            dispatch(connectionSuccess(hubID))
        }

        const onConnectionFailure = error => {
            console.error('error: ', error)
            dispatch(connectionFailure(error))
        }

        hubService.initializeHub(appName, alias, subscribeToHub, onConnectionFailure)
    }
}

const connectionStop = () => {
    return {
        type: collaborationTypes.COLLABORATION_CONNECTION_STOP,
    }
}

const stop = () => {
    return dispatch => {
        hubService.stopHub().then(
            success => {
                dispatch(connectionStop())
            },
            error => {
                console.error(error)
            }
        )
    }
}

export const hubCreators = {
    connect,
    stop,
}
