import { useState, useContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Box, Button, Chip, Typography } from '@mui/material'
import { uniq } from 'lodash'
import produce from 'immer'

import { PropertyType } from 'genesis-suite/types/architectureTypes'
import {
    Aggregation,
    ChartType,
    DataField,
    Category,
    SeriesConfig,
    CategoryTableColumn,
} from 'genesis-suite/types/visualTypes'
import { letMeMap } from 'genesis-suite/types/utilTypes'
import { applicationSelectors, businessExplorerSelectors, insightSelectors } from '../../selectors'
import BusinessExplorerInsights from './BusinessExplorerInsights'
import BusinessExplorerProperties from './BusinessExplorerProperties'
import { getDataFieldProperty } from '../edit_widget/utils'
import BusinessExplorerFormatter from './BusinessExplorerFormatter'
import { businessExplorerCreators } from '../../actions/creators'
import BusinessExplorerPublisher from './BusinessExplorerPublisher'
import { ConfigContext } from '../edit_widget'
import BusinessExplorerWidgets from './BusinessExplorerWidgets'
import { logEvent } from '../../lib/amplitudeClient'
import { Property, ResourceType } from 'genesis-suite/types/networkTypes'
import useResourceMeta from '../../hooks/useResourceMeta'
import { useFeature } from '../../lib/featureFlags'

const titleHeight = 60
const logEvents = {
    createdWidget: false,
    editedWidget: false,
}

export default function BusinessExplorer() {
    const application = useSelector(applicationSelectors.getCurrentAppName)
    const insights = useSelector(insightSelectors.getList)
    const configDictionary = useSelector(businessExplorerSelectors.getConfigDictionary)
    const service = useSelector(businessExplorerSelectors.getService)
    const anchorId = useSelector(businessExplorerSelectors.getAnchorId)
    const selectedIndex = useSelector(businessExplorerSelectors.getSelectedConfigId)
    const dispatch = useDispatch()

    const [enableBuilder] = useFeature('builder')

    const { init, config: editConfig } = useContext(ConfigContext)

    const [isPublishing, setIsPublishing] = useState(false)
    const [publishIds, setPublishIds] = useState<string[]>([])

    const [insight] = useResourceMeta(ResourceType.INSIGHT, service?.name)
    const properties = insight?.properties || []

    const insightFocalPoints = insights.find(i => i.id === service)?.nodeNames ?? []
    const selectedPropertyIds = findSelectedPropertyIds(Object.values(configDictionary), properties)
    const configIds = Object.keys(configDictionary)
    const anchorProperty = properties?.find(p => p.id === anchorId)

    function handlePropertySelect(id) {
        if (!anchorId) return dispatch(businessExplorerCreators.setAnchorId(id))
        if (id === anchorId) return handleClear()

        if (!logEvents.createdWidget) {
            logEvents.createdWidget = true
            logEvent('CREATED_BUSINESS_EXPLORER_WIDGET')
        }

        const property = properties.find(p => p.id === id)
        const configKeysWithProperty = findConfigKeysWithProperty(configDictionary, property)

        if (configKeysWithProperty.length)
            return dispatch(businessExplorerCreators.deleteConfigs(configKeysWithProperty))

        const anchor = properties.find(p => p.id === anchorId)
        const { container, name, semanticType, displayName } = anchor
        const anchorField = {
            resourceType: container.type,
            resourceId: container.id,
            resourceName: container.name,
            id: anchorId,
            name,
            dataTypeId: semanticType.id,
        }
        let categoryField: Category | CategoryTableColumn
        let seriesField: DataField
        let seriesTitle: string

        if (anchor.semanticType.type === PropertyType.QUANTITATIVE) {
            seriesField = anchorField
            seriesTitle = displayName
            categoryField = {
                field: {
                    resourceType: property.container.type,
                    resourceId: property.container.id,
                    resourceName: property.container.name,
                    id,
                    name: property.name,
                    dataTypeId: property.semanticType.id,
                },
                title: property.displayName,
            }
        } else {
            categoryField = {
                field: anchorField,
                title: displayName,
            }
            seriesField = {
                resourceType: property.container.type,
                resourceId: property.container.id,
                resourceName: property.container.name,
                id,
                name: property.name,
                dataTypeId: property.semanticType.id,
            }
            seriesTitle = property.displayName
        }

        const categories = [categoryField]
        const series = [{ service, values: [{ field: seriesField, aggregation: Aggregation.SUM, title: seriesTitle }] }]
        const baseConfig = makeBaseConfig(application)
        dispatch(businessExplorerCreators.createConfig({ ...baseConfig, type: ChartType.COMBO, categories, series }))
    }

    function handleEditConfig(id?: string) {
        if (!logEvents.editedWidget) {
            logEvents.editedWidget = true
            logEvent('EDITED_BUSINESS_EXPLORER_WIDGET')
        }

        if (selectedIndex != null) dispatch(businessExplorerCreators.updateSelectedConfig(editConfig))
        dispatch(businessExplorerCreators.setSelectedConfig(id))
        init(id ? { config: configDictionary[id] } : undefined)
    }

    function handlePublishConfig(id?: string) {
        setPublishIds(s =>
            produce(s, draft => {
                const index = draft.indexOf(id)
                if (index > -1) draft.splice(index, 1)
                else draft.push(id)
            })
        )
    }

    function deleteConfig(id: string) {
        if (id === selectedIndex) init()
        dispatch(businessExplorerCreators.deleteConfig(id))
    }

    function handleClear() {
        dispatch(businessExplorerCreators.reset())
    }

    function handlePublishButton() {
        if (isPublishing) {
            logEvent('CANCEL_BUSINESS_EXPLORER_SAVING')
            initPublish()
        } else {
            logEvent('START_BUSINESS_EXPLORER_SAVING')
            startPublish()
        }
    }

    function startPublish() {
        if (selectedIndex != null) handleEditConfig()

        setPublishIds(Object.keys(configDictionary))
        setIsPublishing(true)
    }

    function handleDonePublish() {
        logEvent('FINISH_BUSINESS_EXPLORER_SAVING')
        initPublish()
    }

    function initPublish() {
        setPublishIds([])
        setIsPublishing(false)
    }

    return (
        <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', overflow: 'hidden' }}>
            <Box height={titleHeight} display="flex" alignItems="center" p={2} borderBottom="1px solid #bdbdbd">
                <Typography variant="h5">Business Explorer</Typography>
                <Box ml={1} />
                <Chip label="Beta" size="small" color="primary" />
                <Box flex="1" />
                <Box display="flex" columnGap="8px">
                    <Button variant="outlined" disabled={!anchorId || isPublishing} onClick={handleClear}>
                        Clear
                    </Button>
                    {enableBuilder && (
                        <Button
                            sx={{ width: '90%' }}
                            variant="contained"
                            color={isPublishing ? 'inherit' : 'primary'}
                            disabled={!configIds.length}
                            onClick={handlePublishButton}
                        >
                            {isPublishing ? 'Cancel' : 'Save'}
                        </Button>
                    )}
                </Box>
            </Box>

            <Box
                sx={{ flex: 1, display: 'grid', overflow: 'hidden' }}
                style={{ gridTemplateColumns: `250px 250px auto ${selectedIndex || isPublishing ? '250px' : ''}` }}
            >
                <BusinessExplorerInsights insights={insights} selectedInsightId={service?.id} />

                {service ? (
                    <>
                        <BusinessExplorerProperties
                            properties={properties}
                            anchorId={anchorId}
                            selectedPropertyIds={selectedPropertyIds}
                            onPropertySelect={handlePropertySelect}
                        />

                        {configIds.length ? (
                            <>
                                <BusinessExplorerWidgets
                                    configDictionary={configDictionary}
                                    publishIds={publishIds}
                                    editId={selectedIndex}
                                    editConfig={editConfig}
                                    onSelect={isPublishing ? handlePublishConfig : undefined}
                                    onEdit={isPublishing ? undefined : handleEditConfig}
                                    onDelete={isPublishing ? undefined : deleteConfig}
                                />

                                {selectedIndex != null && (
                                    <BusinessExplorerFormatter onDone={() => handleEditConfig(null)} />
                                )}

                                <BusinessExplorerPublisher
                                    open={isPublishing}
                                    selectedConfigIds={publishIds}
                                    configDictionary={configDictionary}
                                    focalPoints={insightFocalPoints}
                                    onDone={handleDonePublish}
                                />
                            </>
                        ) : (
                            <HelpMessage>
                                {anchorId
                                    ? `Choose matching ${
                                          anchorProperty?.container.type === ResourceType.NODE
                                              ? 'metrics'
                                              : 'business elements'
                                      }`
                                    : 'Choose a business element or metric as an anchor'}
                            </HelpMessage>
                        )}
                    </>
                ) : (
                    <HelpMessage>Choose an insight</HelpMessage>
                )}
            </Box>
        </Box>
    )
}

const makeBaseConfig = (appName): Omit<SeriesConfig, 'type' | 'categories' | 'series'> => ({
    version: '2',
    title: '',
    appName,
    xAxis: {},
    yAxis: [{}],
    NoDataMessage: 'No Data.',
})

/** loop through multiple configs to create a unique list of all selected properties */
function findSelectedPropertyIds(configs: SeriesConfig[], properties: Property[]) {
    if (!properties) return

    const ids: string[] = []

    configs.forEach(config => {
        const { categories, series } = config
        categories.forEach(cat => {
            ids.push(getDataFieldProperty(cat.field, properties).id)
        })

        letMeMap(series).forEach(({ values, subSeries }) => {
            if (subSeries) ids.push(getDataFieldProperty(subSeries.field, properties).id)
            values.forEach(v => {
                ids.push(getDataFieldProperty(v.field, properties).id)
            })
        })
    })

    return uniq(ids)
}

/** return all config keys that have property in their config */
function findConfigKeysWithProperty(configByKey: { [key: string]: SeriesConfig }, property: Property) {
    return Object.keys(configByKey).filter(key => {
        const { categories, series } = configByKey[key]

        for (const category of categories) {
            if (category.field.id === property.id) return true
        }

        for (const { values, subSeries } of series) {
            for (const value of values) {
                if (value.field.id === property.id) return true
            }

            if (subSeries?.field.id === property.id) return true
        }

        return false
    })
}

function HelpMessage({ children }) {
    return (
        <Box
            sx={{
                bgcolor: 'primary.main',
                borderRadius: '4px',
                height: 'fit-content',
                left: 2,
                p: 2,
                position: 'relative',
                top: '50px',
                width: 'fit-content',
            }}
        >
            <Typography sx={{ color: 'primary.contrastText' }}>{children}</Typography>
        </Box>
    )
}
