import React, { useState, useEffect } from 'react'
import UploadIcon from '@mui/icons-material/Upload'
import classnames from 'classnames'
import {
    Tooltip,
    IconButton,
    Typography,
    Grid,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
} from '@mui/material'
import { CognitiveIcon } from 'genesis-suite/components'
import { isEmpty } from 'lodash'
import { Close } from 'genesis-suite/icons'
import makeStyles from '@mui/styles/makeStyles'
import { iconService } from '../../../lib/services'

const useStyles = makeStyles(({ spacing }) => ({
    closeButton: {
        position: 'absolute',
        top: spacing(1),
        right: spacing(1),
    },
    cognitiveIcon: {
        transition: 'transform 0.2s ease-in-out, background-color 0.2s ease-in-out',
        textAlign: 'center',
        borderRadius: '8px',
        '&:hover': {
            transform: 'scale(1.1)',
            backgroundColor: '#898989',
            cursor: 'pointer',
        },
    },
    cognitiveIconSelected: {
        backgroundColor: '#898989',
    },
}))

export default function IconSelector({ icon = null, onChange = null }) {
    const classes = useStyles()
    const [showIcons, setShowIcons] = useState(false)
    const [selectedIcon, setSelectedIcon] = useState(icon)

    const onSelectClick = () => {
        onChange?.(selectedIcon)
        setShowIcons(false)
    }

    return (
        <>
            <Tooltip title="Upload icon">
                <IconButton onClick={() => setShowIcons(true)} size="small">
                    {icon ? <CognitiveIcon icon={{ file: icon }} /> : <UploadIcon />}
                </IconButton>
            </Tooltip>
            <Dialog open={showIcons} onClose={() => setShowIcons(false)} maxWidth="lg">
                <DialogTitle>Select Icon</DialogTitle>
                <DialogContent style={{ padding: 0, height: '70vh' }}>
                    <IconButton className={classes.closeButton} onClick={() => setShowIcons(false)} size="large">
                        <Close />
                    </IconButton>

                    <Grid container sx={{ width: '100%', marginLeft: 0, padding: 2 }} spacing={2}>
                        <IconsDisplay selectedIcon={selectedIcon} setSelectedIcon={setSelectedIcon} />
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" color="secondary" autoFocus onClick={() => setShowIcons(false)}>
                        Cancel
                    </Button>
                    <Button disabled={!selectedIcon} variant="contained" color="primary" onClick={onSelectClick}>
                        Select
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

function IconsDisplay({ selectedIcon, setSelectedIcon }) {
    const [icons, setIcons] = useState<any>({})
    const [loading, setLoading] = useState<boolean>(true)
    const classes = useStyles()
    const fetchIcons = async () => {
        try {
            setLoading(true)
            const response = await iconService.getIcons()
            setIcons(response)
        } catch (error) {
            console.error('Error fetching icons:', error)
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        fetchIcons()
    }, [])
    return (
        <>
            {loading ? (
                <Typography>Loading Icons...</Typography>
            ) : isEmpty(icons) ? (
                <Typography>No Icons found</Typography>
            ) : (
                Object.keys(icons).map(iconGroup => (
                    <React.Fragment key={iconGroup}>
                        <Typography variant="h6" paddingLeft={1}>
                            {iconGroup}
                        </Typography>
                        <Grid container spacing={2} sx={{ padding: 2 }}>
                            {icons[iconGroup].map((iconName: string) => (
                                <Grid item md={2} key={iconName}>
                                    <div
                                        className={classnames(classes.cognitiveIcon, {
                                            [classes.cognitiveIconSelected]:
                                                selectedIcon === `${iconGroup}/${iconName}`,
                                        })}
                                        onClick={() => setSelectedIcon(`${iconGroup}/${iconName}`)}
                                    >
                                        <CognitiveIcon icon={{ file: `${iconGroup}/${iconName}` }} />
                                        <Typography>{iconName}</Typography>
                                    </div>
                                </Grid>
                            ))}
                        </Grid>
                    </React.Fragment>
                ))
            )}
        </>
    )
}
