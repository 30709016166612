import { isEmpty, isEqual } from 'lodash'
import { useSnackbar } from 'notistack'
import {
    createContext,
    Dispatch,
    ReactNode,
    SetStateAction,
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { useMediaQuery } from '@mui/material'
import { useTheme } from '@mui/styles'
import {
    ChartType,
    Dashboard,
    DashboardDevice,
    DashboardNodeFilter,
    LayoutDetails,
    Widget,
} from 'genesis-suite/types/visualTypes'
import { Layout } from 'react-grid-layout'
import { widgetTypes } from '~/actions/types'
import defaultMobileHomePage from '~/lib/defaultMobileHomePage'
import { widgetService } from '~/lib/services'
import { filterCreators } from '../../../actions/creators'
import { useIsMobile } from '../../../hooks/useIsMobile'
import { logEvent } from '../../../lib/amplitudeClient'
import { updateDashboardOrCreateDraft } from '../../../lib/manageUtils'
import {
    applicationSelectors,
    authSelectors,
    deploymentSelectors,
    filterSelectors,
    menuSelectors,
    moduleSelectors,
    widgetSelectors,
} from '../../../selectors'
import { FilterApplyMethod, FilterSourceType } from '../../../types/FilterTypes'
import { emptyDashboardFilterValues, filterHasValue, filtersMatch } from '../utils/contextFilterUtils'
import {
    getPositionFromLayout,
    gridGap,
    makeGridLayout,
    makeLayout,
    makeWidgetsWithPosition,
    WidgetWithPosition,
} from './dashboardUtils'
import { useHomeDashboardContext } from './HomeDashboardContext'
import { DashboardAction } from './types'

interface Props {
    actions?: DashboardAction[]
    children: ReactNode
    config: Dashboard
    /** (default true) */
    editable?: boolean
    /** Request new widgets (add/remove widget) */
    getWidgets?: () => void
    widgets: Widget[]
}

type ContextProps = {
    actions?: DashboardAction[]
    config: Dashboard
    devicePreview: DashboardDevice
    editable: boolean
    editing: boolean
    filters: DashboardNodeFilter[]
    filterSource: FilterSourceType
    hiddenWidgets: WidgetWithPosition[]
    layout: LayoutDetails
    onEditStart: () => void
    onEditDone: (cancelChanges?: boolean, saveHomeDashboardForAll?: boolean) => Promise<void>
    /** Based on the current device, update the position of only the widgets passed */
    positionWidgets: (updated: Layout[]) => void
    setDevicePreview: Dispatch<SetStateAction<DashboardDevice>>
    showExistingWidgetsDialog: boolean
    toggleShowExistingWidgetsDialog: () => void
    updateConfig: (body: Partial<Dashboard>, save?: boolean) => void
    updateFilters: (filters: DashboardNodeFilter[], method: FilterApplyMethod) => Promise<void>
    widgets: WidgetWithPosition[]
    expandedWidgetRefs?: any
    dashboardGridRowHeightRef?: any
    expanded?: any
    toggleExpandCollapseWidget?: (widgetKey: string, isSubmenu?: boolean) => void
    dashboardLoading?: boolean
}

const DashboardContext = createContext<Partial<ContextProps>>({})

/** State management for editing Series widgets (have a series property for data) */
const DashboardProvider = ({ actions, children, config: _config, editable = true, getWidgets, widgets }: Props) => {
    const { enqueueSnackbar: showSnackbar } = useSnackbar()
    const isMobile = useIsMobile()

    const expandedWidgetRefs = useRef({})
    const dashboardGridRowHeightRef = useRef()

    const appName = useSelector(applicationSelectors.getCurrentAppName)
    const viewFlag = useSelector(deploymentSelectors.getDeploymentViewFlag)
    const tModule = useSelector(moduleSelectors.getCurrentModule)
    const dispatch = useDispatch()

    const [config, setConfig] = useState(_config)
    const [editing, setEditing] = useState(false)
    const [dirty, setDirty] = useState(false)
    const [devicePreview, setDevicePreview] = useState(DashboardDevice.LARGE)
    const [showExistingWidgetsDialog, setShowExistingWidgetsDialog] = useState(false)
    const recommendedWidgets: any = useSelector(widgetSelectors.getRecommendedWidgets)
    const theme = useTheme()
    const isExtraSmall = useMediaQuery(theme.breakpoints.down('sm'))
    const [dashboardLoading, setDashboardLoading] = useState(true)

    let updateUserDashboard: ((dashboard: Dashboard) => void) | undefined
    let addAppLevelDashboard: ((newDashboard: Dashboard) => void) | undefined
    try {
        const homeDashboardContext = useHomeDashboardContext()
        updateUserDashboard = homeDashboardContext.updateUserDashboard
        addAppLevelDashboard = homeDashboardContext.addAppLevelDashboard
    } catch (e) {
        updateUserDashboard = undefined
        addAppLevelDashboard = undefined
    }

    const device = isMobile ? (isExtraSmall ? DashboardDevice.EXTRASMALL : DashboardDevice.SMALL) : devicePreview
    const widgetsWithPosition = makeWidgetsWithPosition(config, widgets, device)
    const visibleWidgets = widgetsWithPosition.filter(w => !w.position?.hide)
    const hiddenWidgets = widgetsWithPosition.filter(w => w.position?.hide)
    const layout = makeLayout(config, device)
    const configs = useSelector(widgetSelectors.getWidgetConfigs)

    const [expanded, setExpanded] = useState({})
    const favorites = useSelector(state => authSelectors.getPreference(state, 'widgetFavorites'))
    const appOptions = JSON.parse(useSelector(moduleSelectors.getAppOptions) || null)
    const businessElements = useSelector(menuSelectors.getTopNav)
    const _360views = useSelector(menuSelectors.getViews)
    const modules = useSelector(moduleSelectors.getActiveModules)

    const checkForEmptyRecommondations = async config => {
        config = configureMobileWidgets(config)
        if (config?.widgets?.length === 0) {
            setConfig(config)
            setDashboardLoading(false)
            return
        }

        const hasRecommendationWidget = config.widgets.some(w => w.inline?.type === ChartType.RECOMMENDED_WIDGET)
        if (!hasRecommendationWidget) {
            setConfig(config)
            setDashboardLoading(false)
            return
        }

        let widgetIds = []

        if (!recommendedWidgets.fetched) {
            const res = await widgetService.getWidgetRecommendations(tModule.name, appName)

            widgetIds = [...new Set(res?.map(apiItem => apiItem.WidgetId))]
            dispatch({ type: widgetTypes.SET_RECOMMENDED_WIDGETS, payload: { widgetIds } })
        } else {
            widgetIds = recommendedWidgets.widgets
        }

        let haveRecommendationWidget = false

        if (widgetIds.length > 0) {
            const configIds = Object.keys(configs)
            const matchingConfigIds = configIds?.filter(configId => widgetIds.includes(configId))
            if (matchingConfigIds.length > 0) {
                haveRecommendationWidget = true
            }
        }

        if (!haveRecommendationWidget) {
            config = {
                ...config,
                widgets: config.widgets.map(widget => {
                    if (widget.inline?.type === ChartType.RECOMMENDED_WIDGET) {
                        return {
                            ...widget,
                            positions: {
                                ...widget.positions,
                                [devicePreview]: {
                                    ...widget.positions?.[devicePreview],
                                    hide: true,
                                    h: 0,
                                },
                            },
                        }
                    }
                    return widget
                }),
            }
        }

        setConfig(config)
        setDashboardLoading(false)
    }

    function configureMobileWidgets(config) {
        if (!isMobile) return config
        if (!config?.widgets?.length) return config

        // Helper: Insert 'widget' below the widget of type 'afterType' or at start if not found
        const insertBelow = (widgetsArr, afterType, widget, shouldInsert) => {
            if (!shouldInsert || !widget) return widgetsArr
            const idx = widgetsArr.findIndex(w => w.inline?.type === afterType)
            if (idx > -1) {
                widgetsArr.splice(idx + 1, 0, widget)
            } else {
                widgetsArr.unshift(widget)
            }
            return widgetsArr
        }

        const getHiddenBizElements = appOptions?.hideBusinessElements ?? []
        const getHidden360Views = appOptions?.hide360Views ?? []

        const businessElementsList = businessElements?.filter(el => !getHiddenBizElements.includes(el.text))
        const viewsList = _360views?.filter(el => !getHidden360Views.includes(el.text))
        const applicationList = modules && [...modules].sort((a, b) => a.title.localeCompare(b.title))

        // Build favoriteWidgets array from your provided 'favorites' code
        const favoriteWidgets = favorites
            ? favorites.reduce((acc, id) => {
                  if (configs[id]) acc.push(configs[id])
                  return acc
              }, [])
            : []
        const hasFavoritesData = favoriteWidgets.length > 0

        const hasBusinessElementsData = businessElementsList?.length > 0
        const has360ViewsData = viewsList?.length > 0
        const hasApplicationData = applicationList?.length > 0

        // Find default widgets from the mobile home page
        const defaultFavoritesWidget = defaultMobileHomePage?.widgets?.find(w => w.inline?.type === ChartType.FAVORITES)
        const defaultBusinessElementsWidget = defaultMobileHomePage?.widgets?.find(
            w => w.inline?.type === ChartType.BUSINESS_ELEMENTS
        )
        const defaultViewsWidget = defaultMobileHomePage?.widgets?.find(w => w.inline?.type === ChartType.VIEWS)
        const defaultApplicationWidget = defaultMobileHomePage?.widgets?.find(
            w => w.inline?.type === ChartType.APPLICATIONS
        )

        // Copy current widgets
        const widgets = config.widgets.map(w => {
            return {
                ...w,
                positions: { ...w.positions, small: { ...w.positions.small, static: false } },
            }
        })

        // Check if these widgets already exist
        const favoritesWidgetIndex = widgets.findIndex(w => w.inline?.type === ChartType.FAVORITES)
        const hasBusinessElementsWidget = widgets.some(w => w.inline?.type === ChartType.BUSINESS_ELEMENTS)
        const hasViewsWidget = widgets.some(w => w.inline?.type === ChartType.VIEWS)
        const hasApplicationWidget = widgets.some(w => w.inline?.type === ChartType.APPLICATIONS)

        // Insert Favorites at the top, if needed
        if (favoritesWidgetIndex > -1 && hasFavoritesData) {
            widgets.splice(favoritesWidgetIndex, 1)
            widgets.unshift(defaultFavoritesWidget)
        }

        // Insert Business Elements below Favorites, if needed
        insertBelow(
            widgets,
            ChartType.FAVORITES,
            defaultBusinessElementsWidget,
            !hasBusinessElementsWidget && hasBusinessElementsData
        )
        insertBelow(widgets, ChartType.BUSINESS_ELEMENTS, defaultViewsWidget, !hasViewsWidget && has360ViewsData)
        insertBelow(widgets, ChartType.VIEWS, defaultApplicationWidget, !hasApplicationWidget && hasApplicationData)

        // If not on mobile, remove widgets with ID = "mobile"
        if (!isMobile) {
            for (let i = widgets.length - 1; i >= 0; i--) {
                if (widgets[i].id === 'mobile') {
                    widgets.splice(i, 1)
                }
            }
        }
        const expandedWidgets = widgets?.reduce((acc, widget, index) => {
            if (widget?.id === 'mobile') {
                acc[index] = false
            }
            return acc
        }, {})
        setExpanded(expandedWidgets)

        return { ...config, widgets }
    }

    const layoutMode = useSelector(widgetSelectors.getCurrentLayout)
    const mainWidgets = visibleWidgets?.filter(w => !w.position?.top)
    const adjLayoutMode = editing ? 0 : layoutMode
    const { columnCount, rowCount } = layout || undefined
    const gridLayout = useMemo(
        () => makeGridLayout(mainWidgets, adjLayoutMode, { row: rowCount, column: columnCount }),
        [mainWidgets, adjLayoutMode, rowCount, columnCount]
    )
    function toggleExpandCollapseWidget(widgetKey, isSubmenu = false) {
        function calculateHeight(element, rowHeight) {
            if (!element) return isExtraSmall ? 2 : 6 // Default height when element is not available
            const contentHeight = element.offsetHeight + 48
            const calculatedRows = Math.ceil((contentHeight + gridGap) / (rowHeight + gridGap))
            return Math.max(calculatedRows, 1) // Ensure at least one row
        }

        setExpanded(prevExpandedState => {
            const isExpanded = isSubmenu ? prevExpandedState[widgetKey] : !prevExpandedState[widgetKey]
            const updatedExpandedState = {
                ...prevExpandedState,
                [widgetKey]: isExpanded,
            }

            const updatedGridLayout = gridLayout?.map(widget => {
                if (widget?.i?.toString() === widgetKey?.toString()) {
                    return {
                        ...widget,
                        h: isExpanded
                            ? calculateHeight(expandedWidgetRefs.current[widget.i], dashboardGridRowHeightRef.current)
                            : isExtraSmall
                            ? 2
                            : 6, // Collapsed height
                    }
                }
                return widget
            })

            updatePositionWidgetsForMobile(updatedGridLayout)

            return updatedExpandedState
        })
    }

    function updatePositionWidgetsForMobile(updated: Layout[]) {
        if (!isMobile) return
        const widgets = config?.widgets.map((w, index) => {
            const position = getPositionFromLayout(
                updated,
                w.id === 'mobile' ? index.toString() : w.id || index.toString()
            )
            if (!position) {
                return w
            }

            const { hide, shrink, top } =
                w.positions?.[isExtraSmall ? DashboardDevice.EXTRASMALL : DashboardDevice.SMALL] || {}
            const otherProps = { ...(hide && { hide }), ...(shrink && { shrink }), ...(top && { top }) }
            return {
                ...w,
                positions: {
                    ...w.positions,
                    [isExtraSmall ? DashboardDevice.EXTRASMALL : DashboardDevice.SMALL]: { ...otherProps, ...position },
                },
            }
        })

        if (isEqual(widgets, config?.widgets)) return
        return updateConfig({ widgets })
    }

    useEffect(() => {
        if (!_config) return

        setDirty(false)
        checkForEmptyRecommondations(_config)
    }, [_config, isMobile])

    const { filters = [], source: filterSource } = useFilters(config?.filters) || {}
    const id = config?.id

    async function updateConfig(body: Partial<Dashboard>, save?: boolean) {
        setDirty(true)
        const newConfig = { ...config, ...body }
        setConfig(newConfig)

        if (save) await handleSave(newConfig)
    }

    async function onEditDone(cancelChanges: boolean, saveHomeDashboardForAll = false) {
        setEditing(false)
        if (editing && devicePreview !== DashboardDevice.LARGE) setDevicePreview(DashboardDevice.LARGE)

        if (!dirty) return
        if (cancelChanges) return setConfig(_config)

        await handleSave(config, saveHomeDashboardForAll)
    }

    async function handleSave(config: Dashboard, saveHomeDashboardForAll = false) {
        try {
            let newConfig: Dashboard
            if (config.homePage && updateUserDashboard) {
                updateUserDashboard(config)
                if (saveHomeDashboardForAll && addAppLevelDashboard) addAppLevelDashboard(config)
                newConfig = config
            } else {
                newConfig = await updateDashboardOrCreateDraft(appName, id, config, viewFlag)
            }
            setConfig(newConfig)
            if (getWidgets) {
                const previousWidgetIds = _config.widgets?.map(w => w.id)
                const updateWidgetIds = config.widgets?.map(w => w.id)
                if (!isEqual(updateWidgetIds, previousWidgetIds)) getWidgets()
            }
        } catch (err) {
            console.error(err)
            showSnackbar(err?.message ? err.message : 'An error occurred saving collection', { variant: 'error' })
        }
    }

    async function positionWidgets(updated: Layout[]) {
        if (!editing) return

        const widgets = config?.widgets.map((w, index) => {
            const position = getPositionFromLayout(updated, w.id || index.toString())
            if (!position) {
                return w
            }

            const { hide, shrink, top } = w.positions?.[devicePreview] || {}
            const otherProps = { ...(hide && { hide }), ...(shrink && { shrink }), ...(top && { top }) }
            return { ...w, positions: { ...w.positions, [devicePreview]: { ...otherProps, ...position } } }
        })

        if (isEqual(widgets, config?.widgets)) return

        return updateConfig({ widgets })
    }

    const toggleShowExistingWidgetsDialog = useCallback(() => setShowExistingWidgetsDialog(s => !s), [])

    async function updateFilters(filters: DashboardNodeFilter[], method: FilterApplyMethod) {
        const withValues = filters.filter(filterHasValue)

        switch (method) {
            case 'apply-only':
                dispatch(filterCreators.setBuilderFilters('dashboard', withValues))
                break
            case 'save':
                logEvent('PERSPECTIVE_FILTERS_SAVE_AS_DEFAULT')
                dispatch(filterCreators.saveDefaultPerspectiveFiltersForUser(withValues, id))
                break
            case 'save-for-all':
                logEvent('PERSPECTIVE_FILTERS_SAVE_AS_DEFAULT_ALL')
                const updatedFilters = config.filters.map(
                    f => withValues.find(updated => filtersMatch(updated, f)) ?? emptyDashboardFilterValues(f)
                )
                await updateConfig({ filters: updatedFilters })
                break
        }
    }

    return (
        <DashboardContext.Provider
            value={{
                actions,
                config,
                devicePreview,
                editable,
                editing,
                filters,
                filterSource,
                hiddenWidgets,
                layout,
                onEditDone,
                onEditStart: () => setEditing(true),
                positionWidgets,
                setDevicePreview,
                showExistingWidgetsDialog,
                toggleShowExistingWidgetsDialog,
                updateConfig,
                updateFilters,
                widgets: visibleWidgets,
                expandedWidgetRefs,
                dashboardGridRowHeightRef,
                expanded,
                toggleExpandCollapseWidget,
                dashboardLoading,
            }}
        >
            {children}
        </DashboardContext.Provider>
    )
}

function useFilters(dashboardFilters: DashboardNodeFilter[]) {
    const appliedFilters = useSelector(filterSelectors.getBuilderFilters) as DashboardNodeFilter[]
    const sessionFilters = appliedFilters.filter(f => f.source === 'dashboard')
    const userFilters: DashboardNodeFilter[] = useSelector(filterSelectors.getUserPerspectiveDefaults)

    if (!dashboardFilters?.length) return

    const emptyDashboardFilters = dashboardFilters.map(emptyDashboardFilterValues)

    function combine(usedFilters: DashboardNodeFilter[]) {
        return emptyDashboardFilters.map(empty => {
            return usedFilters.find(used => filtersMatch(used, empty)) ?? empty
        })
    }

    if (sessionFilters.length) return { filters: combine(sessionFilters), source: FilterSourceType.SESSION }
    if (!isEmpty(userFilters)) return { filters: combine(userFilters), source: FilterSourceType.USER_DEFAULTS }
    return {
        filters: dashboardFilters,
        source: dashboardFilters.some(filterHasValue) ? FilterSourceType.WIDGET_DEFAULTS : undefined,
    }
}

export { DashboardContext, DashboardProvider }
